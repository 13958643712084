import { createContext, useContext } from "react";
import { useActor, useSelector } from "@xstate/react";
import { State, EventObject, Sender, Interpreter } from "xstate";

export const AuthMachine = createContext({});

type Selector<IReturnType, IContext> = (state: State<IContext>) => IReturnType;

export function useAuthState<IEvent extends EventObject, IContext>(): [
  State<IContext>,
  Sender<IEvent>
] {
  const ctx = useContext(AuthMachine);
  if (ctx === undefined) {
    throw new Error(`must be used within a AuthMachine`);
  }
  return useActor(ctx as Interpreter<any, any, any>);
}

export function useAuthSelector<IReturnType, IContext>(
  selector: Selector<IReturnType, IContext>
) {
  const ctx = useContext(AuthMachine);

  if (ctx === undefined) {
    throw new Error(`must be used within a AuthMachine`);
  }
  return useSelector(ctx as Interpreter<any, any, any>, selector);
}
