import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { PageQuery } from "@marketing-site/api/urbana/contentful/queries";
import Footer from "@whopper/footer";
import { SEO } from "@marketing-site/components/_contentful";
import { HeroCareers } from "@marketing-site/components/_contentful/hero/HeroCareers";
import { IHeroProductAlias } from "@marketing-site/components/_contentful/hero/HeroProduct";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { IContentChild } from "@marketing-site/types/shared.model";
import { mapComponents } from "@marketing-site/utils";
import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import styled from "styled-components";
import { LoadingPage } from "../Page/LoadingPage";

export interface ICareersProps {
  locale?: string;
  "*"?: string;
}

export const Careers = (): any => {
  const pageData: ICareersProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const suffix = pageData["*"];
  const slug = "careers" + (suffix ? `/${suffix}` : "");

  const {
    data: contentfulData,
    error: contentfulError,
    isLoading: contentfulLoading,
  } = useFetchContentQuery({
    query: PageQuery(slug, locale),
    space: "hopperCom",
  });

  const getBody = () => {
    if (contentfulLoading || !contentfulData) {
      return <LoadingPage />;
    } else {
      // @ts-ignore: Need to figure out types for responses coming from contentful
      const page = contentfulData;
      const children = page.contentCollection.items.map(
        (child: IContentChild<any>) => child
      );

      const careersPageMap = {
        HeroProduct: (props: React.PropsWithChildren<IHeroProductAlias>) => {
          return <HeroCareers {...props} />;
        },
      };

      const childComponents = mapComponents(children, slug, careersPageMap);
      return (
        <>
          <SEO {...page.seo} locale={locale} canonical={path} />
          <NavigationBar contentfulNavProps={page.navigation} />
          <Body>
            {childComponents.map((component, i) => {
              return <Fragment key={i}>{component}</Fragment>;
            })}
          </Body>
          <Footer {...page.navigation.footer} />
        </>
      );
    }
  };

  return <React.Fragment>{getBody()}</React.Fragment>;
};

const Body = styled.div`
  position: relative;
  z-index: 10;
`;
