import { CardList } from '@marketing-site/components/card';
import {
  IFeatureCard,
  IGenericSection,
} from '@marketing-site/types/shared.model';
import React from 'react';
import styled from 'styled-components';

export const LeaderCardsSection = (props: IGenericSection<IFeatureCard>) => {
  return (
    <Wrapper id="leader-cards-section">
      <CardList cards={props.body.items} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 5rem 0;
`;
