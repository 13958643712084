import { ProtectedRoute, useAuthProvider } from "@hopper-b2b/hopper-auth";
import { getLang } from "@hopper-b2b/i18n";
import {
  PATH_410,
  PATH_ABOUT,
  PATH_AFFILIATES,
  PATH_CAREERS,
  PATH_CULTURE,
  PATH_DOWNLOAD,
  PATH_GIFT_CARD,
  PATH_HOME,
  PATH_JOBS,
  PATH_LEGAL,
  PATH_LOCALE,
  PATH_NOT_AVAILABLE_YET,
  PATH_REFER,
  PRODUCT_PAGES,
  TRAVEL_PAGES,
} from "@marketing-site/utils/urlPaths";
import React, { PropsWithChildren, Suspense, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom-v5-compat";
import {
  AboutPage,
  Careers,
  Culture,
  DealDrops,
  Download,
  Legal,
  LoadingPage,
  Product,
} from "./pages";
import { PageGone } from "./pages/410/410Page";
import { Affiliates } from "./pages/Affiliates/AffiliatesPage";
import { JobsPage } from "./pages/Careers/jobs/JobsPage";
import { NotAvailableYet } from "./pages/Download/NotAvailableYetPage";
import { GiftCardPage } from "./pages/GiftCard/GiftCardPage";
import { ReferralPage } from "./pages/Referral/ReferralPage";
import { EventActionName } from "./types";
import { getLocaleFromPathname } from "./utils/helpers";
import { useTrackMarketingSiteEvent } from "./utils/hooks";
import { LocaleContext } from "./utils/hooks/localeContext";
import { logPageView } from "./utils/segmentAnalyticsHelpers";
import { FeatureFlagsContext } from "@hopper-b2b/utilities";
import { useExperiment } from "@hopper-b2b/experiments";

declare global {
  interface Window {
    analytics: any;
  }
}

export function App() {
  const location = useLocation();
  const locale = getLocaleFromPathname(location.pathname);
  const enableRewardsBanner = useExperiment("WebSignInPrompts");

  const trackEvent = useTrackMarketingSiteEvent();

  useEffect(() => {
    trackEvent({ eventName: EventActionName.ViewedPage });
    // TODO - Replace with gtag(...)
    // https://hopper-jira.atlassian.net/browse/HDC-1322
    logPageView(locale);
  }, [location, locale, trackEvent]);

  const featureFlags = {
    enableRewardsBanner,
  };

  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      <LocaleContext.Provider value={locale}>
        <ProtectedRouteWrapper>
          <Routes>
            <Route path={"/"} element={<DealDrops />} />
            <Route path={PATH_LOCALE + PATH_HOME} element={<DealDrops />} />

            <Route path={PATH_ABOUT} element={<AboutPage />} />
            <Route path={PATH_LOCALE + PATH_ABOUT} element={<AboutPage />} />

            <Route path={PATH_DOWNLOAD} element={<Download />} />
            <Route path={PATH_LOCALE + PATH_DOWNLOAD} element={<Download />} />

            <Route
              path={PATH_NOT_AVAILABLE_YET}
              element={<NotAvailableYet />}
            />
            <Route
              path={PATH_LOCALE + PATH_NOT_AVAILABLE_YET}
              element={<NotAvailableYet />}
            />

            <Route path={PATH_CAREERS} element={<Careers />} />
            <Route path={PATH_LOCALE + PATH_CAREERS} element={<Careers />} />

            <Route path={PATH_JOBS} element={<JobsPage />} />
            <Route path={PATH_LOCALE + PATH_JOBS} element={<JobsPage />} />

            <Route path={PATH_CULTURE} element={<Culture />} />
            <Route path={PATH_LOCALE + PATH_CULTURE} element={<Culture />} />

            <Route path={PRODUCT_PAGES} element={<Product />} />
            <Route path={PATH_LOCALE + PRODUCT_PAGES} element={<Product />} />

            <Route path={TRAVEL_PAGES} element={<Product />} />
            <Route path={PATH_LOCALE + TRAVEL_PAGES} element={<Product />} />

            <Route path={PATH_LEGAL} element={<Legal />} />
            <Route path={PATH_LOCALE + PATH_LEGAL} element={<Legal />} />

            <Route path={PATH_AFFILIATES} element={<Affiliates />} />
            <Route
              path={PATH_LOCALE + PATH_AFFILIATES}
              element={<Affiliates />}
            />

            <Route path={PATH_REFER} element={<ReferralPage />} />
            <Route path={PATH_LOCALE + PATH_REFER} element={<ReferralPage />} />

            <Route path={PATH_GIFT_CARD} element={<GiftCardPage />} />
            <Route
              path={PATH_LOCALE + PATH_GIFT_CARD}
              element={<GiftCardPage />}
            />

            <Route path={PATH_410} element={<PageGone />} />

            <Route path={"*"} element={<Navigate to="/" />} />
          </Routes>
        </ProtectedRouteWrapper>
      </LocaleContext.Provider>
    </FeatureFlagsContext.Provider>
  );
}

export default App;

const ProtectedRouteWrapper = ({ children }: PropsWithChildren) => {
  const locale = getLang();

  const { state } = useAuthProvider();
  return (
    <ProtectedRoute locale={locale} sessionInfo={state?.sessionInfo}>
      <Suspense fallback={<LoadingPage />}>{children}</Suspense>
    </ProtectedRoute>
  );
};
