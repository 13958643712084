import React from 'react';

const Medium = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.375 11.25C8.375 11 8.25 10.75 8.125 10.625L6.25 8.375V8H12L16.5 17.875L20.5 8H26V8.375L24.375 9.875C24.25 10 24.125 10.125 24.25 10.375V21.625C24.25 21.75 24.25 22 24.375 22.125L25.875 23.625V24H18V23.625L19.625 22C19.75 21.875 19.75 21.75 19.75 21.5V12.375L15.25 23.75H14.75L9.5 12.375V20C9.5 20.375 9.625 20.625 9.75 20.875L11.875 23.375V23.75H6V23.375L8.125 20.875C8.375 20.625 8.5 20.375 8.375 20V11.25Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Medium;
