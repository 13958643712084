import { PATH_HOME, PATH_PROFILE, PATH_TRIPS } from "@hopper-b2b/hopper-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Link, Menu, MenuItem } from "@mui/material";

export type UserMenuProps = {
  anchorEl?: any;
  onClose?: () => void;
  onLogout: () => void;
};

export const UserMenu = ({ anchorEl, onClose, onLogout }: UserMenuProps) => {
  const { t } = useI18nContext();

  return (
    <Menu keepMounted anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      <MenuItem
        component={Link}
        href={`${PATH_HOME}${PATH_PROFILE}`}
        color="textSecondary"
      >
        {t?.("myProfile")}
      </MenuItem>
      <MenuItem
        component={Link}
        href={`${PATH_HOME}${PATH_TRIPS}`}
        color="textSecondary"
      >
        {t?.("myTrips")}
      </MenuItem>
      <MenuItem>
        <Link color="textSecondary" onClick={onLogout} underline="hover">
          {t?.("signOut")}
        </Link>
      </MenuItem>
    </Menu>
  );
};
