import { HeaderNavigationMenuItemType } from "@hopper-b2b/hopper-utils";
import {
  Link,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow_right.svg";
import { ReactComponent as CancelForAnyReasonIcon } from "../../../assets/icons/products/cancelForAnyReason.svg";
import { ReactComponent as CarrotCashIcon } from "../../../assets/icons/products/carrotCash.svg";
import { ReactComponent as ChangeForAnyReasonIcon } from "../../../assets/icons/products/changeforAnyReason.svg";
import { ReactComponent as FlightDisruptionGuaranteeIcon } from "../../../assets/icons/products/flightDisruptionGuarantee.svg";
import { ReactComponent as LeaveForAnyReasonIcon } from "../../../assets/icons/products/leaveForAnyReason.svg";
import { ReactComponent as PriceFreezeIcon } from "../../../assets/icons/products/priceFreeze.svg";
import { ReactComponent as PricePredictionIcon } from "../../../assets/icons/products/pricePrediction.svg";
import { ReactComponent as VipSupportIcon } from "../../../assets/icons/products/vipSupport.svg";
import { ReactComponent as CarsIcon } from "../../../assets/icons/travel/cars.svg";
import { ReactComponent as FlightsIcon } from "../../../assets/icons/travel/flights.svg";
import { ReactComponent as HomesIcon } from "../../../assets/icons/travel/homes.svg";
import { ReactComponent as HotelsIcon } from "../../../assets/icons/travel/hotels.svg";
import styles from "./NavigationMenuItem.module.scss";

export const iconMap = {
  // Products
  carrotCash: CarrotCashIcon,
  pricePrediction: PricePredictionIcon,
  priceFreeze: PriceFreezeIcon,
  cancelForAnyReason: CancelForAnyReasonIcon,
  changeForAnyReason: ChangeForAnyReasonIcon,
  leaveForAnyReason: LeaveForAnyReasonIcon,
  flightDisruptionGuarantee: FlightDisruptionGuaranteeIcon,
  vipSupport: VipSupportIcon,
  // Travel Verticals
  flights: FlightsIcon,
  hotels: HotelsIcon,
  cars: CarsIcon,
  homes: HomesIcon,
};

export const NavigationMenuItem = ({
  text,
  icon,
  link,
}: HeaderNavigationMenuItemType) => {
  const IconComponent = icon && typeof icon === "string" ? iconMap[icon] : null;

  return (
    <MenuItem component={Link} href={link} color="textPrimary">
      {IconComponent && (
        <ListItemIcon color="primary">
          <IconComponent className={styles.icon} />
        </ListItemIcon>
      )}
      <ListItemText inset={!icon} primary={text} className={styles.text} />
      <ListItemSecondaryAction>
        <ArrowRight />
      </ListItemSecondaryAction>
    </MenuItem>
  );
};
