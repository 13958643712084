import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { AboutPageQuery } from "@marketing-site/api/urbana/contentful/queries/about.query";
import { StarRatings } from "@marketing-site/components";
import { SEO } from "@marketing-site/components/_contentful";
import { HeroAbout } from "@marketing-site/components/_contentful/hero/HeroAbout";
import { IHeroProductAlias } from "@marketing-site/components/_contentful/hero/HeroProduct";
import { FeaturedCompaniesSection } from "@marketing-site/components/generic-section";
import { mapComponents } from "@marketing-site/utils";
import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import styled from "styled-components";
import { LoadingPage } from "../Page/LoadingPage";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import Footer from "@whopper/footer";


export type IAboutPageProps = {
  locale?: string;
};

export const AboutPage = () => {
  const pageData: IAboutPageProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const slug = "about";
  const {
    data: contentfulData,
    error: contentfulError,
    isLoading: contentfulLoading,
  } = useFetchContentQuery({
    query: AboutPageQuery(slug, locale),
    space: "hopperCom",
  });

  const getBody = () => {
    if (contentfulLoading || !contentfulData) {
      return <LoadingPage />;
    } else {
      const page = contentfulData;
      const children = page.contentCollection.items;
      const aboutPageMap = {
        HeroProduct: (props: React.PropsWithChildren<IHeroProductAlias>) => {
          return (
            <HeroAbout {...props}>
              <StarRatings
                label={
                  children.find(
                    ({ title }: { title: string }) =>
                      title === "Star Ratings Text"
                  ).richText.json
                }
              />
            </HeroAbout>
          );
        },
        GenericSection: FeaturedCompaniesSection,
      };
      const childComponents = mapComponents(children, "about", aboutPageMap);
      return (
        <>
          <SEO {...page.seo} locale={locale} canonical={path} />
          <NavigationBar contentfulNavProps={page.navigation} />
          <Body>
            {childComponents.map((component, i) => {
              return <Fragment key={i}>{component}</Fragment>;
            })}
          </Body>
          <Footer {...page.navigation.footer} />
        </>
      );
    }
  };

  return <Fragment>{getBody()}</Fragment>;
};

const Body = styled.div`
  position: relative;
  z-index: 10;
`;
