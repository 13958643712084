export enum ActionName {
  SendCode = "SEND_CODE",
  ResendCode = "RESEND_CODE",
  SendSMS = "SEND_SMS",
  SendEmail = "SEND_EMAIL",
  VerifyPayment = "VERIFY_PAYMENT",
  ScheduleQuote = "SCHEDULE_QUOTE",
  ScheduleFulfill = "SCHEDULE_FULFILL",
  MyTrip = "MY_TRIP",
  // excluding ShopSummary from recaptcha assessment until further vetting
  // ShopSummary = "SHOP_SUMMARY",
}

export const recaptchaEndpoints = new Map([
  ["/api/v0/auth/code/send", ActionName.SendCode],
  ["/api/v0/auth/code/resend", ActionName.ResendCode],
  ["/api/v0/paymentMethods/verify", ActionName.VerifyPayment],
  ["/api/v1/purchase/quote/schedule", ActionName.ScheduleQuote],
  ["/api/v1/purchase/fulfill/schedule", ActionName.ScheduleFulfill],
  ["/api/v0/myTrip/accessToken", ActionName.MyTrip],
  // ["/api/v4/shopSummary", ActionName.ShopSummary],
]);

export const getRecaptchaToken = async (
  recaptchaActionKey: string,
  actionName: string
): Promise<string> => {
  return await window.grecaptcha.enterprise.execute(recaptchaActionKey, {
    action: actionName,
  });
};
