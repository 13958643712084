import React from 'react';

const Facebook = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.315 8.9578C20.6917 8.83314 19.8498 8.74 19.3204 8.74C17.8867 8.74 17.7936 9.36333 17.7936 10.3607V12.1361H21.3774L21.065 15.8137H17.7936V27H13.3063V15.8137H11V12.1361H13.3063V9.86127C13.3063 6.74533 14.7708 5 18.4477 5C19.7252 5 20.6602 5.187 21.8753 5.43633L21.315 8.9578Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Facebook;
