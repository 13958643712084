import clsx from "clsx";
import { Box, Typography } from "@mui/material";
import { formatDateTime, removeTimezone } from "@hopper-b2b/utilities";
import { AlgomerchTag, TagType } from "@hopper-b2b/types";
import { AirlineIcon } from "../AirlineIcon";
import { Icon, IconName } from "../Icon";
import "./styles.scss";
import { AlgomerchTags } from "../AlgomerchTags";

type ITags = {
  value: AlgomerchTag;
  type: TagType;
}[];

export interface IFlightShopRowProps {
  airlineCode: string;
  airlineName: string;
  arrivalTime: string;
  className?: string;
  currentPriceText: string;
  rewardsPriceText?: string;
  departureTime: string;
  destinationCode: string;
  duration: string;
  layoverString: string;
  onClickTag?: (label: string) => void;
  originCode: string;
  tags: ITags;
  tagsLimit?: number;
  onClick?: () => void;
  additionalAirlinesCount?: number;
  plusDays: number;
}

export const FlightShopRow = (props: IFlightShopRowProps) => {
  const {
    airlineCode,
    airlineName,
    arrivalTime,
    className,
    currentPriceText,
    rewardsPriceText,
    departureTime,
    destinationCode,
    duration,
    layoverString,
    onClickTag,
    originCode,
    tags,
    tagsLimit,
    onClick,
  } = props;

  return (
    <Box className={clsx("flight-shop-row", className)} onClick={onClick}>
      <Box className={clsx("flight-shop-row-column", "airline-icon")}>
        <AirlineIcon airlineCode={airlineCode} altText={airlineName} />
      </Box>
      <Box className={clsx("flight-shop-row-column", "flight-times")}>
        <Box className="flight-time-info">
          <Typography variant="body1">
            {formatDateTime(removeTimezone(departureTime), "h:mm A")}
          </Typography>
          <Box className="flight-time-icon">
            <Icon name={IconName.B2BAirplaneIcon} ariaLabel="" />
            <Box className="flight-line" />
            <Box className="flight-line-circle" />
          </Box>
          <Typography variant="body1">
            {formatDateTime(removeTimezone(arrivalTime), "h:mm A")}
          </Typography>
        </Box>
      </Box>
      <Box className={clsx("flight-shop-row-column", "flight-details")}>
        <Box>
          <Typography variant="body1">{airlineName}</Typography>
        </Box>
        {destinationCode ? (
          <Box>
            <Typography variant="body2">
              {originCode} - {destinationCode}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2">{originCode}</Typography>
          </Box>
        )}
      </Box>
      <Box className={clsx("flight-shop-row-column", "duration")}>
        <Typography variant="body1">{duration}</Typography>
        <Box>
          <Typography variant="body2">{layoverString}</Typography>
        </Box>
      </Box>
      <Box className={clsx("flight-shop-row-column", "badges")}>
        <AlgomerchTags
          tags={tags}
          tagsLimit={tagsLimit}
          onClickTag={onClickTag}
        />
      </Box>
      <Box className={clsx("flight-shop-row-column", "price")}>
        <Typography variant="body1" className="price-value">
          {currentPriceText}
        </Typography>
        {rewardsPriceText && (
          <Typography variant="body2" className="rewards-value">
            {`/ ${rewardsPriceText}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
