import {
  VerifiedAuthMethod,
  VerifiedAuthMethodEnum,
  VerifiedEmail,
  VerifiedPhone,
} from "@b2bportal/auth-api";
import { trackEvent } from "@hopper-b2b/api";
import { getChildState } from "@hopper-b2b/checkout";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BTextField } from "@hopper-b2b/ui";
import { useState } from "react";
import {
  AuthEvents,
  AuthMachineEventType,
  getAuthType,
  getEmail,
  getFormattedPhoneNumber,
  getMatchingUserMatchCondition,
  getMatchingUserVerifiedAuthMethods,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import {
  AuthTrackingEvents,
  AuthType,
  MergeChoseLoginEvent,
} from "../../../../types/tracking";
import "./styles.scss";

export const MergeAccountModal = () => {
  const [state] = useAuthState<AuthEvents, unknown>();

  const childState = getChildState(state.value);

  return childState === "idle" ? <ConfirmMergeModal /> : <VerifyModal />;
};

const ConfirmMergeModal = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();
  const authType = useAuthSelector(getAuthType);
  // Email
  // Phone
  const matchCondition = useAuthSelector(getMatchingUserMatchCondition);
  const phoneNumber = useAuthSelector(getFormattedPhoneNumber);
  const email = useAuthSelector(getEmail);
  // VerifiedEmail
  // VerifiedPhone
  // VerifiedSso
  const verifiedAuthMethods = useAuthSelector(
    getMatchingUserVerifiedAuthMethods
  );

  const onContinue = () => {
    trackEvent({
      eventName: AuthTrackingEvents.MergeChoseLogin,
      properties: {
        auth_type: authType,
      },
    } as MergeChoseLoginEvent);
    send(AuthMachineEventType.SEND_CODE);
  };

  const subtitle =
    matchCondition === "Email"
      ? t("loginMatchingEmailSubheader", {
          redactedEmail: getEmailFromVerifiedMethods(verifiedAuthMethods),
        })
      : t("loginMatchingPhoneSubheader", {
          redactedPhone: getPhoneFromVerifiedMethods(verifiedAuthMethods),
        });

  const primaryButtonLabel =
    matchCondition === "Email"
      ? t("continueAddingEmail")
      : t("continueAddingPhone");

  const description =
    matchCondition === "Email"
      ? phoneNumber
        ? t("mergeModalPhoneDescription", { phoneNumber })
        : ""
      : email
      ? t("mergeEmailEmailDescription", { email })
      : "";

  return (
    <>
      <div className="title-container">
        <h1 className="title">{t("mergeAccountTitle")}</h1>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div className="merge-account-container">
        <ActionButton
          onClick={onContinue}
          message={primaryButtonLabel}
          className="continue-button"
        />
        {description ? (
          <p className="merge-description">{description}</p>
        ) : null}
      </div>
    </>
  );
};

const VerifyModal = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();
  const [code, setCode] = useState<string>("");
  const matchCondition = useAuthSelector(getMatchingUserMatchCondition);

  const verifiedAuthMethods = useAuthSelector(
    getMatchingUserVerifiedAuthMethods
  );
  const onContinue = () => {
    send({
      type: AuthMachineEventType.VERIFY_CODE,
      code,
      auth_type: matchCondition === "Phone" ? AuthType.Phone : AuthType.Email,
    });
  };

  // const resendCode = () => {
  //   send(AuthMachineEventType.RESEND);
  // };

  const title =
    matchCondition === "Phone" ? t("verifyPhoneTitle") : t("verifyEmailTitle");

  const subtitle = t("verifyCodeSubtitle", {
    emailOrPhone:
      matchCondition === "Phone"
        ? getPhoneFromVerifiedMethods(verifiedAuthMethods)
        : getEmailFromVerifiedMethods(verifiedAuthMethods),
  });

  return (
    <>
      <div className="title-container">
        <h1 className="title">{title}</h1>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div className="merge-verify-container">
        <B2BTextField
          onChange={(newCode: string) => setCode(newCode)}
          disabled={false}
          inputMode="numeric"
        />
        <ActionButton
          onClick={onContinue}
          message={t("verify")}
          className="continue-button"
        />
        {/* TODO: Validate resend section */}
        {/* <ResendSection onResend={resendCode} /> */}
      </div>
    </>
  );
};

const getEmailFromVerifiedMethods = (
  verifiedMethods?: VerifiedAuthMethod[]
) => {
  return (
    (
      verifiedMethods?.find(
        (method) =>
          method.VerifiedAuthMethod === VerifiedAuthMethodEnum.VerifiedEmail
      ) as VerifiedEmail
    )?.redactedEmail || ""
  );
};

const getPhoneFromVerifiedMethods = (
  verifiedMethods?: VerifiedAuthMethod[]
) => {
  return (
    (
      verifiedMethods?.find(
        (method) =>
          method.VerifiedAuthMethod === VerifiedAuthMethodEnum.VerifiedPhone
      ) as VerifiedPhone
    )?.redactedPhone || ""
  );
};
