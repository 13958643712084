import React from 'react';

const LinkedIn = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 26H6.25V12.625H10.5V26ZM8.375 10.75C7 10.75 6 9.75 6 8.375C6 7 7.125 6 8.375 6C9.75 6 10.75 7 10.75 8.375C10.75 9.75 9.75 10.75 8.375 10.75ZM26 26H21.75V18.75C21.75 16.625 20.875 16 19.625 16C18.375 16 17.125 17 17.125 18.875V26H12.875V12.625H16.875V14.5C17.25 13.625 18.75 12.25 20.875 12.25C23.25 12.25 25.75 13.625 25.75 17.75V26H26Z"
        fill="#505050"
      />
    </svg>
  );
};

export default LinkedIn;
