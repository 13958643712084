"use client";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  GuestsSelection,
  IPassengerCounts,
  SEARCH_TRAVELERS_UPDATED,
} from "@hopper-b2b/types";

import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { ActionButton } from "../ActionButton";
import { ActionLink } from "../ActionLink";
import "./styles.scss";

export const MINIMUM_ADULTS_COUNT = 1;
export const MAXIMUM_COUNT = 6;

const MAX_CHILD_AGE = 17;
const DEFAULT_CHILD_AGE = MAX_CHILD_AGE;

export type PassengerCountPickerType = IPassengerCounts | GuestsSelection;

const isPassengerCounts = (counts: PassengerCountPickerType) => {
  if ("adultsCount" in counts) {
    return true;
  } else {
    return false;
  }
};

const getAdultsCount = (counts: PassengerCountPickerType) => {
  // TODO: fix compiler issue where using isPassengerCounts function does not help resolving counts as type of IPassengerCounts
  if ("adultsCount" in counts) {
    return counts.adultsCount;
  } else {
    return counts.adults;
  }
};

const getChildrenCount = (counts: PassengerCountPickerType) => {
  if ("childrenCount" in counts) {
    return counts.childrenCount;
  } else {
    return counts.children.length;
  }
};

const getInfantsInSeatCount = (counts: PassengerCountPickerType) => {
  if ("infantsInSeatCount" in counts) {
    return counts.infantsInSeatCount;
  } else {
    return 0;
  }
};

const getPets = (counts: PassengerCountPickerType) => {
  if ("pets" in counts) {
    return counts.pets;
  } else {
    return 0;
  }
};

const getInfantsOnLapCount = (counts: PassengerCountPickerType) => {
  if ("infantsOnLapCount" in counts) {
    return counts.infantsOnLapCount;
  } else {
    return 0;
  }
};
const getRoomsCount = (counts: PassengerCountPickerType) => {
  if ("rooms" in counts) {
    return counts.rooms;
  } else {
    return 0;
  }
};
const getChildrenAges = (counts: PassengerCountPickerType) => {
  return "children" in counts ? counts.children ?? [] : [];
};

export interface IPassengerCountPickerProps {
  onClickApply?: (passengerCounts: IPassengerCounts) => void;
  onClickApplyGuest?: (passengerCounts: GuestsSelection) => void;
  setPassengerCounts: (passengerCounts: PassengerCountPickerType) => void;
  counts: PassengerCountPickerType;
  minimumCount?: number;
  maximumCount?: number;
  titles?: PassengerCountPickerTitles;
  warningMessage?: string;
  unlimited?: boolean;
  className?: string;
  applyButtonText?: string;
  showRooms?: boolean;
  hideChildrenSubtitle?: boolean;
  modalSubtitle?: string;
  captureChildrenAges?: boolean;
  showPets?: boolean;
}

export type PassengerCountPickerTitles = {
  modalTitle: string;
  modalSubtitle?: string;
  adultTitle: string;
  adultSubtitle: string;
  childrenTitle: string;
  childrenSubtitle: string;
  childrenAgeMessage?: string | null;
  childrenAgePlaceholderKey?: string;
  infantSeatTitle?: string;
  infantSeatSubtitle?: string;
  infantLapTitle?: string;
  infantLapSubtitle?: string;
  roomsTitle?: string;
  roomsSubtitle?: string;
  petsTitle?: string;
  petsTooltip?: string;
};

export const PassengerCountPicker = (props: IPassengerCountPickerProps) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const {
    onClickApply,
    onClickApplyGuest,
    setPassengerCounts,
    counts,
    titles = {
      modalTitle: t("passengerCount.modalTitle"),
      modalSubtitle: props.modalSubtitle || null,
      adultTitle: t("passengerCount.adultTitle"),
      adultSubtitle: "",
      childrenTitle: t("passengerCount.childrenTitle"),
      childrenSubtitle: props.hideChildrenSubtitle
        ? ""
        : t("passengerCount.childrenSubtitle"),
      childrenAgeMessage: t("passengerCount.childrenAgeMessage"),
      childrenAgePlaceholderKey: "passengerCount.childrenAgePlaceholder",
      infantLapTitle: t("passengerCount.infantLapTitle"),
      infantLapSubtitle: t("passengerCount.infantLapSubtitle"),
      infantSeatTitle: t("passengerCount.infantSeatTitle"),
      infantSeatSubtitle: t("passengerCount.infantSeatSubtitle"),
      roomsTitle: props.showRooms ? t("passengerCount.roomsTitle") : "",
      petsTitle: t("passengerCount.petsTitle"),
    },
    minimumCount = MINIMUM_ADULTS_COUNT,
    maximumCount = MAXIMUM_COUNT,
    warningMessage = t("passengerCount.warningMessage", {
      maximumCount: MAXIMUM_COUNT,
    }),
    unlimited,
    className,
    applyButtonText = t("passengerCount.apply"),
    captureChildrenAges = false,
    showPets = false,
  } = props;

  const [adultsCount, setAdultsCount] = useState(getAdultsCount(counts));
  const [childrenCount, setChildrenCount] = useState(getChildrenCount(counts));
  const [infantsInSeatCount, setInfantsInSeatCount] = useState(
    getInfantsInSeatCount(counts)
  );
  const [infantsOnLapCount, setInfantsOnLapCount] = useState(
    getInfantsOnLapCount(counts)
  );
  const [roomsCount, setRoomsCount] = useState(getRoomsCount(counts));
  const [childrenAges, setChildrenAges] = useState(getChildrenAges(counts));
  const [pets, setPets] = useState(getPets(counts));

  const maximumReached =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount >=
      maximumCount;
  const maximumExceeded =
    !unlimited &&
    adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount >
      maximumCount;
  const missingChildrenAges =
    captureChildrenAges &&
    (childrenAges.length < childrenCount ||
      childrenAges.some(
        (age) => !Number.isInteger(age) || age < 0 || age > MAX_CHILD_AGE
      ));

  const applyDisabled = maximumExceeded || missingChildrenAges;

  const handleApplyClick = useCallback(() => {
    if ("adultsCount" in counts) {
      setPassengerCounts &&
        setPassengerCounts({
          adultsCount,
          childrenCount,
          infantsInSeatCount,
          infantsOnLapCount,
        });
    } else {
      setPassengerCounts &&
        setPassengerCounts({
          adults: adultsCount,
          children: captureChildrenAges
            ? childrenAges.slice(0, childrenCount)
            : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
          rooms: roomsCount,
          pets: pets,
        });
    }

    trackEvent({ eventName: SEARCH_TRAVELERS_UPDATED, properties: undefined });

    onClickApply &&
      onClickApply({
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
      });

    onClickApplyGuest &&
      onClickApplyGuest({
        adults: adultsCount,
        children: captureChildrenAges
          ? childrenAges.slice(0, childrenCount)
          : Array(childrenCount).fill(DEFAULT_CHILD_AGE),
        rooms: roomsCount,
        pets: pets,
      });
  }, [
    counts,
    onClickApply,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    onClickApplyGuest,
    captureChildrenAges,
    childrenAges,
    roomsCount,
    setPassengerCounts,
    pets,
  ]);

  return (
    <div className={clsx("passenger-count-picker-root", className)}>
      <div className="passenger-count-picker-container">
        <div
          id="passenger-count-picker"
          className="passenger-count-picker-title"
        >
          {titles.modalTitle}
          {titles.modalSubtitle ? (
            <p className="passenger-count-picker-subtitle">
              {titles.modalSubtitle}
            </p>
          ) : null}
        </div>
        <Box mt={3} className="passenger-count-picker-types-section">
          {renderCountPick({
            title: titles.adultTitle,
            subtitle: titles.adultSubtitle,
            className: "adults",
            minimumCount,
            maximumReached,
            count: adultsCount,
            setCount: setAdultsCount,
          })}
          {renderCountPick({
            title: titles.childrenTitle,
            subtitle: titles.childrenSubtitle,
            className: "children",
            maximumReached,
            count: childrenCount,
            setCount: setChildrenCount,
          })}
          {captureChildrenAges ? (
            <ChildrenAgePickers
              childCount={childrenCount}
              message={titles.childrenAgeMessage}
              placeholderKey={titles.childrenAgePlaceholderKey}
              childrenAges={childrenAges}
              onChildAgesChange={setChildrenAges}
            />
          ) : null}
          {titles.roomsTitle &&
            renderCountPick({
              title: titles.roomsTitle,
              subtitle: titles.roomsSubtitle,
              className: "rooms",
              minimumCount,
              count: roomsCount,
              setCount: setRoomsCount,
            })}
          {showPets &&
            titles.petsTitle &&
            renderCheckbox({
              title: titles.petsTitle,
              subtitle: titles.petsTooltip,
              className: "pets-check-box",
              checked: pets > 0,
              onCheckBox: setPets,
            })}
          {isPassengerCounts(counts) &&
            titles.infantSeatTitle &&
            renderCountPick({
              title: titles.infantSeatTitle,
              subtitle: titles.infantSeatSubtitle,
              className: "infants-in-seat",
              count: infantsInSeatCount,
              setCount: setInfantsInSeatCount,
              maximumReached,
            })}
          {isPassengerCounts(counts) &&
            titles.infantLapTitle &&
            renderCountPick({
              title: titles.infantLapTitle,
              subtitle: titles.infantLapSubtitle,
              className: "infants-on-lap",
              count: infantsOnLapCount,
              setCount: setInfantsOnLapCount,
              maximumReached,
            })}
        </Box>
        <div className="passenger-count-button-container">
          {!unlimited && !props.modalSubtitle && warningMessage ? (
            <div className="passenger-count-picker-warning-message">
              {warningMessage}
            </div>
          ) : null}
          {
            <ActionButton
              size="medium"
              fullWidth={matchesMobile}
              onClick={handleApplyClick}
              disabled={applyDisabled}
              message={applyButtonText}
            />
          }
        </div>
      </div>
    </div>
  );
};

const renderCheckbox = (props: {
  title: string;
  subtitle?: string;
  className: string;
  checked: boolean;
  onCheckBox: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { title, subtitle, className, checked, onCheckBox } = props;
  return (
    <div className={clsx("passenger-count-picker-type", className)}>
      <div className="passenger-type-description-container">
        <div className="passenger-type-description">
          <div className="passenger-type-title">{title}</div>
          {subtitle && (
            <div className="passenger-type-subtitle">{subtitle}</div>
          )}
        </div>
      </div>
      <div className="passenger-count-picker-container">
        <div className="passenger-count-number">
          <Checkbox
            checked={checked}
            onChange={(e) => onCheckBox(e.target.checked ? 1 : 0)}
          />
        </div>
      </div>
    </div>
  );
};

const renderCountPick = (props: {
  title: string;
  subtitle?: string;
  className: string;
  minimumCount?: number;
  maximumReached?: boolean;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {
    title,
    subtitle,
    className,
    minimumCount,
    count,
    setCount,
    maximumReached = false,
  } = props;

  const minusDisabled = count <= (minimumCount ?? 0);

  return (
    <div className={clsx("passenger-count-picker-type", className)}>
      <div className="passenger-type-description-container">
        <div className="passenger-type-description">
          <div className="passenger-type-title">{title}</div>
          {subtitle && (
            <div className="passenger-type-subtitle">{subtitle}</div>
          )}
        </div>
      </div>
      <div className="passenger-count-picker-container">
        <ActionLink
          label={`Decrease ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "minus")}
          disabled={minusDisabled}
          onClick={() => {
            if (!minusDisabled) {
              setCount((prevCount) => prevCount - 1);
            }
          }}
          content={
            <div
              className={clsx("button-content-wrapper", {
                disabled: minusDisabled,
              })}
            >
              <FontAwesomeIcon
                icon={faMinus as IconProp}
                className={clsx("icon", "minus", minusDisabled && "disabled")}
              />
            </div>
          }
        />
        <div className="passenger-count-number-container">
          <div className="passenger-count-number">
            {count}
            <span
              className="passenger-count-alert"
              aria-live="polite"
              aria-atomic={true}
            >
              {subtitle ? `${count} ${title} ${subtitle}` : `${count} ${title}`}
            </span>
          </div>
        </div>
        <ActionLink
          label={`Increase ${title} ${subtitle || ""} Traveler`}
          className={clsx("passenger-count-picker-button", "plus")}
          onClick={() => setCount((prevCount) => prevCount + 1)}
          disabled={maximumReached}
          content={
            <div
              className={clsx("button-content-wrapper", {
                disabled: maximumReached,
              })}
            >
              {" "}
              <FontAwesomeIcon
                icon={faPlus as IconProp}
                className={clsx("icon", "plus")}
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

const ChildrenAgePickers = ({
  message,
  placeholderKey,
  childCount,
  childrenAges,
  onChildAgesChange,
}: {
  message: string;
  placeholderKey?: string;
  childCount: number;
  childrenAges: number[];
  onChildAgesChange: (childrenAges: number[]) => void;
}) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const captureChildAge = useCallback(
    (childIndex: number) => (event: SelectChangeEvent) => {
      const newChildrenAges = [...childrenAges];
      newChildrenAges[childIndex] = parseInt(event.target.value as string);
      onChildAgesChange(newChildrenAges);
    },
    [childrenAges, onChildAgesChange]
  );
  if (childCount <= 0) return null;
  return (
    <div className="passenger-count-picker-child-age-container">
      <div className="passenger-count-picker-warning-message">{message}</div>
      <div className="passenger-count-picker-child-age-picker-container">
        {Array.from({ length: childCount }).map((_, childIndex) => (
          <FormControl
            variant="outlined"
            className="passenger-count-picker-child-age-picker"
            key={childIndex}
          >
            <Select
              id="demo-simple-select-outlined"
              value={childrenAges[childIndex]?.toString() ?? ""}
              displayEmpty
              onChange={captureChildAge(childIndex)}
              native={matchesMobile}
            >
              {placeholderKey ? (
                matchesMobile ? (
                  <option key="-" value="" disabled>
                    {t(placeholderKey, { index: childIndex + 1 })}
                  </option>
                ) : (
                  <MenuItem key="-" value="" disabled>
                    {t(placeholderKey, { index: childIndex + 1 })}
                  </MenuItem>
                )
              ) : null}
              {Array.from({ length: MAX_CHILD_AGE + 1 }).map((_, ageIndex) =>
                matchesMobile ? (
                  <option key={ageIndex} value={ageIndex}>
                    {ageIndex}
                  </option>
                ) : (
                  <MenuItem key={ageIndex} value={ageIndex}>
                    {ageIndex}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        ))}
      </div>
    </div>
  );
};
