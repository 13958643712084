import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useCallback, useEffect } from "react";

import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IPassengerCounts,
  TripCategory,
} from "@hopper-b2b/types";
import {
  ActionLink,
  IconComponent,
  IconName,
  MobilePopoverCard,
} from "@hopper-b2b/ui";

import "./styles.scss";
import { TravelerPicker } from "../TravelerPicker";
import { TripCategoryPicker } from "../TripCategoryPicker";
import { Suggestion } from "@b2bportal/air-shopping-api";
import { FlightLocationAutoComplete } from "../FlightLocationAutocomplete";
import { IIcons } from "../FlightSearch";
import { ActionButton } from "@hopper-b2b/ui-core";

export interface ILocationModalProps {
  hideCategoryBar?: boolean;
  open: boolean;
  onClose: () => void;
  origin: Suggestion | null;
  destination: Suggestion | null;
  setOrigin: (value: Suggestion | null) => void;
  setDestination: (value: Suggestion | null) => void;
  tripCategory: TripCategory;
  hidePassengerPicker?: boolean;
  hideTripCategoryToggle?: boolean;
  icons?: IIcons;
  setOpenLocationModal: (value: boolean) => void;
  setOpenCalendarModal: (value: boolean) => void;
  paxCount: IPassengerCounts;
  disabled?: boolean;
  updatePaxCount: (paxCount: IPassengerCounts) => void;
  updateTripCategory: (tripCategory: TripCategory) => void;
  handleOriginChange: (value: Suggestion | null) => void;
  handleDestinationChange: (value: Suggestion | null) => void;
  onOpen?: () => void;
  handleSearch: () => void;
  originCode?: string;
  destinationCode?: string;
}

export const LocationModal = ({
  tripCategory,
  open,
  onClose,
  setOpenLocationModal,
  setOpenCalendarModal,
  origin,
  destination,
  hidePassengerPicker,
  hideTripCategoryToggle,
  icons,
  paxCount,
  disabled,
  updatePaxCount,
  updateTripCategory,
  handleOriginChange,
  handleDestinationChange,
  onOpen,
  handleSearch,
  originCode,
  destinationCode,
}: ILocationModalProps) => {
  const { t } = useI18nContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGoBack = useCallback(() => {
    setOpenLocationModal(false);
  }, []);

  const onComplete = useCallback(() => {
    setOpenLocationModal(false);
    setOpenCalendarModal(true);
  }, [setOpenCalendarModal, setOpenLocationModal]);

  return (
    <MobilePopoverCard
      open={open}
      onClose={onClose}
      fullScreen
      className="location-modal-popover"
      contentClassName="location-modal-wrapper"
      paperClassName="hopper-location-modal-calendar-picker"
      topLeftButton={
        <ActionLink
          className={clsx("location-modal-header-back-button", {})}
          onClick={handleGoBack}
          content={<FontAwesomeIcon icon={faChevronLeft as IconProp} />}
        />
      }
      topRightButton={
        <ActionLink
          className="location-modal-header-close-button"
          onClick={onClose}
          content={
            <IconComponent
              ariaLabel={t("close.imageAlt")}
              className={clsx("close-button-icon")}
              name={IconName.Close}
            />
          }
          label={t("close.button")}
        />
      }
    >
      <div className={clsx("location-modal-root")}>
      <HeaderCenterSection />
        <div className="location-section-container">
          <div className="location-elements">
            {hidePassengerPicker ? null : (
              <TravelerPicker
                paxCount={paxCount}
                disabled={disabled}
                updatePaxCount={updatePaxCount}
              />
            )}
          </div>
          <div className="location-elements">
            {hideTripCategoryToggle ? null : (
              <TripCategoryPicker
                tripCategory={tripCategory}
                disabled={disabled}
                onChange={updateTripCategory}
              />
            )}
          </div>
          <div className="location-elements">
            <FlightLocationAutoComplete
              id="origin"
              defaultValue={origin}
              label={t?.("searchControl.whereFrom")}
              onChange={handleOriginChange}
              onOpen={onOpen}
              disabled={disabled}
              icon={icons?.location}
              additionalSearchControl={{
                activeControl: "origin",
                destination: destinationCode || undefined,
              }}
            />
          </div>
          <div className="location-elements">
            <FlightLocationAutoComplete
              id="destination"
              defaultValue={destination}
              label={t?.("searchControl.whereTo")}
              onChange={handleDestinationChange}
              onOpen={onOpen}
              disabled={disabled}
              icon={icons?.location}
              additionalSearchControl={{
                activeControl: "destination",
                origin: originCode || undefined,
              }}
            />
          </div>
          
          <div className="button-container">
            <ActionButton
              onClick={onComplete}
              fullWidth
              disabled={disabled}
              size="large"
              message={t?.("continue")}
            />
          </div>
          
        </div>
      </div>
    </MobilePopoverCard>
  );
};

const HeaderCenterSection = () => {
  const { t } = useI18nContext();

  return (
    <div className="header-center-section">
      <div className="location-modal-header-container">
        <h3 className="location-modal-header">
          {t("flightShopLocationModal")}
        </h3>
      </div>
    </div>
  );
};
