import {
  authApi,
  FinalizeUserRequest,
  FinalizeUserResponse,
} from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";

export const finalizeUser = async (
  request: FinalizeUserRequest
): Promise<FinalizeUserResponse> => {
  try {
    const response = await authApi(axiosInstance).apiV0AuthUserFinalizePost(
      request
    );
    return response.data;
  } catch (error) {
    console.error("Finalize user error:", error);
    throw Error("Finalize user error");
  }
};
