import { axiosInstance } from '@marketing-site/api/urbana';
import { SerializedError } from '@reduxjs/toolkit';
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';

type Error = SerializedError | FetchBaseQueryError | undefined;

export const axiosBaseQuery: BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
  },
  unknown,
  Error
> = async ({ url, method, body }) => {
  try {
    const result = await axiosInstance({ url, method, data: body });
    return {
      data: result.data,
      meta: { clientRegion: result.headers['x-client-region'] },
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data,
      } as Error,
    };
  }
};
