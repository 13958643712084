import React from 'react';
import {
  ICtaButton,
  IImage,
  IRichText,
} from '@marketing-site/types/shared.model';
import styled from 'styled-components';
import { RichTextToHtml } from '@marketing-site/utils';
import { media, theme } from '@marketing-site/styles';
import CtaButton from '../cta-button/ctaButton';

interface ICalloutFullWidth {
  backgroundColor: string;
  calloutText: IRichText;
  icon: IImage;
  ctaButton?: ICtaButton;
}

const CalloutFullWidth = (props: ICalloutFullWidth) => {
  return (
    <Wrapper>
      <Container>
        <Icon
          src={props.icon.url}
          alt={props.icon.description}
          title={props.icon.title}
        />
        <Text>{RichTextToHtml(props.calloutText.json)}</Text>
        {props.ctaButton && <CtaButton {...props.ctaButton} />}
      </Container>
    </Wrapper>
  );
};

export default CalloutFullWidth;

const Wrapper = styled.div`
  padding: 32px 12px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  display: flex;
  flex-direction: row;
  ${media.phablet`
    flex-direction: column;
  `};
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  background-color: ${theme.colors.white};
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 34px;
`;

const Icon = styled.img`
  width: 100px;
  height: 100px;
  ${media.phablet`
    width: 80px;
    height: 80px;
  `};
`;

const Text = styled.div``;
