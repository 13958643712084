import React from 'react';
import styled from 'styled-components';
import { media, theme } from '@marketing-site/styles';
import { RichTextToHtml } from '@marketing-site/utils';
import CtaButton from '../cta-button';
import { IHeroProductAlias } from './HeroProduct';
import { ICtaButton } from '@marketing-site/types/shared.model';

type IHeroCareers = IHeroProductAlias & { secondCtaButton?: ICtaButton };
export const HeroCareers = ({
  children,
  ...props
}: React.PropsWithChildren<IHeroCareers>) => {
  return (
    <HeroContainer>
      <Content>
        {children}
        <Headline>{props.headline}</Headline>
        <DescriptionWrapper>
          <Description>
            {RichTextToHtml(props.heroProductDescription.json)}
          </Description>
        </DescriptionWrapper>

        <ButtonGroup>
          {props.ctaButton && <CtaButton {...props.ctaButton} />}
          {props.secondCtaButton && (
            <CtaButton
              {...props.secondCtaButton}
              backgroundColor={theme.colors.white}
            />
          )}
        </ButtonGroup>
      </Content>
      <Image src={props.image.url} />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  display: flex;
  max-width: 1080px;
  flex-direction: row;
  margin: 0px auto;
  padding: 40px 0;
`;

const Content = styled.div`
  flex: 1 0 60%;
  display: flex;
  flex-direction: column;
  align-self: center;

  ${media.phone_large`
    flex: 1 0 100%;
  `}

  > div, h1 {
    padding: 0 30px;
  }
`;

const Headline = styled.h1`
  width: 480px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 72px;
  line-height: 76px;
  ${media.tablet`
    font-size: 46px;
    line-height: 50px;
    width: 280px;
  `};
`;

const DescriptionWrapper = styled.div`
  flex: 1 0 auto;
  display: flex;
`;

const Description = styled.div`
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 34px;

  ${media.tablet`
    font-size: 20px;
    line-height: 26px;
  `}
  ${media.phone_large`
    > p {
      width: 100%;
    }
  `}
`;

const Image = styled.img`
  flex: 1 1 auto;
  object-fit: contain;
  max-height: 420px;
  width: 100%;
  height: auto;
  ${media.phone_large`
    display: none;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  ${media.tablet_large`
    flex-direction: column;
    gap: 0.5rem;
  `}
  & div > button {
      white-space: nowrap;
    }
  }
`;
