import React from 'react';
import styled from 'styled-components';
import { DescriptionPoint } from '@marketing-site/components/_contentful';
import { IDescriptionPoint } from '@marketing-site/types/description-points.model';
import { media, theme } from '@marketing-site/styles';
import { IGenericSection } from '@marketing-site/types/shared.model';
import { RichTextToHtml } from '@marketing-site/utils';

export const FullWidthTextSection = (
  props: IGenericSection<IDescriptionPoint>
) => {
  return (
    <Container>
      <Headline>
        {props.preIcon && (
          <Icon
            src={props.preIcon.url}
            title={props.preIcon.title}
            alt={props.preIcon.description}
          />
        )}
        {props.headline}
      </Headline>
      <Body>{RichTextToHtml(props.description?.json)}</Body>

      {props.body.items && (
        <DescriptionPointsWrapper>
          {props.body.items.map((item, i) => {
            return (
              <DescriptionPoint
                key={`fullwidth-descPoint-${i}`}
                {...item}
                alignment={props.meta?.descriptionPointAlignment || 'left'}
              />
            );
          })}
        </DescriptionPointsWrapper>
      )}
    </Container>
  );
};

export default FullWidthTextSection;

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  width: 92%;
`;

const Headline = styled.h3`
  display: inline-flex;
  gap: 16px;
  margin-bottom: 0;
`;

const Body = styled.div`
  margin-bottom: 42px;
`;

const Icon = styled.img`
  width: 42px;
  height: 42px;
`;

const DescriptionPointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 64px;
  ${media.tablet`
    flex-direction: column;
    gap: 0;
  `};
`;
