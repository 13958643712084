import { useExperiment } from "@hopper-b2b/experiments";
import { ExperimentVariant } from "@hopper-b2b/types";
import { Header } from "@marketing-site/components";
import { IFooterProps } from "@marketing-site/components/footer/footer";
import { IHeaderProps } from "@marketing-site/components/header/header";
import { HopperNavigationWrapper } from "@marketing-site/components/header/hopperWebNavigationWrapper";
import React from "react";
import styled from "styled-components";

type NavigationBarProps = {
  useGlobalColor?: boolean;
  contentfulNavProps?: {
    header?: IHeaderProps;
    footer?: IFooterProps;
  };
  backgroundColor?: string;
};

export const NavigationBar = ({
  contentfulNavProps,
  useGlobalColor = false,
  backgroundColor = "#fff",
}: NavigationBarProps) => {
  // Default to Available to prevent jittering
  const hopperWebNavigationControl =
    useExperiment(
      "hopper-web-navigation-control",
      ExperimentVariant.Available,
      true
    ) ?? ExperimentVariant.Available;

  return contentfulNavProps && contentfulNavProps.header ? (
    hopperWebNavigationControl ? (
      <HopperNavigationWrapper headerNavigation={contentfulNavProps.header} />
    ) : (
      <NavHeader
        {...contentfulNavProps.header}
        backgroundColor={backgroundColor}
        useGlobalColor={useGlobalColor}
      />
    )
  ) : null;
};

const NavHeader = styled(Header)`
  position: relative;
  z-index: 21;
`;
