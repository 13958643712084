import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import clsx from "clsx";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FlightSearch, HotelSearch } from "../";
import { ReactComponent as FlightsSvg } from "../../../assets/icons/Flights.svg";
import { ReactComponent as HotelsSvg } from "../../../assets/icons/Hotels.svg";
import { ReactComponent as StaysSvg } from "../../../assets/icons/Stays.svg";
import {
  useIsAirEnabled,
  useIsLodgingEnabled,
  useIsRoomsEnabled,
  useIsStaysEnabled,
} from "../../context";
import styles from "./Tabs.module.scss";

export const HOTELS_TAB_VALUE = "hotel-tab" as const;
export const FLIGHTS_TAB_VALUE = "flights-tab" as const;
export const STAYS_TAB_VALUE = "stays-tab" as const;

export type TabValue =
  | typeof HOTELS_TAB_VALUE
  | typeof FLIGHTS_TAB_VALUE
  | typeof STAYS_TAB_VALUE;

export type TabType = {
  value: TabValue;
  label: string;
  icon: React.ReactElement;
  panel: ReactNode;
};

export type TabsProps = {
  initalHotelAdultGuests: number;
  initialTab?: TabValue;
  onSearch?: (path: string) => void;
  handleSession?: () => void;
};

export const Tabs = ({
  initalHotelAdultGuests,
  initialTab,
  onSearch,
  handleSession,
}: TabsProps) => {
  const { t } = useI18nContext();

  const enableAir = useIsAirEnabled();
  const enableLodging = useIsLodgingEnabled();
  const enableStays = useIsStaysEnabled();
  const enableRooms = useIsRoomsEnabled();

  const definitiveInitialTab = useMemo(() => {
    if (!enableLodging || initialTab === FLIGHTS_TAB_VALUE) {
      return FLIGHTS_TAB_VALUE;
    }
    if (enableStays) {
      return STAYS_TAB_VALUE;
    }
    return HOTELS_TAB_VALUE;
  }, [enableLodging, initialTab]);

  const [activeTab, setActiveTab] = useState<TabValue>(definitiveInitialTab);

  const [skipHotelTappedEntry, setSkipHotelTappedEntry] = useState(false);

  useEffect(() => {
    setActiveTab(definitiveInitialTab);
  }, [definitiveInitialTab]);

  const getTrackingEventName = useCallback((tabValue: TabValue) => {
    switch (tabValue) {
      case HOTELS_TAB_VALUE:
      case STAYS_TAB_VALUE: // TBD: stays can initially use the same tracking as hotels.
        return "hotel_tapped_entry";
      case FLIGHTS_TAB_VALUE:
        return "air_tapped_entry";
      default:
        return "";
    }
  }, []);

  const handleTabChange = useCallback(
    (_e: React.ChangeEvent<unknown>, newValue: TabValue) => {
      setActiveTab(newValue);
      trackEvent({
        eventName: getTrackingEventName(newValue),
        properties: undefined,
      });

      if (newValue === HOTELS_TAB_VALUE) {
        setSkipHotelTappedEntry(true);
      }
    },
    [getTrackingEventName]
  );

  const hotelOnOpen = useCallback(() => {
    trackEvent({
      eventName: "hotel_viewed_search",
      properties: undefined,
    });
    handleSession?.();
  }, [handleSession]);

  const tabs: TabType[] = useMemo(
    () =>
      [
        enableLodging && !enableStays
          ? {
              value: HOTELS_TAB_VALUE,
              label: t?.("hotels"),
              icon: <HotelsSvg />,
              panel: (
                <HotelSearch
                  onSearch={onSearch}
                  initialAdultGuests={initalHotelAdultGuests}
                  onOpen={hotelOnOpen}
                  enableRooms={enableRooms}
                  skipHotelTappedEntry={skipHotelTappedEntry}
                />
              ),
            }
          : undefined,
        enableStays
          ? {
              value: STAYS_TAB_VALUE,
              label: t?.("stays"),
              icon: <StaysSvg />,
              panel: (
                <HotelSearch
                  onSearch={onSearch}
                  initialAdultGuests={initalHotelAdultGuests}
                  onOpen={hotelOnOpen}
                  enableRooms={enableRooms}
                  skipHotelTappedEntry={skipHotelTappedEntry}
                  staysEnabled={true}
                />
              ),
            }
          : undefined,
        enableAir
          ? {
              value: FLIGHTS_TAB_VALUE,
              label: t?.("flights"),
              icon: <FlightsSvg />,
              panel: (
                <FlightSearch onSearch={onSearch} onOpen={handleSession} />
              ),
            }
          : undefined,
      ].filter(Boolean),
    [
      enableAir,
      enableLodging,
      enableStays,
      enableRooms,
      handleSession,
      hotelOnOpen,
      initalHotelAdultGuests,
      onSearch,
      t,
      skipHotelTappedEntry,
    ]
  );
  return (
    <div className={clsx(styles.searchContainer, "hopper-search-root")}>
      <TabContext value={activeTab}>
        <TabList
          onChange={handleTabChange}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          {tabs.map((tab: TabType) => (
            <Tab
              key={tab.value}
              data-testid={tab.value}
              className={styles.tab}
              value={tab.value}
              label={tab.label}
              icon={tab.icon}
              sx={{
                " &.Mui-selected": {
                  color: "inherit",
                },
              }}
            />
          ))}
        </TabList>
        {tabs.map((tab: TabType) => (
          <TabPanel
            key={tab.value}
            className={styles.tabPanel}
            value={tab.value}
          >
            {tab.panel}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};
