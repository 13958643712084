import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { trackEvent } from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";
import { IApiConfig } from "@hopper-b2b/types";
import { useCallback } from "react";

export type UseTrackEventProps = Omit<TrackEventRequest, "properties"> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
};

// TODO - Keep moving common tracking properties from `AxiosInterceptors`
export const useTrackEvent = () => {
  const { initialized, trackingProperties: experimentsTrackingProperties } =
    useExperiments();

  return useCallback(
    async (event: UseTrackEventProps, apiConfig?: IApiConfig) =>
      initialized &&
      trackEvent({
        ...event,
        properties: {
          ...(event.properties ?? {}),
          ...experimentsTrackingProperties,
        },
        apiConfig,
      }),
    [experimentsTrackingProperties, initialized]
  );
};
