import React from 'react';
import styled from 'styled-components';
import { DescriptionPoint } from '@marketing-site/components/_contentful';
import { IDescriptionPoint } from '@marketing-site/types/description-points.model';
import { media, theme } from '@marketing-site/styles';
import { IGenericSection } from '@marketing-site/types/shared.model';
import GenericHeader from './GenericHeader';

// TO USE:
// Need the following meta json object in contentful -
// {
//   "reactComponent": "DescriptionPointsSection",
//   "descriptionPointAlignment": "left", (or right, center)
//   "numPerRow": 2 (or 3, 4)
// }

export const DescriptionPointsSection = (
  props: IGenericSection<IDescriptionPoint>
) => {
  return (
    <>
      <GenericHeader
        preheadline={props.preheadline}
        headline={props.headline}
        flareColor={props.flareColor}
        description={props.description}
      />
      <DescriptionPointsWrapper>
        <DescriptionPointsContainer
          align={props.meta?.descriptionPointAlignment || 'left'}
        >
          {props.body?.items.map((point: IDescriptionPoint, i: number) => {
            return (
              <DescriptionPoint
                {...point}
                key={`descriptionPoint-${i}`}
                alignment={props.meta?.descriptionPointAlignment || 'left'}
                total={props.meta?.numPerRow || 2}
              />
            );
          })}
        </DescriptionPointsContainer>
      </DescriptionPointsWrapper>
    </>
  );
};

export default DescriptionPointsSection;

const Preheadline = styled.h3`
  margin-bottom: 24px;
  color: ${theme.colors.grey[400]};
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  width: 180px;
  ${media.tablet`
    align-self: center;
  `};
  ${media.desktop_up`
    width: auto;
  `}
`;
const DescriptionPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 16px;
  width: 92%;
  max-width: 1080px;
`;

const DescriptionPointsContainer = styled.div<{
  align: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
  align-content: center;
  align-items: ${(p) => p.align};
  ${media.tablet`
    flex-direction: column;
  `};
`;
