import { FailedPayment, FailedProduct } from "@b2bportal/purchase-api";
import { AirChfarExerciseDiscountContext } from "../states/Payments/AirChfarExercise";
import { CancelForAnyReasonContext } from "../states/Products/AirCFAR/context";
import { AirChangeForAnyReasonContext } from "../states/Products/AirChFAR/context";
import { DisruptionProtectionContext } from "../states/Products/AirDisruption/context";
import { VipSupportContext } from "../states/Products/VipSupport/context";
import { PassengerPricing } from "@hopper-b2b/types";
import { AssignAction, EventObject } from "xstate";
import {
  AirPFExerciseContext,
  AirPriceDropContext,
  CardPaymentContext,
  CartContext,
  CommonParentContext,
  ContactInformationContext,
  ReviewContext,
  TripInsuranceContext,
} from "../states";
import { WalletContext } from "../states/Payments/Wallet";

// Move to index.ts
export * from "../states/Products/AirPriceFreeze/states/Purchase/types";

// Parent machine states
export enum ParentState {
  auth = "auth",
  route = "route",
  loading = "loading",
  passengerInformation = "passengerInformation",
  contactInformation = "contactInformation",
  passport = "passport",
  cartQuote = "cartQuote",
  cartUpdate = "cartUpdate",
  cartUpdateForSeats = "cartUpdateForSeats",
  cartUpdateBeforeFulfill = "cartUpdateBeforeFulfill",
  seatSelection = "seatSelection",
  bagsSelection = "bagsSelection",
  payment = "payment",
  review = "review",
  cartFulfill = "cartFulfill",
  loyaltySubmit = "loyaltySubmit",
  bookingConfirmation = "bookingConfirmation",
  priceFreeze = "priceFreeze",
  priceFreezeDiscount = "priceFreezeDiscount",
  cancelForAnyReason = "cancelForAnyReason",
  changeForAnyReason = "changeForAnyReason",
  changeForAnyReasonDiscount = "changeForAnyReasonDiscount",
  disruption = "disruption",
  tripInsurance = "tripInsurance",
  vipSupport = "vipSupport",
  cardPayment = "cardPayment",
  awardsPayment = "awardsPayment",
  guestCardPayment = "guestCardPayment",
  wallet = "wallet",
  resume = "resume",
  featureFlags = "featureFlags",
  priceDrop = "priceDrop",
  lodgingShop = "lodgingShop",
  homesShop = "homesShop",
  applePay = "applePay",
}

export enum GenericChildState {
  loading = "loading",
  idle = "idle",
  update = "update",
  delete = "delete",
  add = "add",
  error = "error",
  saving = "saving",
  addForm = "addForm",
  updateForm = "updateForm",
  tripPricing = "tripPricing",
}

export enum ContactInformationState {
  idle = "idle",
  createUser = "createUser",
  error = "error",
}

export enum PassengerInformationState {
  idle = "idle",
  loading = "loading",
  passengerPicker = "passengerPicker",
  passengerForm = "passengerForm",
  error = "error",
  validate = "validate",
}

export enum PassengerInformationChildState {
  idle = "idle",
  new = "new",
  edit = "edit",
  update = "update",
  delete = "delete",
  add = "add",
  validate = "validate",
  route = "route",
  fetch = "fetch",
}

export enum SeatSelectionState {
  route = "route",
  loading = "loading",
  idle = "idle",
  error = "error",
  validate = "validate",
}

export enum PaymentInformationState {
  loading = "loading",
  route = "route",
  idle = "idle",
  add = "add",
  delete = "delete",
  error = "error",
}

export enum PaymentInformationChildState {
  idle = "idle",
  add = "add",
  delete = "delete",
  verify = "verify",
}

export enum PassportState {
  idle = "idle",
  update = "update",
  updateAndContinue = "updateAndContinue",
  validate = "validate",
}

export enum CartQuoteState {
  discard = "discard",
  schedule = "schedule",
  polling = "polling",
  error = "error",
  route = "route",
}

export enum CartFulfillState {
  schedule = "schedule",
  polling = "polling",
  error = "error",
}

export enum CartUpdateState {
  route = "route",
  update = "update",
  getBreakdown = "getBreakdown",
  error = "error",
}

export type PricingInfo = {
  fare: PassengerPricing[] | undefined;
};

export interface IContactInfo {
  countryCode?: string;
  phoneNumber: string;
  email: string;
}

export enum PassengerErrorModalTypes {
  InfantOnly = "InfantOnly",
  TripPricing = "TripPricing",
  UpdatePassenger = "UpdatePassenger",
  FetchPassengers = "FetchPassengers",
  ValidatePassengers = "ValidatePassengers",
  DeletePassenger = "DeletePassenger",
  ValidateAndTripPricing = "ValidateAndTripPricing",
  UnaccompaniedMinor = "UnaccompaniedMinor",
  NoPassengers = "NoPassengers",
  PassportValidationFail = "PassportValidationFail",
  ADTRequired = "ADTRequired",
  INSNotAllowed = "INSNotAllowed",
  InSufficientADTCount = "InSufficientADTCount",
  TooManyPassengers = "TooManyPassengers",
  InfantIsMinor = "InfantIsMinor",
  SearchPassengerNumNotReached = "SearchPassengerNumNotReached",
}

export enum PaymentErrorModalTypes {
  ListPaymentMethods = "ListPaymentMethods",
  VerifyPayment = "VerifyPayment",
  CardDeclined = "CardDeclined",
  ExcessiveUserActivity = "ExcessiveUserActivity",
  GenericError = "GenericError",
  DeletePayment = "DeletePayment",
  FetchNequiBalance = "FetchNequiBalance",
}

export enum PassportErrorModalTypes {
  TripPricing = "TripPricing",
  UpdatePassenger = "UpdatePassenger",
  ValidatePassengers = "ValidatePassengers",
}

export enum ContactErrorModalTypes {
  InvalidPhoneNumber = "InvalidPhoneNumber",
}

export interface ContactError {
  type: ContactErrorModalTypes;
  data: unknown;
}

export interface PassengerError {
  type: PassengerErrorModalTypes;
  data: unknown;
}

export interface PaymentError {
  type: PaymentErrorModalTypes;
  data: unknown;
}

export interface PassportError {
  type: PassportErrorModalTypes;
  data: unknown;
}

export enum CartQuoteErrorModal {
  Invalid = "Invalid",
  Missing = "Missing",
  NoSelectedTrip = "NoSelectedTrip",
  NoProducts = "NoProducts",
  NoCipherText = "NoCipherText",
  FailedPolling = "FailedPolling",
  FailedPollingNoErrorCode = "FailedPollingNoErrorCode",
  NoEmail = "NoEmail",
}

export type CartQuoteError = {
  type: CartQuoteErrorModal;
  data?: unknown;
};

export enum CartUpdateErrorModal {
  FailedPaymentAdd = "FailedPaymentAdd",
  FailedProductAdds = "FailedProductAdds",
  FailedPaymentRemoves = "FailedPaymentRemoves",
  FailedProductRemoves = "FailedProductRemoves",
  NoCipherText = "NoCipherText",
  NoEmailForProduct = "NoEmailForProduct",
  UnavailableCartState = "UnavailableCartState",
  Unknown = "Unknown",
}

export interface CartUpdateError {
  type: Array<CartUpdateErrorModal>;
  data: {
    failedPaymentAdds?: Array<FailedPayment>;
    failedProductAdds?: Array<FailedProduct>;
    failedPaymentRemoves?: Array<FailedPayment>;
    failedProductRemoves?: Array<FailedProduct>;
  };
}

export enum CartFulfillErrorModal {
  Invalid = "Invalid",
  Missing = "Missing",
  NoCipherText = "NoCipherText",
  FailedPolling = "FailedPolling",
  FailedPollingNoErrorCode = "FailedPollingNoErrorCode",
  BrokenCart = "BrokenCart",
}

export type CartFulfillError = {
  type: CartFulfillErrorModal;
  data?: unknown;
};

export enum HotelLoyaltySubmitTypes {
  Failed = "Failed",
  NoLoyaltyNumberAssociatedWithUser = "NoLoyaltyNumberAssociatedWithUser",
}

export type PaymentMethodData = {
  paymentMethodId: string;
};

export enum SeatSelectionErrorModal {
  GenericError = "GenericError",
  FailedAddToCart = "FailedAddToCart",
  BrokenCart = "BrokenCart",
}

export type SeatSelectionError = {
  type: SeatSelectionErrorModal;
  data?: unknown;
};

export enum CheckoutQueryParams {
  tripCategory = "tripCategory",
  departureLabel = "departureLabel",
  returnLabel = "returnLabel",
  tripId = "tripId",
  outgoingSliceId = "outgoingSliceId",
  outgoingFareId = "outgoingFareId",
  outgoingFareRating = "outgoingFareRating",
  returnSliceId = "returnSliceId",
  returnFareId = "returnFareId",
  returnFareRating = "returnFareRating",
  contactCountry = "contactCountry",
  contactPhone = "contactPhone",
  contactEmail = "contactEmail",
  selectedLapInfantIds = "selectedLapInfantIds",
  selectedPassengerIds = "selectedPassengerIds",
  selectedPaymentMethodId = "selectedPaymentMethodId",
  checkoutState = "checkoutState",
}

export type GenericActionTypes<Context> = Record<
  string,
  AssignAction<Context, EventObject>
>;

export interface ChildMachineConfig {
  parentMachineId: string;
  nextState?: string;
  previousState?: string;
  nextStateFromReview?: string;
  failedState?: string;
}

export interface PartialParentContext extends CommonParentContext, CartContext {
  [ParentState.contactInformation]: ContactInformationContext;
  [ParentState.review]: ReviewContext;
  [ParentState.priceFreeze]?: AirPFExerciseContext;
  [ParentState.priceDrop]?: AirPriceDropContext;
  [ParentState.cancelForAnyReason]?: CancelForAnyReasonContext;
  [ParentState.changeForAnyReason]?: AirChangeForAnyReasonContext;
  [ParentState.changeForAnyReasonDiscount]?: AirChfarExerciseDiscountContext;
  [ParentState.disruption]?: DisruptionProtectionContext;
  [ParentState.tripInsurance]?: TripInsuranceContext;
  [ParentState.vipSupport]?: VipSupportContext;
  [ParentState.cardPayment]?: CardPaymentContext;
  [ParentState.wallet]?: WalletContext;
}
