import React, { useState, MouseEvent } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { useLocation } from 'react-router-dom';
import { GetIcon, Helpers } from '@marketing-site/utils';
import { updatePathnameWithNewLocale } from '@marketing-site/utils/helpers';

const LanguageDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const languageMapping = [
    {
      language: 'English',
      pathPrefix: '/',
    },
    {
      language: 'Español',
      pathPrefix: '/es',
    },
    {
      language: 'Français',
      pathPrefix: '/fr',
    },
  ];

  const currLang = Helpers.getLocaleFromPathname(useLocation().pathname);
  const currLocation = useLocation().pathname;

  const handleHover = (e: MouseEvent<HTMLDivElement>) => {
    setShowDropdown(e.type === 'mouseenter' || e.type === 'click');
  };

  return (
    <Container onMouseEnter={handleHover} onClick={handleHover}>
      <ToggleWrapper>
        <Selected>
          {currLang}
          {GetIcon('globe')}
        </Selected>
      </ToggleWrapper>
      {showDropdown && (
        <OptionsWrapper onMouseLeave={handleHover}>
          <OptionsContainer>
            {languageMapping.map((lang) => {
              return (
                <Option
                  key={lang.pathPrefix}
                  href={updatePathnameWithNewLocale(
                    currLocation,
                    lang.pathPrefix
                  )}
                >
                  {lang.language}
                </Option>
              );
            })}
          </OptionsContainer>
        </OptionsWrapper>
      )}
    </Container>
  );
};

export default LanguageDropdown;

const Container = styled.div`
  display: flex;
  align-self: center;
  position: relative;
  font-size: 16px;
  z-index: 12;
  cursor: pointer;
  color: ${theme.colors.grey[700]};
`;

const ToggleWrapper = styled.div`
  width: 80px;
  padding: 12px 16px;
  border-style: solid;
  border-width: 2px;
  border-radius: 20px;
  box-sizing: border-box;
`;

const Selected = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
`;

const OptionsWrapper = styled.div`
  padding-top: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 160px;
  box-sizing: border-box;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 12;

  align-items: center;
  padding: 12px;
  background-color: ${theme.colors.white};
  border-radius: 20px;
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 12%);
`;
const Option = styled.a`
  text-decoration: none;
  font-weight: 700;
  font-size: 18px;
  color: ${theme.colors.grey[800]};
  text-align: center;
  border-radius: 10px;
  padding: 12px 0px;
  width: 100%;
  :hover {
    background-color: rgba(1, 170, 228, 0.1);
  }
  transition: 200ms ease-in;
`;
