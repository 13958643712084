import * as React from "react";
import clsx from "clsx";
import { Box } from "@mui/material";
import "./styles.scss";

export interface ITagProps {
  className?: string;
  label: string;
  showInfoIcon?: boolean;
  expanded?: boolean;
  onClick?: () => void;
}

export const Tag = ({
  label,
  className,
  expanded,
  onClick,
}: ITagProps): React.ReactElement => {
  const isDisabled = true;
  return (
    <Box
      role="button"
      tabIndex={0}
      aria-expanded={expanded}
      className={clsx("algomerch-tag", className)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.stopPropagation();
          onClick && onClick();
        }
      }}
      disabled={isDisabled}
      onClick={
        !isDisabled
          ? (event) => {
              event.stopPropagation();
              onClick && onClick();
            }
          : null
      }
    >
      <span>{label}</span>
    </Box>
  );
};
