import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '@marketing-site/styles/media';
import { RichTextToHtml } from '@marketing-site/utils';

import Lightbulb from '@marketing-site/static/icons/lightbulb.png';

const Faq = (props: any) => {
  return (
    <Container id={props.sectionId}>
      <Headline>
        {props.headline}
        <LightbulbIcon src={Lightbulb} />
      </Headline>
      <Questions>{RichTextToHtml(props.questions.json)}</Questions>
    </Container>
  );
};

export default Faq;

Faq.propTypes = {
  headline: PropTypes.string,
  questions: PropTypes.object,
  sectionId: PropTypes.string,
};

const Container = styled.div`
  width: 86%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 20px;
`;

const Headline = styled.h2`
  font-weight: 800;
  font-size: 28px;
  margin-bottom: -12px;
`;

const LightbulbIcon = styled.img`
  position: absolute;
  width: 26px;
  margin-top: 4px;
  margin-left: 4px;

  ${media.tablet_large_up`
    margin-top: 8px;
  `};

  ${media.phone`
    margin-top: 0;
  `};
`;

const Questions = styled.div`
  h4 {
    margin-bottom: 0;
  }

  p {
    margin-top: 4px;
  }
`;
