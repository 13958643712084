import { EventActionName } from "@marketing-site/types";

export const logPageView = (locale: string, flags?: any) => {
  if (typeof window?.analytics?.page === "function") {
    const experiments = flags
      ? Object.keys(flags).map(
          (key: string) =>
            `${key.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)}_${
              flags[key]
            }`
        )
      : [];
    if (typeof window?.analytics?.track === "function") {
      window.analytics.track(
        EventActionName.ViewedPage,
        { page_locale: locale, experiments },
        {
          app: {
            name: "hopper-marketing-site",
          },
        }
      );
    }
  }
};
