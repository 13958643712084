import { FunctionComponent, ReactNode } from "react";
import { Box } from "@mui/material";
import clsx from "clsx";

import "./styles.scss";

export interface IFlightCardWrapperProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const FlightCardWrapper: FunctionComponent<IFlightCardWrapperProps> = ({
  className,
  children,
  onClick,
}: IFlightCardWrapperProps) => {
  return (
    <Box className={clsx("flight-card", className)} onClick={onClick}>
      {children}
    </Box>
  );
};
