import {
  CreateSessionRequestEnum,
  FinalizeUserResponse,
  FinalizeUserResponseEnum,
} from "@b2bportal/auth-api";
import { createSession, finalizeUser } from "../../api";
import { AuthMachineContext } from "../context";
import { FinalizeUserEvent } from "../events";
import { getAuthStateToken } from "../selectors";

export const finalizeUserService = async (
  context: AuthMachineContext,
  event: FinalizeUserEvent
) => {
  const authStateToken = getAuthStateToken({ context }) as string;

  const firstName = event.missingFields.FirstName;
  const lastName = event.missingFields.LastName;
  const phoneNumber = event.missingFields.PhoneNumber || undefined;
  const email = event.missingFields.Email || undefined;

  try {
    const res: FinalizeUserResponse = await finalizeUser({
      phoneNumber,
      email,
      lastName,
      firstName,
      authStateToken: authStateToken,
    });

    if (
      res.FinalizeUserResponse === FinalizeUserResponseEnum.FinalizeUserSuccess
    ) {
      return createSession({
        authStateToken: res.authStateToken,
        CreateSessionRequest: CreateSessionRequestEnum.Finalized,
      });
    } else if (
      res.FinalizeUserResponse ===
      FinalizeUserResponseEnum.MatchingAccountsFound
    ) {
      // Do not create session but move on to the Merge state
      return res;
    } else {
      throw new Error("FinalizeUserResponseEnum.FinalizeUserFailure");
    }
  } catch (error) {
    console.error("Error finalizing User", error);
    throw new Error("FinalizeUserResponseEnum.FinalizeUserFailure");
  }
};
