"use client";

import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from "react";

export enum SessionStateEnum {
  Anonymous = "Anonymous",
  Guest = "Guest",
  Authenticated = "Authenticated",
}
export type ISessionContextProps = {
  sessionType?: SessionStateEnum;
  isLoggedIn?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  countryCode?: string;
  isLoginModalOpen?: boolean;
  isFirstSession?: boolean;
  setLogingModalOpen?: (val: boolean) => void;
  setSessionContext?: (val: ISessionContextProps) => void;
};

export interface ISessionContextProvider {
  sessionContext: ISessionContextProps;
}

export const SessionContext = createContext<ISessionContextProps>({
  sessionType: SessionStateEnum.Anonymous,
  isLoggedIn: false,
});

export const useSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return { ...ctx };
};

export const useSetSessionContext = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(
    (context: ISessionContextProps) => {
      ctx?.setSessionContext?.(context);
    },
    [ctx]
  );
};

export const useOpenAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(() => {
    ctx?.setLogingModalOpen?.(true);
  }, [ctx]);
};

export const useCloseAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(() => {
    ctx?.setLogingModalOpen?.(false);
  }, [ctx]);
};

export const useToggleAuthModal = () => {
  const ctx = useContext(SessionContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SessionContextProvider`);
  return useCallback(() => {
    ctx?.setLogingModalOpen?.(!ctx.isLoginModalOpen);
  }, [ctx]);
};

export const useIsSessionAuthenticated = () => {
  const sessionContext = useSessionContext();

  return sessionContext.sessionType === SessionStateEnum.Authenticated;
};

export const SessionContextProvider = ({
  children,
  sessionContext: intialSessionContext,
}: PropsWithChildren<ISessionContextProvider>) => {
  const [isLoginModalOpen, setLogingModalOpen] = useState(
    intialSessionContext?.isLoginModalOpen
  );

  const [sessionContext, setSessionContext] = useState({});

  return (
    <SessionContext.Provider
      value={{
        ...intialSessionContext,
        ...sessionContext,
        isLoginModalOpen,
        setLogingModalOpen,
        setSessionContext,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
