import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { PageQuery } from "@marketing-site/api/urbana/contentful/queries";
import { TakeoverImage } from "@marketing-site/components/_contentful";
import { ITakeoverImage } from "@marketing-site/components/_contentful/takeover-image/takeoverImage";
import { Page } from "@marketing-site/pages/Page/Page";
import { getLocaleFromPathname } from "@marketing-site/utils/helpers";
import { useDeviceTypes } from "@whopper/utilities";
import React from "react";
import { useLocation } from "react-router-dom";
import { LoadingPage } from "../Page/LoadingPage";
import { selectClientRegion } from "@marketing-site/redux/utilitiesQuery";
import { useSelector } from "react-redux";

export const DealDrops = () => {
  const location = useLocation();
  const locale = getLocaleFromPathname(location.pathname);
  const path = "/";
  const slug = "deal-drops";
  const clientRegion = useSelector(selectClientRegion);

  const { data: contentfulData, isLoading: contentfulLoading } =
    useFetchContentQuery({
      query: PageQuery(slug, locale),
      space: "hopperCom",
    });

  const hopperHomepageTakeover: ITakeoverImage | null | undefined =
    contentfulData?.takeoverImagesCollection?.items?.[0];

  return contentfulLoading || !contentfulData ? (
    <LoadingPage />
  ) : (
    <>
      <Page
        slug={slug}
        locale={locale}
        canonical={path}
        content={contentfulData}
      />
      {clientRegion === "US" && hopperHomepageTakeover && (
        <TakeoverImage {...hopperHomepageTakeover} />
      )}
    </>
  );
};
