export const apiVersionPrefix = "/api/v0";

export const fetchUserInfoPath = `${apiVersionPrefix}/userInfo`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix = `${apiVersionPrefix}/takeovers`;
export const airBookingApiPrefix = `${apiVersionPrefix}/itinerary/flight`;

// #region Air Booking
export const acceptScheduleChangePath = `${airBookingApiPrefix}/acceptScheduleChange`;
export const denyScheduleChangePath = `${airBookingApiPrefix}/denyScheduleChange`;
// #endregion

// #region Email
export const emailPrefix = `${apiVersionPrefix}/email`;
export const resendEmailEndpoint = `${emailPrefix}/resend`;
// #endregion

// #region IVR
const ivrPrefix = `${apiVersionPrefix}/ivr`;

export const bookingSupportIdPath = `${ivrPrefix}/encode`;
// #endregion

// #region Self Serve
const selfServePrefix = `${apiVersionPrefix}/selfserve`;
const selfServeNonCartPrefix = `${selfServePrefix}/app`;

// #region Flight
const selfServeFlightPrefix = `${selfServePrefix}/flight`;
const selfServeNonCartFlightPrefix = `${selfServeNonCartPrefix}/flight`;

export const confirmFlightCancelPath = `${selfServeFlightPrefix}/cancelFulfill`;
export const confirmNonCartFlightCancelPath = `${selfServeNonCartFlightPrefix}/cancelFulfill`;
export const getFlightCancelQuotePath = `${selfServeFlightPrefix}/cancelQuote`;
export const getFlightCancelQuoteAnyPath = `${selfServeFlightPrefix}/cancelQuoteAny`;
export const getFlightCancelEligibilityPath = `${selfServeNonCartFlightPrefix}/cancelEligibility`;
export const getFlightExchangePolicyPath = `${selfServePrefix}/flight/exchangeEligibilityQuote`;
export const pollFlightCancelQuotePath = `${selfServeNonCartFlightPrefix}/cancelQuotePoll`;
export const pollFlightCancelFulfillPath = `${selfServeFlightPrefix}/flightCancelFulfillmentPoll`;
export const pollNonCartFlightCancelFulfillPath = `${selfServeNonCartFlightPrefix}/flightCancelFulfillmentPoll`;

// #endregion

// #region Ground
const selfServeGroundPrefix = `${selfServePrefix}/ground`;

export const cancelGroundByReservationPath = `${selfServeGroundPrefix}/cancel`;
export const getGroundCancellationInfoPath = `${cancelGroundByReservationPath}/policy`;

// #endregion

// #region Hotel
const selfServeHotelsPrefix = `${selfServePrefix}/lodging`;
const selfServeNonCartHotelPrefix = `${selfServeNonCartPrefix}/lodging`;

export const confirmHotelCancelPath = `${selfServeHotelsPrefix}/cancelFulfill`;
export const confirmNonCartHotelCancelPath = `${selfServeNonCartHotelPrefix}/cancelFulfill`;
export const getHotelCancelQuotePath = `${selfServeHotelsPrefix}/cancelQuote`;
export const getHotelCancelQuoteAnyPath = `${selfServeHotelsPrefix}/cancelQuoteAny`;
export const getHotelChangeQuotePath = `${selfServeHotelsPrefix}/changeQuote`;
export const getHotelChangeQuoteAnyPath = `${selfServeHotelsPrefix}/changeQuoteAny`;
export const pollHotelCancelFulfillPath = `${selfServeHotelsPrefix}/cancelFulfillmentPoll`;

// #endregion

// #region Home
const selfServeHomesPrefix = `${selfServePrefix}/homes`;
export const getHomesCancelQuotePath = `${selfServeHomesPrefix}/cancelQuote`;
export const getConfirmHomeCancelPath = `${selfServeHomesPrefix}/cancelFulfill`;

// #endregion

// #endregion
