import {
  Box,
  Link,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { ReactNode, isValidElement, useCallback } from "react";
import { HeaderMenuItemType } from "../HeaderMenu";

export type HeaderMenuItemProps = {
  item: HeaderMenuItemType;
  onClose: (e?: Event) => void;
};

export const HeaderMenuItem = ({ item, onClose }: HeaderMenuItemProps) => {
  const onClick = useCallback(
    (event) => {
      item.onClick?.(event);
      if (item.link) {
        window.location.href = item.link;
      }
      onClose(event);
    },
    [item, onClose]
  );

  return item.children ? (
    <Box mb={2}>
      <ListSubheader disableSticky={true}>
        <Typography variant="caption">{item.text}</Typography>
      </ListSubheader>
      {item.children?.map(
        (item: HeaderMenuItemType | ReactNode, index: number) =>
          isValidElement(item) ? (
            item
          ) : (
            <HeaderMenuItem
              key={index}
              item={item as HeaderMenuItemType}
              onClose={onClose}
            />
          )
      )}
    </Box>
  ) : (
    <ListItem
      button
      component={Link}
      onClick={onClick}
      disabled={item.disabled}
      color="textPrimary"
    >
      <ListItemText color="textPrimary" primary={item.text} />
    </ListItem>
  );
};
