import { config } from "@marketing-site/config";
import axios from "axios";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request) => {
  request.headers.set("Content-Type", "application/json");

  if (process.env["NX_USE_MOCK"] === "true") {
    request.headers.set("x-api-key", config["apiKey"]);
    request.headers.set("x-mock-match-request-body", true);
  }

  return request;
});
