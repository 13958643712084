export enum AuthKind {
  SignupSameUserId = "signup_same_user_id",
  LoginSameUserId = "login_same_user_id",
  LoginChangeUserId = "login_change_user_id",
  Unknown = "unknown",
}
export enum AuthType {
  Email = "email",
  Phone = "phone",
  Google = "google",
  Apple = "apple",
  Facebook = "facebook",
}
export enum AuthSource {
  Flights = "flights",
  Hotels = "hotels",
  Homes = "homes",
}

export enum AuthTrackingEvents {
  // B2bportal/user is presented choice of authentication method to create new account / sign-in into existing account
  ViewedSignup = "auth_frontend_viewed_signup",

  // B2bportal/user selects the authentication method.
  ChoseSignup = "auth_frontend_chose_signup",

  // B2bportal/user viewed the prompt for phone number when user chose that auth option.
  ViewedCollectPhone = "auth_frontend_viewed_collect_phone",

  // B2bportal/user submitted invalid phone number.
  CollectPhoneInvalidForm = "auth_frontend_collect_phone_invalid_form",

  // B2bportal/user completed phone number details form and submits by clicking / tapping “Verify” button.
  CompletedPhoneDetails = "auth_frontend_completed_phone_details",

  // B2bportal/user viewed the prompt for email address when user chose that auth option.
  ViewedCollectEmail = "auth_frontend_viewed_collect_email",

  // B2bportal/user submitted invalid email address.
  CollectEmailInvalidForm = "auth_frontend_collect_email_invalid_form",

  // B2bportal/user completed email address form and submits by clicking / tapping “Verify” button.
  CompletedEmailDetails = "auth_frontend_completed_email_details",

  // B2bportal/user cancelled SSO auth
  SsoCancelled = "auth_frontend_sso_cancelled",

  // B2bportal/user successfully requested to resend a verification code.
  SsoFailure = "auth_frontend_sso_failure",

  // B2bportal/user successfully requested to resend a verification code.
  ResendVerificationCodeSuccess = "auth_frontend_resend_verification_code_success",

  // B2bportal/user failed to request resending a verification code.
  ResendVerificationCodeFailure = "auth_frontend_resend_verification_code_failure",

  // B2bportal/user successfully verifies using authentication method. In case of auth_type: phone and auth_type: email,that means that user entered valid verification code that was accepted by backend. In case of SSO this is emitted as soon as backend accepts iDP token as valid.
  VerifySuccess = "auth_frontend_verify_success",

  // B2bportal/user provided invalid verification code or iDP token.
  VerifyFailure = "auth_frontend_verify_failure",

  // B2bportal/user selects the authentication method during the merge flow.
  MergeChoseLogin = "auth_frontend_merge_chose_login",

  // B2bportal/user successfully requested to resend a verification code during the merge flow.
  MergeResendVerificationCodeSuccess = "auth_frontend_merge_resend_verification_code_success",

  // B2bportal/user failed to request resending a verification code during the merge flow.
  MergeResendVerificationCodeFailure = "auth_frontend_merge_resend_verification_code_failure",

  // B2bportal/user successfully verifies using authentication method during the merge flow. In case of auth_type: phone and auth_type: email, that means that user entered valid verification code that was accepted by backend. In case of SSO this is emitted as soon as backend accepts iDP token as valid.
  MergeVerifySuccess = "auth_frontend_merge_verify_success",

  // B2bportal/user provided invalid verification code or iDP token during the merge flow.
  MergeVerifyFailure = "auth_frontend_merge_verify_failure",

  // B2bportal/user merged successfully into existing account during the merge flow.
  MergeSuccess = "auth_frontend_merge_success",

  // B2bportal/user views form to finalize user
  ViewedFinalize = "auth_frontend_viewed_finalize",

  // B2bportal/invalid form during finalize user
  FinalizeInvalidForm = "auth_frontend_finalize_invalid_form",

  // B2bportal/sign up or login success
  Success = "auth_frontend_success",

  // B2bportal/sign up or Login success tracking on User B. Applicable only when User A auth_frontend_success event has the following values of kind: signup_change_user_id, login_change_user_id
  ChangeUserId = "auth_frontend_change_user_id",

  // B2bportal/unknown auth error
  Failure = "auth_frontend_unknown_failure",
}

export interface ViewedSignupEvent {
  eventName: AuthTrackingEvents.ViewedSignup;
  properties: {
    source?: AuthSource;
  };
}

export interface ChoseSignupEvent {
  eventName: AuthTrackingEvents.ChoseSignup;
  properties: {
    auth_type: AuthType;
  };
}

export interface ViewedCollectPhoneEvent {
  eventName: AuthTrackingEvents.ViewedCollectPhone;
  properties: {
    auth_type: AuthType.Phone;
  };
}

export interface CompletedPhoneDetailsEvent {
  eventName: AuthTrackingEvents.CompletedPhoneDetails;
  properties: {
    auth_type: AuthType.Phone;
  };
}

export interface SsoFailureEvent {
  eventName: AuthTrackingEvents.SsoFailure;
  properties: Record<string, never>;
}

export interface ResendVerificationCodeSuccessEvent {
  eventName: AuthTrackingEvents.ResendVerificationCodeSuccess;
  properties: {
    auth_type: AuthType;
  };
}

export interface ResendVerificationCodeFailureEvent {
  eventName: AuthTrackingEvents.ResendVerificationCodeFailure;
  properties: {
    auth_type: AuthType;
  };
}

export interface VerifySuccessEvent {
  eventName: AuthTrackingEvents.VerifySuccess;
  properties: {
    auth_type: AuthType;
  };
}

export interface VerifyFailureEvent {
  eventName: AuthTrackingEvents.VerifyFailure;
  properties: {
    auth_type: AuthType;
  };
}

export interface MergeChoseLoginEvent {
  eventName: AuthTrackingEvents.MergeChoseLogin;
  properties: {
    auth_type: AuthType;
  };
}

export interface MergeVerifySuccessEvent {
  eventName: AuthTrackingEvents.MergeVerifySuccess;
  properties: {
    auth_type: AuthType;
  };
}

export interface MergeVerifyFailureEvent {
  eventName: AuthTrackingEvents.MergeVerifyFailure;
  properties: {
    auth_type: AuthType;
  };
}

export interface MergeSuccessEvent {
  eventName: AuthTrackingEvents.MergeSuccess;
  properties: {
    auth_type: AuthType;
  };
  userOverride?: string;
}

export interface ViewedFinalizeEvent {
  eventName: AuthTrackingEvents.ViewedFinalize;
  properties: {
    auth_type: AuthType;
  };
}

export interface FinalizeInvalidFormEvent {
  eventName: AuthTrackingEvents.FinalizeInvalidForm;
  properties: {
    auth_type: AuthType;
  };
}

export interface AuthSuccessEvent {
  eventName: AuthTrackingEvents.Success;
  properties: {
    auth_type: AuthType;
    kind: AuthKind;
    page: string;
  };
  userOverride?: string;
}

export interface AuthFailureEvent {
  eventName: AuthTrackingEvents.Failure;
  properties: {
    auth_type?: AuthType;
    message?: string;
  };
}

// Not implemented
export interface ChangeUserIdEvent {
  eventName: AuthTrackingEvents.ChangeUserId;
  properties: {
    kind: AuthKind;
    from_user_id: string;
  };
}

// Not implemented
export interface ViewedCollectEmailEvent {
  eventName: AuthTrackingEvents.ViewedCollectEmail;
  properties: Record<string, never>;
}

// Not implemented
export interface CollectEmailInvalidFormEvent {
  eventName: AuthTrackingEvents.CollectEmailInvalidForm;
  properties: Record<string, never>;
}

// Not implemented
export interface CompletedEmailDetailsEvent {
  eventName: AuthTrackingEvents.CompletedEmailDetails;
  properties: Record<string, never>;
}

// Not implemented
export interface SsoCancelledEvent {
  eventName: AuthTrackingEvents.SsoCancelled;
  properties: Record<string, never>;
}

// Not implemented
export interface MergeResendVerificationCodeFailureEvent {
  eventName: AuthTrackingEvents.MergeResendVerificationCodeFailure;
  properties: {
    auth_type: AuthType;
  };
}

// Not implemented
export interface MergeResendVerificationCodeSuccessEvent {
  eventName: AuthTrackingEvents.MergeResendVerificationCodeSuccess;
  properties: {
    auth_type: AuthType;
  };
}

// Not implemented
export interface CollectPhoneInvalidFormEvent {
  eventName: AuthTrackingEvents.CollectPhoneInvalidForm;
  properties: {
    auth_type: AuthType.Phone;
  };
}
