import {
  LodgingSelectionEnum,
  Lodgings,
  Suggestion,
} from "@b2bportal/lodging-api";
import { Autocomplete, AutocompleteProps } from "../../common";
import { fetchLocations } from "./fetchLocations";
import LocationSvg from "../../../../assets/icons/Location.svg";
import { useTenantIcons } from "@hopper-b2b/utilities";

export interface HotelLocationAutoCompleteProps
  extends Omit<
    AutocompleteProps<Suggestion>,
    "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
  > {
  defaultValue?: Suggestion;
}

export const HotelLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  onChange,
  onOpen,
}: HotelLocationAutoCompleteProps) => {
  const icons = useTenantIcons();

  return (
    <Autocomplete
      id={id}
      key={defaultValue ? "destination-loaded" : "loading-destination"}
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      fetch={fetchLocations}
      groupBy={groupLocations}
      getOptionLabel={getOptionLabel}
      icon={icons.locationAutocompleteMarker ?? LocationSvg}
      onOpen={onOpen}
    />
  );
};

const groupLocations = (value?: Suggestion): LodgingSelectionEnum =>
  (value?.id as Lodgings)?.lodgingSelection?.LodgingSelection;

const getOptionLabel = (value?: Suggestion) => {
  if (
    value?.id.Id === "Lodgings" &&
    value.id.lodgingSelection.LodgingSelection === LodgingSelectionEnum.Place
  ) {
    return value.id.lodgingSelection.searchTerm;
  }
  return value?.label;
};
