import React, { PropsWithChildren } from 'react';

type OrderedList = '1' | 'A' | 'i' | 'a' | 'I' | undefined;
interface NestedOrderedListProps {
  listType: OrderedList;
}
export const NestedOrderedList = (
  props: PropsWithChildren<NestedOrderedListProps>
) => {
  return <ol type={props.listType}>{props.children}</ol>;
};
