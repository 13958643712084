import { useI18nextContext } from "../I18nContext";
import { BrandNames, Currency, FiatPrice } from "@hopper-b2b/types";
import { getLang } from "../utils/getLang";
import { formatCurrencyWithOptions as hopperFormatCurrency } from "@hopper-i18n/formatter";

export function useI18nCurrency() {
  const ctx = useI18nextContext();
  if (ctx === undefined) throw new Error(`must be used within a I18nProvider`);
  const { language, options } = ctx;
  const { resources } = options;

  let selectedCurrency = (resources?.[language]?.["brand"] as BrandNames)
    ?.currency;
  if (selectedCurrency === undefined) {
    // Default to English resource, if language can't be found
    selectedCurrency = (resources?.["en"]?.["brand"] as BrandNames)?.currency;
  }
  const code = selectedCurrency?.code || Currency.USD;
  let locale = selectedCurrency?.locale || "en-US";

  locale = getLang(locale, true);

  const formatCurrency = (
    value: number,
    options?: Intl.NumberFormatOptions
  ) => {
    const fiatCurrency: Omit<FiatPrice, "currencySymbol"> = {
      value,
      currencyCode: code,
    };
    return formatFiatCurrency(fiatCurrency, options);
  };

  const formatFiatCurrency = (
    fiatPrice?: Omit<FiatPrice, "currencySymbol">,
    options?: Intl.NumberFormatOptions
  ) => {
    if (!fiatPrice) return;

    // https://hopper-jira.atlassian.net/wiki/spaces/IEP/pages/6205308931/Tenant-specific+currency+symbols
    // `brandingSymbol` may become undefined in most cases (see the LegacySymbolOverrides table below)
    // and it is fine to pass undefined to `hopperFormatCurrency` function below.
    const brandingSymbol: string | undefined =
      selectedCurrency?.symbolOverride?.[fiatPrice.currencyCode];
    return hopperFormatCurrency({
      currencyCode: fiatPrice.currencyCode,
      locale,
      amount: fiatPrice.value,
      symbol: brandingSymbol,
      options,
    });
  };

  return { formatCurrency, formatFiatCurrency };
}
