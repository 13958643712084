import { LoadingBunny, LoadingText } from "@hopper-b2b/ui";
import "./styles.scss";

export const LoadingContent = ({ message }: { message: string }) => {
  return (
    <div className="auth-loading-content">
      <LoadingBunny classes={["loading-bunny"]} />
      <LoadingText className="auth-loading-message" message={message} />
    </div>
  );
};
