import { footerData } from "@marketing-site/api/common/navigation";
import { LoadingBunny } from "@marketing-site/components/LoadingBunny";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import styled from "styled-components";
import Footer from "@whopper/footer";

export const LoadingPage = () => {
  return (
    <Fragment>
      <NavigationBar />
      <Layout
        container
        justifyContent={"center"}
        alignItems={"center"}
        className={"loading-bunny-container"}
      >
        <Grid
          container
          item
          xs={6}
          sm={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Loading />
          <Text>Just a moment...</Text>
        </Grid>
      </Layout>
      <Footer {...footerData} />
    </Fragment>
  );
};

const Layout = styled(Grid)`
  height: 85vh;
`;

const Loading = styled(LoadingBunny)`
  height: auto;
  width: 100%;
`;
const Text = styled(Typography)`
  padding-top: 12px;
  color: #505050;
`;
