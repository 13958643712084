import { media } from "@marketing-site/styles";
import { IImage } from "@marketing-site/types/shared.model";
import { IStyledBackground } from "@marketing-site/types";
import { getSanitizedDeepLinkUrl } from "@marketing-site/utils/helpers";
import { LocaleContext } from "@marketing-site/utils/hooks/localeContext";
import {
  selectBackgroundColor,
  selectHeroCarouselIndex,
  setBackgroundColor,
  setHeroCarouselIndex,
} from "@marketing-site/redux/utilitiesQuery";
import { ChevronLeft, ChevronRight, Lens } from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swipeable } from "react-swipeable";
import styled from "styled-components";
import Countdown from "../countdown";
import "./styles.scss";

const HeroCarousel = (props: IHeroCarouselProps) => {
  const indexLimit = props.imagesCollection.items.length;
  const dispatch = useDispatch();
  const [id, setId] = useState<ReturnType<typeof setTimeout>>();
  const carouselIndex = useSelector(selectHeroCarouselIndex);
  const backgroundColor = useSelector(selectBackgroundColor);
  const currLocale = useContext(LocaleContext);

  const isMobile = useMediaQuery("(max-width: 960px)");

  useEffect(() => {
    const nextIndex = carouselIndex + 1;
    const newId = setTimeout(() => {
      handleIndexChange(nextIndex);
    }, props.scrollDelay ?? 5000);
    setId(newId);
    return () => clearTimeout(newId);
  }, [carouselIndex]);

  useEffect(() => {
    dispatch(
      setBackgroundColor(
        props.imagesCollection.items[carouselIndex].backgroundColor
      )
    );
  }, [carouselIndex]);

  const handleIndexChange = (nextIndex: number) => {
    nextIndex = nextIndex < 0 ? indexLimit - 1 : nextIndex % indexLimit;
    dispatch(setHeroCarouselIndex(nextIndex));
  };

  const handleClick = (index: number) => {
    handleIndexChange(index);
    return () => clearTimeout(id);
  };

  return (
    <Background
      style={{ transition: "background-color 1s ease-out" }}
      backgroundColor={backgroundColor}
    >
      <Swipeable
        preventDefaultTouchmoveEvent
        onSwipedLeft={() => handleClick(carouselIndex - 1)}
        onSwipedRight={() => handleClick(carouselIndex + 1)}
      >
        <Container>
          {props.countdown && <Countdown {...props.countdown} />}
          {props.imagesCollection.items.map(
            (carouselImage: ICarouselImage, index: number) => {
              return isMobile ? (
                <MobileImage
                  key={index}
                  className={carouselIndex !== index ? "hidden" : "visible"}
                  src={carouselImage.mobileImage.url}
                  onClick={() => {
                    window.location.href = carouselImage.downloadLink;
                  }}
                />
              ) : (
                <DesktopImage
                  key={index}
                  className={carouselIndex !== index ? "hidden" : "visible"}
                  src={carouselImage.desktopImage.url}
                  onClick={() => {
                    window.location.href = getSanitizedDeepLinkUrl(
                      carouselImage.downloadLink,
                      currLocale
                    );
                  }}
                />
              );
            }
          )}
          {indexLimit > 1 && (
            <CarouselNav>
              <ChevronLeft
                className={"arrow"}
                onClick={() => handleClick(carouselIndex - 1)}
              />
              {[...Array(indexLimit)].map((e, i) => (
                <Lens
                  key={i}
                  className={i === carouselIndex ? "dot selected" : "dot"}
                />
              ))}
              <ChevronRight
                className={"arrow"}
                onClick={() => handleClick(carouselIndex + 1)}
              />
            </CarouselNav>
          )}
        </Container>
      </Swipeable>
    </Background>
  );
};

export default HeroCarousel;

HeroCarousel.__typeName = "HeroCarousel";

export interface ICarouselImage {
  title: string;
  backgroundColor: string;
  desktopImage: IImage;
  mobileImage: IImage;
  downloadLink: string;
}

export interface ICarouselImagesCollection {
  items: ICarouselImage[];
}

export interface IHeroCarouselProps {
  headline: string;
  backgroundColor: string;
  flareColor: string;
  scrollDelay: number;
  countdown: any;
  imagesCollection: ICarouselImagesCollection;
}

const Background = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor};
  padding-bottom: 12px;
`;

const CarouselNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesktopImage = styled.img`
  cursor: pointer;
  width: 100%;
  max-width: 60%;
  ${media.desktop_xl_up`
    max-width: 880px;
  `};
`;

const MobileImage = styled.img`
  cursor: pointer;
  width: 100%;
  max-width: 100%;
`;
