import { useExperiment } from "@hopper-b2b/experiments";
import {
  RootProps as HopperSearchProps,
  RootWithAuth as HopperSearchWithAuth,
} from "@hopper-b2b/hopper-search";
import { selectBackgroundColor } from "@marketing-site/redux/utilitiesQuery";
import { media, theme } from "@marketing-site/styles";
import { IStyledBackground } from "@marketing-site/types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Break } from "../_contentful";

export type HopperSearchComponentProps = Omit<
  HopperSearchProps,
  | "isAirEnabled"
  | "isLodgingEnabled"
  | "isStaysEnabled"
  | "isCalendarPredictionEnabled"
> & {
  slug: string;
  firstPageItem: any;
};

export const HopperSearch = ({
  slug,
  firstPageItem,
}: HopperSearchComponentProps) => {
  const globalBackgroundColor = useSelector(selectBackgroundColor);

  const backgroundColor = useMemo(() => {
    const firstItemBackgroundColor = firstPageItem?.backgroundColor;

    if (firstItemBackgroundColor === "global") return globalBackgroundColor;

    const backgroundColor =
      firstPageItem?.cornerColor ?? firstPageItem?.backgroundColor;

    return backgroundColor
      ? (theme.colors as any)?.[backgroundColor] ?? backgroundColor
      : null;
  }, [globalBackgroundColor]);

  const onSearch = (path: string) => {
    window.location.href = path + `&utm_source=hopper_web`;
  };

  const isHome = slug === "deal-drops";

  // A bit hacky but hiding tabs for travel/flights and travel/hotels pages
  const isFlights = slug === "flights";
  const isHotels = slug === "hotels";

  const hopperWebSearchControl = useExperiment("hopper-web-search-control");
  const hopperWebSearchControlAir = useExperiment(
    "hopper-web-search-control-air"
  );
  const hopperWebSearchControlLodging = useExperiment(
    "hopper-web-search-control-lodging"
  );
  const hopperWebStays = useExperiment("hopper-web-stays");

  const isAirEnabled = (isHome || isFlights) && hopperWebSearchControlAir;
  const isLodgingEnabled =
    (isHome || isHotels) && hopperWebSearchControlLodging;
  const isStaysEnabled = isLodgingEnabled && hopperWebStays;

  return hopperWebSearchControl && (isAirEnabled || isLodgingEnabled) ? (
    <>
      <Background backgroundColor={backgroundColor}>
        <SearchContainer>
          <HopperSearchWithAuth
            isAirEnabled={isAirEnabled}
            isLodgingEnabled={isLodgingEnabled}
            isCalendarPredictionEnabled={true}
            isStaysEnabled={isStaysEnabled}
            onSearch={onSearch}
          />
        </SearchContainer>
      </Background>
      {!isHome && (
        <Divider>
          <Break
            isLine={false}
            waveType="valley"
            waveColor="white"
            prevBackgroundColor={backgroundColor}
          />
        </Divider>
      )}
    </>
  ) : null;
};

const Background = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor ?? "transparent"};
  position: relative;
  width: 100vw;
`;

const Divider = styled.div`
  position: relative;
  width: 100vw;
`;

const SearchContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  ${media.tablet`
    max-width: 100%;
  `};
  ${media.tablet_up`
    max-width: 80%;
  `};
  ${media.desktop_xl_up`
    max-width: 1140px;
  `};
`;
