import { createGlobalStyle } from 'styled-components';

import theme from './theme';
import media from './media';

/**
 * injectGlobal is technically an escape hatch provided by styled-components
 * that will enforce global cascading style rules which is against the whole
 * styled-components theory. This is where we define fronts, global resets,
 * and the very base styles.
 */

export default createGlobalStyle`

html,body {
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: #ffffff
}

body {
  font-family: ${theme.fontfamily.sansSerif};
  font-weight: 400;
  margin: 0;
  color: ${theme.colors.black};
  line-height: 20px;
}

.container {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  ${media.desktop`
    padding-left: 30px;
    padding-right: 30px;
    align-items: flex-start;
  `}
}

.section-heading {
  line-height: 16px;
  text-align: center;
  align-items: center;
  color: ${theme.colors.grey[400]};
  font-weight: 400;
  font-size: 16px;
}

h1, h2, h3, h4 {
  color: ${theme.colors.grey[800]};
}

h1 {
  font-size: 72px;
  line-height: 76px;

  ${media.tablet`
    font-size: 46px;
    line-height: 50px;
  `};

  ${media.phone`
    font-size: 42px;
    line-height: 49px;
  `};
}

h2 {
  font-size: 42px;
  line-height: 48px;

  ${media.tablet`
    font-size: 36px;
    line-height: 42px;
  `};

  ${media.phone`
    font-size: 28px;
    line-height: 32px;
  `};
}

h3 {
  // Tablet and desktop sizes are the same
  font-size: 36px;
  line-height: 42px;

  ${media.phone`
    font-size: 28px;
    line-height: 32px;
  `};
}

h4 {
  font-size: 24px;
  line-height: 32px;

  // Phone and tablet sizes are the same
  ${media.tablet`
    font-size: 22px;
  `};
}

h5 {
  font-size: 16px;
  line-height: 19px;
  color: ${theme.colors.grey[400]};
  font-weight: 500;
}

h6 {
  color: ${theme.colors.grey[600]};
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

p {
  color: ${theme.colors.grey[700]};
  font-size: 18px;
  line-height: 26px;
}

b{
  font-weight: 600;
}

a {
  text-decoration: none;
  color: ${theme.colors.skyblue};
  font-weight: 600;
}

`;
