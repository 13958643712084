import React from 'react';
import styled from 'styled-components';
import { RichTextToHtml } from '@marketing-site/utils';
import { IDescriptionPoint } from '@marketing-site/types/description-points.model';
import { media, theme } from '@marketing-site/styles';
import { GetIcon } from '@marketing-site/utils/';
import { Tag } from '@marketing-site/components/tag-label/Tag';

const DescriptionPoint = (props: IDescriptionPoint) => {
  return props.alignment === 'left' ? (
    <DescriptionPointContainer numPerRow={props.total} position={'flex-start'}>
      {props.icon && <Icon src={props.icon.url} alt={props.icon.title} />}
      <div>
        <Headline>{props.headline}</Headline>
        {props.highlightedText && (
          <Tag color={theme.colors.skyblue} text={props.highlightedText} />
        )}
        <Description>{RichTextToHtml(props.description.json)}</Description>
        {props.actionLink && (
          <ActionLink
            href={props.actionLink.url}
            target={props.actionLink.isExternal ? 'blank' : ''}
          >
            {props.actionLink.text} {GetIcon('chevron-right-heavy-blue')}
          </ActionLink>
        )}
      </div>
    </DescriptionPointContainer>
  ) : props.alignment === 'right' ? (
    <DescriptionPointContainer numPerRow={props.total} position={'end'}>
      {props.icon && <Icon src={props.icon.url} alt={props.icon.title} />}
      <div>
        <Headline>{props.headline}</Headline>
        {props.highlightedText && (
          <Tag color={theme.colors.skyblue} text={props.highlightedText} />
        )}
        <Description>{RichTextToHtml(props.description.json)}</Description>
        {props.actionLink && (
          <ActionLink
            href={props.actionLink.url}
            target={props.actionLink.isExternal ? 'blank' : ''}
          >
            {props.actionLink.text} {GetIcon('chevron-right-heavy-blue')}
          </ActionLink>
        )}
      </div>
    </DescriptionPointContainer>
  ) : (
    // Center Align
    <DescriptionPointContainer numPerRow={props.total} position={'center'}>
      {props.icon && (
        <Icon src={props.icon.url} alt={props.icon.title} center />
      )}
      <div>
        <Headline>{props.headline}</Headline>
        {props.highlightedText && (
          <Tag color={theme.colors.skyblue} text={props.highlightedText} />
        )}
        <Description center>
          {RichTextToHtml(props.description.json)}
        </Description>
        {props.actionLink && (
          <ActionLink
            href={props.actionLink.url}
            target={props.actionLink.isExternal ? 'blank' : ''}
          >
            {props.actionLink.text} {GetIcon('chevron-right-heavy-blue')}
          </ActionLink>
        )}
      </div>
    </DescriptionPointContainer>
  );
};

export default DescriptionPoint;

interface ICenter {
  center?: boolean;
}

interface IPositionAlignment {
  position: string;
  numPerRow: number;
}

const DescriptionPointContainer = styled.div<IPositionAlignment>`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  align-items: ${(p: IPositionAlignment) => p.position};
  text-align: ${(p: IPositionAlignment) => p.position};
  margin-bottom: 42px;
  flex: 0 0 ${(p: IPositionAlignment) => Math.floor(100 / p.numPerRow) - 3}%;
  ${media.tablet`
    flex-direction: row;
    text-align: left;
    align-items: flex-start;
  `};
`;

const Icon = styled.img<ICenter>`
  ${(p: ICenter) =>
    p.center
      ? `
        width: 60px;
        height: 60px;
        margin-bottom: 8px;
      `
      : `
      width: 42px;
      height: 42px;
      margin-bottom: 20px;
    `}
  ${media.desktop`
    margin-right: 18px;
  `};
`;

const Headline = styled.h4`
  margin-top: 0;
  margin-bottom: 2px;
  font-weight: 800;
  font-size: 22px;
`;

const Description = styled.div<ICenter>`
  margin-top: 4px;
  margin-bottom: 8px;
  max-width: ${(p: ICenter) => (p.center ? '300px' : 'auto')};
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ActionLink = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: ${theme.colors.skyblue};
  svg {
    margin-bottom: -1px;
  }
`;
