import {
  IGenericSection,
  IImageTextDescription,
} from '@marketing-site/types/shared.model';
import React from 'react';
import styled from 'styled-components';
import { ImageTextDescription } from '../_contentful';
import GenericHeader from './GenericHeader';

export const ImageTextDescriptionSection = (
  props: IGenericSection<IImageTextDescription>
) => {
  return (
    <>
      <GenericHeader
        preheadline={props.preheadline}
        headline={props.headline}
        flareColor={props.flareColor}
        description={props.description}
        subheadline={props.subheadline}
      />
      <ImageTextDescriptionContainer>
        {props.body?.items.map((item: IImageTextDescription, i: number) => {
          return <ImageTextDescription {...item} key={`imageTextDesc-${i}`} />;
        })}
      </ImageTextDescriptionContainer>
    </>
  );
};

const ImageTextDescriptionContainer = styled.div``;
