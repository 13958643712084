import {
  CreateSessionResponseEnum,
  CreateSessionSuccess,
  FinalizeUserResponseEnum,
  FinalizeUserSuccess,
  FinalizedRequired,
  MatchingAccountsFound,
} from "@b2bportal/auth-api";
import { getObjectKeysAsObject } from "@hopper-b2b/checkout";
import { DoneInvokeEvent } from "xstate";
import { AuthMachineContext } from "./context";

export const AuthGuards = {
  isVerifySuccess: (
    _ctx: AuthMachineContext,
    event: DoneInvokeEvent<CreateSessionSuccess | FinalizedRequired>
  ) =>
    event.data.CreateSessionResponse ===
    CreateSessionResponseEnum.CreateSessionSuccess,
  isFinalizeMatchingAccount: (
    _ctx: AuthMachineContext,
    event: DoneInvokeEvent<FinalizeUserSuccess | MatchingAccountsFound>
  ) =>
    event.data.FinalizeUserResponse ===
    FinalizeUserResponseEnum.MatchingAccountsFound,
};

export const AuthGuardTypes = getObjectKeysAsObject(AuthGuards);
