import { theme } from '@marketing-site/styles';
import { IHasChildren } from '@marketing-site/types/shared.model';
import React from 'react';
import styled from 'styled-components';

/**
 *
 * @param Component the wrapper component that contains the text to be flared. e.g. <h1>This text is the child</h1> – the Component in this case must be the h1 element.
 * @returns Component with FlareSVG and a Position component that will position the flare relative to the end of the text. This works for wrapped text as well.
 */
interface Config {
  size?: number;
  color?: string;
  left?: string;
}
export function withFlare<P>(
  Component: React.ComponentType<P>,
  config: Config = {
    size: 35,
    color: theme.colors.skyblue,
    left: '-20%',
  }
): React.FC<P & WithFlareProps> {
  return function NewComponent(props) {
    return (
      <Component {...props}>
        {props.children}
        <Position>
          <FlareSvg
            size={config.size}
            left={config.left}
            color={config.color}
          />
        </Position>
      </Component>
    );
  };
}

/**
 * This is positioned absolutely without a top/right/left/bottom so that it is located relative to the end-of-line of the target text, without taking up space on the DOM. This is so that the flare won't wrap separately from the text.
 */
const Position = styled.span`
  position: absolute;
`;

interface WithFlareProps extends IHasChildren {
  size?: number;
  left?: string;
  color?: string;
  flipped?: boolean;
}
const FlareSvg = ({
  size,
  left,
  color = theme.colors.skyblue,
}: WithFlareProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: 'relative',
        display: 'inline-block',
        verticalAlign: 'top',
        left: `${left}`,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4995 35.3202C38.4538 38.3709 32.4854 39.1421 27.5776 38.9796C22.6698 38.8171 20.5942 36.9208 20.6514 34.7118C20.7086 32.5028 22.7782 30.7695 27.7491 30.9266C32.6569 31.0891 38.5624 31.7494 38.4995 35.3202ZM18.1598 15.8063C21.7888 12.3186 26.404 8.48704 28.7262 11.0105C30.6986 13.175 27.0639 18.0246 23.4979 21.5068C19.8689 24.9945 17.2143 25.1574 15.7408 23.5983C14.2041 22.0447 14.5307 19.294 18.1598 15.8063ZM4.74227 0.000964607C7.67205 0.0737978 8.52669 6.09405 8.4066 11.1607C8.28077 16.163 6.43474 18.2661 4.26751 18.1927C2.10027 18.1193 0.385853 15.9975 0.505946 10.9308C0.631775 5.92848 1.23915 -0.0875172 4.74227 0.000964607Z"
        fill={color}
      />
    </svg>
  );
};

export function withSideFlares<P>(
  Component: React.ComponentType<P>,
  { size, color }: { size?: number; color?: string }
): React.FC<P & WithFlareProps> {
  return function NewComponent(props) {
    return (
      <InlineWrapper>
        <SideFlareSvg size={size} color={color} />
        <Component {...props}>{props.children}</Component>
        <SideFlareSvg size={size} color={color} flipped />
      </InlineWrapper>
    );
  };
}
const InlineWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const SideFlareSvg = ({
  size,
  color = theme.colors.yellow,
  flipped,
}: WithFlareProps) => {
  return (
    <svg
      width="21"
      height="45"
      viewBox="0 0 21 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `scale(${flipped ? -1 : 1},1)`,
        margin: '5px 0.5rem 0',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0852 1.26857C10.9939 -0.583982 15.1343 2.60561 18.0479 5.71948C20.9614 8.83336 21.067 11.2769 19.6701 12.6035C18.2733 13.9301 15.9339 13.7277 12.9782 10.5785C10.0647 7.46459 6.8454 3.43119 9.0852 1.26857ZM9.5458 25.7879C5.16733 25.7094 -0.028668 25.2374 0.100958 22.2558C0.224085 19.7103 5.44551 18.9527 9.78192 18.9958C14.1604 19.0743 15.8909 20.6039 15.8346 22.4699C15.8205 24.3713 13.9243 25.8663 9.5458 25.7879ZM8.0418 43.7703C6.28762 41.9264 9.47401 37.6904 12.6711 34.6408C15.8321 31.6343 18.2621 31.4715 19.5477 32.8475C20.8332 34.2236 20.578 36.5844 17.3809 39.634C14.2199 42.6404 10.1384 45.976 8.0418 43.7703Z"
        fill={color}
      />
    </svg>
  );
};
