"use client";

import { AppRouterCacheProvider } from "@mui/material-nextjs/v14-appRouter";
import { createContext, PropsWithChildren, useContext } from "react";

export type ISSRContextProps = {
  isServer: boolean;
};

export interface ISSRContextProvider {
  locale: string;
  ssrContext: ISSRContextProps;
}

export const defaultSSRContext: ISSRContextProps = {
  isServer: false,
};

export const SSRContext = createContext<ISSRContextProps>(defaultSSRContext);

export const useSSRContext = () => {
  const ctx = useContext(SSRContext);
  if (ctx === undefined)
    throw new Error(`must be used within a SSRContextProvider`);
  return { ...ctx };
};

export const useIsServer = () => {
  const { isServer } = useSSRContext();
  return isServer;
};

const WithAppRouterCacheProvider = ({
  locale,
  children,
}: PropsWithChildren<{ locale: string }>) => {
  const isServer = useIsServer();

  return isServer ? (
    <AppRouterCacheProvider options={{ key: "css" }}>
      {children}
    </AppRouterCacheProvider>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export const SSRContextProvider = ({
  children,
  locale,
  ssrContext,
}: PropsWithChildren<ISSRContextProvider>) => {
  return (
    <SSRContext.Provider value={ssrContext}>
      <WithAppRouterCacheProvider locale={locale}>
        {children}
      </WithAppRouterCacheProvider>
    </SSRContext.Provider>
  );
};
