import { Button, ButtonProps } from "@mui/material";
import clsx from "clsx";

export interface IActionButtonProps {
  className?: string;
  buttonClassName?: string;
  onClick?: (e?: React.MouseEvent) => void;
  disabled?: boolean;
  message: JSX.Element | string | null;
  defaultStyle?: "h4r-primary" | "h4r-secondary";
  icon?: string;
  fill?: "blue" | "red";
  ariaLabelText?: string;
  ariaLabelledBy?: string;
  disableElevation?: boolean;
  size?: "small" | "large" | "medium";
  testId?: string;
  submit?: boolean;
}

export const ActionButton = ({
  className,
  fill,
  message,
  icon,
  variant,
  defaultStyle,
  disableElevation = true,
  size = "large",
  submit = false,
  ...rest
}: IActionButtonProps & ButtonProps) => {
  const buttonVariant = defaultStyle
    ? defaultStyle === "h4r-primary"
      ? "contained"
      : defaultStyle === "h4r-secondary"
      ? "outlined"
      : "contained"
    : "contained";
  return (
    <Button
      className={clsx(
        className,
        defaultStyle,
        fill,
        "hopper-button",
        defaultStyle === "h4r-secondary" ? "secondary" : "primary",
        size ? size : "large"
      )}
      variant={variant ?? buttonVariant}
      color="primary"
      size={size}
      disableElevation={disableElevation}
      startIcon={icon}
      {...(submit ? { type: "submit" } : {})}
      {...rest}
    >
      {message}
    </Button>
  );
};
