import { RegionType, Suggestion } from "@b2bportal/air-shopping-api";
import { Autocomplete, AutocompleteProps } from "../../common";
import { fetchLocations } from "./fetchLocations";
import LocationSvg from "../../../../assets/icons/Location.svg";

export type FlightLocationAutoCompleteProps = Omit<
  AutocompleteProps<Suggestion>,
  "fetch" | "groupBy" | "getOptionLabel" | "sortOptions"
>;

export const FlightLocationAutoComplete = ({
  id,
  defaultValue,
  label,
  disabled,
  onChange,
  onOpen,
  icon,
  additionalSearchControl,
}: FlightLocationAutoCompleteProps) => (
  <Autocomplete
    id={id}
    paperClassName={`${id}-autocomplete-results`}
    defaultValue={defaultValue}
    label={label}
    disabled={disabled}
    onChange={onChange}
    fetch={(apiConfig, search, callback) =>
      fetchLocations(apiConfig, search, callback, additionalSearchControl)
    }
    sortOptions={sortLocations}
    groupBy={groupLocations}
    getOptionLabel={getOptionLabel}
    getGroupLabelKey={getGroupLabelKey}
    icon={icon ? icon : LocationSvg}
    onOpen={onOpen}
  />
);

const groupLocations = (value?: Suggestion) =>
  value?.id.Id === "Flight" ? value?.id?.code?.regionType : undefined;

const sortLocations = (options?: Suggestion[]): Suggestion[] => {
  const cities = [];
  const airports = [];

  options?.forEach((suggestion: Suggestion) => {
    const group: RegionType = groupLocations(suggestion);
    group === RegionType.city
      ? cities.push(suggestion)
      : airports.push(suggestion);
  });

  return [...cities, ...airports];
};

const getOptionLabel = (value?: Suggestion) => value?.label;
const getGroupLabelKey = (group: string) => `searchControl.${group}`;
