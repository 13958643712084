import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";

export const ResendSection = ({ onResend }: { onResend: () => void }) => {
  const { t } = useI18nContext();

  return (
    <div className="resend-section">
      <p className="resend-description">{t("resendDescription")}</p>
      {/* TODO: disable after send set timeout? */}
      <ActionButton
        defaultStyle="h4r-secondary"
        onClick={onResend}
        message={t("resendCode")}
        className="resend-button"
      />
    </div>
  );
};
