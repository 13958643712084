import Box from "@mui/material/Box";
import clsx from "clsx";

import { AirlineIconSize, iconNameToSourceMap, iconSizeMap } from "./types";

import { mapValues } from "lodash-es";
import "./styles.scss";

export interface IAirlineIconProps {
  // An airline code must consist of two uppercase letters or digits
  airlineCode: string;
  size?: AirlineIconSize;
  className?: string;
  altText?: string;
}

const AirlineIcon = (props: IAirlineIconProps) => {
  const {
    size = "large",
    className,
    airlineCode = "PLACEHOLDER",
    altText,
  } = props;
  // an object containing the airline's 2x, 3x, and 4x icons whether they exist or not
  const { large, medium, small } = mapValues(
    iconSizeMap,
    (size) => iconNameToSourceMap[`${airlineCode}-${size}`]
  );
  let airlineIconSource = "";

  switch (size) {
    case "large":
      airlineIconSource = large || medium || small;
      break;
    case "medium":
      airlineIconSource = medium || small;
      break;
    case "small":
      airlineIconSource = small;
  }

  if (!airlineIconSource) {
    airlineIconSource = iconNameToSourceMap[`PLACEHOLDER-${iconSizeMap[size]}`];
  }

  return (
    <Box className={clsx("airline-icon", size, className)}>
      <img alt={altText ?? ""} src={airlineIconSource} />
    </Box>
  );
};

export default AirlineIcon;
