import React, { ReactNode } from "react";
import { XIcon } from "@marketing-site/static/icons";
import { Dialog } from "@mui/material";
import styled from "styled-components";

interface CardModalProps {
  onClose: () => void;
  open: boolean;
  children?: ReactNode | ReactNode[];
}
export const CardModal = (props: CardModalProps) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          boxShadow: "unset",
          borderRadius: 20,
          margin: "0 auto",
          width: "315px",
          height: "522px",
          position: "relative",
        },
      }}
      className={"card-modal-container"}
    >
      <Close onClick={props.onClose} id="card_close_btn">
        <XIcon />
      </Close>
      {props.children}
    </Dialog>
  );
};

const Close = styled.button`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: 1rem;
  top: 0px;
  right: 0px;
  padding: 2px;
  color: #01aae4;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
`;
