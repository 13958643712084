import { mapValues } from "lodash-es";

export const ENABLED = "enabled";
export const DISABLED = "disabled";

/**
 * @deprecated Remove all instances
 */
export enum TenantFlag {
  CustomerSupport = "CustomerSupport",
  FareClassTooltip = "FareClassTooltip",
  FlightSearchCalendarPrediction = "FlightSearchCalendarPrediction",
  FlightShopPrediction = "FlightShopPrediction",
  FlightShopPredictionLearnMore = "FlightShopPredictionLearnMore",
  FlightShopShowFilterNames = "FlightShopShowFilterNames",
  FlightShopShowFilterPriceStationary = "FlightShopShowFilterPriceStationary",
  FlightPriceDropProtection = "FlightPriceDropProtection",
  TenantSupportsViFlights = "TenantSupportsViFlights",
  FlightMobileShopItineraryModal = "FlightMobileShopItineraryModal",
  FlightLocationIataLookup = "FlightLocationIataLookup",
}

/**
 * @deprecated Remove all instances
 */
const mapOfFeatureFlags: Record<TenantFlag, string | undefined> = {
  [TenantFlag.CustomerSupport]: import.meta.env.VITE_CUSTOMER_SUPPORT,
  [TenantFlag.FareClassTooltip]: import.meta.env.VITE_FARE_CLASS_TOOLTIP,
  [TenantFlag.FlightSearchCalendarPrediction]: import.meta.env
    .VITE_FLIGHT_SEARCH_CALENDAR_PREDICTION,
  [TenantFlag.FlightShopPrediction]: import.meta.env
    .VITE_FLIGHT_SHOP_PREDICTION,
  [TenantFlag.FlightShopPredictionLearnMore]: import.meta.env
    .VITE_FLIGHT_SHOP_PREDICTION_LEARN_MORE,
  [TenantFlag.FlightShopShowFilterNames]: import.meta.env
    .VITE_FLIGHT_SHOP_SHOW_FILTER_NAMES,
  [TenantFlag.FlightShopShowFilterPriceStationary]: import.meta.env
    .VITE_FLIGHT_SHOP_SHOW_FILTER_PRICE_STATIONARY,
  [TenantFlag.FlightPriceDropProtection]: import.meta.env
    .VITE_FLIGHT_PRICE_DROP_PROTECTION,
  [TenantFlag.TenantSupportsViFlights]: import.meta.env
    .VITE_TENANT_SUPPORTS_VI_FLIGHTS,
  [TenantFlag.FlightMobileShopItineraryModal]: import.meta.env
    .VITE_FLIGHT_MOBILE_SHOP_ITINERARY_MODAL,
  [TenantFlag.FlightLocationIataLookup]: import.meta.env
    .VITE_FLIGHT_IATA_LOOKUP,
};

/**
 * @deprecated Remove all instances
 */
export const tenantFlagsEnabled = mapValues(
  mapOfFeatureFlags,
  (flag) => flag === ENABLED
);

/**
 * @deprecated Remove all instances
 */
export const tenantFlagsDisabled = mapValues(
  mapOfFeatureFlags,
  (flag) => flag === undefined || flag === DISABLED
);

export enum Environment {
  local = "local",
  development = "development",
  test = "test",
  integration = "integration",
  staging = "staging",
  production = "production",
}

export const BASE_URL = import.meta.env.VITE_BASE_URL;

// TENANT VARIABLES
/**
 * @deprecated Remove all instances
 */
const mapOfEnvVariables = {
  flightPriceChangeDiff: import.meta.env.VITE_FLIGHT_PRICE_CHANGE_DIFF,
  allowedQuoteRetries: import.meta.env.VITE_ALLOWED_QUOTE_RETRIES,
  clientName: import.meta.env.VITE_CLIENT,
  flightSearchLoadingTime: import.meta.env.VITE_FLIGHT_SEARCH_LOADING_TIME,
  pollQuoteLoadingTime: import.meta.env.VITE_POLL_QUOTE_LOADING_TIME,
  cartFulfillLoadingTime: import.meta.env.VITE_CART_FULFILL_LOADING_TIME,
  googleIdentityClientId: import.meta.env.VITE_GOOGLE_IDENTITY_CLIENT_ID,
};

/**
 * @deprecated Remove all instances
 */
export const getEnvVariables = (
  key: keyof typeof mapOfEnvVariables
): string | number | undefined => key && mapOfEnvVariables[key];
