import {
  FOOTER_DATA_EN,
  FOOTER_DATA_FR,
  FOOTER_DATA_ES,
} from "../assets/footerLinks";
import Footer from "./Footer";

interface StaticFooterProps {
  language: string;
}

const StaticFooter = ({ language }: StaticFooterProps) => {
  const footerData = getFooterDataByLocale(language);
  return <Footer {...footerData} />;
};

export default StaticFooter;

const getFooterDataByLocale = (locale: string) => {
  switch (locale) {
    case "en":
      return FOOTER_DATA_EN;
    case "fr":
      return FOOTER_DATA_FR;
    case "es":
      return FOOTER_DATA_ES;
    default:
      return FOOTER_DATA_EN;
  }
};
