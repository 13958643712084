import { useI18nContext } from "@hopper-b2b/i18n";
import { IPassengerCounts } from "@hopper-b2b/types";
import {
  DesktopPopupModal,
  IconComponent,
  IconName,
  PassengerCountPicker,
} from "@hopper-b2b/ui";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import styles from "./TravelerPicker.module.scss";

export type TravelerPickerProps = {
  paxCount: IPassengerCounts;
  disabled?: boolean;
  updatePaxCount: (count: IPassengerCounts) => void;
};

export const TravelerPicker = ({
  paxCount,
  disabled,
  updatePaxCount,
}: TravelerPickerProps) => {
  const { t } = useI18nContext();

  const [showPaxCountPicker, setShowPaxCountPicker] = useState<boolean>(false);

  const numTravelers = Object.values(paxCount).reduce((a, b) => a + b, 0);

  const handleClosePassengerPicker = useCallback(() => {
    setShowPaxCountPicker(false);
  }, []);

  const handlePassengerCountsChange = useCallback(
    (passengerCounts: IPassengerCounts) => {
      updatePaxCount(passengerCounts);
      setShowPaxCountPicker(false);
    },
    [updatePaxCount]
  );

  const tenantIcons = useTenantIcons();
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setShowPaxCountPicker(true)}
        fullWidth
        size="large"
        disabled={disabled}
        classes={{ label: styles.buttonLabel }}
        className="traveler-picker"
        disableRipple
        startIcon={
          tenantIcons.guest ? (
            <img src={tenantIcons.guest} alt="" />
          ) : (
            <IconComponent
              aria-hidden={true}
              className={"iconStart"}
              name={IconName.B2BUser}
              ariaLabel=""
            />
          )
        }
        endIcon={<IconComponent name={IconName.Dropdown} />}
      >
        <Grid container>
          <Grid item>
            <Typography
              color="textSecondary"
              variant="body2"
            >{`${numTravelers} ${t("searchFilter.pickerTraveler", {
              count: numTravelers,
            })}`}</Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Button>
      <DesktopPopupModal
        open={showPaxCountPicker}
        contentClassName="passenger-count-picker-popup-container"
        onClose={handleClosePassengerPicker}
        invisibleBackdrop={false}
      >
        <PassengerCountPicker
          minimumCount={1}
          onClickApply={handlePassengerCountsChange}
          className=""
          setPassengerCounts={(counts: IPassengerCounts) =>
            updatePaxCount(counts)
          }
          counts={paxCount}
          onClose={handleClosePassengerPicker}
        />
      </DesktopPopupModal>
    </>
  );
};
