import React, { useId } from "react";
import TextField, { type TextFieldProps } from "@mui/material/TextField";
import clsx, { ClassValue } from "clsx";
import "./styles.scss";

export type IB2BTextFieldProps = {
  allowInputSizeOverride?: boolean;
  className?: ClassValue;
  defaultValue?: string | number;
  errorHelper?: string;
  id?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  label?: string;
  /**
   * The value of the `input` element, required for a controlled component.
   */
  value?: string;
  width?: string;
} & Omit<TextFieldProps, "onBlur" | "onChange" | "onFocus" | "variant">;

export const B2BTextField = (props: IB2BTextFieldProps) => {
  const {
    onChange,
    onFocus,
    onBlur,
    defaultValue,
    required,
    placeholder,
    label,
    errorHelper,
    type,
    inputMode,
    inputRef,
    value,
    fullWidth,
    width,
    autoFocus,
    disabled,
    error,
    className,
    id,
    allowInputSizeOverride,
    ...muiProps
  } = props;

  const inputId = useInputId(id);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onFocus) {
      onFocus(event.target.value);
    }
  };

  return (
    <TextField
      {...muiProps}
      id={inputId}
      error={error || !!errorHelper}
      className={clsx(
        "b2b-text-field",
        { error: error || !!errorHelper },
        className
      )}
      variant={"filled"}
      type={type}
      inputRef={inputRef}
      InputProps={{
        disableUnderline: true,
        inputProps: {
          inputMode,
        },
        className: allowInputSizeOverride ? "override-size" : "",
      }}
      InputLabelProps={{
        className: "b2b-text-field-label",
      }}
      onChange={(ev) => handleChange(ev)}
      onFocus={(ev) => handleFocus(ev)}
      onBlur={(ev) => handleBlur(ev)}
      label={label}
      value={value}
      placeholder={placeholder}
      defaultValue={defaultValue}
      helperText={errorHelper}
      fullWidth={fullWidth}
      style={{
        width: width,
      }}
      autoFocus={autoFocus}
      disabled={disabled}
      required={required}
    />
  );
};

/**
Custom hook that generates an id in order to always render TextField with an id.
This will prevent an a11y issue "Ensures every form element has a label".
If you need a specific id then you can enforce it by passing `hardcodedId`.
Otherwise, it always generates a unique id using the `useId` hook.
@param hardcodedId - Optional hardcoded id that will come from the `id` prop on `B2BTextField`.
@returns The input id, either the hardcoded id or an id generated by `React.useId`.
*/
function useInputId(hardcodedId?: string) {
  const generatedId = useId();

  return hardcodedId ? hardcodedId : generatedId;
}
