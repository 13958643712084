"use client";

import { trackEvent } from "@hopper-b2b/api";
import { getParentState } from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PhoneInputField } from "@hopper-b2b/ui";
import { phoneRegex } from "@hopper-b2b/utilities";
import { ActionButton, useImageSrc } from "@hopper-b2b/ui-core";
import {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { interpret } from "xstate";
import AuthLoginBunny from "../../assets/icons/auth-login-bunny.png";
import {
  AuthEvents,
  AuthMachine,
  AuthMachineEventType,
  authMachine,
  useAuthState,
} from "../../machine";
import { getIsLoading } from "../../machine/selectors";
import {
  AuthSource,
  AuthTrackingEvents,
  AuthType,
  ChoseSignupEvent,
  CompletedPhoneDetailsEvent,
  ViewedSignupEvent,
} from "../../types/tracking";
import {
  AuthErrorContent,
  FinalizeForm,
  GoogleLogin,
  LoadingContent,
  MergeAccountModal,
  SuccessModal,
  VerifyEmailModal,
  VerifyPhoneModal,
} from "./components";
import { AuthModal } from "./components/AuthModal";
import "./styles.scss";

export type AuthPromptProps = {
  open: boolean;
  onClose: () => void;
  googleIdentityClientId?: string;
  isPhoneAuthEnabled?: boolean;
  isGoogleSsoAuthEnabled?: boolean;
};

export const AuthPrompt = (props: AuthPromptProps) => {
  const service = useRef(undefined);

  useEffect(() => {
    if (!service.current) {
      service.current = interpret(authMachine, {
        devTools: import.meta.env.DEV,
      }).start();
    }

    return () => {
      service.current?.stop();
      service.current = undefined;
    };
  }, []);

  return (
    service.current && (
      <AuthMachine.Provider value={service.current}>
        <AuthMachineMappedComponents {...props} />
      </AuthMachine.Provider>
    )
  );
};

const AuthMachineMappedComponents = ({
  open,
  onClose,
  ...rest
}: AuthPromptProps) => {
  const { t } = useI18nContext();
  const [state] = useAuthState();
  const parentState = getParentState(state.value);

  const loading = getIsLoading(state.value);

  const ModalContent = useMemo(() => {
    if (loading) {
      return <LoadingContent message={t("loading")} />;
    }

    switch (parentState) {
      case "landing":
        return <LandingForm {...rest} />;
      case "googleLogin":
        // Handled in loading state
        return null;
      case "phoneLogin":
        return <VerifyPhoneModal />;
      case "emailLogin":
        return <VerifyEmailModal />;
      case "finalize":
        return <FinalizeForm />;
      case "success":
        return <SuccessModal onClose={onClose} />;
      case "error":
        return <AuthErrorContent onClose={onClose} />;
      case "merge":
        return <MergeAccountModal />;
      default:
        onClose?.();
        return null;
    }
  }, [loading, onClose, parentState, t]);

  const disableOnClose = loading || parentState === "success";

  return (
    <AuthModal open={open} onClose={disableOnClose ? undefined : onClose}>
      <div className="modal-content-container">{ModalContent}</div>
    </AuthModal>
  );
};

export const LandingForm = ({
  googleIdentityClientId,
  isPhoneAuthEnabled,
  isGoogleSsoAuthEnabled,
}: Pick<
  AuthPromptProps,
  "googleIdentityClientId" | "isPhoneAuthEnabled" | "isGoogleSsoAuthEnabled"
>) => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();
  const [phoneError, setPhoneError] = useState(false);
  const AuthLoginBunnySrc = useImageSrc(AuthLoginBunny);
  const { pathname } = window.location;

  const onContinue = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      trackEvent({
        eventName: AuthTrackingEvents.CompletedPhoneDetails,
        properties: {
          auth_type: AuthType.Phone,
        },
      } as CompletedPhoneDetailsEvent);

      send(AuthMachineEventType.SEND_CODE);

      trackEvent({
        eventName: AuthTrackingEvents.ChoseSignup,
        properties: {
          auth_type: AuthType.Phone,
        },
      } as ChoseSignupEvent);

      e.preventDefault();
    },
    [send]
  );

  useEffect(() => {
    let source;
    if (pathname.includes("flights")) {
      source = AuthSource.Flights;
    } else if (pathname.includes("hotels")) {
      source = AuthSource.Hotels;
    }

    trackEvent({
      eventName: AuthTrackingEvents.ViewedSignup,
      ...(source && {
        properties: {
          source,
        },
      }),
    } as ViewedSignupEvent);
  }, [pathname]);

  const onPhoneChange = useCallback(
    (phoneNumber: string, countryCode: string) => {
      if (phoneRegex.test(phoneNumber)) {
        setPhoneError(false);
      } else {
        setPhoneError(true);
      }
      send({
        type: AuthMachineEventType.SET_PHONE_NUMBER,
        phoneNumber: `${countryCode}${phoneNumber}`,
      });
    },
    [send]
  );

  const showGoogleSso = useMemo(
    () => isGoogleSsoAuthEnabled && googleIdentityClientId,
    [isGoogleSsoAuthEnabled, googleIdentityClientId]
  );

  return (
    <div className="auth-prompt-container">
      <img className="auth-bunny" src={AuthLoginBunnySrc} alt="" />
      <h1 className="title">{t("signInOrCreateNewTitle")}</h1>
      {showGoogleSso && (
        <>
          <GoogleLogin googleIdentityClientId={googleIdentityClientId} />
        </>
      )}
      {showGoogleSso && isPhoneAuthEnabled && (
        <div className="auth-divider">
          <p>
            <span>{t("or")}</span>
          </p>
        </div>
      )}
      {isPhoneAuthEnabled && (
        <form onSubmit={onContinue} noValidate>
          <div className="auth-phone-number">
            <PhoneInputField
              label={t("enterPhoneNumber")}
              onChange={(phoneNumber, countryCode) =>
                onPhoneChange(phoneNumber, countryCode)
              }
              hideErrorHelperWhenEmpty
              disabled={false}
            />
          </div>
          <ActionButton
            fullWidth
            className="continue-button"
            type="submit"
            disabled={phoneError}
            message={t("continue")}
          />
        </form>
      )}
    </div>
  );
};
