// English contentful footer data from 09/07/2024.
export const FOOTER_DATA_EN = {
  left: {
    hasLogo: true,
    socialMedia: [
      {
        url: "https://www.facebook.com/hoppertravel",
        platform: "Facebook",
      },
      {
        url: "https://twitter.com/hopper",
        platform: "Twitter",
      },
      {
        url: "https://www.instagram.com/hopper",
        platform: "Instagram",
      },
      {
        url: "https://www.linkedin.com/company/hopper",
        platform: "LinkedIn",
      },
      {
        url: "https://www.youtube.com/user/HopperBoston",
        platform: "YouTube",
      },
      {
        url: "https://www.tiktok.com/@hopper",
        platform: "Tiktok",
      },
    ],
  },
  columns: [
    {
      links: [
        {
          url: "https://hopper.com/travel/flights",
          title: "Flights",
        },
        {
          url: "https://hopper.com/travel/hotels",
          title: "Hotels",
        },
        {
          url: "https://hopper.com/travel/rental-cars",
          title: "Rental Cars",
        },
        {
          url: "https://hopper.com/travel/homes",
          title: "Homes",
        },
      ],
      header: "Travel",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/refer",
          title: "Referral Program",
        },
        {
          url: "https://hopper.com/product/price-prediction",
          title: "Price Prediction",
        },
        {
          url: "https://hopper.com/product/change-for-any-reason",
          title: "Change for Any Reason",
        },
        {
          url: "https://hopper.com/product/cancel-for-any-reason",
          title: "Cancel for Any Reason",
        },
        {
          url: "https://hopper.com/product/leave-for-any-reason",
          title: "Leave for Any Reason",
        },
        {
          url: "https://hopper.com/product/premium-disruption-assistance",
          title: "Premium Disruption Assistance",
        },
        {
          url: "https://hopper.com/product/price-freeze",
          title: "Price Freeze™",
        },
        {
          url: "https://hopper.com/product/vip-support",
          title: "VIP Support",
        },
      ],
      header: "Products",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/about",
          title: "About",
        },
        {
          url: "https://hopper.com/culture",
          title: "Culture",
        },
        {
          url: "https://hopper.com/careers",
          title: "Careers",
        },
        {
          url: "https://hts.hopper.com",
          title: "HTS (Hopper Technology Solutions)",
        },
        {
          url: "https://hosts.hopper.com/",
          title: "Partner with Hopper Homes",
        },
        {
          url: "https://form.typeform.com/to/PVt36UoW",
          title: "Partner with Hopper Hotels",
        },
        {
          url: "https://form.typeform.com/to/AFYX7hU4",
          title: "Advertise on Hopper",
        },
        {
          url: "https://hopper.com/affiliates",
          title: "Hopper Affiliate Program",
        },
      ],
      header: "Company",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://help.hopper.com/en_us/categories/help-with-flights-Hywr1lPz_",
          title: "Flights",
        },
        {
          url: "https://help.hopper.com/en_us/categories/help-with-hotels-SJKr1lwfd",
          title: "Hotels & Homes",
        },
        {
          url: "https://help.hopper.com/en_us/categories/hopper-products-S1oSJlwzd",
          title: "Hopper Products",
        },
        {
          url: "https://help.hopper.com/en_us/categories/billing-and-payments-rJIHJevz_",
          title: "Billing & Payment",
        },
        {
          url: "https://help.hopper.com/en_us/categories/using-the-app-H13HJgvMu",
          title: "Using the App",
        },
        {
          url: "https://hopper.com/legal/privacy-policy",
          title: "Privacy Notice",
        },
        {
          url: "https://hopper.com/legal/terms-and-conditions",
          title: "Terms & Conditions",
        },
      ],
      header: "FAQs",
      isExternal: true,
    },
    {
      links: [
        {
          url: "https://media.hopper.com/news",
          title: "News",
        },
        {
          url: "https://media.hopper.com/press-kit",
          title: "Press Kit",
        },
        {
          url: "https://media.hopper.com/research",
          title: "Research",
        },
        {
          url: "https://media.hopper.com/enquiries",
          title: "Media Enquiries",
        },
      ],
      header: "Media",
      isExternal: true,
    },
  ],
};

// Spanish contentful footer data from 09/07/2024.
export const FOOTER_DATA_ES = {
  left: {
    hasLogo: true,
    socialMedia: [
      {
        url: "https://www.facebook.com/hoppertravel",
        platform: "Facebook",
      },
      {
        url: "https://twitter.com/hopper",
        platform: "Twitter",
      },
      {
        url: "https://www.instagram.com/hopper",
        platform: "Instagram",
      },
      {
        url: "https://www.linkedin.com/company/hopper",
        platform: "LinkedIn",
      },
      {
        url: "https://www.youtube.com/user/HopperBoston",
        platform: "YouTube",
      },
      {
        url: "https://medium.com/@hopper_travel",
        platform: "Medium",
      },
    ],
  },
  columns: [
    {
      links: [
        {
          url: "https://hopper.com/es/travel/flights",
          title: "Vuelos",
        },
        {
          url: "https://hopper.com/es/travel/hotels",
          title: "Hoteles",
        },
        {
          url: "https://hopper.com/es/travel/rental-cars",
          title: "Vehículos",
        },
        {
          url: "https://hopper.com/es/travel/homes",
          title: "Viviendas",
        },
      ],
      header: "Viajes",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/es/refer",
          title: "Programa de referidos",
        },
        {
          url: "https://hopper.com/es/eproduct/price-prediction",
          title: "Predicción de precios",
        },
        {
          url: "https://hopper.com/es/product/change-for-any-reason",
          title: "Cambiar por cualquier motivo",
        },
        {
          url: "https://hopper.com/es/product/cancel-for-any-reason",
          title: "Cancelar por cualquier motivo",
        },
        {
          url: "https://hopper.com/es/product/leave-for-any-reason",
          title: "Abandonar por cualquier motivo",
        },
        {
          url: "https://hopper.com/es/product/premium-disruption-assistance",
          title: "Asistencia prémium en caso de interrupción",
        },
        {
          url: "https://hopper.com/es/product/price-freeze",
          title: "Congelar precio™",
        },
        {
          url: "https://hopper.com/es/product/vip-support",
          title: "Atención VIP",
        },
      ],
      header: "Productos",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/es/about/",
          title: "¿Quiénes somos?",
        },
        {
          url: "https://hopper.com/es/culture/",
          title: "Cultura",
        },
        {
          url: "https://hopper.com/es/careers/",
          title: "Oportunidades laborales",
        },
        {
          url: "https://hts.hopper.com/",
          title: "HTS (Hopper Technology Solutions)",
        },
        {
          url: "https://hosts.hopper.com/",
          title: "Únete a viviendas Hopper",
        },
        {
          url: "https://form.typeform.com/to/PVt36UoW",
          title: "Únete a hoteles Hopper",
        },
        {
          url: "https://hopper.com/es/affiliates",
          title: "Programa de afiliados de Hopper",
        },
      ],
      header: "Compañía",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://help.hopper.com/es/categories/ayuda-con-los-vuelos-Hywr1lPz_",
          title: "Vuelos",
        },
        {
          url: "https://help.hopper.com/es/categories/ayuda-con-hoteles-SJKr1lwfd",
          title: "Hoteles y viviendas",
        },
        {
          url: "https://help.hopper.com/es/categories/productos-y-servicios-de-hopper-S1oSJlwzd",
          title: "Productos Hopper",
        },
        {
          url: "https://help.hopper.com/es/categories/facturacion-y-pagos-rJIHJevz_",
          title: "Facturación y pagos",
        },
        {
          url: "https://help.hopper.com/es/categories/asistencia-con-la-aplicacin-H13HJgvMu",
          title: "Uso de la app",
        },
        {
          url: "https://hopper.com/es/legal/privacy-policy",
          title: "Aviso de privacidad",
        },
        {
          url: "https://hopper.com/es/legal/terms-and-conditions",
          title: "Términos y Condiciones",
        },
      ],
      header: "Preguntas frecuentes",
      isExternal: true,
    },
  ],
};

// French contentful footer data from 09/07/2024.
export const FOOTER_DATA_FR = {
  left: {
    hasLogo: true,
    socialMedia: [],
  },
  columns: [
    {
      links: [
        {
          url: "https://hopper.com/fr/travel/flights",
          title: "Vols",
        },
        {
          url: "https://hopper.com/fr/travel/hotels",
          title: "Hôtels",
        },
        {
          url: "https://hopper.com/fr/travel/rental-cars",
          title: "Voitures",
        },
        {
          url: "https://hopper.com/fr/travel/homes",
          title: "Logements",
        },
      ],
      header: "Voyages",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/fr/refer",
          title: "Programme de parrainage",
        },
        {
          url: "https://hopper.com/fr/product/price-prediction",
          title: "Prévision des prix",
        },
        {
          url: "https://hopper.com/fr/product/change-for-any-reason",
          title: "Modification sans motif",
        },
        {
          url: "https://hopper.com/fr/product/cancel-for-any-reason",
          title: "Annulation sans motif",
        },
        {
          url: "https://hopper.com/fr/product/leave-for-any-reason",
          title: "Départ anticipé pour tout motif",
        },
        {
          url: "https://hopper.com/fr/product/premium-disruption-assistance",
          title: "Assistance premium en cas de perturbations",
        },
        {
          url: "https://hopper.com/fr/product/price-freeze",
          title: "Gel de prix™",
        },
        {
          url: "https://hopper.com/fr/product/vip-support",
          title: "Assistance VIP",
        },
      ],
      header: "Produits",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/fr/about",
          title: "À propos",
        },
        {
          url: "https://hopper.com/fr/culture",
          title: "Culture",
        },
        {
          url: "https://hopper.com/fr/careers",
          title: "Carrières",
        },
        {
          url: "https://hts.hopper.com",
          title: "HTS (Hopper Technology Solutions)",
        },
        {
          url: "https://hosts.hopper.com/",
          title: "Partenariat avec Hopper Homes",
        },
        {
          url: "https://form.typeform.com/to/PVt36UoW",
          title: "Partenariat avec Hopper Hotels",
        },
        {
          url: "https://hopper.com/fr/affiliates",
          title: "Programme d'affiliation Hopper",
        },
      ],
      header: "Société",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://help.hopper.com/fr/categories/assistance-avec-les-vols-Hywr1lPz_",
          title: "Vols",
        },
        {
          url: "https://help.hopper.com/fr/categories/assistance-avec-les-hotels-SJKr1lwfd",
          title: "Hôtels et logements",
        },
        {
          url: "https://help.hopper.com/fr/categories/produits-et-services-de-hopper-S1oSJlwzd",
          title: "Produits Hopper",
        },
        {
          url: "https://help.hopper.com/fr/categories/facturation-et-paiement-rJIHJevz_",
          title: "Facturation et paiement",
        },
        {
          url: "https://help.hopper.com/fr/categories/assistance-avec-lapplication-H13HJgvMu",
          title: "Utilisation de l'application",
        },
        {
          url: "https://hopper.com/fr/legal/privacy-policy",
          title: "Avis de confidentialité",
        },
        {
          url: "https://hopper.com/fr/legal/terms-and-conditions",
          title: "Conditions générales",
        },
      ],
      header: "FAQs",
      isExternal: true,
    },
    {
      links: [
        {
          url: "https://media.hopper.com/fr/news",
          title: "Informations",
        },
        {
          url: "https://media.hopper.com/fr/press-kit",
          title: "Dossier de presse",
        },
        {
          url: "https://media.hopper.com/fr/enquiries",
          title: "Demandes de presse",
        },
      ],
      header: "Médias",
      isExternal: true,
    },
  ],
};
