"use client";

import {
  END_DATE,
  FirstDayOfWeek,
  FocusedInput,
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
} from "@datepicker-react/hooks";

import { getLang } from "@hopper-b2b/i18n";
import { IMonthBucket } from "@hopper-b2b/types";
import { dayCountBelowMax, isBetweenDays } from "@hopper-b2b/utilities";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import * as React from "react";
import { useState } from "react";
import { context as DateRangePickerContext } from "./context";
import {
  ColumnView,
  GroupView,
  HorizontalView,
  PriceRangeTags,
} from "./components";
import * as textConstant from "./constants";

import { PickerType } from "./types";

dayjs.extend(isBetween);

export interface IDateRangePickerProps {
  pickerType?: PickerType;
  startDate: Date | null;
  endDate: Date | null;
  months: IMonthBucket[] | null;
  columnView?: boolean;
  // focusedMonthIndex must be given in order to make columnView focus on an arbitrary month
  // note that this was not previously handled in the old design since it was always showing 2 months
  focusedMonthIndex?: number;
  // setStartDate and setEndDate must be given for rendering the horizontal/columnView
  setStartDate?: (date: Date | null) => void;
  setEndDate?: (date: Date | null) => void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  minNumDays?: number;
  maxNumDays?: number;
  groupView?: boolean;
  // setFocusedMonthIndex must be given for working with the groupView + columnView combination
  setFocusedMonthIndex?: (index: number) => void;
  // for coloured price tags
  showPriceRangeTags?: boolean;
  priceTags: string[];
  currency: string;
  // hide airline icon for cars module
  hideSelectedDateIcon?: boolean;
  startDateLabel?: string;
  endDateLabel?: string;
  className?: string;
  assets?: Record<string, any>;
  isMissingDate?: boolean;
  preventSameDateSelection?: boolean;
}
export interface IDateRangePickerState {
  focusedInput: FocusedInput;
}

const initialState: IDateRangePickerState = {
  focusedInput: START_DATE,
};

const dayCountAboveMin = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minNumDays?: number
) => {
  if (!minNumDays) {
    return true;
  }

  if (startDate && endDate) {
    return true;
  }

  // If only one of startDate or endDate is set, we don't really know if it's really a "start" or an "end".
  // We will allow dates in both past and future directions from such date.

  if (startDate) {
    return !dayjs(date).isBetween(
      dayjs(startDate).subtract(minNumDays - 1, "day"),
      dayjs(startDate).add(minNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  if (endDate) {
    return !dayjs(date).isBetween(
      dayjs(endDate).subtract(minNumDays - 1, "day"),
      dayjs(endDate).add(minNumDays - 1, "day"),
      "day",
      "[]"
    );
  }

  return true;
};

const dayCountWithinRange = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minNumDays?: number,
  maxNumDays?: number
) => {
  return (
    dayCountAboveMin(date, startDate, endDate, minNumDays) &&
    dayCountBelowMax(date, startDate, endDate, maxNumDays)
  );
};

const isDateAllowed = (
  date: Date,
  startDate: Date | null,
  endDate: Date | null,
  minAllowedDate?: Date,
  maxAllowedDate?: Date,
  minNumDays?: number,
  maxNumDays?: number
) => {
  return (
    isBetweenDays(date, minAllowedDate, maxAllowedDate) &&
    dayCountWithinRange(date, startDate, endDate, minNumDays, maxNumDays)
  );
};

const generateOnDateSelect =
  ({
    startDate,
    endDate,
    handleDateChange,
    pickerType,
    preventSameDateSelection,
  }: {
    startDate: Date | null;
    endDate: Date | null;
    handleDateChange: (args: OnDatesChangeProps) => void;
    pickerType: PickerType;
    preventSameDateSelection: boolean;
  }) =>
  (date: Date) => {
    if (pickerType === PickerType.RANGE && startDate) {
      if (date >= startDate && !endDate) {
        if (
          !(preventSameDateSelection && dayjs(date).isSame(startDate, "day"))
        ) {
          handleDateChange({
            startDate: startDate,
            endDate: date,
            focusedInput: END_DATE,
          });
        }
      } else {
        handleDateChange({
          startDate: date,
          endDate: null,
          focusedInput: START_DATE,
        });
      }
    } else {
      handleDateChange({
        startDate: date,
        endDate: null,
        focusedInput: START_DATE,
      });
    }
  };

const generateIsDateHovered =
  ({
    startDate,
    endDate,
    hoveredDate,
    pickerType,
    isDateAllowed,
    minAllowedDate,
    maxAllowedDate,
    minNumDays,
    maxNumDays,
  }: {
    startDate: Date | null;
    endDate: Date | null;
    hoveredDate: Date | null;
    pickerType: PickerType;
    isDateAllowed: (
      date: Date,
      startDate: Date | null,
      endDate: Date | null,
      minAllowedDate?: Date,
      maxAllowedDate?: Date,
      minNumDays?: number,
      maxNumDays?: number
    ) => boolean;
    minAllowedDate?: Date;
    maxAllowedDate?: Date;
    minNumDays?: number;
    maxNumDays?: number;
  }) =>
  (date: Date) => {
    if (
      pickerType === PickerType.RANGE &&
      hoveredDate &&
      startDate &&
      !endDate &&
      (startDate <= hoveredDate
        ? dayjs(date).isBetween(startDate, hoveredDate, "day", "[]")
        : dayjs(date).isBetween(hoveredDate, startDate, "day", "[]"))
    ) {
      if (
        isDateAllowed(
          date,
          startDate,
          endDate,
          minAllowedDate,
          maxAllowedDate,
          minNumDays,
          maxNumDays
        )
      ) {
        return true;
      }
      if (
        isDateAllowed(
          date,
          startDate,
          endDate,
          minAllowedDate,
          maxAllowedDate,
          minNumDays,
          maxNumDays
        ) &&
        isDateAllowed(
          hoveredDate,
          startDate,
          endDate,
          minAllowedDate,
          maxAllowedDate,
          minNumDays,
          maxNumDays
        )
      ) {
        return true;
      }
    }

    return (
      pickerType === PickerType.DAY &&
      !!hoveredDate &&
      isDateAllowed(
        hoveredDate,
        startDate,
        endDate,
        minAllowedDate,
        maxAllowedDate,
        minNumDays,
        maxNumDays
      ) &&
      dayjs(date).isSame(hoveredDate, "day")
    );
  };

export const firstWeekDay = (lang: string): number => {
  try {
    const intLoc = new Intl.Locale(lang);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Remove once weekInfo is fully supported
    return intLoc?.weekInfo.firstDay || 0;
  } catch (e) {
    return 0;
  }
};

export const DateRangePicker = ({
  pickerType = PickerType.RANGE,
  startDate,
  endDate,
  columnView,
  focusedMonthIndex,
  setStartDate,
  setEndDate,
  months = [],
  minAllowedDate,
  maxAllowedDate,
  minNumDays,
  maxNumDays,
  groupView,
  setFocusedMonthIndex,
  showPriceRangeTags = false,
  priceTags,
  hideSelectedDateIcon,
  startDateLabel,
  endDateLabel,
  className,
  assets,
  isMissingDate = false,
  preventSameDateSelection,
}: IDateRangePickerProps) => {
  const [state, setState] = useState(initialState);
  const { focusedInput } = state;
  const [ariaLiveMessage, setAriaLiveMessage] = React.useState("");

  // If endDate is before startDate, we remove it to ensure this component does not throw.
  const cleanedEndDate =
    endDate && startDate && endDate < startDate ? null : endDate;

  const createAriaLiveMessage = (
    startDate: Date | null,
    endDate: Date | null
  ) => {
    const startDateMessage = startDate
      ? `${dayjs(startDate).format("MMMM DD, YYYY")}`
      : "";
    const endDateMessage = endDate
      ? `${dayjs(endDate).format("MMMM DD, YYYY")}`
      : "";
    if (startDateMessage)
      setAriaLiveMessage(
        textConstant.SELECTED_DATE(startDateMessage, endDateMessage)
      );
  };

  const handleDateChange = ({
    startDate,
    endDate,
    focusedInput,
  }: OnDatesChangeProps) => {
    if (!focusedInput) {
      setState({ focusedInput: START_DATE });
    } else {
      setState({ focusedInput });
    }
    if (startDate) createAriaLiveMessage(startDate, endDate);
    if (setStartDate && focusedInput === "startDate") {
      setStartDate(startDate);
    }
    if (setEndDate && focusedInput === "endDate") {
      setEndDate(endDate);
    } else if (setEndDate) {
      setEndDate(null);
    }
  };

  const numberOfMonths = groupView || columnView ? 12 : 2;
  const firstDayOfWeek = firstWeekDay(getLang()) as FirstDayOfWeek; // 0 is Sunday.

  const {
    isDateSelected,
    isFirstOrLastSelectedDate,
    isDateFocused,
    focusedDate,
    onDateHover,
    onDateFocus,
    onResetDates,
    hoveredDate,
    goToPreviousMonths,
    goToNextMonths,
  } = useDatepicker({
    firstDayOfWeek: firstDayOfWeek,
    startDate,
    endDate: cleanedEndDate,
    focusedInput,
    onDatesChange: handleDateChange,
    numberOfMonths,
  });

  const isDateHovered = generateIsDateHovered({
    startDate,
    endDate,
    hoveredDate,
    pickerType,
    isDateAllowed,
    minAllowedDate,
    maxAllowedDate,
    minNumDays,
    maxNumDays,
  });

  const onDateSelect = generateOnDateSelect({
    startDate,
    endDate,
    handleDateChange,
    pickerType,
    preventSameDateSelection,
  });

  const isDateBlockedFunction = (date: Date) =>
    !isDateAllowed(
      date,
      startDate,
      endDate,
      minAllowedDate,
      maxAllowedDate,
      minNumDays,
      maxNumDays
    );
  const priceRangeTags = !!priceTags.length && (
    <PriceRangeTags priceTags={priceTags} className={className} />
  );

  return (
    <DateRangePickerContext.Provider
      value={{
        focusedDate,
        hoveredDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked: isDateBlockedFunction,
        isFirstOrLastSelectedDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        onResetDates,
        goToPreviousMonths,
        goToNextMonths,
        startDate,
        endDate,
        focusedInput,
        pickerType,
        months,
      }}
    >
      {columnView && !groupView ? (
        <ColumnView
          className={className}
          startDate={startDate}
          cleanedEndDate={cleanedEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          minAllowedDate={minAllowedDate ?? null}
          maxAllowedDate={maxAllowedDate ?? null}
          numberOfMonths={numberOfMonths}
          focusedMonthIndex={focusedMonthIndex}
          pickerType={pickerType}
          priceRangeTags={priceRangeTags}
          hideSelectedDateIcon={hideSelectedDateIcon}
          startDateLabel={startDateLabel}
          endDateLabel={endDateLabel}
          assets={assets}
          isMissingDate={isMissingDate}
        />
      ) : !columnView && groupView ? (
        <GroupView
          minAllowedDate={minAllowedDate ?? null}
          maxAllowedDate={maxAllowedDate ?? null}
          setFocusedMonthIndex={setFocusedMonthIndex}
          numberOfMonths={numberOfMonths}
          priceRangeTags={priceRangeTags}
        />
      ) : (
        <HorizontalView
          className={className}
          startDate={startDate}
          cleanedEndDate={cleanedEndDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          minAllowedDate={minAllowedDate ?? null}
          maxAllowedDate={maxAllowedDate ?? null}
          numberOfMonths={numberOfMonths}
          showPriceRangeTags={showPriceRangeTags}
          priceRangeTags={priceRangeTags}
          priceTags={priceTags}
        />
      )}
      <div aria-live="polite" className="sr-only">
        {ariaLiveMessage}
      </div>
    </DateRangePickerContext.Provider>
  );
};
