import { UserInfoField, VerificationMethod } from "@b2bportal/auth-api";
import { AuthType } from "../types/tracking";

export enum AuthMachineEventType {
  VERIFY_CODE = "VERIFY_CODE",
  SEND_CODE = "SEND_CODE",
  RESEND = "RESEND",
  GOOGLE_SIGN_IN = "GOOGLE_SIGN_IN",
  ERROR = "ERROR",
  TRY_AGAIN = "TRY_AGAIN",
  FINALIZE_USER = "FINALIZE_USER",
  VERIFY = "VERIFY",
  OTHER_OPTIONS = "OTHER_OPTIONS",
  SET_PHONE_NUMBER = "SET_PHONE_NUMBER",
  SET_EMAIL = "SET_EMAIL",
  SET_PREVIOUS_USER_TRACKING = "SET_PREVIOUS_USER_TRACKING",

  // NOT USED
  SET_AUTH_TOKEN = "SET_AUTH_TOKEN",
  SET_VERIFICATION_ALTERNATIVE = "SET_VERIFICATION_ALTERNATIVE",
  SET_ERROR = "SET_ERROR",
  SET_VERIFY_CODE_RESPONSE = "SET_VERIFY_CODE_RESPONSE",
}

export interface VerifyCodeEvent {
  type: AuthMachineEventType.VERIFY_CODE;
  code: string;
  auth_type: AuthType;
}

export interface ResendCodeEvent {
  type: AuthMachineEventType.RESEND;
  verificationMethod: VerificationMethod;
}

export interface AdditionalVerifyOptionEvent {
  type: AuthMachineEventType.OTHER_OPTIONS;
}

export interface GoogleSignInEvent {
  type: AuthMachineEventType.GOOGLE_SIGN_IN;
  idToken: string;
}

export interface SendErrorEvent {
  type: AuthMachineEventType.ERROR;
}

export interface TryAgainEvent {
  type: AuthMachineEventType.TRY_AGAIN;
}

export interface FinalizeUserEvent {
  type: AuthMachineEventType.FINALIZE_USER;
  missingFields: Record<UserInfoField, string>;
}

export interface VerifyEvent {
  type: AuthMachineEventType.VERIFY;
}

// NOT USED
export interface SetAuthTokenEvent {
  type: AuthMachineEventType.SET_AUTH_TOKEN;
  token: string;
}

export interface SetPhoneNumberEvent {
  type: AuthMachineEventType.SET_PHONE_NUMBER;
  phoneNumber: string;
}

export interface SetEmailEvent {
  type: AuthMachineEventType.SET_EMAIL;
  email: string;
}

export interface SetPreviousUserTrackingEvent {
  type: AuthMachineEventType.SET_PREVIOUS_USER_TRACKING;
  previousUserTracking: string;
}
export interface SetVerificationAlternativeEvent {
  type: AuthMachineEventType.SET_VERIFICATION_ALTERNATIVE;
  verificationAlternative?: { redactedEmail?: string; redactedPhone?: string };
}

export interface SetErrorEvent {
  type: AuthMachineEventType.SET_ERROR;
  error: string;
}

export interface SetVerifyCodeResponse {
  type: AuthMachineEventType.SET_VERIFY_CODE_RESPONSE;
  token: string;
  missingFields: Array<UserInfoField>;
}

export interface SendCodeEvent {
  type: AuthMachineEventType.SEND_CODE;
}

export type AuthEvents =
  | SetAuthTokenEvent
  | SetPhoneNumberEvent
  | SetEmailEvent
  | SetVerificationAlternativeEvent
  | SetErrorEvent
  | VerifyEvent
  // ABOVE NOT USED
  | FinalizeUserEvent
  | VerifyCodeEvent
  | SendCodeEvent
  | ResendCodeEvent
  | GoogleSignInEvent
  | SendErrorEvent
  | TryAgainEvent
  | SetPreviousUserTrackingEvent
  | AdditionalVerifyOptionEvent;
