import React from 'react';
import { Experiment, Variant } from 'react-optimize';
import { Control, CarrotCash } from './variants';

const Takeover = (props: any) => {
  /**
   * We need to set up some sort of validation to allow different tests to run at one time
   * is there a way to check if a test is running on a page?
   * Built with https://github.com/hudovisk/react-optimize
   * Currently set up for a/b test, but for multivariant we can refactor this to create the
   * different experiments/flows programatically, assuming we know how many we're using.
   */

  // return process.env.GATSBY_OPTIMIZE_EXPERIMENT_ID_TAKEOVER ? (
  //   <Experiment id={process.env.GATSBY_OPTIMIZE_EXPERIMENT_ID_TAKEOVER}>
  //     <Variant id="0">
  //       <Control {...props} />
  //     </Variant>
  //     <Variant id="1">
  //       <CarrotCash />
  //     </Variant>
  //     <Variant id="2">
  //       {/* Can be modified in Optimize */}
  //       <Control {...props} />
  //     </Variant>
  //     <Variant id="3">
  //       {/* Can be modified in Optimize */}
  //       <Control {...props} />
  //     </Variant>
  //     <Variant id="4">
  //       {/* Can be modified in Optimize */}
  //       <Control {...props} />
  //     </Variant>
  //   </Experiment>
  // ) : (
  //   <Control {...props} />
  // );

  return <Control {...props} />;
};

export default Takeover;
