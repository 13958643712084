import { IHasChildren } from "@marketing-site/types/shared.model";
import { IStyledBackground } from "@marketing-site/types";
import React from "react";
import styled from "styled-components";

export function withBackgroundColor<P>(
  Component: React.ComponentType<P>,
  bgColor: string
): React.FC<P & IHasChildren> {
  return function ComponentWithBackground(props) {
    // Some components use background color to set the behavior of child components e.g. CtaButtons which need to know the background color to handle the hover effects.
    return (
      <BackgroundWrapper backgroundColor={bgColor}>
        <Component {...props} backgroundColor={bgColor}>
          {props.children}
        </Component>
      </BackgroundWrapper>
    );
  };
}

const BackgroundWrapper = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor};
`;
