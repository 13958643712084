import React, { useContext } from "react";
import styled from "styled-components";
import { theme } from "@marketing-site/styles";
import { Countdown } from "@marketing-site/components/_contentful";
import { IStyledCallout, IStyledSaleItem } from "@marketing-site/types";
import { RichTextToHtml } from "@marketing-site/utils";
import BadgeSticker from "./badgeSticker";
import { ISale } from "@marketing-site/types/sale.model";
import { getSanitizedDeepLinkUrl } from "@marketing-site/utils/helpers";
import { LocaleContext } from "@marketing-site/utils/hooks/localeContext";
interface IIndexable<T = any> {
  [key: string]: T;
}

const SaleItem = (props: ISale) => {
  // We want to strip special characters from the headline to pass to
  // AppsFlyer as an af_ad param to get more fine-tuned results of what
  // tiles are performing better.
  const regex = /[-&,()./]/g;
  const regexDoubleSpace = /\s\s/g;
  const regexSpaces = /\s/g;
  const strippedHeadlineParam = props.headline
    .trim()
    .replace(regex, "")
    .replace(regexDoubleSpace, "_")
    .replace(regexSpaces, "_")
    .toLowerCase();
  const appsFlyerAddtlParam = `&af_ad=${strippedHeadlineParam}${
    props.isLightningDeal ? "_lightning" : ""
  }`;
  const currLocale = useContext(LocaleContext);
  // we want to add af_ad before af_web_dp since any params passed after will be part of that "deeplink" url
  // if the string contains af_web_dp, slice and insert before
  const afWebDpIndex = props.actionLink.indexOf("&af_web_dp");
  const saleItemActionLinkAddtlParam =
    afWebDpIndex > -1
      ? props.actionLink.slice(0, afWebDpIndex) +
        appsFlyerAddtlParam +
        props.actionLink.slice(afWebDpIndex)
      : props.actionLink + appsFlyerAddtlParam;

  return (
    <Container>
      <TopWrapper>
        <Top img={props.image?.url} missedSale={props.missedSale}>
          {props.isLightningDeal && (
            <Callout
              bgColor={(theme.colors as IIndexable)[props.calloutColor!]}
            >
              <span role="img" aria-label="lightning">
                ⚡
              </span>{" "}
              LIGHTNING DEAL
            </Callout>
          )}
          {(props.showSaleBadge || props.showSaleBadge === null) && (
            <Badge>
              <StickerWrapper>
                <BadgeSticker
                  color={(theme.colors as IIndexable)[props.badgeColor]}
                  id={props.sys.id}
                />

                <Discount>{props.discount}</Discount>
                {(props.isDiscount || props.isDiscount === null) && (
                  <Off>OFF</Off>
                )}
              </StickerWrapper>
            </Badge>
          )}
        </Top>
        <Feature>{props.headline}</Feature>
        {!!props.subheadline?.json && (
          <Subheader>{RichTextToHtml(props.subheadline.json)}</Subheader>
        )}
      </TopWrapper>
      <span></span>
      <Bottom>
        <Action
          href={getSanitizedDeepLinkUrl(
            saleItemActionLinkAddtlParam.trim(),
            currLocale
          )}
        >
          {props.actionText}
        </Action>
        {!props.missedSale && props.countdown && (
          <Countdown {...props.countdown} />
        )}
      </Bottom>
    </Container>
  );
};

export default SaleItem;

const Container = styled.div`
  // Added to make sales accessible if by the web booking component (temp fix)
  z-index: 10;
  position: relative;

  display: flex;
  height: auto;
  flex-direction: column;

  box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);
  border-radius: 20px;

  span {
    flex-grow: 1;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.div<IStyledSaleItem>`
  position: relative;
  background-image: url(${(p) => p.img});
  background-position: center bottom;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 20px 20px 0 0;
  height: 100px;
  margin-bottom: 0;

  ${(p) => p.missedSale && "filter: grayscale(1);"};
`;

const Callout = styled.div<IStyledCallout>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  margin: 4px;
  padding: 4px 8px;
  border-radius: 500px;
  background-color: ${(p) => p.bgColor};
  color: ${(p) => p.theme.colors.grey[800]};
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
Callout.defaultProps = {
  bgColor: "rgba(255, 255 ,255, 0.9)",
};

const Badge = styled.div`
  float: right;
  margin-right: 8px;
`;

const StickerWrapper = styled.div`
  position: relative;
  top: 8px;
`;

const Discount = styled.p`
  font-weight: 800;
  font-size: 14px;
  color: ${theme.colors.white};
  margin-top: -48px;
  text-align: center;
`;

const Off = styled.p`
  color: ${theme.colors.white};
  font-weight: 800;
  font-size: 10px;
  text-align: center;
  margin-top: -28px;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2px 14px 14px 14px;
`;

const Feature = styled.p`
  font-weight: 800;
  font-size: 19px;
  line-height: 20px;
  color: ${theme.colors.grey[800]};
  margin: 4px 14px 4px 14px;
`;

const Subheader = styled.div`
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${(p) => p.theme.colors.grey[600]};
    margin: 0px 14px 8px 14px;
  }
`;

const Action = styled.a`
  margin-left: 0;
  text-decoration: none;
  text-align: center;
  background-color: ${theme.colors.skyblue};
  color: white;
  padding: 12px 6px;
  border-radius: 12px;
  font-weight: 700;
  font-size: 15px;
`;
