import { SearchParameters } from "@b2bportal/air-shopping-api";
import { trackEvent } from "@hopper-b2b/api";
import { apiConfig } from "@hopper-b2b/hopper-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IApiConfig } from "@hopper-b2b/types";
import { useDebounce } from "@hopper-b2b/utilities";
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  InputAdornment,
  Autocomplete as MuiAutocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Loading } from "../Loading";
import styles from "./Autocomplete.module.scss";

export type AutocompleteProps<T> = {
  id: string;
  defaultValue?: T;
  label: string;
  disabled?: boolean;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    callback: (newOptions: T[]) => void
  ) => Promise<void>;
  onChange: (value: T) => void;
  sortOptions?: (options?: T[]) => T[];
  groupBy?: (option: T) => string;
  getOptionLabel?: (option: T) => string;
  getGroupLabelKey?: (group: string) => string;
  icon?: string;
  onOpen?: () => void;
  paperClassName?: string;
  additionalSearchControl?: SearchParameters;
};

export const Autocomplete = <T,>({
  id,
  defaultValue,
  label,
  disabled,
  fetch,
  sortOptions,
  onChange,
  groupBy,
  getOptionLabel,
  getGroupLabelKey,
  icon,
  onOpen,
  paperClassName,
}: AutocompleteProps<T>) => {
  const { t } = useI18nContext();

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [shrink, setShrink] = useState<boolean>(false);

  const [value, setValue] = useState<T>(() => defaultValue);
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState<T[]>([]);

  const debouncedSearch = useDebounce(search, 300);
  useEffect(() => {
    if (!open) return;

    setLoading(true);
    fetch(apiConfig, debouncedSearch, (newOptions: T[]) => {
      setOptions(sortOptions ? sortOptions(newOptions) : newOptions);
      setLoading(false);
    });
  }, [fetch, open, debouncedSearch, sortOptions]);

  const handleOnInputChange = useCallback((_event, newInputValue) => {
    setSearch(newInputValue);
  }, []);

  const handleOnChangeValue = useCallback(
    (_event, value: T) => {
      setValue(value);
      onChange(value);
      trackEvent({
        eventName: "tapped_search_suggestion",
        ...value?.["trackingPropertiesV2"],
        encryptedProperties: value?.["trackingPropertiesV2"]
          ?.encryptedProperties
          ? [value?.["trackingPropertiesV2"].encryptedProperties]
          : [],
      });
    },
    [onChange]
  );

  const handleOnOpen = useCallback(() => {
    onOpen && onOpen();
    setOpen(true);
  }, [onOpen]);

  const handleOnClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOnFocus = useCallback(() => {
    setShrink(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setShrink(false);
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        label={label}
        aria-label={label}
        variant="filled"
        InputLabelProps={{
          shrink: shrink || !!value,
          focused: false,
          className: styles.label,
        }}
        InputProps={{
          ...params.InputProps,
          disableUnderline: true,
          startAdornment: (
            <>
              <InputAdornment
                position="start"
                className={styles.startAdornment}
              >
                {icon ? <img src={icon} alt="" /> : null}
              </InputAdornment>
              {params.InputProps.startAdornment}
            </>
          ),
          endAdornment: (
            <>
              <InputAdornment position="end" className={styles.endAdornment}>
                <Loading loading={loading} />
              </InputAdornment>
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [label, icon, loading, shrink, value]
  );

  const renderGroup = useCallback(
    ({ key, group, children }: AutocompleteRenderGroupParams) => (
      <Box key={key} className={styles.group}>
        <Typography variant="caption" color="textSecondary">
          {t?.(getGroupLabelKey ? getGroupLabelKey(group) : group)}
        </Typography>
        {children}
      </Box>
    ),
    []
  );

  return (
    <MuiAutocomplete
      id={id}
      open={open}
      disabled={disabled}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      value={value}
      inputValue={search}
      defaultValue={defaultValue}
      options={options}
      loading={loading}
      loadingText={t?.("loading")}
      noOptionsText={t?.("noOptions")}
      classes={{ paper: paperClassName }}
      onChange={handleOnChangeValue}
      onInputChange={handleOnInputChange}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      disableClearable={loading}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      renderInput={renderInput}
      renderGroup={renderGroup}
      // Disable UI filtering
      filterOptions={(options: T[]) => options}
    />
  );
};
