import { IImage } from "@marketing-site/types/shared.model";
import React from "react";
import { Helmet } from "react-helmet-async";

interface ISeoProps {
  locale?: string;
  canonical: string;
  title?: string;
  description?: string;
  sharedImage?: IImage;
}

const baseUrl = "https://hopper.com";

const SEO = ({
  locale = "en",
  canonical,
  title,
  description,
  sharedImage,
}: ISeoProps) => {
  const addHttps = (path: string) => {
    return path.substring(0, 5) === "https" ? path : `https:${path}`;
  };
  const canonicalUrl = `${baseUrl}${canonical}`.replace(/\/+$/, "");

  return (
    <Helmet>
      <html lang={locale} />
      <title>{title}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={description} />
      {sharedImage && (
        <>
          <meta name="image" content={addHttps(sharedImage.url)} />
          <meta name="og:image" content={addHttps(sharedImage.url)} />
          <meta name="twitter:image" content={addHttps(sharedImage.url)} />
        </>
      )}

      <meta name="og:description" content={description} />
      <meta name="og:site_name" content="Hopper" />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO;
