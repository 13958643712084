/**
 * Link function to take relative urls and attach a base url when needed.

 * Note: Footer links from contentful are currently hardocded to hopper.com/[...] so this is currently not useful
 *
 * @param path
 * @param isExternal
 * @returns updated url for a link to go to
 */
const setLink = (path: string, isExternal?: boolean): string => {
    // Regex to match everything after "http(s)://"
    const regex = /(?!https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?/;
  
    if (isExternal === true) {
      return `https://${path.match(regex)}`;
    } else {
      const baseUrl =
        typeof window !== 'undefined'
          ? window.location.origin
          : 'https://hopper.com';
      if (path === null) return '';
      if (path[0] === '/' && path.length > 1) {
        // add base url since it is a relative link.
        return `${baseUrl}${path}`;
      }
      return path;
    }
};

export default setLink;