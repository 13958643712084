import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { ActionButton, ActionLink } from "@hopper-b2b/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useCallback, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  FeatureFlagsContext,
  useOpenAuthModal,
  useSessionContext,
} from "@hopper-b2b/utilities";
import { RewardsLogo } from "../RewardsLogo";

function shouldModalBeSeen() {
  return !document.cookie
    .split(";")
    .some((cookie) => cookie.includes("rewardsModalSeen"));
}

function modalHasBeenSeen() {
  document.cookie = `rewardsModalSeen=true; expires=${dayjs()
    .endOf("day")
    .toDate()
    .toUTCString()}; path=/`;
}

export const RewardsModal = () => {
  const { t } = useI18nContext();
  const { isLoggedIn } = useSessionContext();
  const openAuthModal = useOpenAuthModal();
  const [hasNotBeenSeen, setHasNotBeenSeen] = useState(shouldModalBeSeen);
  const onClose = useCallback(() => {
    setHasNotBeenSeen(false);
  }, []);
  const shouldBeSeen = !isLoggedIn && hasNotBeenSeen;

  useEffect(() => {
    if (shouldBeSeen) {
      modalHasBeenSeen();
    }
  }, [shouldBeSeen]);

  return (
    <Dialog
      maxWidth={"sm"}
      scroll="paper"
      open={shouldBeSeen}
      fullWidth
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          backgroundColor: "#F2F5FA",
          display: "flex",
          padding:
            "var(--container-padding, 32px) 0px var(--font-size-04, 16px) 0px",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          alignSelf: "stretch",
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            width: "279px",
            height: "114px",
            padding: "36px 20px 39.833px 30px",
          }}
        >
          <RewardsLogo
            sx={{
              height: "46px",
              gap: "12px",
              flexShrink: 0,
            }}
          />
        </Box>
        <ActionLink
          content={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6308 17.1309C18.1231 16.6386 18.1231 15.8405 17.6308 15.3482L12.2826 10.0001L17.6308 4.65191C18.1231 4.15963 18.1231 3.36149 17.6308 2.86921C17.1385 2.37693 16.3404 2.37693 15.8481 2.86921L10.4999 8.21736L5.1519 2.86933C4.65963 2.37705 3.86148 2.37705 3.36921 2.86933C2.87693 3.36161 2.87693 4.15974 3.3692 4.65202L8.71724 10.0001L3.3692 15.3481C2.87693 15.8404 2.87693 16.6385 3.3692 17.1308C3.86148 17.6231 4.65962 17.6231 5.1519 17.1308L10.4999 11.7828L15.8481 17.1309C16.3404 17.6232 17.1385 17.6232 17.6308 17.1309Z"
                fill="#878787"
              />
            </svg>
          }
          onClick={onClose}
          style={{
            width: "var(--font-line-height-03, 20px)",
            height: "var(--font-line-height-03, 20px)",
            position: "absolute",
            top: "24px",
            right: "24px",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          padding: "var(--container-padding, 32px)",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "var(--container-padding, 32px)",
          alignSelf: "stretch",
        }}
      >
        <Stack
          direction="column"
          spacing={3}
          pt="var(--container-padding, 32px)"
          alignItems="center"
        >
          <Typography variant="headlineLarge">
            {t("rewards.banner.title")}
          </Typography>
          <Typography
            variant="titleMedium"
            sx={{
              fontSize: "17px",
              fontWeight: "400",
              textAlign: "center",
              textWrap: "balance",
            }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: t("rewards.modal.subtitle") }}
            ></span>
          </Typography>
        </Stack>
        <ActionButton
          size="medium"
          message={t("rewards.modal.cta")}
          onClick={openAuthModal}
          fullWidth
        />
      </DialogContent>
    </Dialog>
  );
};

export const ControlledRewardsModal = () => {
  const featureFlagsCtx = useContext(FeatureFlagsContext);
  const enableRewardsBanner = featureFlagsCtx?.enableRewardsBanner;

  return enableRewardsBanner ? <RewardsModal /> : null;
};
