import React from "react";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import "./styles.scss";

interface ILoadingTextProps {
  message: string;
  className?: string;
}
const LoadingText = ({ message, className }: ILoadingTextProps) => {
  return (
    <Box
      className={clsx("loading-text", className)}
      display="flex"
      justifyContent="center"
    >
      <Typography
        variant="inherit"
        className={clsx("loading-text-copy", "message")}
      >
        {message}
      </Typography>
      <Typography
        variant="inherit"
        className={clsx("loading-text-copy", "ellipsis")}
      >
        .
      </Typography>
      <Typography
        variant="inherit"
        className={clsx("loading-text-copy", "ellipsis")}
      >
        .
      </Typography>
      <Typography
        variant="inherit"
        className={clsx("loading-text-copy", "ellipsis")}
      >
        .
      </Typography>
    </Box>
  );
};

export default LoadingText;
