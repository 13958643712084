import React from 'react';

const TikTok = () => {
  return (
    <svg
      width="26"
      height="31"
      viewBox="0 0 26 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_635_1570)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.925 0C19.4252 4.39934 21.826 7.0222 26.0002 7.30123V12.2493C23.5812 12.4911 21.4623 11.682 18.9978 10.1566V19.411C18.9978 31.1674 6.46613 34.8413 1.42801 26.4146C-1.80949 20.9922 0.173024 11.4773 10.5585 11.096V16.3138C9.76729 16.444 8.92154 16.6487 8.14854 16.9184C5.83864 17.7183 4.52909 19.2157 4.89286 21.8572C5.5931 26.9169 14.669 28.4143 13.9142 18.5275V0.00930093H18.925V0Z"
          fill="#606060"
        />
      </g>
      <defs>
        <clipPath id="clip0_635_1570">
          <rect width="26" height="31" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TikTok;
