export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  import.meta.env.VITE_EMAIL_REGEX
    ? new RegExp(import.meta.env.VITE_EMAIL_REGEX)
    : // eslint-disable-next-line no-useless-escape
      /^[a-zA-Z0-9]+(?:\.?[a-zA-Z0-9]+)*(?:[_\-]*(?:[a-zA-Z0-9]+\.[a-zA-Z0-9]+)*[a-zA-Z0-9]*)*@[a-zA-Z0-9]+(?:(?:\-*[a-zA-Z0-9]+)*\.[a-zA-Z0-9]+)+$/;
export const phoneRegex =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;
export const nameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð -]+$/;
export const specialCharacterRegex = /^[A-Za-z0-9]+$/;
export const cpfRegex = /^[-0-9.]+$/;
