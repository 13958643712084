import { XIcon } from "@marketing-site/static/icons";
import { Countdown } from "@marketing-site/components/_contentful";
import { media } from "@marketing-site/styles";
import React from "react";
import { isMobileOnly } from "react-device-detect";
import styled from "styled-components";

const ExternalIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3147 1H8.04895C7.337 1.00078 6.65443 1.2855 6.15101 1.79167C5.64758 2.29785 5.36441 2.98416 5.36364 3.7C5.36364 3.93869 5.45794 4.16761 5.62581 4.3364C5.79367 4.50518 6.02134 4.6 6.25874 4.6C6.49614 4.6 6.72381 4.50518 6.89168 4.3364C7.05954 4.16761 7.15385 3.93869 7.15385 3.7C7.15415 3.4614 7.24856 3.23266 7.41636 3.06395C7.58415 2.89523 7.81165 2.80031 8.04895 2.8H14.3147C14.552 2.80031 14.7795 2.89523 14.9473 3.06395C15.1151 3.23266 15.2095 3.4614 15.2098 3.7V16.3C15.2095 16.5386 15.1151 16.7673 14.9473 16.9361C14.7795 17.1048 14.552 17.1997 14.3147 17.2H8.04895C7.81165 17.1997 7.58415 17.1048 7.41636 16.9361C7.24856 16.7673 7.15415 16.5386 7.15385 16.3C7.15385 16.0613 7.05954 15.8324 6.89168 15.6636C6.72381 15.4948 6.49614 15.4 6.25874 15.4C6.02134 15.4 5.79367 15.4948 5.62581 15.6636C5.45794 15.8324 5.36364 16.0613 5.36364 16.3C5.36441 17.0158 5.64758 17.7021 6.15101 18.2083C6.65443 18.7145 7.337 18.9992 8.04895 19H14.3147C15.0266 18.9992 15.7092 18.7145 16.2126 18.2083C16.7161 17.7021 16.9992 17.0158 17 16.3V3.7C16.9992 2.98416 16.7161 2.29785 16.2126 1.79167C15.7092 1.2855 15.0266 1.00078 14.3147 1Z"
      fill="#fff"
    />
    <path
      d="M6.4305 13.2375L9.11582 10.65C9.203 10.5659 9.27238 10.465 9.31978 10.3533C9.36718 10.2416 9.39161 10.1214 9.39161 10C9.39161 9.87856 9.36718 9.75838 9.31978 9.64668C9.27238 9.53498 9.203 9.43408 9.11582 9.35005L6.4305 6.76255C6.34561 6.68077 6.24554 6.61661 6.13599 6.57372C6.02644 6.53084 5.90956 6.51007 5.79202 6.5126C5.67449 6.51513 5.5586 6.54091 5.45098 6.58847C5.34335 6.63603 5.2461 6.70444 5.16477 6.7898C5.00052 6.96218 4.9111 7.1931 4.91618 7.43177C4.92126 7.67044 5.02043 7.8973 5.19187 8.06245L6.26874 9.1H1.8951C1.65771 9.1 1.43003 9.19482 1.26217 9.3636C1.09431 9.53238 1 9.7613 1 10C1 10.2387 1.09431 10.4676 1.26217 10.6364C1.43003 10.8052 1.65771 10.9 1.8951 10.9H6.26874L5.19187 11.9375C5.02043 12.1027 4.92126 12.3296 4.91618 12.5682C4.9111 12.8069 5.00052 13.0378 5.16477 13.2102C5.32902 13.3826 5.55465 13.4823 5.79202 13.4874C6.02939 13.4925 6.25906 13.4026 6.4305 13.2375Z"
      fill="#fff"
    />
    <path
      d="M14.3147 1H8.04895C7.337 1.00078 6.65443 1.2855 6.15101 1.79167C5.64758 2.29785 5.36441 2.98416 5.36364 3.7C5.36364 3.93869 5.45794 4.16761 5.62581 4.3364C5.79367 4.50518 6.02134 4.6 6.25874 4.6C6.49614 4.6 6.72381 4.50518 6.89168 4.3364C7.05954 4.16761 7.15385 3.93869 7.15385 3.7C7.15415 3.4614 7.24856 3.23266 7.41636 3.06395C7.58415 2.89523 7.81165 2.80031 8.04895 2.8H14.3147C14.552 2.80031 14.7795 2.89523 14.9473 3.06395C15.1151 3.23266 15.2095 3.4614 15.2098 3.7V16.3C15.2095 16.5386 15.1151 16.7673 14.9473 16.9361C14.7795 17.1048 14.552 17.1997 14.3147 17.2H8.04895C7.81165 17.1997 7.58415 17.1048 7.41636 16.9361C7.24856 16.7673 7.15415 16.5386 7.15385 16.3C7.15385 16.0613 7.05954 15.8324 6.89168 15.6636C6.72381 15.4948 6.49614 15.4 6.25874 15.4C6.02134 15.4 5.79367 15.4948 5.62581 15.6636C5.45794 15.8324 5.36364 16.0613 5.36364 16.3C5.36441 17.0158 5.64758 17.7021 6.15101 18.2083C6.65443 18.7145 7.337 18.9992 8.04895 19H14.3147C15.0266 18.9992 15.7092 18.7145 16.2126 18.2083C16.7161 17.7021 16.9992 17.0158 17 16.3V3.7C16.9992 2.98416 16.7161 2.29785 16.2126 1.79167C15.7092 1.2855 15.0266 1.00078 14.3147 1Z"
      stroke="#fff"
      strokeWidth="0.5"
    />
    <path
      d="M6.4305 13.2375L9.11582 10.65C9.203 10.5659 9.27238 10.465 9.31978 10.3533C9.36718 10.2416 9.39161 10.1214 9.39161 10C9.39161 9.87856 9.36718 9.75838 9.31978 9.64668C9.27238 9.53498 9.203 9.43408 9.11582 9.35005L6.4305 6.76255C6.34561 6.68077 6.24554 6.61661 6.13599 6.57372C6.02644 6.53084 5.90956 6.51007 5.79202 6.5126C5.67449 6.51513 5.5586 6.54091 5.45098 6.58847C5.34335 6.63603 5.2461 6.70444 5.16477 6.7898C5.00052 6.96218 4.9111 7.1931 4.91618 7.43177C4.92126 7.67044 5.02043 7.8973 5.19187 8.06245L6.26874 9.1H1.8951C1.65771 9.1 1.43003 9.19482 1.26217 9.3636C1.09431 9.53238 1 9.7613 1 10C1 10.2387 1.09431 10.4676 1.26217 10.6364C1.43003 10.8052 1.65771 10.9 1.8951 10.9H6.26874L5.19187 11.9375C5.02043 12.1027 4.92126 12.3296 4.91618 12.5682C4.9111 12.8069 5.00052 13.0378 5.16477 13.2102C5.32902 13.3826 5.55465 13.4823 5.79202 13.4874C6.02939 13.4925 6.25906 13.4026 6.4305 13.2375Z"
      stroke="#fff"
      strokeWidth="0.5"
    />
  </svg>
);

const ViewMore = () => {
  return (
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.99984 18.0006C0.802082 18.0006 0.608774 17.9419 0.444354 17.832C0.279934 17.7221 0.151786 17.566 0.0761119 17.3833C0.000437503 17.2006 -0.0193656 16.9995 0.0192064 16.8056C0.0577784 16.6116 0.152993 16.4334 0.292814 16.2936L7.58577 9.0006L0.292814 1.70763C0.106426 1.51988 0.00204197 1.26591 0.00252377 1.00135C0.00300556 0.736796 0.108314 0.483213 0.295384 0.296143C0.482454 0.109072 0.736038 0.0037641 1.00059 0.0032823C1.26515 0.00280051 1.51912 0.107184 1.70687 0.293572L9.70687 8.29357C9.79975 8.3864 9.87342 8.49662 9.92369 8.61794C9.97396 8.73925 9.99983 8.86928 9.99983 9.0006C9.99983 9.13192 9.97396 9.26195 9.92369 9.38326C9.87342 9.50457 9.79975 9.6148 9.70687 9.70763L1.70687 17.7076C1.61413 17.8006 1.50393 17.8744 1.38259 17.9247C1.26126 17.975 1.13118 18.0008 0.99984 18.0006Z"
        fill="#01AAE4"
      />
    </svg>
  );
};

const ControlTakeover = (props: any) => {
  // This is the default takeover. Any edits here will effect the default controller.
  // If you're looking to add a new takeover variant, please add it as a new component
  // under /variants

  const takeoverID = "takeover_wrapper";

  const countdownProps = {
    countdownSize: "medium",
    startDate: props.saleStartDate,
    endDate: props.saleEndDate,
    presaleText: props.timerPresaleText,
    isSaleText: props.timerEndSaleText,
  };

  const isSaleLive = () => {
    const currDate = new Date();
    const startDate = new Date(props.saleStartDate);
    // If the current date is after the start date (currDate is greater than saleLive), the sale is live
    const saleLive = startDate < currDate;
    if (saleLive) {
      return true;
    }
    return false;
  };

  const closeTakeover = () => {
    const TakeoverEle = document.getElementById(takeoverID);
    if (TakeoverEle) {
      TakeoverEle.style.display = "none";
    }
    // Send click close event
    window?.gtag("event", "close", {
      event_category: "click",
      event_label: "takeover",
    });
  };

  const linkClick = () => {
    // Send click learn more event
    window?.gtag("event", "cta", {
      event_category: "click",
      event_label: "takeover",
    });
    window.location.href = props.actionLink;
  };
  return (
    <TakeoverWrapper id={takeoverID} className="takeover">
      <TakeoverContainer id="takeover_container">
        <TakeoverBody>
          <Close onClick={() => closeTakeover()} id="takeover_close_btn">
            <XIcon />
          </Close>
          <Content id="takeover_content">
            <ContentImg id="takeover_img" src={props.image.url} />
            <ContentText>
              {props.showTimer && (
                <CountdownWrapper>
                  <Countdown {...countdownProps} />
                </CountdownWrapper>
              )}
              <Headline>
                {isSaleLive() ? props.isSaleHeadline : props.notSaleHeadline}
              </Headline>
              <ContentRow>
                <Description>{props.description}</Description>
              </ContentRow>
              <ActionLink onClick={linkClick} tabIndex={1}>
                {isMobileOnly ? props.actionTextMobile : props.actionText}
                {!isMobileOnly && <ExternalIcon />}
              </ActionLink>
              {!isMobileOnly && <QRCode src={props.qrCode.url} />}
              {props.learnMoreLink && (
                <LearnMore href={props.learnMoreLink}>
                  {props.learnMoreText}
                  <span>{ViewMore()}</span>
                </LearnMore>
              )}
            </ContentText>
          </Content>
        </TakeoverBody>
      </TakeoverContainer>
    </TakeoverWrapper>
  );
};

export default ControlTakeover;

const TakeoverWrapper = styled.div`
  z-index: 10000;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

const TakeoverContainer = styled.div`
  position: relative;
  top: 20vh;
  margin: 0 10px;

  ${media.phablet`
    top: 10px;
  `};
`;

const TakeoverBody = styled.div`
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 30px;

  ${media.tablet_large`
  max-width: 760px;
  `};
`;

const Close = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 12px;
  right: 12px;
  padding: 16px;
  color: #01aae4;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
`;

const Content = styled.div`
  margin-top: 24px;
  clear: both;
  display: flex;
  flex-direction: row;

  ${media.phablet_large`
    flex-direction: column;
  `};
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  margin-left: 42px;

  ${media.tablet`
    width: 40%;
    margin-left: 32px;
  `};

  ${media.phablet_large`
    width: 100%;
    margin-left: 0;
  `};
`;

const CountdownWrapper = styled.div`
  ${media.phablet_large`
    margin: 0 auto;
    margin-top: 4px;
    margin-bottom: -16px;
  `};
`;

const ContentImg = styled.img`
  width: 450px;
  align-self: center;
  border-radius: 10px;

  ${media.tablet_large`
    width: 50%;
  `};
`;

const Headline = styled.h2`
  font-size: 36px;
  line-height: 38px;
  color: #505050;
  margin-top: -4px;
  margin-bottom: 8px;
  width: 310px;

  ${media.tablet_large`
    font-size: 32px;
  `};

  ${media.phablet_large`
    width: 100%;
    margin-top: 6px;
    text-align: center;
  `};

  ${media.phablet`
    font-size: 28px;
    line-height: 30px;
  `};
`;

const Description = styled.p`
  font-size: 20px;
  line-height: 28px;
  color: #606060;
  margin-bottom: 12px;
  margin-top: 4px;

  ${media.tablet_large`
    font-size: 18px;
  `};

  ${media.phablet_large`
    text-align:center;
  `};

  ${media.phablet`
    font-size: 16px;
    line-height: 20px;
  `};
`;

const QRCode = styled.img`
  height: 140px;
  width: 140px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
`;

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionLink = styled.button`
  color: ${(p) => p.theme.colors.white};
  background-color: ${(p) => p.theme.colors.skyblue};
  font-family: "proxima-nova";
  cursor: pointer;
  border-radius: 20px;
  padding: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  font-size: 18px;
  ${media.phablet`
    font-size: 14px;
  `};
  border: none;
`;

const LearnMore = styled.a`
  text-decoration: none;
  text-align: center;
  margin-left: 4px;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
  color: ${(p) => p.theme.colors.skyblue};
  :hover {
    color: ${(p) => p.theme.colors.lightsky};
  }
  span svg {
    height: 10px;
    margin-left: 4px;
    margin-bottom: 1px;
  }
`;
