import { useExperiment } from "@hopper-b2b/experiments";
import { RootWithAuth as HopperNavigationWithAuth } from "@hopper-b2b/hopper-navigation";
import { HeaderNavigationType } from "@hopper-b2b/hopper-utils";
import { config } from "@marketing-site/config";
import React from "react";

export type HopperNavProps = {
  headerNavigation: HeaderNavigationType;
};

export const HopperNavigationWrapper = ({
  headerNavigation,
}: HopperNavProps) => {
  const hopperWebPhoneAuth = useExperiment("hopper-web-phone-auth");
  const hopperWebGoogleSsoAuth = useExperiment("hopper-web-google-sso-auth");

  return (
    <HopperNavigationWithAuth
      showLanguageSelect={true}
      headerNavigation={headerNavigation}
      googleIdentityClientId={config.googleIdentityClientId}
      isPhoneAuthEnabled={hopperWebPhoneAuth}
      isGoogleSsoAuthEnabled={hopperWebGoogleSsoAuth}
    />
  );
};
