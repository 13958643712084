import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CtaButton, Break } from "@marketing-site/components/_contentful";
import { RichTextToHtml } from "@marketing-site/utils";
import theme from "@marketing-site/styles/theme";
import { IStyledBackground, IStyledCtaFooter } from "@marketing-site/types";

const CtaFooter = (props: any) => {
  const pageBreakData = {
    isLine: false,
    waveType: "valley",
    waveColor: props.hasLightBackground ? "gray" : "skyblue",
    type: "pageBreak",
  };

  const bottomPageBreakData = {
    isLine: false,
    waveType: "hill",
    waveColor: "white",
    prevBackgroundColor: theme.colors.grey[100],
    type: "pageBreak",
  };

  return (
    <CtaFooterWrapper
      backgroundColor={props.backgroundColor}
      id={props.sectionId}
    >
      <Break {...pageBreakData} />
      <CtaFooterContainerColor isLight={props.hasLightBackground}>
        <CtaFooterContainer className={"container"}>
          <CtaFooterHeadline isLight={props.hasLightBackground}>
            {props.headline}
          </CtaFooterHeadline>
          <CtaFooterSubheadline isLight={props.hasLightBackground}>
            {RichTextToHtml(props.footerDescription.json)}
          </CtaFooterSubheadline>
          <CtaFooterButtons>
            {props.actionsCollection.items.map((action: any, i: number) => {
              return (
                <CtaButton
                  {...action}
                  key={`footerCtaButton-${i}`}
                  backgroundColor={
                    props.hasLightBackground
                      ? theme.colors.grey[100]
                      : theme.colors.skyblue
                  }
                  isFilled={props.hasLightBackground ? true : false}
                  color={props.hasLightBackground ? "skyblue" : "white"}
                />
              );
            })}
          </CtaFooterButtons>
        </CtaFooterContainer>
      </CtaFooterContainerColor>
      {props.hasLightBackground && <Break {...bottomPageBreakData} />}
    </CtaFooterWrapper>
  );
};

export default CtaFooter;

CtaFooter.propTypes = {
  headline: PropTypes.string.isRequired,
  headlineHasFlare: PropTypes.bool.isRequired,
  footerDescription: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  hasLightBackground: PropTypes.bool,
  actionsCollection: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object))
    .isRequired,
  sectionId: PropTypes.string,
  type: PropTypes.string,
};

const CtaFooterWrapper = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor};
  padding-top: 40px;
`;

const CtaFooterContainerColor = styled.div<IStyledCtaFooter>`
  background-color: ${(p) =>
    p.isLight ? theme.colors.grey[100] : theme.colors.skyblue};
  padding-bottom: 80px;
`;

const CtaFooterContainer = styled.div`
  padding-top: 40px;
`;

const CtaFooterHeadline = styled.h2<IStyledCtaFooter>`
  margin-top: 0;
  color: ${(p) => (p.isLight ? theme.colors.grey[800] : theme.colors.white)};
  font-weight: 800;
  font-size: 28px;
  margin-bottom: 0;
`;

const CtaFooterSubheadline = styled.div<IStyledCtaFooter>`
  margin: 0 auto;
  p {
    color: ${(p) => (p.isLight ? theme.colors.grey[700] : theme.colors.white)};
    font-size: 18px;
    b {
      font-weight: 700;
    }
  }
`;

const CtaFooterButtons = styled.div`
  margin: 0 0;
  margin-top: 40px;
  display: flex;
  align-items: baseline;
  flex-direction: column;
`;
