import { authApi } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";
import { deleteRefreshToken } from "./localStorage";

export const removeSession = async () => {
  try {
    deleteRefreshToken();
    const res = await authApi(axiosInstance).apiV0AuthSessionRemovePut();
    return res.data;
  } catch (error) {
    console.error("Remove session error:", error);
    throw Error("Remove session error");
  }
};
