import { IFeatureCard } from "@marketing-site/types/shared.model";
import { Container, Grid as MuiGrid } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { Card } from "./Card";
import { CardBack } from "./CardBack";
import { CardModal } from "./CardModal";

interface CardListProps {
  cards: IFeatureCard[];
}
export const CardList = (props: CardListProps) => {
  const [modalData, setModalData] = useState<IFeatureCard | null>(null);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = (value: IFeatureCard) => {
    setModalData(value);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          maxWidth={1200}
        >
          {props.cards.map((card, i) => (
            <Grid item xs="auto" className="grid-item">
              <Card
                key={`people-card-${i}`}
                {...card}
                onClick={handleOpenModal}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
      {modalData && (
        <CardModal onClose={handleCloseModal} open={showModal}>
          <CardBack {...modalData} />
        </CardModal>
      )}
    </>
  );
};

const Grid = styled(MuiGrid)`
  .grid-item:first-child > .mobile-card-wrapper {
    @keyframes wiggle {
      0% {
        transform: rotate(0deg);
      }
      82% {
        transform: rotate(0deg);
      }
      85% {
        transform: rotate(-5deg);
      }
      88% {
        transform: rotate(5deg);
      }
      91% {
        transform: rotate(-5deg);
      }
      94% {
        transform: rotate(5deg);
      }
      97% {
        transform: rotate(-5deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    animation: wiggle 4s infinite;
  }
`;
