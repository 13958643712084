import { media } from '@marketing-site/styles';
import { IGenericSection } from '@marketing-site/types/shared.model';
import React from 'react';
import styled from 'styled-components';
import GenericHeader from './GenericHeader';

export const HeadshotsSection = (props: IGenericSection<never>) => {
  return (
    <Container>
      <GenericHeader
        preheadline={props.preheadline}
        headline={props.headline}
        flareColor={props.flareColor}
        description={props.description}
      />
      <HeadshotsContainer>
        {props.bodyMedia.items.map((image, i) => (
          <Headshot
            key={`Headshot-${i}`}
            src={image.url}
            alt={image.title}
            position={i}
          />
        ))}
      </HeadshotsContainer>
    </Container>
  );
};

interface IPosition {
  position: number;
}

const Container = styled.div`
  max-width: 1080px;
  margin: 0 auto;
  padding: 2rem 0;
`;

const HeadshotsContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1080px;
  gap: 4px;
`;

const Headshot = styled.img<IPosition>`
  width: 170px;
  height: 170px;
  flex: 0 0 ${Math.floor(100 / 6) - 3}%;
  border-radius: 22px;

  ${(p: IPosition) =>
    p.position > 7 && media.phablet`display:none; flex: 0 0 50%;`}
`;
