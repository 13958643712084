import { getObjectKeysAsObject } from "@hopper-b2b/checkout";
import { resendCodeService } from "./resendCode";
import { sendCodeToPhoneService } from "./sendCodeToPhone";
import { sendCodeToEmailService } from "./sendCodeToEmail";
import { verifyCodeService } from "./verifyCode";
import { createGoogleIdentitySessionService } from "./createGoogleIdentitySession";
import { finalizeUserService } from "./finalizeUser";
import { sendCodeForMergeService } from "./sendCodeForMerge";
import { verifyMergeUserService } from "./verifyMergeUser";

export const AuthServices = {
  createGoogleIdentitySessionService,
  finalizeUserService,
  resendCodeService,
  sendCodeForMergeService,
  sendCodeToEmailService,
  sendCodeToPhoneService,
  verifyCodeService,
  verifyMergeUserService,
};

export const AuthServiceTypes = getObjectKeysAsObject(AuthServices);
