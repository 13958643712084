import { UserInfoField, UserMatch } from "@b2bportal/auth-api";
import { AuthType } from "../types/tracking";

export interface AuthMachineContext {
  authStateToken?: string;
  phoneNumber?: string;
  email?: string;
  verificationAlternative?: { redactedEmail?: string; redactedPhone?: string };
  error?: string;
  missingFields: Array<UserInfoField>;
  userMatch?: UserMatch;
  authType?: AuthType;
  previousUserTrackingToken?: string;
}

export const initialAuthContext: AuthMachineContext = {
  authStateToken: undefined,
  phoneNumber: undefined,
  email: undefined,
  verificationAlternative: undefined,
  error: undefined,
  missingFields: [],
  userMatch: undefined,
  authType: undefined,
  previousUserTrackingToken: undefined,
};
