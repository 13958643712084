import { useContext } from "react";

import type { Slots } from "./slots";
import { SlotContext } from "./context";

type SlotProps<Id extends keyof Slots> = {
  id: Id;
  component?: JSX.Element;
} & React.ComponentProps<Slots[Id]>;

export function Slot<Id extends keyof Slots>(props: SlotProps<Id>) {
  const slots = useContext(SlotContext);
  const { id, component: Component, ...componentProps } = props;

  const SlotComponent = slots[id];

  return SlotComponent ? (
    <SlotComponent {...(componentProps as any)} />
  ) : (
    Component || null
  );
}
