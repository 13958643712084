import { contentfulApi } from '@marketing-site/api/urbana/contentful/apiSlice';
import { RootState } from '@marketing-site/main';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UtilitiesState {
  backgroundColor: string;
  heroCarouselIndex: number;
  clientRegion?: string;
}

const initialState: UtilitiesState = {
  backgroundColor: '#FA6866',
  heroCarouselIndex: 0,
  clientRegion: undefined,
};

export const utilitiesSlice = createSlice({
  name: 'utilities',
  initialState,
  reducers: {
    setBackgroundColor: (
      state: UtilitiesState,
      action: PayloadAction<string>
    ) => {
      state.backgroundColor = action.payload;
    },
    setHeroCarouselIndex: (
      state: UtilitiesState,
      action: PayloadAction<number>
    ) => {
      state.heroCarouselIndex = action.payload;
    },
    setClientRegion: (state: UtilitiesState, action: PayloadAction<string>) => {
      state.clientRegion = action.payload;
    },
  },
  // Watch for fetchContent endpoint fulfillment, and then update clientRegion in state
  extraReducers: (builder) => {
    builder.addMatcher(
      contentfulApi.endpoints.fetchContent.matchFulfilled,
      (state, { payload }) => {
        state.clientRegion = payload.clientRegion;
      }
    );
  },
});

export const { setBackgroundColor, setHeroCarouselIndex, setClientRegion } =
  utilitiesSlice.actions;

export default utilitiesSlice.reducer;

export const selectBackgroundColor = (state: RootState) =>
  state.utilities.backgroundColor;

export const bgSelector = createSelector(
  selectBackgroundColor,
  (bgColor) => bgColor
);

export const selectHeroCarouselIndex = (state: RootState) =>
  state.utilities.heroCarouselIndex;

export const heroCarouselIndexSelector = createSelector(
  selectHeroCarouselIndex,
  (index) => index
);

export const selectClientRegion = (state: RootState) =>
  state.utilities.clientRegion;
