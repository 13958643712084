import React, { createElement, ReactElement, ReactNode } from 'react';
import {
  Block,
  BLOCKS,
  Document,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { Link } from '@marketing-site/components/link';
import { NestedOrderedList } from '@marketing-site/components/rich-text-renderer/NestedOrderedList';
import * as ts from 'typescript';

/**
 * Rich Text React Renderer README: https://github.com/contentful/rich-text/blob/master/packages/rich-text-react-renderer/README.md
 * Rich Text Types: https://github.com/contentful/rich-text/blob/master/packages/rich-text-types/src/types.ts
 */
const richTextToHtml = (richText: Document | undefined) => {
  if (richText === undefined) return;
  const options: Options = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => createElement('b', null, text),
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Block, children: ReactNode) => {
        return createElement('p', null, children as ReactNode);
      },
      [BLOCKS.HEADING_3]: (node: Block, children: ReactNode) => {
        return createElement('h3', null, children);
      },
      [INLINES.HYPERLINK]: (node: Block, children: ReactNode) => {
        let userIdPrefix = 'user_id:';
        if (String(node.data.uri).startsWith(userIdPrefix)) {
          // https://hopper-jira.atlassian.net/browse/PG-6356
          // Convert <a href="withuserid:*"> links to add user id from the URL query parameter
          // as <a href="javascript:*"> doesn't work with mobile browsers
          var element = createElement(
            'a',
            {
              href: '#',
              onClick: () => {
                event?.preventDefault();
                let userId = new URLSearchParams(location.search).get(
                  'user_id'
                );
                let url = new URL(
                  String(node.data.uri).split(userIdPrefix).pop() || ''
                );
                url.searchParams.append('user_id', userId || '');
                window.location.href = url.toString();
              },
            },
            children as React.ReactNode
          );
          return element;
        } else {
          return createElement(
            'a',
            { href: node.data.uri },
            children as React.ReactNode
          );
        }
      },
      [BLOCKS.OL_LIST]: (node: Block, children: ReactNode) => {
        return createElement(
          NestedOrderedList,
          { listType: '1' },
          children as React.ReactNode
        );
      },
    },
  };

  const rnodes: ReactElement[] = documentToReactComponents(
    richText,
    options
  ) as ReactElement[];
  type OrderedList = '1' | 'A' | 'i' | 'a' | 'I' | undefined;
  const listTypes: Array<OrderedList> = ['1', 'A', 'i', 'a', 'I'];
  const nodeQueue = [...rnodes];

  /**
   * DFS to search for and replace <ol> elements' listType prop depending on the depth of the traversal.
   * This is needed because the Contentful RichTextRenderer doesn't support nested ordered lists.
   * Changing the render function causes the ordering to be reversed from child to ancestor.
   */
  type OrderedListProps = {
    children: ReactElement[];
    listType: OrderedList;
  };
  const recurseNode = (
    node: ReactElement<OrderedListProps>,
    listIndex: number
  ) => {
    let index = listIndex;
    if (!node.props || !Array.isArray(node.props.children)) {
      // Terminal node
      return node;
    } else {
      if (node.type === NestedOrderedList) {
        // Replace node with a clone of itself, since cannot modify readonly ReactElement props
        node = React.cloneElement(node, {
          listType: listTypes[index],
        });
        index += 1;
      }
      for (let i = 0; i < node.props.children.length; i++) {
        node.props.children[i] = recurseNode(node.props.children[i], index);
      }
      return node;
    }
  };

  nodeQueue.forEach((node) => {
    return recurseNode(node, 0);
  });

  return nodeQueue;
};

// Got this function from https://gist.github.com/slavikshen/7b29b06215b9e7a08455 – needs refactoring and typing
export const getInnerText = (node: any) => {
  let buf = '';
  if (node) {
    const type = typeof node;
    if (type === 'string' || type === 'number') {
      buf += node;
    } else if (type === 'object') {
      let children = null;
      if (Array.isArray(node)) {
        children = node;
      } else {
        const props = node.props;
        if (props) {
          children = props.children;
        }
      }
      if (children) {
        if (Array.isArray(children)) {
          children.forEach(function (o) {
            buf += getInnerText(o);
          });
        } else {
          buf += getInnerText(children);
        }
      }
    }
  }
  return buf;
};

export default richTextToHtml;
