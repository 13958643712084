import clsx from "clsx";
import dayjs from "dayjs";
import Airplane from "../../../../assets/icons/flight/airplane.svg";

interface IFlightTimingProps {
  departureTime: string;
  arrivalTime: string;
  className?: string;
  timeFormat?: string;
}

const DEFAULT_TIME_FORMAT = "h:mm A";

const formatTime = (t: string, timeFormat?: string) =>
  dayjs(t).format(timeFormat || DEFAULT_TIME_FORMAT);

export const FlightTiming = ({
  departureTime,
  arrivalTime,
  className,
  timeFormat,
}: IFlightTimingProps) => {
  const departureTimeFormatted = formatTime(departureTime, timeFormat);
  const arrivalTimeFormatted = formatTime(arrivalTime, timeFormat);

  return (
    <div className={clsx("other-timing", className)}>
      {departureTimeFormatted}
      <img className="airplane" src={Airplane} alt="airplane-icon" />
      {arrivalTimeFormatted}
    </div>
  );
};
