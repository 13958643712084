import { formatDateForUrl } from "@hopper-b2b/hopper-utils";
import {
  FLIGHT_SHOP_TYPE,
  FlightShopQueryParams,
  FlightShopType,
  SliceStopCountFilter,
  TripCategory,
  IPassengerCounts,
  FlightSearchQueryParams,
} from "@hopper-b2b/types";

type FlightShopQueryBuilderProps = {
  origin: string;
  destination: string;
  departureDate: string;
  returnDate?: string;
  isOneWay: boolean;
  paxCount: IPassengerCounts;
  stopOptions?: SliceStopCountFilter;
};
export const flightShopQueryBuilder = ({
  origin,
  destination,
  departureDate,
  returnDate,
  isOneWay,
  paxCount,
  stopOptions = SliceStopCountFilter.ANY_NUMBER,
}: FlightShopQueryBuilderProps) => {
  departureDate = formatDateForUrl(departureDate);
  returnDate = formatDateForUrl(returnDate);
  const tripCategory = !isOneWay
    ? TripCategory.ROUND_TRIP
    : TripCategory.ONE_WAY;

  const flightShopQueryObject = {
    [FlightShopQueryParams.ORIGIN]: origin ?? "",
    [FlightShopQueryParams.DESTINATION]: destination ?? "",
    [FlightShopQueryParams.DEPARTURE_DATE]: departureDate,
    ...(!isOneWay && returnDate
      ? {
          [FlightShopQueryParams.RETURN_DATE]: returnDate,
        }
      : {}),
    [FlightShopQueryParams.TRIP_CATEGORY]: tripCategory,
    [FlightShopQueryParams.STOP_OPTIONS]: stopOptions,
    [FLIGHT_SHOP_TYPE]: FlightShopType.DEFAULT,
    [FlightSearchQueryParams.ADULT_COUNT]: paxCount?.adultsCount
      ? paxCount.adultsCount.toString()
      : "",
    [FlightSearchQueryParams.CHILD_COUNT]: paxCount?.childrenCount
      ? paxCount.childrenCount.toString()
      : "",
    [FlightSearchQueryParams.INFANT_IN_LAP_COUNT]: paxCount?.infantsOnLapCount
      ? paxCount.infantsOnLapCount.toString()
      : "",
    [FlightSearchQueryParams.INFANT_IN_SEAT_COUNT]: paxCount?.infantsInSeatCount
      ? paxCount.infantsInSeatCount.toString()
      : "",
  };

  const shopQuery = new URLSearchParams(flightShopQueryObject).toString();
  return shopQuery;
};
