import { removeTrailingSlash } from '@marketing-site/utils/helpers';

export const LegalPageQuery = (slug: string, locale = 'en') => {
  return `query {
    pageCollection(limit: 1, where: {slug_contains: "${removeTrailingSlash(
      slug
    )}" locale: "${locale}" }) {
      items{
        slug
        locale
        metadataTitle
        metadataDescription
        metadataSharedImage {
          title
          url
        }
        navigation {
          header
          footer
        }
        headline
        content1 {
          json
        }
      }
    }
    associatedLocales: pageCollection(where: {slug: "${slug}"}) {
      items {
        locale
      }
    }
  }`;
};
