import { SessionInfo } from "@b2bportal/auth-api";

export const UPDATE_SESSION = "auth/UPDATE_SESSION";
export type UPDATE_SESSION = typeof UPDATE_SESSION;
export interface IUpdateSession {
  type: UPDATE_SESSION;
  authStateToken?: string;
  sessionInfo: SessionInfo;
}

export const FINALIZED_REQUIRED = "auth/FINALIZED_REQUIRED";
export type FINALIZED_REQUIRED = typeof FINALIZED_REQUIRED;
export interface IFinalizedRequired {
  type: FINALIZED_REQUIRED;
  authStateToken: string;
  missingFields: string[];
}
export const FINALIZE_USER_SUCCESS = "auth/FINALIZE_USER_SUCCESS";
export type FINALIZE_USER_SUCCESS = typeof FINALIZE_USER_SUCCESS;
export interface IFinalizeUserSuccess {
  type: FINALIZE_USER_SUCCESS;
  authStateToken: string;
}

export const FINALIZE_USER_FAILED = "auth/FINALIZE_USER_FAILED";
export type FINALIZE_USER_FAILED = typeof FINALIZE_USER_FAILED;
export interface IFinalizeUserFailed {
  type: FINALIZE_USER_FAILED;
}

export const REMOVE_SESSION = "auth/REMOVE_SESSION";
export type REMOVE_SESSION = typeof REMOVE_SESSION;
export interface IRemoveSession {
  type: REMOVE_SESSION;
}

export type AuthActions =
  | IUpdateSession
  | IFinalizedRequired
  | IFinalizeUserSuccess
  | IFinalizeUserFailed
  | IRemoveSession;
