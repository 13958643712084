import React from 'react';

const ChevronRight = () => {
  return (
    <svg
      width="13"
      height="23"
      viewBox="0 0 13 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.27279 1.22672C2.56985 0.523776 1.43015 0.523776 0.727208 1.22672C0.0242641 1.92966 0.0242641 3.06936 0.727208 3.7723L3.27279 1.22672ZM11 11.4995L12.2728 12.7723C12.9757 12.0694 12.9757 10.9297 12.2728 10.2267L11 11.4995ZM0.727208 19.2267C0.0242641 19.9297 0.0242641 21.0694 0.727208 21.7723C1.43015 22.4752 2.56985 22.4752 3.27279 21.7723L0.727208 19.2267ZM0.727208 3.7723L9.72721 12.7723L12.2728 10.2267L3.27279 1.22672L0.727208 3.7723ZM9.72721 10.2267L0.727208 19.2267L3.27279 21.7723L12.2728 12.7723L9.72721 10.2267Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default ChevronRight;
