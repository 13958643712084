import {
  CategorizedResponse,
  LocationQueryEnum,
  SearchParameters,
  Suggestion,
} from "@b2bportal/air-shopping-api";
import { fetchLocationAutocomplete } from "@hopper-b2b/api";
import { IApiConfig } from "@hopper-b2b/types";

export const fetchLocations = async (
  apiConfig: IApiConfig | undefined,
  search: string,
  callback: (newOptions: Suggestion[]) => void,
  additionalSearchControl?: SearchParameters
): Promise<void> => {
  const response = await fetchLocationAutocomplete(
    {
      LocationQuery: LocationQueryEnum.Label,
      l: search,
      searchParameters: {
        ...additionalSearchControl,
      },
    },
    apiConfig
  );

  const options: Suggestion[] = [];
  response?.categories?.reduce<Suggestion[]>(
    (acc: Suggestion[], categoryRes: CategorizedResponse) => {
      acc.push(...categoryRes.results);
      return acc;
    },
    options
  );

  callback(options);
};
