import { removeTrailingSlash } from '@marketing-site/utils/helpers';
import { ComponentQueries, TakeoverAnnouncementComponent } from './commponents';

export const PageQuery = (url: string, locale = 'en'): string => {
  return `query{
    pageCollection(
      limit: 1
      locale: "${locale}"
      where: { slug_contains: "${removeTrailingSlash(url)}" }
    )
    {
      items{
          slug
          showNavigation
          ${NavigationQuery}
          seo {
            title
            description
            sharedImage {
              description
              title
              url
            }
          }
          showBookingComponent
          bookingComponentPosition
          takeoverAnnouncement {
            ${TakeoverAnnouncementComponent}
          }
          takeoverImagesCollection(limit: 10) {
            items {
              experimentIdentifier
              downloadUrl
              image {
                description
                title
                url
              }
            }
          }
          contentCollection(limit: 40){
            items {
              ${ComponentQueries}
            }
          }
        }
      }
    
  }`
    .replace('\n/g', '')
    .replace(/\s\s+/g, ' ');
};

export const NavigationQuery = `
    navigation {
    header
    footer
  }
`;
