import { theme } from '@marketing-site/styles';
import { IGenericHeader } from '@marketing-site/types/shared.model';
import { RichTextToHtml } from '@marketing-site/utils';
import { withSideFlares } from '@marketing-site/utils/hoc/withFlare';
import React from 'react';
import styled from 'styled-components';

const GenericHeader = (props: IGenericHeader) => {
  const FlaredHeadline =
    props.flareColor !== 'none' && props.headline
      ? withSideFlares(Headline, {
          color: theme.colors[props.flareColor],
        })
      : Headline;
  return (
    <Container>
      <Preheadline>{props.preheadline}</Preheadline>
      <FlaredHeadline>{props.headline}</FlaredHeadline>
      {props.subheadline && <Subheadline>{props.subheadline}</Subheadline>}
      <Description>
        {props.description && RichTextToHtml(props.description.json)}
      </Description>
    </Container>
  );
};

export default GenericHeader;

const Container = styled.div`
  text-align: center;
  margin-top: 50px;
  max-width: 720px;
  margin: 0 auto;
  padding: 2rem 0;
`;

const Preheadline = styled.h5`
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
`;

const Headline = styled.h2`
  margin-top: 14px;
  margin-bottom: 5px;
`;

const Subheadline = styled.div``;

const Description = styled.div`
  margin-top: 0;
  p {
    font-size: 20px;
    line-height: 28px;
  }
`;
