import React from "react";

export const HotelsIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1903)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.8886 3.37061C12.1776 1.17298 16.0444 0 20 0C25.3025 0.00606532 30.386 2.11515 34.1354 5.86456C37.8848 9.61397 39.9939 14.6975 40 20C40 23.9556 38.827 27.8224 36.6294 31.1114C34.4318 34.4004 31.3082 36.9638 27.6537 38.4776C23.9991 39.9913 19.9778 40.3874 16.0982 39.6157C12.2186 38.844 8.65492 36.9392 5.85787 34.1421C3.06082 31.3451 1.15601 27.7814 0.384303 23.9018C-0.387401 20.0222 0.0086658 16.0009 1.52242 12.3463C3.03617 8.69181 5.59962 5.56823 8.8886 3.37061ZM10.7405 33.8578C13.4813 35.6892 16.7036 36.6667 20 36.6667C24.4188 36.6617 28.6551 34.9042 31.7797 31.7797C34.9042 28.6551 36.6618 24.4188 36.6667 20C36.6667 16.7036 35.6892 13.4813 33.8578 10.7405C32.0265 7.99968 29.4235 5.86347 26.3781 4.60201C23.3326 3.34055 19.9815 3.01049 16.7485 3.65358C13.5155 4.29666 10.5458 5.88401 8.21489 8.21488C5.88402 10.5458 4.29667 13.5155 3.65359 16.7485C3.0105 19.9815 3.34056 23.3326 4.60202 26.3781C5.86348 29.4235 7.99968 32.0265 10.7405 33.8578ZM12.5 20.8V13.65C12.5 13.474 12.4382 13.3216 12.3145 13.193C12.1908 13.0643 12.0443 13 11.875 13H10.625C10.4557 13 10.3092 13.0643 10.1855 13.193C10.0618 13.3216 10 13.474 10 13.65V27H12.5V23.4H27.5V27H30V21.45C30 21.274 29.9382 21.1216 29.8145 20.993C29.6908 20.8643 29.5443 20.8 29.375 20.8H12.5ZM17.3926 19.3883C17.8809 18.8805 18.125 18.2677 18.125 17.55C18.125 16.8323 17.8809 16.2195 17.3926 15.7117C16.9043 15.2039 16.3151 14.95 15.625 14.95C14.9349 14.95 14.3457 15.2039 13.8574 15.7117C13.3691 16.2195 13.125 16.8323 13.125 17.55C13.125 18.2677 13.3691 18.8805 13.8574 19.3883C14.3457 19.8961 14.9349 20.15 15.625 20.15C16.3151 20.15 16.9043 19.8961 17.3926 19.3883ZM18.75 20.15H30V19.5C30 18.4234 29.6338 17.5043 28.9014 16.7426C28.1689 15.9809 27.2852 15.6 26.25 15.6H19.375C19.2057 15.6 19.0592 15.6643 18.9355 15.793C18.8118 15.9216 18.75 16.074 18.75 16.25V20.15Z"
          fill="#01AAE4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1903">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
