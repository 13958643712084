import { isExternal, isUrl } from '@marketing-site/utils/helpers';
import React from 'react';
import { Link } from 'react-router-dom';

interface ILinkComponent {
  destination: string;
  className?: string;
  onMouseEnter?: React.MouseEventHandler;
  onFocus?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  onBlur?: React.MouseEventHandler;
}

const LinkComponent = ({
  children,
  ...props
}: React.PropsWithChildren<ILinkComponent>) => {
  const destination = props.destination === '/en' ? '/' : props.destination;
  if (!isUrl(destination)) {
    return (
      <a className={props.className} href={destination}>
        {children}
      </a>
    );
  }

  if (isExternal(destination)) {
    return (
      <a
        className={props.className}
        href={destination}
        target={destination.includes('.pdf') ? '_blank' : '_self'}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={props.className} to={destination}>
      {children}
    </Link>
  );
};

export default LinkComponent;
