import React from 'react';

const Youtube = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.45 10.4286C26.175 9.42857 25.4875 8.71429 24.525 8.42857C22.875 8 15.8625 8 15.8625 8C15.8625 8 8.98751 8 7.20001 8.42857C6.23751 8.71429 5.55 9.42857 5.275 10.4286C5 12.2857 5 16 5 16C5 16 5 19.7143 5.4125 21.5714C5.6875 22.5714 6.375 23.2857 7.3375 23.5714C8.9875 24 16 24 16 24C16 24 22.875 24 24.6625 23.5714C25.625 23.2857 26.3125 22.5714 26.5875 21.5714C27 19.7143 27 16 27 16C27 16 27 12.2857 26.45 10.4286ZM13.8 19.4286V12.5714L19.575 16L13.8 19.4286Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Youtube;
