import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import "./styles.scss";

export const AdditionalOptionSection = ({
  onClick,
  show,
}: {
  onClick: () => void;
  show: boolean;
}) => {
  const { t } = useI18nContext();

  return show ? (
    <div className="additional-option-section">
      <p className="additional-option-description">
        {t("additionalOptionDescription")}
      </p>
      <ActionButton
        defaultStyle="h4r-secondary"
        onClick={onClick}
        message={t("moreOptions")}
        className="more-option-button"
      />
    </div>
  ) : null;
};
