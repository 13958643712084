import React from 'react';
import styled from 'styled-components';
import Company from './company';
import { media } from '@marketing-site/styles';
import {
  IGenericSection,
  ILink,
  IImage,
} from '@marketing-site/types/shared.model';
import { RichTextToHtml } from '@marketing-site/utils';

interface IFeaturedCompany extends ILink {
  image: IImage;
}

export const FeaturedCompaniesSection = (
  props: IGenericSection<IFeaturedCompany>
) => {
  return (
    <FeaturedContainer>
      {props.preheadline && (
        <Preheadline className={'section-heading'}>
          {props.preheadline.toUpperCase()}
        </Preheadline>
      )}
      {props.headline && <Headline>{props.headline}</Headline>}
      {props.description && (
        <Description>{RichTextToHtml(props.description.json)}</Description>
      )}
      <CompaniesContainer id={'featuredCompaniesContainer'}>
        {props.body.items.map((company) => {
          return (
            <Company key={`featured-company-${company.text}`} {...company} />
          );
        })}
      </CompaniesContainer>
    </FeaturedContainer>
  );
};

const Preheadline = styled.div`
  margin: 20px auto;
`;

const Headline = styled.h2`
  margin: 0 auto;
`;

const Description = styled.div`
  margin: 0 auto;
`;

const FeaturedContainer = styled.div`
  ${media.tablet`
    max-width: 590px;
    text-align: left;
    padding: 30px;
    align-items: start;
  `}
  margin: 0 auto;
  padding-bottom: 100px;
  align-items: center;
  text-align: center;
  max-width: 980px;
  display: flex;
  flex-direction: column;

  h2,
  p {
    ${media.tablet`
    max-width: 590px;
  `}
    max-width: 550px;
  }
`;

const CompaniesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: center;
  gap: 10px 10px;

  ${media.tablet_up`
    justify-content: space-around;
    width: 100%;
  `};
`;
