import { LocationPinIcon } from "@marketing-site/static/icons";
import { theme } from "@marketing-site/styles";
import {
  IFeatureCard,
  ThemeColorKey,
} from "@marketing-site/types/shared.model";
import { IStyledCardBackground } from "@marketing-site/types";
import React from "react";
import styled from "styled-components";
import { Tag } from "../tag-label/Tag";

type CardBackProps = IFeatureCard;
export const CardBack = (props: CardBackProps) => {
  return (
    <Back className="card-back">
      <Layout>
        <Image
          src={props.imageSm.url}
          role="img"
          aria-label={props.imageSm.description}
        />
        <Title>{props.title}</Title>
        <Subtitle>{props.subtitle}</Subtitle>
        <Tags>
          {props.tags.map((location, idx) => (
            <Tag
              color={theme.colors[props.tagColor as ThemeColorKey]}
              key={`location-tag-${idx}`}
            >
              <LocationPinIcon
                color={theme.colors[props.tagColor as ThemeColorKey]}
              />
              {location}
            </Tag>
          ))}
        </Tags>
        <Description>{props.cardDescription}</Description>
      </Layout>
    </Back>
  );
};

const Back = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Layout = styled.div`
  padding: 1.5rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: start;
  color: ${(p) => p.theme.colors.grey[800]};
`;

const Image = styled.div<IStyledCardBackground>`
  width: 86px;
  height: 86px;
  background-image: url(${(p) => p.src});
  background-size: cover;
  border-radius: 50%;
  flex: 0 0 86px;
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  flex: 0 0 32px;
`;
const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.grey[400]};
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  flex: 1 0 20px;
`;
const Tags = styled.div`
  display: flex;
  gap: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  height: 20px;
  flex: 0 0 20px;
`;
const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  flex: 1 0 auto;
`;
