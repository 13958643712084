"use client";

import { SendCodeRequestEnum, UserState } from "@b2bportal/auth-api";
import {
  AxiosInterceptors,
  AxiosInterceptorsNext,
  AxiosInterceptorsProps,
  axiosInstance,
  createRequestLog,
  createResponseErrorLog,
  createResponseLog,
} from "@hopper-b2b/api";
import { useExperiments } from "@hopper-b2b/experiments";

import { branding } from "@hopper-b2b/hopper-theming";
import {
  ActionName,
  // getRecaptchaToken,
  recaptchaEndpoints,
} from "@hopper-b2b/hopper-utils";
import { getRootLang, useI18nContext } from "@hopper-b2b/i18n";
import { useIsServer, useUserSource } from "@hopper-b2b/utilities";
import axios, { InternalAxiosRequestConfig } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuthProvider } from "../../context";

export interface IAxiosInterceptors {
  version?: string;
  recaptchaActionKey?: string;
  children?: React.ReactNode;
  hopperSessionToken?: string;
  errorAction?: AxiosInterceptorsProps["errorAction"];
}

const TENANT = "hopper-app";

const AxiosInterceptorWrapper = ({
  children,
  hopperSessionToken,
  version,
  recaptchaActionKey,
  errorAction,
}: IAxiosInterceptors) => {
  const { language } = useI18nContext();
  const userInfoContext = useAuthProvider();
  const { trackingProperties } = useExperiments();
  const userSource = useUserSource();
  const isServer = useIsServer();
  const [innerWidth, setInnerWidth] = useState(0);

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    window.addEventListener("resize", () => setInnerWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setInnerWidth(window.innerWidth)
      );
  }, []);

  const currency = useMemo(
    () => branding[language]?.currency.code || "USD",
    [language]
  );
  const headers = useMemo(
    () => ({
      "Accept-Language": `${language}, ${getRootLang(language)}`,
      "X-Sec-CH-Viewport-Width": innerWidth,
      "X-Accept-Currency": `${currency}`,
    }),
    [language, innerWidth, currency]
  );

  // Using Google reCaptcha tokens on a few key endpoints
  const handleReq = useCallback(
    async (req: InternalAxiosRequestConfig) => {
      if (recaptchaActionKey && recaptchaEndpoints.has(req.url)) {
        try {
          let actionName = recaptchaEndpoints.get(req.url);
          if (actionName === ActionName.SendCode) {
            switch (req.data?.SendCodeRequest) {
              case SendCodeRequestEnum.SendCodeSms:
                actionName = ActionName.SendSMS;
                break;
              case SendCodeRequestEnum.SendCodeEmail:
                actionName = ActionName.SendEmail;
                break;
            }
          }
          // const token = await getRecaptchaToken(recaptchaActionKey, actionName);
          // req.headers["X-Recaptcha-Token"] = token;
        } catch (e) {
          console.error("Error retrieving reCaptcha token:", e);
        }
      }
      return req;
    },
    [recaptchaActionKey]
  );

  useEffect(() => {
    const requestInterceptor = {
      instance: axiosInstance.interceptors.request.use(handleReq),
      global: axios.interceptors.request.use(handleReq),
    };

    //runs when component unmount
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor.instance);
      axios.interceptors.request.eject(requestInterceptor.global);
    };
  }, [handleReq]);

  if (hopperSessionToken) headers["Hopper-Session"] = hopperSessionToken;

  return isServer ? (
    <AxiosInterceptorsNext
      experimentTrackingProperties={trackingProperties}
      userSource={userSource}
      tenant={TENANT}
      currency={currency}
      isAgentPortal={!!userInfoContext?.state?.sessionInfo?.delegatedTo}
      isSignedIn={userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn}
      isGuestUser={
        userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
        UserState.Guest
      }
      delegatedTo={userInfoContext?.state?.sessionInfo?.delegatedTo}
      requestHeaders={headers}
      version={version}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      errorAction={errorAction}
    >
      {children}
    </AxiosInterceptorsNext>
  ) : (
    <AxiosInterceptors
      experimentTrackingProperties={trackingProperties}
      userSource={userSource}
      tenant={TENANT}
      currency={currency}
      isAgentPortal={!!userInfoContext?.state?.sessionInfo?.delegatedTo}
      isSignedIn={userInfoContext?.state?.sessionInfo?.userScope?.isSignedIn}
      isGuestUser={
        userInfoContext?.state?.sessionInfo?.userInfo?.userState ===
        UserState.Guest
      }
      delegatedTo={userInfoContext?.state?.sessionInfo?.delegatedTo}
      requestHeaders={headers}
      version={version}
      logResponse={createResponseLog}
      logRequest={createRequestLog}
      logError={createResponseErrorLog}
      errorAction={errorAction}
    >
      {children}
    </AxiosInterceptors>
  );
};

export default AxiosInterceptorWrapper;
