import React, { Fragment, useMemo } from "react";
import styled from "styled-components";
import * as Components from "@marketing-site/components/_contentful";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { HopperSearch } from "@marketing-site/components/search/hopperSearch";
import { mapComponents } from "@marketing-site/utils";
import { ContentfulComponentMap } from "@marketing-site/utils/mapComponent";
import { LoadingPage } from "./LoadingPage";
import Footer from "@whopper/footer";
import { RewardsModal } from "@hopper-b2b/wallet";

export interface IPageProps {
  slug: string;
  locale: string;
  canonical: string;
  content: any;
  pageType?: string;
  loading?: boolean;
  customComponentMap?: ContentfulComponentMap;
}

export const Page = ({
  slug,
  locale,
  canonical,
  content,
  pageType,
  loading,
  children,
  customComponentMap = {},
}: React.PropsWithChildren<IPageProps>) => {
  const childElements = mapComponents(
    content?.contentCollection?.items ?? [],
    pageType,
    customComponentMap
  );

  const firstItem = useMemo(
    () =>
      content?.contentCollection?.items?.find(
        (item: any) => item && Object.keys(item).length > 0
      ),
    [content?.contentCollection?.items]
  );

  return !loading && content ? (
    <Fragment>
      <Components.SEO {...content?.seo} locale={locale} canonical={canonical} />
      <NavigationBar contentfulNavProps={content?.navigation} />
      <Body>
        {content?.showBookingComponent && (
          <>
            <HopperSearch slug={slug} firstPageItem={firstItem} />
            <RewardsModal />
          </>
        )}
        {children}
        {childElements?.map((component, i) => (
          <Fragment key={i}>{component}</Fragment>
        ))}
      </Body>
      {content?.navigation?.footer && (
        <Footer {...content?.navigation.footer} />
      )}
    </Fragment>
  ) : (
    <LoadingPage />
  );
};

const Body = styled.div`
  position: relative;
  z-index: 10;
`;
