/* eslint-disable prettier/prettier */
import React, { useEffect, useRef } from 'react';
import './assets/bootstrap/css/bootstrap.min.css';
import './assets/css/style.css';
import { jobsEmbed } from './assets/js/lever-jobs-embed.js';
import logo from './assets/img/logo.png'; 
import search from './assets/img/search.svg'; 
import close from './assets/img/close.svg'; 
import loading from './assets/img/loading.gif'; 

export const JobsPage = (): any => {
  const dataFetchedRef = useRef(false);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    jobsEmbed();
  }, []);

  return (
    <div id="careersPage">
      <header>
        <div className="container">
          <div className="row">
            <div className="col">
              <a href="/">
                <img src={logo} alt="Hopper logo" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="container">
          <div className="row">
            <div className="col">
              <div id="new-list" className="filter">
                <span>Filter by:</span>
                <div className="search">
                  <input
                    id="lever-jobs-search"
                    className="search"
                    placeholder="Search"
                  />
                  <img src={search} alt="Hopper logo" />
                </div>
                <div id="lever-jobs-filter">
                  <select className="lever-jobs-filter-locations selectpicker">
                    <option value="" disabled selected>
                      Location
                    </option>
                  </select>
                  <select className="lever-jobs-filter-departments">
                    <option value="" disabled selected>
                      Business unit
                    </option>
                  </select>
                  <select className="lever-jobs-filter-teams">
                    <option value="" disabled selected>
                      Team
                    </option>
                  </select>
                  <select className="lever-jobs-filter-work-types">
                    <option value="" disabled selected>
                      Work type
                    </option>
                  </select>
                  <select className="lever-jobs-filter-location-types">
                    <option value="" disabled selected>
                      Location type
                    </option>
                  </select>
                  <div
                    id="lever-clear-filters"
                    className="lever-clear-filters"
                    style={{ display: 'none' }}
                  >
                    <a>
                      Clear filters <img src={close} alt="Close" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className='loading'>
                <img src={loading} alt="Loading" />
                <p>
                  Just a moment...
                </p>
              </div>
              <div id="lever-no-results" style={{ display: 'none' }}>
                  <p>No job postings match these filters.</p>
                  <a className="lever-clear-filters">
                    Go back to all job postings
                  </a>
                </div>
              <div id="lever-jobs-container"></div>
            </div>
          </div>
        </div>
      </main>
      <footer></footer>
    </div>
  );
};
