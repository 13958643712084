import { Branding } from "@hopper-b2b/types";
import dayjs from "dayjs";
import "dayjs/locale/en";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import i18next from "i18next";
import { FC, ReactNode } from "react";
import en_translations from "../locales/en/index";
import { I18nContext } from "./I18nContext";
import { I18nNamespace, Translation, TranslationLanguage } from "./types";

// This is the server-side version of the I18nProvider
// This does not contain the hooks that are used in the client-side version.
// Since this cannot access localStorage, it uses the localeParam to determine the language.

// const en_translations = {
//   ...en_seo,
//   ...en_hotels,
//   ...en_ui,
//   ...en_app,
// };

interface iI18nProvider {
  children: ReactNode;
  branding: Branding;
  defaultLng: string;
  localeParam?: string;
  tenantTranslation?: Translation;
}

export const I18nProviderSSR: FC<iI18nProvider> = ({
  children,
  localeParam,
  branding,
  tenantTranslation = {},
}: iI18nProvider) => {
  const lang = localeParam;

  const translationLanguage =
    lang in TranslationLanguage
      ? TranslationLanguage[lang as TranslationLanguage]
      : branding.default in TranslationLanguage
      ? TranslationLanguage[branding.default]
      : TranslationLanguage.en;

  i18next.init({
    resources: {},
    lng: lang,
    fallbackLng: branding.default || "en",
  });

  i18next?.services?.formatter?.addCached("daterange", (lng, options) => {
    const format = new Intl.DateTimeFormat(lng, {
      month: "short",
      day: "numeric",
      ...options,
    });
    return (val) => (format as any).formatRange(val[0], val[1]);
  });

  // Set the locale for dayjs
  dayjs.locale(lang);
  dayjs.extend(LocalizedFormat);

  // Deep merge the values in the namespace.
  const deep = true;
  // Overwrite the values in the namespace.
  const overwrite = true;

  function addResourceBundles(language: string) {
    //Brand namespace
    i18next.addResourceBundle(
      language,
      I18nNamespace.brand,
      { ...branding[language] },
      deep,
      overwrite
    );

    //Translation namespace
    const translation = en_translations;
    i18next.addResourceBundle(
      language,
      I18nNamespace.translation,
      translation,
      deep,
      overwrite
    );

    if (tenantTranslation[language]) {
      i18next.addResourceBundle(
        language,
        I18nNamespace.translation,
        tenantTranslation[language],
        deep,
        overwrite
      );
    }
  }

  addResourceBundles(translationLanguage);
  // Load the resources from fallback language
  const fallbackLng = i18next.options.fallbackLng?.[0];
  if (!!fallbackLng && fallbackLng !== translationLanguage) {
    addResourceBundles(fallbackLng);
  }

  return (
    <I18nContext.Provider value={i18next}>{children}</I18nContext.Provider>
  );
};
