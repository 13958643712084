const REFRESH_TOKEN = "refresh_token";
export const setRefreshToken = (token: string) => {
  try {
    localStorage.setItem(REFRESH_TOKEN, token);
  } catch (e) {
    console.error(e);
  }
};

export const getRefreshToken: () => string = () => {
  const defaultValue = "";
  try {
    return localStorage.getItem(REFRESH_TOKEN) || defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
};

export const deleteRefreshToken = () => {
  try {
    return localStorage.removeItem(REFRESH_TOKEN);
  } catch (e) {
    console.error(e);
  }
};
