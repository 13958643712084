import React from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { PageQuery } from "@marketing-site/api/urbana/contentful/queries";
import { TakeoverImage } from "@marketing-site/components/_contentful";
import { ITakeoverImage } from "@marketing-site/components/_contentful/takeover-image/takeoverImage";
import { Page } from "@marketing-site/pages/Page/Page";
import { getLocaleFromPathname } from "@marketing-site/utils/helpers";
import { useDeviceTypes } from "@whopper/utilities";
import { useLocation } from "react-router-dom";
import { LoadingPage } from "../Page/LoadingPage";
import { HopperSearch } from "@marketing-site/components/search/hopperSearch";
import { Box } from "@mui/material";
import { RewardsModal } from "@hopper-b2b/wallet";

import "./index.scss";

const COMPONENT_MAP = {
  AlertBanner: null,
  AccordionItem: null,
  Break: null,
  CalloutFullWidth: null,
  Carousel: null,
  Countdown: null,
  CtaButton: null,
  CtaDescription: null,
  CtaFooter: null,
  DescriptionPoint: null,
  DescriptionPointsSection: null,
  Faq: null,
  HeroCarousel: null,
  HeroProduct: null,
  ImageTextDescription: null,
  NavigationPage: null,
  PageNav: null,
  Review: null,
  ReviewsSection: null,
  SaleItem: null,
  SaleSection: null,
  SectionHeader: null,
  TakeoverAnnouncement: null,
  TakeoverImage: null,
};

export const DealDrops = () => {
  const location = useLocation();
  const locale = getLocaleFromPathname(location.pathname);
  const path = "/";
  const slug = "deal-drops";
  const { matchesMobile } = useDeviceTypes();

  const { data: contentfulData, isLoading: contentfulLoading } =
    useFetchContentQuery({
      query: PageQuery(slug, locale),
      space: "hopperCom",
    });

  const hopperHomepageTakeover = useExperiment("hopper-homepage-takeover");

  const experimentTakeover =
    contentfulData?.takeoverImagesCollection?.items?.find(
      (img: ITakeoverImage) => img.experimentIdentifier !== null
    );
  const controlTakeover = contentfulData?.takeoverImagesCollection?.items?.find(
    (img: ITakeoverImage) => img.experimentIdentifier === null
  );

  const displayedTakeover: ITakeoverImage | null | undefined = matchesMobile
    ? controlTakeover
    : hopperHomepageTakeover
    ? experimentTakeover
    : null;

  return contentfulLoading || !contentfulData ? (
    <LoadingPage />
  ) : (
    <>
      <Page
        slug={slug}
        locale={locale}
        canonical={path}
        content={{ ...contentfulData, showBookingComponent: false }}
        customComponentMap={COMPONENT_MAP}
      >
        <Box className="deal-drop-v2-search-container">
          <HopperSearch
            slug={slug}
            firstPageItem={{ backgroundColor: "transparent" }}
          />
        </Box>
      </Page>
      {displayedTakeover && <TakeoverImage {...displayedTakeover} />}
      <RewardsModal />
    </>
  );
};
