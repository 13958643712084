const imgIconQuery = `title url`;

const internalType = `
  __typename
`;

const link = `
  link {
    text
    image {
      title
      url
    }
    url
    isExternal
  }
`;

const linkComponent = `
  ... on Link {
    text
    image {
      title
      url
    }
    url
    isExternal
    ${internalType}
  }
`;

const CTAButtonComponentNested = `
  preheadline
  headline
  postheadline
  link
  openInNewTab
  color
  isFilled
  size
`;

const CountdownComponent = `
  countdownSize
  startDate
  presaleText
  endDate
  isSaleText
`;

const CalloutFullWidthComponent = `
  ... on CalloutFullWidth {
    calloutText:headline {
      json
    }
    icon {
      ${imgIconQuery}
    }
    ctaButton {
      ${CTAButtonComponentNested}
    }
    ${internalType}
  }
`;

const SaleItemComponent = `
  ... on SaleItem {
    sys {
      id
    }
    headline
    subheadline {
      json
    }
    discount
    isDiscount
    isLightningDeal
    actionLink
    actionText
    showSaleBadge
    badgeColor
    image {
      ${imgIconQuery}
    }
    countdown {
      ${CountdownComponent}
    }
  }
`;

const CarouselComponent = `
... on Carousel {
  subheadline
  headline
  itemsCollection {
    items{
      ${imgIconQuery}
    }
  }
  sectionId
  ${internalType}
}
`;

const ctaButtonComponent = `
  ... on CtaButton {
    ${CTAButtonComponentNested}
    ${internalType}
  }
`;

const ctaDescriptionComponent = `
 ... on CtaDescription {
   headline
   description
   image {
     ${imgIconQuery}
   }
   isImageSmall
   imageTop
   ${internalType}
   action {
     ${CTAButtonComponentNested}
   }
   sectionId
 }
`;

const saleSectionComponent = `
  ... on SaleSection {
    headline
    containMissedSales
    countdown {
      ${CountdownComponent}
    }
    saleItemsCollection(limit: 40) {
      items{
        ${SaleItemComponent}
      }
    }
    sectionId
    ${internalType}
  }
`;

const faqComponent = `
  ... on Faq {
    headline
    questions {
      json
    }
    sectionId
    ${internalType}
  }
`;

const heroComponent = `
  ... on Hero {
    subheadline
    headline
    description
    countdown {
      ${CountdownComponent}
    }
    flareColor
    backgroundColor
    ctaButton{
      ${CTAButtonComponentNested}
    }
    image {
      ${imgIconQuery}
    }
    ${internalType}
  }
`;

const heroCarouselComponent = `
  ... on HeroCarousel {
    headline
    flareColor
    backgroundColor
    scrollDelay
    countdown {
      ${CountdownComponent}
    }
    imagesCollection  {
      items {
        backgroundColor
        downloadLink
        desktopImage {
          ${imgIconQuery}
        }
        mobileImage {
          ${imgIconQuery}
        }
      }
    }
    ${internalType}
  }
`;

const heroProductComponent = `
  ... on HeroProduct {
    preIcon {
      ${imgIconQuery}
    }
    cornerColor
    headline
    heroProductDescription : description {
      json
    }
    ctaButton {
      ${CTAButtonComponentNested}
    }
    secondCtaButton {
      ${CTAButtonComponentNested}
    }
    image {
      ${imgIconQuery}
    }
    backgroundColor
    ${internalType}
  }
`;

const navigationPageComponent = `
  ... on PageNav {
    showNav
    navigationType
    navLinksCollection(limit: 15) {
      items{
        text
        image{
          ${imgIconQuery}
        }
        isExternal
        url
      }
    }
    ${internalType}
  }
`;

const productFeaturesComponent = `
  ... on ProductFeatures {
    preheadline
    headline
    lastWordUnderlined
    accordion {
      headline
      description {
        json
      }
      sectionImage {
        ${imgIconQuery}
      }
      icon {
        ${imgIconQuery}
      }
      ${link}
    }
    ${internalType}
  }
`;

const imageTextDescriptionComponent = `
  ... on ImageTextDescription {
    preHeadline
    headline
    headlineHasFlare
    headlineFlareColor
    textDescription: description {
      json
    }
    preIcon {
      ${imgIconQuery}
    }
    image {
      ${imgIconQuery}
    }
    imageLocation
    actionLink: ${link}
    ${internalType}
  }
`;

const pageBreakComponent = `
  ... on Break {
    isLine
    waveColor
    waveType
    useGlobalColor
    ${internalType}
  }
`;

const sectionHeaderComponent = `
  ... on SectionHeader {
    preheadline
    headline
    headlineHasFlares
    flareColor
    subheader: description {
      json
    }
    ${internalType}
  }
`;

const descriptionPointComponent = `
  ... on DescriptionPoint {
    headline
   highlightedText
    description {
      json
    }
    actionLink: ${link}
    icon {
      ${imgIconQuery}
    }
  }
`;

const descriptionPointsSectionComponent = `
  ... on DescriptionPointsSection {
    showPreheadline
    preheadline
    numberPerRow
    alignment
    descriptionPointsCollection(limit: 14) {
      items {
        ${descriptionPointComponent}
      }
    }
    ${internalType}
  }
`;

const reviewComponent = `
  ... on Review {
    headline
    description
    source
    icon {
      ${imgIconQuery}
    }
  }
`;

const reviewsSectionComponent = `
  ... on ReviewsSection {
    preheadline
    headline
    reviewsCollection(limit: 5) {
      items {
        ${reviewComponent}
      }
    }
    sectionId
    ${internalType}
  }
`;

const ctaFooterComponent = `
  ... on CtaFooter {
    actionsCollection(limit: 2) {
      items{
        ${CTAButtonComponentNested}
      }
    }
    headline
    headlineHasFlare
    hasLightBackground
    footerDescription: description {
      json
    }
    sectionId
    ${internalType}
  }
`;

const takeoverAnnouncementComponent = `
    showTakeover
    showTimer
    notSaleHeadline
    isSaleHeadline
    saleStartDate
    timerPresaleText
    saleEndDate
    timerEndSaleText
    description
    image {
      ${imgIconQuery}
    }
    actionText
    actionTextMobile
    actionLink
    qrCode {
      ${imgIconQuery}
    }
    learnMoreText
    learnMoreLink
`;

const primativesComponent = `
  ... on Primitives {
    title
    textList
    richText {
      json
    }
    shortText
    ${internalType}
  }
`;

const genericSectionComponent = `
  ... on GenericSection {
    title
    meta
    preheadline
    headline
    subheadline
    flareColor
    genericSectionDescription : description {
      json
    }
    body : bodyCollection(limit: 12) {
      items {
        ${descriptionPointComponent}
      }
    }
    bodyMedia : bodyMediaCollection(limit: 16) {
      items {
        ${imgIconQuery}
      }
    }
    ${internalType}
  }
`;

export const ComponentQueries = [
  heroComponent,
  heroCarouselComponent,
  heroProductComponent,
  CarouselComponent,
  ctaButtonComponent,
  ctaDescriptionComponent,
  sectionHeaderComponent,
  reviewsSectionComponent,
  navigationPageComponent,
  faqComponent,
  saleSectionComponent,
  pageBreakComponent,
  descriptionPointsSectionComponent,
  ctaFooterComponent,
  imageTextDescriptionComponent,
  primativesComponent,
  CalloutFullWidthComponent,
  genericSectionComponent,
].join();

export const SectionQueries = [
  descriptionPointComponent,
  reviewComponent,
  SaleItemComponent,
];

export const TakeoverAnnouncementComponent = takeoverAnnouncementComponent;
