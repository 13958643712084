import { useContext } from "react";
import { Box } from "@mui/material";
import { MonthType, useMonth, FirstDayOfWeek } from "@datepicker-react/hooks";
import { context as DateRangePickerContext } from "../../context";
import {
  Day,
  generateBucketClassName,
  generatePriceLabel,
} from "../Day/component";
import { IMonthBucket } from "@hopper-b2b/types";
import "./styles.scss";
import dayjs from "dayjs";
import clsx from "clsx";

export interface IMonthProps extends MonthType {
  firstDayOfWeek: FirstDayOfWeek;
  hideWeekdayLabels?: boolean;
  hideHeader?: boolean;
  hideBody?: boolean;
  headerClassName?: string;
  bodyClassName?: string;
  rootClassName?: string;
  monthFormat?: string;
  isMiniCalendar?: boolean;
  onClick?: () => void;
  className?: string;
  nextMonth?: (date: string) => void;
  prevMonth?: (date: string) => void;
  currentFocusDate?: Date;
  setCurrentFocusDate?: (date?: Date) => void;
  setIsDateFocused?: (bool: boolean) => void;
  isDateFocused?: boolean;
  priceTags?: string[];
}

export const Month = ({
  year,
  month,
  firstDayOfWeek,
  hideWeekdayLabels,
  hideHeader,
  hideBody,
  headerClassName,
  bodyClassName,
  rootClassName,
  monthFormat = "MMMM",
  isMiniCalendar,
  onClick,
  className,
  currentFocusDate,
  setCurrentFocusDate,
  setIsDateFocused,
  isDateFocused,
  priceTags,
}: IMonthProps) => {
  const weekdayLabelFormat = (date: Date) => dayjs(date).format("dd")[0];
  const dayLabelFormat = (date: Date) => dayjs(date).format("D");

  const monthLabelFormat = (date: Date) => {
    const monthLabel = dayjs(date).format(monthFormat);
    const isYearIncluded = monthFormat.toLowerCase().includes("y");
    if (isYearIncluded) {
      return monthLabel;
    } else {
      return dayjs(date).isSame(dayjs(), "year")
        ? monthLabel
        : monthLabel + " " + dayjs(date).format("YYYY");
    }
  };

  const { months: monthBuckets } = useContext(DateRangePickerContext);

  const { days, weekdayLabels, monthLabel } = useMonth({
    year,
    month,
    firstDayOfWeek,
    weekdayLabelFormat,
    monthLabelFormat,
    dayLabelFormat,
  });

  return (
    <Box
      className={clsx(
        "month-root",
        { "mini-calendar": isMiniCalendar },
        rootClassName,
        className
      )}
      onClick={() => onClick && onClick()}
    >
      {!hideHeader && (
        <Box className={clsx("month-header", headerClassName)}>
          <Box className="month-header-contents">
            <Box className="month-label">{monthLabel}</Box>
          </Box>
        </Box>
      )}
      <Box
        className={clsx("month-body", bodyClassName)}
        aria-label={monthLabel}
      >
        {!hideWeekdayLabels &&
          generateRenderWeekdayLabels({ month, year })(weekdayLabels)}
        {!hideBody &&
          generateRenderDays({
            year,
            monthLabel,
            month: month,
            months: monthBuckets,
            isMiniDay: isMiniCalendar,
            className: className,
            currentFocusDate,
            setCurrentFocusDate,
            setIsDateFocused,
            isDateFocused,
            priceTags,
          })(days)}
      </Box>
    </Box>
  );
};

export const generateRenderWeekdayLabels =
  ({ month, year }: { month: number; year: number }) =>
  (labels: string[]) =>
    labels.map((label, index) => (
      <Box className="weekday-label" key={`${month} ${year} ${index}`}>
        {label}
      </Box>
    ));

export const generateCalculateDateStyle =
  ({
    months,
    currentMonth,
    priceTags,
  }: {
    months: IMonthBucket[] | null;
    currentMonth: number;
    priceTags?: string[];
  }) =>
  (
    day: number | { dayLabel: string; date: Date }
  ): { styleClassName: string; priceLabelName: string } => {
    if (months && typeof day !== "number") {
      const dates =
        months.find(({ monthIndex }) => monthIndex === currentMonth)?.dates ||
        [];

      const bucket = dates.find((dateBucket) =>
        dayjs(day.date).isSame(dateBucket.date, "day")
      )?.bucket;

      if (typeof bucket === "number") {
        return {
          styleClassName: generateBucketClassName(bucket),
          priceLabelName: generatePriceLabel(bucket, priceTags),
        };
      }
    }

    return { styleClassName: "", priceLabelName: "" };
  };

export const generateRenderDays =
  ({
    year,
    month,
    months,
    monthLabel,
    isMiniDay,
    className,
    currentFocusDate,
    setCurrentFocusDate,
    setIsDateFocused,
    isDateFocused,
    priceTags,
  }: {
    year: number;
    month: number;
    months: IMonthBucket[] | null;
    monthLabel: string;
    isMiniDay?: boolean;
    className?: string;
    currentFocusDate?: Date;
    setCurrentFocusDate?: (date?: Date) => void;
    setIsDateFocused?: (bool: boolean) => void;
    isDateFocused?: boolean;
    priceTags?: string[];
  }) =>
  (
    days: (
      | number
      | {
          dayLabel: string;
          date: Date;
        }
    )[]
  ) =>
    (
      days as {
        dayLabel: string;
        date: Date;
      }[]
    ).map((day, index) => {
      const { styleClassName, priceLabelName } = generateCalculateDateStyle({
        months,
        currentMonth: month,
        priceTags,
      })(day);

      return (
        <Day
          className={clsx(styleClassName, className)}
          key={
            typeof day === "number"
              ? `${month} ${index} ${year}`
              : `${day.dayLabel} ${day.date} ${year}`
          }
          monthLabel={monthLabel}
          dayLabel={day.dayLabel}
          date={day.date}
          isMiniDay={isMiniDay}
          currentFocusDate={currentFocusDate}
          setCurrentFocusDate={setCurrentFocusDate}
          setIsDateFocused={setIsDateFocused}
          isDateFocused={isDateFocused}
          priceLabelName={priceLabelName}
        />
      );
    });
