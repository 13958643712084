import React from "react";
import styled from "styled-components";
import { media, theme } from "@marketing-site/styles/";
import { RichTextToHtml } from "@marketing-site/utils";
import { IStyledBackground, IStyledFlare } from "@marketing-site/types";
import { IBaseComponent, IRichText } from "@marketing-site/types/shared.model";
import { withSideFlares } from "@marketing-site/utils/hoc/withFlare";

export interface ISectionHeader extends IBaseComponent {
  preheadline: string;
  headline: string;
  headlineHasFlares: boolean;
  flareColor: string;
  subheader: IRichText;
}

const SectionHeader = (props: ISectionHeader) => {
  const FlaredHeadline =
    props.headlineHasFlares && props.flareColor
      ? withSideFlares(Headline, {
          color: (theme.colors as any)[props.flareColor],
        })
      : Headline;
  return (
    <SectionHeaderContainer>
      <Preheadline>{props.preheadline}</Preheadline>
      <FlaredHeadline>{props.headline}</FlaredHeadline>
      {props.subheader && (
        <Subheadline>{RichTextToHtml(props.subheader.json)}</Subheadline>
      )}
    </SectionHeaderContainer>
  );
};

export default SectionHeader;

const Background = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor};
`;

const SectionHeaderContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  max-width: 720px;
  margin: 0 auto;
  padding: 2rem 0;
`;

const Preheadline = styled.h5`
  margin-top: 0;
  margin-bottom: 0;
`;

const Headline = styled.h2`
  margin-top: 14px;
  margin-bottom: 5px;
`;

const Subheadline = styled.div`
  margin-top: 0;
  p {
    font-size: 20px;
    line-height: 28px;
  }
`;
