import React from "react";

export const LeaveForAnyReasonIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1891)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.1976 26.2762C4.16573 29.3594 5.86739 32.161 8.15668 34.4409C11.9065 38.1822 16.9888 40.2817 22.2886 40.279C27.5883 40.2763 32.6727 38.1715 36.4264 34.4264C36.7781 34.0748 36.9758 33.598 36.976 33.101C36.9763 32.6039 36.7791 32.1274 36.4278 31.7761C36.0765 31.4248 35.6 31.2276 35.1029 31.2279C34.6059 31.2281 34.1291 31.4258 33.7775 31.7775C31.1665 34.3951 27.7409 36.0438 24.0676 36.4505C20.3943 36.8572 16.6935 35.9977 13.5777 34.0141C10.4619 32.0305 8.11787 29.0418 6.93355 25.5426C5.74922 22.0434 5.79561 18.2435 7.06501 14.7717C8.33442 11.2999 10.7508 8.36445 13.9141 6.45106C17.0775 4.53766 20.7983 3.76108 24.4606 4.24981C25.5435 4.39431 26.602 4.64687 27.6218 5.0001L27.6238 4.97947C29.8648 5.78647 31.9164 7.08233 33.6209 8.79114C33.9725 9.14277 34.4493 9.34046 34.9463 9.34072C35.4433 9.34097 35.9199 9.14377 36.2711 8.7925C36.6224 8.44123 36.8196 7.96466 36.8194 7.46764C36.8191 6.97061 36.6214 6.49384 36.2698 6.14221C33.9363 3.81405 31.0885 2.1198 27.9945 1.17038L28.0001 1.11309C26.5062 0.667631 24.9597 0.396922 23.3915 0.310391C22.6206 0.26785 21.8498 0.270126 21.0831 0.316509C16.1655 0.571873 11.5018 2.63397 8.00003 6.1277L8.14516 6.13913C7.55848 6.72556 7.00719 7.34951 6.49508 8.00799C4.50959 10.561 3.17237 13.5568 2.59805 16.7385C2.02373 19.9202 2.22946 23.193 3.1976 26.2762ZM38.4886 18.4986L32.7898 12.5327C32.108 11.8224 31.0114 11.8224 30.3295 12.5327C29.6534 13.2429 29.6534 14.3906 30.3295 15.1009L33.0682 17.9645L20.7386 17.9645C19.7784 17.9645 19 18.777 19 19.7827C19 20.7884 19.7784 21.6009 20.7386 21.6009H33.0682L30.3352 24.4645C29.6591 25.1747 29.6591 26.3224 30.3352 27.0327C31.0114 27.7429 32.1136 27.7429 32.7898 27.0327L38.4886 21.044C38.8295 20.7088 39 20.2656 39 19.7713C39 19.277 38.8295 18.8281 38.4886 18.4986Z"
          fill="#01AAE4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1891">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
