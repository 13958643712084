import React from "react";
import styled from "styled-components";
import media from "@marketing-site/styles/media";
import { RichTextToHtml } from "@marketing-site/utils";
import { IStyledImageTextDescription } from "@marketing-site/types";
import { IImageTextDescription } from "@marketing-site/types/shared.model";
import { LinkText } from "@marketing-site/components/";

const ImageTextDescription = ({
  className,
  ...props
}: IImageTextDescription) => {
  return (
    <Wrapper>
      <ImageTextContainer>
        <TextContainer order={props.imageLocation === "Left" ? 2 : 1}>
          <div>
            {props.preHeadline && (
              <PreHeadline>{props.preHeadline}</PreHeadline>
            )}
            {props.preIcon && (
              <PreIcon
                src={props.preIcon.url}
                title={props.preIcon.title}
                alt={props.preIcon.description}
              />
            )}
            <Headline>{props.headline}</Headline>
            {props.textDescription && (
              <Description>
                {RichTextToHtml(props.textDescription.json)}
              </Description>
            )}
            {props.actionLink && <LinkText {...props.actionLink} />}
          </div>
        </TextContainer>
        <ImageContainer order={props.imageLocation === "Left" ? 1 : 2}>
          <Image src={props.image.url} alt={props.image.title} />
        </ImageContainer>
      </ImageTextContainer>
    </Wrapper>
  );
};

export default ImageTextDescription;

const Wrapper = styled.div`
  padding: 30px 0;
`;

const ImageTextContainer = styled.div<IStyledImageTextDescription>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1080px;
  margin-right: auto;
  margin-left: auto;
  ${media.phone_large`
    flex-direction: column;
  `};
  > div {
    padding: 0 30px;
  }
`;

const TextContainer = styled.div<{ order: number }>`
  flex: 1 1 50%;
  order: ${(p) => p.order};
  ${media.phone_large`
    order: 1
  `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PreHeadline = styled.h5``;

const Headline = styled.h3`
  max-width: 480px;
  margin: 12px 0 0 0;
`;

const Description = styled.div`
  max-width: 500px;
`;

const ImageContainer = styled.div<{ order: number }>`
  flex: 1 1 50%;
  order: ${(p) => p.order};
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.phone_large`
    order: 1
  `}
`;

const Image = styled.img`
  object-fit: contain;
  max-width: 450px;
  width: 100%;
  height: auto;
`;

const PreIcon = styled.img`
  width: 42px;
  height: 42px;
`;
