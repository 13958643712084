import { EventObject, TransitionDefinition } from "xstate";

// Returns stringified list of transitions to assist with debugging xstate machines
// Assumes no parallel states exist
export const getStringifiedTransitions = <TContext, TEvent extends EventObject>(
  transitions: Array<TransitionDefinition<TContext, TEvent>>
) => {
  return transitions
    .map((t) =>
      t.source && t.target?.[0] ? [`${t.source?.id} -> ${t.target[0]?.id}`] : ""
    )
    .filter((t) => !!t)
    .join(", ");
};
