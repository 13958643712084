import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { PageQuery } from "@marketing-site/api/urbana/contentful/queries";
import { StarRatings } from "@marketing-site/components";
import { SEO } from "@marketing-site/components/_contentful";
import {
  HeroNotAvailableYet,
  IHeroNotAvailableYetAlias,
} from "@marketing-site/components/_contentful/hero/HeroNotAvailableYet";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { theme } from "@marketing-site/styles";
import { mapComponents } from "@marketing-site/utils";
import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import styled from "styled-components";
import { LoadingPage } from "../Page/LoadingPage";
import Footer from "@whopper/footer";

type INotAvailableYetProps = {
  locale?: string;
};

export const NotAvailableYet = (props: INotAvailableYetProps) => {
  const pageData: INotAvailableYetProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const slug = "not-available-yet";

  const {
    data: contentfulData,
    error: _contentfulError,
    isLoading: contentfulLoading,
  } = useFetchContentQuery({
    query: PageQuery(slug, locale),
    space: "hopperCom",
  });

  const getBody = () => {
    if (contentfulLoading || !contentfulData) {
      return <LoadingPage />;
    } else {
      const page = contentfulData;
      const children = page.contentCollection.items;

      const downloadText = children.find(
        ({ title }: { title: string }) => title === "Download the app text"
      ).shortText;

      const notAvailableYetPageMap = {
        HeroProduct: (
          props: React.PropsWithChildren<IHeroNotAvailableYetAlias>
        ) => {
          return (
            <HeroNotAvailableYet {...props} getTheAppText={downloadText}>
              <StarRatings
                label={
                  children.find(
                    ({ title }: { title: string }) =>
                      title === "Star Ratings Text"
                  ).richText.json
                }
              />
            </HeroNotAvailableYet>
          );
        },
      };

      const childComponents = mapComponents(
        children,
        slug,
        notAvailableYetPageMap
      );

      return (
        <>
          <SEO {...page.seo} locale={locale} canonical={path} />
          <NavigationBar contentfulNavProps={page.navigation} />
          <BackgroundWrapper>
            {childComponents.map((component, i) => {
              return <Fragment key={i}>{component}</Fragment>;
            })}
          </BackgroundWrapper>
          <Footer {...page.navigation.footer} />
        </>
      );
    }
  };

  return <React.Fragment>{getBody()}</React.Fragment>;
};

const BackgroundWrapper = styled.div`
  background-color: ${theme.colors.grey[100]};
  height: 100vh;
`;
