// @ts-nocheck
import media from "@marketing-site/styles/media";
import theme from "@marketing-site/styles/theme";
import { IStyledPageBreak } from "@marketing-site/types";
import { selectBackgroundColor } from "@marketing-site/redux/utilitiesQuery";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

export const WaveSVG = (props: {
  type: string;
  prevColor: string;
  color: string;
}) => {
  return props.type === "valley" ? (
    <WaveWrapper
      prevColor={props.prevColor}
      // style={{ transition: 'background-color 1s ease-out' }}
    >
      <svg
        viewBox="0 0 2100 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform="scale(1.5)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1439.88 120H-6.10352e-05V53.6722C25.6449 48.1751 91.5219 36.2143 172.809 37.4225C262.331 38.7514 382.557 62.1293 458.668 71.8549C559.131 84.601 613.715 88.1046 720.059 88.1046C826.403 88.1046 937.923 70.828 1038.97 49.9873C1143.44 28.4217 1223.78 10.0577 1315.42 3.35243C1369.06 -0.574077 1413.18 -0.332446 1440 0.513264V120H1439.88Z"
          fill={props.color}
        />
      </svg>
    </WaveWrapper>
  ) : (
    <WaveWrapper
      isHill
      prevColor={props.color}
      // style={{ transition: 'background-color 1s ease-out' }}
    >
      <svg
        viewBox="0 0 2100 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          transform="scale(1.5)"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1439.88 120H-6.10352e-05V53.6722C25.6449 48.1751 91.5219 36.2143 172.809 37.4225C262.331 38.7514 382.557 62.1293 458.668 71.8549C559.131 84.601 613.715 88.1046 720.059 88.1046C826.403 88.1046 937.923 70.828 1038.97 49.9873C1143.44 28.4217 1223.78 10.0577 1315.42 3.35243C1369.06 -0.574077 1413.18 -0.332446 1440 0.513264V120H1439.88Z"
          fill={props.prevColor}
        />
      </svg>
    </WaveWrapper>
  );
};

const PageBreak = (props: any) => {
  // grab right shade of grey if needed\
  const waveColor =
    props.waveColor === "gray"
      ? theme.colors.grey[100]
      : (theme.colors as any)[props.waveColor.toLowerCase()];

  const globalBackgroundColor = useSelector(selectBackgroundColor);

  return (
    <>
      {props.isLine ? (
        <BreakLine />
      ) : (
        <WaveSVG
          type={props.waveType}
          color={waveColor}
          prevColor={
            props.useGlobalColor
              ? globalBackgroundColor
              : props.prevBackgroundColor
          }
        />
      )}
    </>
  );
};

export default PageBreak;

PageBreak.propTypes = {
  isLine: PropTypes.bool.isRequired,
  waveColor: PropTypes.string,
  waveType: PropTypes.string,
  backgroundColor: PropTypes.string,
  prevBackgroundColor: PropTypes.string,
  useGlobalColor: PropTypes.bool,
};

const WaveWrapper = styled.div<IStyledPageBreak>`
  width: 102%; // make slightly bigger for overlapping/resizing
  margin-left: -2px; // overlap edge of svg
  ${(p) => p.isHill && "svg { transform: matrix(1, 0, 0, -1, 0, 0);}"}
  ${(p) => p.prevColor && "background-color: " + p.prevColor + ";"}

  svg {
    margin-top: -2px;
    margin-bottom: -10px;
  }
`;

const BreakLine = styled.div<IStyledPageBreak>`
  width: 1020px;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 122px;
  margin-bottom: 100px;
  background-color: ${theme.colors.grey[200]};

  ${media.desktop`
    width: 90%;
  `}

  ${media.phablet`
    margin: ${(p: any) => (p.isThin ? "32" : "60")}px auto;
  `}
`;
