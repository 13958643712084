import { Tag } from '@marketing-site/components/tag-label/Tag';
import { theme } from '@marketing-site/styles';
import { IImage } from '@marketing-site/types/shared.model';
import React from 'react';
import styled from 'styled-components';

interface ImageBadgeProps {
  image: IImage;
  label: string;
  badgeText: string;
  badgeColor: string;
}
const ImageBadge = ({
  image,
  label,
  badgeText,
  badgeColor,
}: ImageBadgeProps) => {
  return (
    <Container>
      <Image src={image.url} />
      <Tag color={badgeColor} text={badgeText} />
      <Label>{label}</Label>
    </Container>
  );
};
export default ImageBadge;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
`;

const Image = styled.img`
  max-width: 190px;
  margin: 1rem;
`;

const Label = styled.p`
  font-style: normal;
  font-weight: 800;
  font-size: 1.375rem;
  line-height: 32px;
  text-align: center;
  margin: 1rem 0;
`;
