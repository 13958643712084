import { media, theme } from '@marketing-site/styles';
import { IHeroProduct, IRichText } from '@marketing-site/types/shared.model';
import { RichTextToHtml } from '@marketing-site/utils';
import React from 'react';
import styled from 'styled-components';
import CtaButton from '../cta-button';

export interface IHeroNotAvailableYetAlias
  extends Omit<IHeroProduct, 'description'> {
  heroProductDescription: IRichText;
  getTheAppText: string;
}

export const HeroNotAvailableYet = ({
  children,
  ...props
}: React.PropsWithChildren<IHeroNotAvailableYetAlias>) => {
  const ctaButtonProps = {
    headline: props.getTheAppText,
    link: '/download',
    color: 'skyblue',
    isFilled: true,
  };

  return (
    <Background>
      <Container>
        <Image src={props.image.url} />
        <Content>
          <Text>
            <Headline>{props.headline}</Headline>
            <Description>
              {RichTextToHtml(props.heroProductDescription.json)}
            </Description>
            <CtaContainer>
              {children}
              <CtaButton {...ctaButtonProps} />
            </CtaContainer>
          </Text>
        </Content>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${theme.colors.white};
  padding-top: 20px;
  padding-bottom: 20px;
  ${media.tablet`
    padding-bottom: 40px;
  `};
`;

const Container = styled.div`
  display: flex;
  width: 94%;
  max-width: 880px;
  margin: 10vh auto 0;
  ${media.tablet`
    flex-direction: column;
    margin: auto;
  `};
`;

const Image = styled.img`
  flex: 1 1 50%;
  object-fit: contain;
  max-height: 420px;
  width: 100%;
  height: auto;
  ${media.tablet`
    display: none;
    max-height: 300px;
  `}
`;

const Content = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
  ${media.tablet`
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  `}
`;

const Text = styled.div`
  max-width: 450px;
  ${media.tablet`
    max-width: auto;
    margin: auto;
  `}
`;

const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
  ${media.tablet`
    font-size: 32px;
  `};
`;

const Headline = styled.h2`
  ${media.tablet`
    font-size: 32px;
  `};
  font-weight: 800;
  margin-top: 32px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  margin-bottom: 24px;
  p {
    font-size: 18px;
    line-height: 25px;
    color: ${theme.colors.grey[600]};
    font-weight: 400;
    ${media.tablet`
      font-size: 20px;
    `};
  }
`;
