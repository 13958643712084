export interface Experiment {
  id: string;
  /**
   * @remarks Many experiments use the standard control-available variant system,
   * but some experiments use other custom strings as variant names.
   */
  variant: ExperimentVariant | string;
  /**
   * @deprecated use variant
   */
  group?: string;
}

export type ExperimentsType = { [id: string]: ExperimentVariant | string };
export type TrackingPropertiesType = {
  [key: string]: string | string[];
};

export interface ExperimentState {
  experiments: ExperimentsType;
  trackingProperties?: TrackingPropertiesType;
}

export interface ActiveExperimentsRequest {
  partnerExperiments: string;
}

export interface ActiveExperimentsResponse {
  experiments: Experiment[];
  trackingProperties: TrackingPropertiesType;
}

export enum ExperimentVariant {
  /**
   * @description The "on" state
   */
  Available = "available",
  /**
   * @description The "off" state
   */
  Control = "control",
}
