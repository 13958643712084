import React from 'react';
import styled from 'styled-components';
import { DescriptionPoint } from '@marketing-site/components/_contentful';
import {
  IDescriptionPoint,
  IDescriptionPointsSection,
} from '@marketing-site/types/description-points.model';
import { media, theme } from '@marketing-site/styles';

const DescriptionPointsSection = (props: IDescriptionPointsSection) => {
  return (
    <DescriptionPointsWrapper>
      {props.showPreheadline && <Preheadline>{props.preheadline}</Preheadline>}
      <DescriptionPointsContainer align={props.alignment}>
        {props.descriptionPointsCollection.items.map(
          (point: IDescriptionPoint, i: number) => {
            return (
              <DescriptionPoint
                {...point}
                key={`descriptionPoint-${i}`}
                alignment={props.alignment}
                total={props.numberPerRow}
              />
            );
          }
        )}
      </DescriptionPointsContainer>
    </DescriptionPointsWrapper>
  );
};

export default DescriptionPointsSection;

const Preheadline = styled.h3`
  margin-bottom: 24px;
  color: ${theme.colors.grey[400]};
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  width: 180px;
  ${media.tablet`
    align-self: center;
  `};
  ${media.desktop_up`
    width: auto;
  `}
`;
const DescriptionPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 16px;
  width: 92%;
  max-width: 1080px;
`;

const DescriptionPointsContainer = styled.div<{
  align: string;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 32px;
  align-content: center;
  align-items: ${(p) => p.align};
  ${media.tablet`
    flex-direction: column;
  `};
`;
