import { TranslationLanguage, getRootLang } from "@hopper-b2b/i18n";

export const supportedLanguageCodes = {
  [TranslationLanguage.en]: "EN",
  [TranslationLanguage.es]: "ES",
  [TranslationLanguage.fr]: "FR",
};

export const supportedLanguageNames = {
  [TranslationLanguage.en]: "English",
  [TranslationLanguage.es]: "Español",
  [TranslationLanguage.fr]: "Français",
};

export const supportedLanguagePaths = {
  [TranslationLanguage.en]: null,
  [TranslationLanguage.es]: "es",
  [TranslationLanguage.fr]: "fr",
};

export const defaultLanguage = TranslationLanguage.en;

const getCurrentLanguageFromUrl = (): TranslationLanguage => {
  const splitPath =
    typeof window !== "undefined" && window.location.pathname.split("/");
  const currentLanguage = splitPath[1];

  return TranslationLanguage[currentLanguage];
};

export const getCurrentLanguage = (): TranslationLanguage =>
  getCurrentLanguageFromUrl() ??
  TranslationLanguage[getRootLang(defaultLanguage)] ??
  defaultLanguage;

export const getLanguagePath = (lang: TranslationLanguage): string => {
  const langPath = supportedLanguagePaths[lang];
  const splitPath = window.location.pathname.split("/");

  const currentLangPath = splitPath[1];
  const shouldDelete =
    currentLangPath && !!TranslationLanguage[currentLangPath];

  if (!langPath) {
    splitPath.splice(1, shouldDelete ? 1 : 0);
  } else {
    splitPath.splice(1, shouldDelete ? 1 : 0, langPath);
  }

  const languagePath = splitPath.join("/");

  return !languagePath || languagePath === "" ? "/" : languagePath;
};
