import { useTrackEvent, UseTrackEventProps } from "@hopper-b2b/tracking";
import { useCallback } from "react";

export const useTrackMarketingSiteEvent = () => {
  const extraProperties = { web_app_name: "hopper-marketing-site" };

  const trackEvent = useTrackEvent();

  return useCallback(
    (props: UseTrackEventProps) =>
      trackEvent({
        ...props,
        properties: { ...props.properties, ...extraProperties },
      }),
    [trackEvent]
  );
};
