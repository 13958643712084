import { authApi, ResendCodeRequest } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";

export const resendCode = async (request: ResendCodeRequest) => {
  try {
    const res = await authApi(axiosInstance).apiV0AuthCodeResendPost(request);
    return res.data;
  } catch (error) {
    console.error("Send code error", error);
    throw Error("Send code error");
  }
};
