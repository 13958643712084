import { Box } from "@mui/material";
import clsx from "clsx";
import "./styles.scss";

export enum DiscountPosition {
  Before = "before",
  After = "after",
}
export interface ItemPriceProps {
  current: string;
  discounted?: string;
  discountPosition?: DiscountPosition;
}

export const ItemPrice = ({
  current,
  discounted,
  discountPosition = DiscountPosition.After,
}: ItemPriceProps) => (
  <Box
    className={clsx(
      "item-price",
      discounted !== undefined && "discounted",
      discountPosition === DiscountPosition.Before && "discount-before"
    )}
  >
    <span className="original">{current}</span>
    {discounted && <span className="discount">{discounted}</span>}
  </Box>
);
