import { axiosInstance } from "@hopper-b2b/api";
import { AxiosError, AxiosResponse } from "axios";
import { getRefreshToken, refreshSession } from "../../api";

// We only want ONE refresh token request at a time
const refreshToken = (() => {
  let ongoingRefreshTokenPromise = null;
  return (): Promise<AxiosError | AxiosResponse> => {
    if (ongoingRefreshTokenPromise === null) {
      ongoingRefreshTokenPromise = refreshSession({
        refreshToken: getRefreshToken(),
      }).then(() => {
        ongoingRefreshTokenPromise = null;
      });
    }
    return ongoingRefreshTokenPromise;
  };
})();

const RETRY_NAMESPACE = "--HOPPER-RETRY--";

const retryOrFail = (
  error: AxiosError
): Promise<AxiosError | AxiosResponse> => {
  const { config } = error;
  config[RETRY_NAMESPACE] ??= { retries: 1 };

  if (config[RETRY_NAMESPACE].retries > 0) {
    config[RETRY_NAMESPACE].retries--;
    return refreshToken()
      .then(() => axiosInstance(config))
      .catch(() => Promise.reject(error));
  }

  return Promise.reject(error);
};

export default retryOrFail;
