import { theme } from "@marketing-site/styles";
import { IStyledBackground, IStyledCtaButton } from "@marketing-site/types";
import { Helpers } from "@marketing-site/utils";
import React from "react";
import styled from "styled-components";

export interface ICtaButton {
  preheadline?: string;
  headline: string | React.ReactNode;
  postheadline?: string;
  isFilled: boolean;
  link: string;
  openInNewTab?: boolean;
  color?: string;
  icon?: string;
  small?: boolean;
  fullyRounded?: boolean;
  short?: boolean;
  backgroundColor?: string;
}

const CtaButton: React.FC<ICtaButton> = ({
  backgroundColor = "transparent",
  ...props
}) => {
  const handleClick = (props: any) => {
    const sanitizedText = props.headline.toLowerCase().replace(/ /g, "_");
    window?.gtag("event", sanitizedText, {
      event_category: "click",
      event_label: "cta_button",
    });

    window?.open(
      Helpers.setLink(props.link),
      props.openInNewTab ? "_blank" : "_self"
    );
  };

  return (
    <Background backgroundColor={backgroundColor}>
      {props.preheadline ? (
        <CtaButtonText>
          <Preheadline>{props.preheadline}</Preheadline>
          <CtaButtonWrapper
            isFilled={props.isFilled}
            backgroundColor={backgroundColor}
            small={props.small}
            fullyRounded={props.fullyRounded}
            short={props.short}
            color={props.color || "skyblue"}
            onClick={() => handleClick(props)}
          >
            {props.icon && <Icon src={props.icon} />}
            {props.headline}
          </CtaButtonWrapper>
        </CtaButtonText>
      ) : props.postheadline ? (
        <CtaButtonText>
          <CtaButtonWrapper
            isFilled={props.isFilled}
            backgroundColor={backgroundColor}
            small={props.small}
            fullyRounded={props.fullyRounded}
            short={props.short}
            color={props.color || "skyblue"}
            onClick={() => handleClick(props)}
          >
            {props.icon && <Icon src={props.icon} />}
            {props.headline}
          </CtaButtonWrapper>
          <Postheadline>{props.postheadline}</Postheadline>
        </CtaButtonText>
      ) : (
        <CtaButtonText>
          <CtaButtonWrapper
            isFilled={props.isFilled}
            backgroundColor={backgroundColor}
            small={props.small}
            fullyRounded={props.fullyRounded}
            color={props.color || "skyblue"}
            short={props.short}
            onClick={() => handleClick(props)}
          >
            {props.icon && <Icon src={props.icon} />}
            {props.headline}
          </CtaButtonWrapper>
        </CtaButtonText>
      )}
    </Background>
  );
};

export default CtaButton;

const Background = styled.div<IStyledBackground>`
  background-color: ${(p: IStyledBackground) => p.backgroundColor};
`;

const Preheadline = styled.h3`
  font-weight: 700;
  font-size: 14px;
  color: ${theme.colors.grey[700]};
  margin-bottom: 4px;
`;

const Postheadline = styled.h3`
  font-weight: 600;
  font-size: 14px;
  color: ${theme.colors.grey[400]};
  margin-top: 4px;
`;

const CtaButtonText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CtaButtonWrapper = styled.button<IStyledCtaButton>`
  display: flex;
  align-items: center;
  font-family: "proxima-nova";
  cursor: pointer;
  padding: ${(p: IStyledCtaButton) => (p.small ? "10px 10px" : " 12px 65px")};
  border: 2.5px solid;
  ${(p: IStyledCtaButton) => p.small && "height: 50px;"}
  border-radius: ${(p: IStyledCtaButton) => (p.fullyRounded ? "25px" : "20px")};
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  :not(:last-child) {
    margin-right: 24px;
  }

  margin-right: 0px !important;
  text-align: center;

  // if short modify padding
  ${(p: IStyledCtaButton) => {
    return (
      p.short &&
      `
      padding-left: 40px;
      padding-right: 40px;
    `
    );
  }}

  // TODO: Clean this up to use css consts, props.theme doesn't seem to pass to css inheritly
  ${(p: IStyledCtaButton) => {
    return p.isFilled
      ? `
      color: ${theme.colors.white};
      background-color: ${(theme.colors as any)[p.color]};
      border-color: ${(theme.colors as any)[p.color]};
      :hover {
        color: ${(theme.colors as any)[p.color]};
        background-color: ${p.backgroundColor};
        border-color: ${(theme.colors as any)[p.color]};
      }
    `
      : `
      color: ${(theme.colors as any)[p.color]};
      background-color: transparent;
      border-color: ${(theme.colors as any)[p.color]};
      :hover {
        color: ${p.backgroundColor};
        background-color: ${(theme.colors as any)[p.color]};
        border-color: ${(theme.colors as any)[p.color]};
      }
    `;
  }}
`;

const Icon = styled.img`
  margin-right: 12px;
`;
