import { COLOR_THEMES, installColorConfig } from "@hopper-b2b/types";
import { getDarkModePreferred } from "@hopper-b2b/utilities";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { hopperDarkVariables, muiDarkTheme } from "./darkTheme";
import { hopperVariables, muiTheme } from "./defaultTheme";
import { hdsTheme } from "./hdsTheme";

export type HopperThemingProviderProps = {
  enableDarkMode?: boolean;
  enableHDSTheme?: boolean;
  isOnPrimary?: boolean;
};

export const HopperThemingProvider = ({
  children,
  enableDarkMode,
  enableHDSTheme,
  isOnPrimary,
}: PropsWithChildren<HopperThemingProviderProps>) => {
  const [theme, setTheme] = useState(muiTheme);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const isDarkModePreferred = useMemo(() => {
    const colorScheme = getDarkModePreferred()
      ? COLOR_THEMES.DARK
      : COLOR_THEMES.LIGHT;
    return isDarkMode && colorScheme === COLOR_THEMES.DARK;
  }, [isDarkMode]);

  useEffect(() => {
    if (enableDarkMode) {
      setIsDarkMode(true);
    }
  }, [enableDarkMode]);

  useEffect(() => {
    if (isDarkModePreferred) {
      setTheme(muiDarkTheme);
    }
  }, [isDarkMode, isDarkModePreferred]);

  useEffect(() => {
    installColorConfig(
      isDarkModePreferred ? hopperDarkVariables : hopperVariables
    );
  }, [isDarkModePreferred]);

  if (isOnPrimary) {
    hdsTheme.colorMode = "onPrimary";
    hdsTheme.components.MuiButton.defaultProps.color = "secondary";
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={enableHDSTheme ? hdsTheme : theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
