import { HeaderNavigationMenuItemType } from "@hopper-b2b/hopper-utils";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import {
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  List,
  Paper,
  Popper,
} from "@mui/material";
import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./HeaderMenu.module.scss";
import { HeaderMenuItem } from "./HeaderMenuItem";
import { StaticFooter as Footer } from "@whopper/footer";
import { useI18nContext } from "@hopper-b2b/i18n";

export const HEADER_MENU_POPPER_ID = "header-menu-popper";

export type HeaderMenuItemType = HeaderNavigationMenuItemType & {
  disabled?: boolean;
  children?: (HeaderNavigationMenuItemType | ReactNode)[];
};

export type HeaderMenuProps = {
  open: boolean;
  anchorEl: any;
  headerMenuItems?: HeaderMenuItemType[];
  onClose: (e?: Event) => void;
};

export const HeaderMenu = ({
  open,
  anchorEl,
  onClose,
  headerMenuItems,
}: HeaderMenuProps) => {
  const { language } = useI18nContext();
  const id = open ? HEADER_MENU_POPPER_ID : undefined;

  const { matchesMobile } = useDeviceTypes();
  return (
    <WrapperComponent
      id={id}
      isOpen={open}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <Fade in={open}>
        <Paper
          className={clsx(styles.paper, {
            [styles.mobilePaper]: matchesMobile,
          })}
        >
          {matchesMobile && <Divider />}
          <List>
            {headerMenuItems?.map((item: HeaderMenuItemType, index: number) => (
              <HeaderMenuItem
                key={item.identifier ?? index}
                item={item}
                onClose={onClose}
              />
            ))}
            {matchesMobile && (
              <Box pl={3} pr={3}>
                <Divider style={{ marginBottom: "1rem" }} />
                <Box>
                  <Footer language={language} />
                </Box>
              </Box>
            )}
          </List>
        </Paper>
      </Fade>
    </WrapperComponent>
  );
};

type WrapperComponentProps = {
  id: string;
  isOpen: boolean;
  anchorEl: any;
  onClose: () => void;
};

const WrapperComponent = ({
  id,
  isOpen,
  anchorEl,
  children,
  onClose,
}: PropsWithChildren<WrapperComponentProps>) => {
  const { matchesMobile } = useDeviceTypes();

  const menuContent = isOpen ? (
    <ClickAwayListener onClickAway={onClose}>
      <div>{children}</div>
    </ClickAwayListener>
  ) : (
    children
  );

  return matchesMobile && isOpen ? (
    <Box className={styles.mobileWrapper} zIndex="tooltip">
      {menuContent}
    </Box>
  ) : (
    <Popper
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      disablePortal={true}
      keepMounted={true}
      placement={matchesMobile ? "bottom-start" : "bottom-end"}
    >
      {menuContent}
    </Popper>
  );
};
