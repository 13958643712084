import React from "react";

export const PricePredictionIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1038_1763)">
        <path
          d="M20 0C16.0444 0 12.1776 1.17298 8.8886 3.37061C5.59962 5.56823 3.03617 8.69181 1.52242 12.3463C0.00866568 16.0009 -0.387401 20.0222 0.384303 23.9018C1.15601 27.7814 3.06082 31.3451 5.85787 34.1421C8.65492 36.9392 12.2186 38.844 16.0982 39.6157C19.9778 40.3874 23.9991 39.9913 27.6537 38.4776C31.3082 36.9638 34.4318 34.4004 36.6294 31.1114C38.827 27.8224 40 23.9556 40 20C40 14.6957 37.8929 9.60859 34.1421 5.85786C30.3914 2.10714 25.3043 0 20 0ZM20 36.25C16.7861 36.25 13.6443 35.2969 10.972 33.5114C8.29969 31.7258 6.21689 29.1879 4.98697 26.2186C3.75704 23.2493 3.43524 19.982 4.06225 16.8298C4.68926 13.6776 6.23692 10.7821 8.50952 8.50951C10.7821 6.23691 13.6776 4.68925 16.8298 4.06224C19.982 3.43523 23.2493 3.75703 26.2186 4.98696C29.1879 6.21688 31.7258 8.29968 33.5114 10.972C35.297 13.6443 36.25 16.7861 36.25 20C36.2434 24.3077 34.5292 28.4371 31.4832 31.4832C28.4372 34.5292 24.3077 36.2434 20 36.25Z"
          fill="#01AAE4"
        />
        <path
          d="M21.25 18.125H18.75C18.2527 18.125 17.7758 17.9275 17.4242 17.5758C17.0725 17.2242 16.875 16.7473 16.875 16.25C16.875 15.7527 17.0725 15.2758 17.4242 14.9242C17.7758 14.5725 18.2527 14.375 18.75 14.375H21.25C21.7316 14.4145 22.1795 14.6384 22.5 15C22.8516 15.3511 23.3281 15.5483 23.825 15.5483C24.3219 15.5483 24.7984 15.3511 25.15 15C25.5011 14.6484 25.6984 14.1719 25.6984 13.675C25.6984 13.1781 25.5011 12.7016 25.15 12.35C24.2525 11.4445 23.0684 10.8789 21.8 10.75V10C21.8 9.50272 21.6025 9.0258 21.2508 8.67417C20.8992 8.32254 20.4223 8.125 19.925 8.125C19.4277 8.125 18.9508 8.32254 18.5992 8.67417C18.2475 9.0258 18.05 9.50272 18.05 10V10.7C16.593 10.8257 15.243 11.5164 14.2886 12.6244C13.3341 13.7324 12.851 15.1698 12.9424 16.6294C13.0338 18.0889 13.6926 19.4548 14.7778 20.435C15.8631 21.4153 17.2887 21.9321 18.75 21.875H21.25C21.7473 21.875 22.2242 22.0725 22.5758 22.4242C22.9275 22.7758 23.125 23.2527 23.125 23.75C23.125 24.2473 22.9275 24.7242 22.5758 25.0758C22.2242 25.4275 21.7473 25.625 21.25 25.625H17.5C17.3348 25.6179 17.1766 25.5563 17.05 25.45C16.8776 25.2731 16.6716 25.1325 16.444 25.0365C16.2165 24.9405 15.972 24.8911 15.725 24.8911C15.478 24.8911 15.2335 24.9405 15.006 25.0365C14.7784 25.1325 14.5724 25.2731 14.4 25.45C14.0489 25.8016 13.8517 26.2781 13.8517 26.775C13.8517 27.2719 14.0489 27.7484 14.4 28.1C14.8067 28.507 15.2901 28.8292 15.8223 29.0481C16.3544 29.2669 16.9246 29.3781 17.5 29.375H18.125V30C18.125 30.4973 18.3225 30.9742 18.6742 31.3258C19.0258 31.6775 19.5027 31.875 20 31.875C20.4973 31.875 20.9742 31.6775 21.3258 31.3258C21.6775 30.9742 21.875 30.4973 21.875 30V29.3C23.3185 29.166 24.6537 28.4775 25.5999 27.3793C26.5462 26.281 27.0296 24.8586 26.9487 23.4113C26.8677 21.9639 26.2287 20.6043 25.1659 19.6184C24.1031 18.6325 22.6994 18.0972 21.25 18.125Z"
          fill="#01AAE4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1038_1763">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
