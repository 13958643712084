"use client";

import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

const UTM_SOURCE_PARAM = "utm_source";
export const USER_SOURCE_KEY = "user_source";

export const UserSourceContext = createContext<string | null | undefined>(
  undefined
);

export function useUserSource(): string | null {
  const ctx = useContext(UserSourceContext);
  if (ctx === undefined)
    throw new Error(`must be used within a UserSourceProvider`);
  return ctx;
}

export const UserSourceProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userSource, setUserSource] = useState(null);

  useEffect(() => {
    setUserSource(sessionStorage.getItem(USER_SOURCE_KEY));
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const param = new URLSearchParams(location?.search).get(UTM_SOURCE_PARAM);
    if (param && !userSource) {
      setUserSource(param);
      sessionStorage.setItem(USER_SOURCE_KEY, param);
    }
  }, [userSource]);

  return (
    <UserSourceContext.Provider value={userSource}>
      {children}
    </UserSourceContext.Provider>
  );
};
