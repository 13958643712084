import { theme } from '@marketing-site/styles';
import React from 'react';

export const LocationPinIcon = (props: {
  color?: string;
  size?: { height?: number; width?: number };
}) => {
  return (
    <svg
      width={props.size?.width ?? '11'}
      height={props.size?.height ?? '12'}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50003 1.57503e-06C4.42467 -0.000787282 3.3727 0.299583 2.47442 0.863903C1.57615 1.42822 0.871056 2.23169 0.446484 3.17478C0.021913 4.11787 -0.103477 5.15913 0.085847 6.16958C0.275171 7.18002 0.770891 8.11525 1.5116 8.8594L3.93318 11.3577C3.94929 11.3738 3.96618 11.3892 3.98306 11.4031C4.18695 11.5964 4.42932 11.7487 4.69587 11.8512C4.96242 11.9537 5.24776 12.0042 5.53508 11.9997C5.8224 11.9953 6.10588 11.936 6.36882 11.8254C6.63176 11.7147 6.86883 11.5549 7.06607 11.3555L9.48538 8.8623C10.227 8.11847 10.7235 7.18325 10.9136 6.17257C11.1036 5.1619 10.9787 4.12021 10.5544 3.17665C10.1301 2.23308 9.42498 1.42913 8.52652 0.864453C7.62807 0.299777 6.57575 -0.000793941 5.50003 1.57503e-06ZM5.50003 6.86252C5.12707 6.86252 4.76249 6.75695 4.45238 6.55917C4.14228 6.36138 3.90058 6.08026 3.75785 5.75135C3.61513 5.42244 3.57778 5.06052 3.65054 4.71135C3.72331 4.36219 3.9029 4.04146 4.16663 3.78972C4.43035 3.53799 4.76635 3.36655 5.13215 3.2971C5.49794 3.22764 5.8771 3.26329 6.22167 3.39953C6.56624 3.53577 6.86075 3.76648 7.06796 4.06249C7.27516 4.3585 7.38576 4.70651 7.38576 5.06252C7.3851 5.53972 7.18622 5.99719 6.83272 6.33462C6.47922 6.67205 5.99996 6.8619 5.50003 6.86252Z"
        fill={props.color ?? theme.colors.skyblue}
      />
    </svg>
  );
};
