import React from 'react';
import styled from 'styled-components';
import { media } from '@marketing-site/styles';
import { IImage, ILink } from '@marketing-site/types/shared.model';

interface FeaturedCompany extends ILink {
  image: IImage;
}

const Company = (props: FeaturedCompany) => {
  return (
    <CompanyContainer>
      <CompanyImg src={props.image.url} alt={props.text} />
    </CompanyContainer>
  );
};

export default Company;

// CSS copied from MachuPichu, may need adjusting when the page is ready
const CompanyContainer = styled.div`
  overflow: hidden;
  width: 180px;
  height: 100px;
  padding: 5px 10px;
  border-radius: 20px;

  ${media.desktop`
    width: 154px;
    height: 95px;
    padding: 5px 10px;
    border-radius: 17px;
  `}

  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
`;

const CompanyImg = styled.img`
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 100px;
`;
