import { footerData, headerData } from "@marketing-site/api/common/navigation";
import { Break, CtaButton, SEO } from "@marketing-site/components/_contentful";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { theme } from "@marketing-site/styles";
import React from "react";
import { useLocation } from "react-router-dom-v5-compat";
import styled from "styled-components";
import Footer from "@whopper/footer";


export const PageGone = () => {
  const location = useLocation();
  const content = {
    seo: {
      canonical: location.pathname,
      title: "Resource is no longer available | Hopper",
      description: "Oh no! It looks like this page has been removed.",
      sharedImage: {
        title: "Hopper",
        url: "https://images.ctfassets.net/dllbbq2u2bi6/4Xb8KQEdB5GzAk4HvjmeUu/28620f38946416bb5d2d2366986cc381/app-flights-bg.jpg",
      },
    },
    headline: "Oh no!",
    description: "It looks like this page has been removed.",
    ctaButton: {
      headline: "Take me home",
      isFilled: true,
      link: "/",
    },
    wave: {
      isLine: false,
      waveType: "valley",
      waveColor: "white",
      prevBackgroundColor: theme.colors.grey[100],
    },
  };

  return (
    <>
      <SEO {...content.seo} />
      <NavigationBar contentfulNavProps={{ header: headerData }} />
      <Background>
        <Container>
          <Headline>{content.headline}</Headline>
          <Description>{content.description}</Description>
          <CtaButton {...content.ctaButton} />
        </Container>
      </Background>
      <Break {...content.wave} />
      <Footer {...footerData} />
    </>
  );
};

const Background = styled.div`
  background-color: ${theme.colors.grey[100]};
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 24vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Headline = styled.h5`
  margin-bottom: 0;
`;

const Description = styled.p``;
