import React, { Fragment } from "react";
import styled from "styled-components";
import { media, theme } from "@marketing-site/styles";
import { SaleItem, Countdown } from "@marketing-site/components/_contentful";
import { IStyledBackground } from "@marketing-site/types";
import { ISaleSection } from "@marketing-site/types/sale.model";

const SaleSection = (props: ISaleSection) => {
  return (
    <Container id={props.sectionId}>
      <Header>
        <Headline>{props.headline}</Headline>
        {!props.containMissedSales && (
          <CountdownWrapper>
            {props.countdown && <Countdown {...props.countdown} />}
          </CountdownWrapper>
        )}
      </Header>
      <Deals>
        {props.saleItemsCollection.items.map((sale: any, i: number) => {
          return (
            <SaleItem
              {...sale}
              key={`saleItem-${i}`}
              missedSale={props.containMissedSales}
            />
          );
        })}
      </Deals>
    </Container>
  );
};

export default SaleSection;

const Container = styled.div`
  margin: 0 auto;
  width: 94%;
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  padding-top: 16px;
  padding-bottom: 10px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Headline = styled.h3`
  margin-bottom: 24px;
  color: ${theme.colors.grey[400]};
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  width: 180px;

  ${media.desktop_up`
    width: auto;
  `}
`;

const CountdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Deals = styled.div`
  ${media.phablet_up`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  `}
  ${media.phone_large`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
  `}
`;
