import { UserKeyEnum, ResendCodeResponseEnum } from "@b2bportal/auth-api";
import { resendCode } from "../../api";
import { AuthMachineContext } from "../context";
import { getAuthStateToken, getEmail, getPhoneNumber } from "../selectors";
import { trackEvent } from "@hopper-b2b/api";
import {
  AuthTrackingEvents,
  AuthType,
  ResendVerificationCodeFailureEvent,
  ResendVerificationCodeSuccessEvent,
} from "../../types/tracking";
import { ResendCodeEvent } from "../events";

const trackFailure = (authType: AuthType) =>
  trackEvent({
    eventName: AuthTrackingEvents.ResendVerificationCodeFailure,
    properties: {
      auth_type: authType,
    },
  } as ResendVerificationCodeFailureEvent);

export const resendCodeService = (
  context: AuthMachineContext,
  event: ResendCodeEvent
) =>
  new Promise((resolve, reject) => {
    const authStateToken = getAuthStateToken({ context }) as string;
    const phone = getPhoneNumber({ context }) as string;
    const email = getEmail({ context }) as string;

    const user = email
      ? { UserKey: UserKeyEnum.UserWithEmail, email }
      : { UserKey: UserKeyEnum.UserWithPhone, phone };

    const trackingAuthType = email ? AuthType.Email : AuthType.Phone;

    resendCode({
      authStateToken: authStateToken,
      user,
      verificationMethod: event.verificationMethod,
    })
      .then((res) => {
        if (
          res.ResendCodeResponse === ResendCodeResponseEnum.ResendCodeFailed
        ) {
          reject("ResendCodeResponseEnum.ResendCodeFailed");
          trackFailure(trackingAuthType);
        } else {
          trackEvent({
            eventName: AuthTrackingEvents.ResendVerificationCodeSuccess,
            properties: {
              auth_type: trackingAuthType,
            },
          } as ResendVerificationCodeSuccessEvent);
          resolve(res);
        }
      })
      .catch((error) => {
        trackFailure(trackingAuthType);
        reject(error);
      });
  });
