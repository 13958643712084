import { IStyledBackground } from "@marketing-site/types";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface TagProps {
  color: string;
  text?: string;
}
export const Tag = (props: PropsWithChildren<TagProps>) => {
  return (
    <Container backgroundColor={props.color}>
      <Text color={props.color}>
        {props.children}
        {props.text}
      </Text>
    </Container>
  );
};

const Container = styled.div<IStyledBackground>`
  height: 20px;
  background-color: ${(p) =>
    p.backgroundColor}33; // additional chars for transparency 20%
  border-radius: 6px;
  display: inline-flex;
  align-items: center;
`;

const Text = styled.div`
  color: ${(p) => p.color};
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  padding: 0.5rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 4px;
`;
