import React from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { DealDrops as DealDropsV1 } from "./DealDropsV1";
import { DealDrops as DealDropsV2 } from "./DealDropsV2";

export const DealDrops = () => {
  const newHopperHomepage = useExperiment("new-hopper-homepage");

  return newHopperHomepage ? (
    <DealDropsV2 />
  ) : (
    <DealDropsV1/>
  );
};
