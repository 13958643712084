import React from "react";
import { IFeatureCard } from "@marketing-site/types/shared.model";
import styled from "styled-components";
import { IStyledCardBackground } from "@marketing-site/types";
import { useMediaQuery } from "@mui/material";
import { CardBack } from "./CardBack";

interface CardExtraProps {
  onClick: (values: IFeatureCard) => void;
}
export const Card = (props: IFeatureCard & CardExtraProps) => {
  const { onClick, ...cardBackProps } = props;
  const isDesktop = useMediaQuery("(min-width:768px)");
  return isDesktop ? (
    <DesktopWrapper>
      <Container className="card-container">
        <Front
          src={props.imageLg.url}
          role="img"
          aria-label={props.imageLg.description}
        />
        <CardBack {...cardBackProps} />
      </Container>
    </DesktopWrapper>
  ) : (
    <MobileWrapper
      onClick={() => props.onClick(cardBackProps)}
      className="mobile-card-wrapper"
    >
      <Container className="mobile-card-container">
        <Tile
          src={props.imageLg.url}
          role="img"
          aria-label={props.imageLg.description}
        />
      </Container>
    </MobileWrapper>
  );
};

const DesktopWrapper = styled.div`
  background-color: transparent;
  width: 315px;
  height: 522px;
  padding: 1rem;

  perspective: 1000px;
  &:hover {
    .card-container {
      transform: rotateY(180deg);
    }
  }
`;

const MobileWrapper = styled.div`
  background-color: transparent;
  width: 155px;
  height: 155px;
  cursor: pointer;
  padding: 0.25rem;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
  transition: transform 0.8s;
  transform-style: preserve-3d;
  > .card-back {
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    transform: rotateY(180deg);
    overflow: hidden;
  }
`;

const Front = styled.div<IStyledCardBackground>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-image: url(${(p) => p.src});
  background-size: cover;
`;

const Tile = styled.div<IStyledCardBackground>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-image: url(${(p) => p.src});
  background-size: cover;
`;
