import React from 'react';
import styled from 'styled-components';

export interface IReviewProps {
  headline: string;
  description: string;
  source: string;
  icon: {
    title: string;
    url: string;
  };
}

const Review = (props: IReviewProps) => {
  return (
    <Container tabIndex={0}>
      {props.icon && <ProfileBun src={props.icon.url} alt={props.icon.title} />}
      <Headline>{props.headline}</Headline>
      <Description>{props.description}</Description>
      <Location>{props.source}</Location>
    </Container>
  );
};

export default Review;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 30px;
  overflow: hidden;
  margin: 15px 20px;
  flex: 1;
`;

const ProfileBun = styled.img`
  margin-bottom: 0;
  width: 40px;
`;

const Headline = styled.h4`
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Description = styled.p`
  margin-top: 0;
  margin-bottom: 12px;
`;

const Location = styled.h5`
  margin-top: auto;
  margin-bottom: 0px;
`;
