import React from 'react';
import styled from 'styled-components';
import { StarIcon } from '@marketing-site/static/icons';
import { theme } from '@marketing-site/styles';
import { Document } from '@contentful/rich-text-types';
import { RichTextToHtml } from '@marketing-site/utils';

type StarRatingsProps = {
  label: Document;
};

const StarRatings = (props: StarRatingsProps) => {
  return (
    <Container>
      <Rating>
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
        <StarIcon />
      </Rating>
      <Text>{RichTextToHtml(props.label)}</Text>
    </Container>
  );
};

export default StarRatings;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const Rating = styled.div`
  display: flex;
  svg {
    margin-right: 5px;
  }
`;
const Text = styled.span`
  color: ${theme.colors.grey[600]};
  p {
    margin: 0 0;
  }
`;
