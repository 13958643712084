import {
  createTheme,
  DeprecatedThemeOptions,
  adaptV4Theme,
} from "@mui/material/styles";
import { ColorConfig } from "@hopper-b2b/types";

export const defaultTheming = {
  palette: {
    mode: "light",
    primary: {
      light: "#FA6866",
      main: "#FA6866",
      contrastText: "#FA6866",
    },
    secondary: {
      light: "#01AAE4",
      main: "#01AAE4",
      contrastText: "#01AAE4",
    },
    text: {
      primary: "#505050",
      secondary: "#878787",
    },
  },
  typography: {
    fontFamily: "Proxima Nova, sans-serif",
    htmlFontSize: 14,
    fontSize: 14,
    h1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "2.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.75rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    h4: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.375rem",
      letterSpacing: 0,
    },
    h5: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1.25rem",
      letterSpacing: 0,
    },
    h6: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    body2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    caption: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    overline: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      fontWeight: 600,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "0.875rem",
      letterSpacing: 0,
    },
    button: {
      fontFamily: "Proxima Nova, sans-serif",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      textTransform: "none" as const,
      letterSpacing: 0,
    },
  },
};

export const asB2BMuiTheme = (_colors: ColorConfig, themeOptions?: any) =>
  createTheme(adaptV4Theme(themeOptions ? themeOptions : defaultTheming));

export const getDarkModePreferred = () => {
  return false;
};
