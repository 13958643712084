import React from 'react';

const Twitter = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 8.30769C27.0625 8.76923 26.125 8.92308 25.0313 9.07692C26.125 8.46154 26.9062 7.53846 27.2188 6.30769C26.2812 6.92308 25.1875 7.23077 23.9375 7.53846C23 6.61538 21.5937 6 20.1875 6C16.9063 6 14.4063 9.07692 15.1875 12.1538C10.9688 12 7.21875 10 4.5625 6.92308C3.15625 9.23077 3.9375 12.1538 6.125 13.6923C5.34375 13.6923 4.5625 13.3846 3.78125 13.0769C3.78125 15.3846 5.5 17.5385 7.84375 18.1538C7.0625 18.3077 6.28125 18.4615 5.5 18.3077C6.125 20.3077 8 21.8462 10.3437 21.8462C8.46875 23.2308 5.65625 24 3 23.6923C5.34375 25.0769 8 26 10.8125 26C20.3437 26 25.6562 18.1538 25.3437 10.9231C26.4375 10.3077 27.375 9.38462 28 8.30769Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Twitter;
