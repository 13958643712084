"use client";

import {
  getLanguagePath,
  supportedLanguageCodes,
  supportedLanguageNames,
} from "@hopper-b2b/hopper-utils";
import { TranslationLanguage, setLang } from "@hopper-b2b/i18n";
import { Button, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/globe.svg";
import { useLanguageContext } from "../LanguageContext";
import styles from "./LanguageSelect.module.scss";

export const LanguageSelect = () => {
  const { supportedLanguages, currentLang, onChange } = useLanguageContext();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  const onMenuItemClick = (lang: TranslationLanguage) => {
    onMenuClose();
    setLang(lang);
    onChange ? onChange() : window.open(getLanguagePath(lang), "_self");
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={onMenuOpen}
        endIcon={<GlobeIcon />}
        className={styles.languageSelector}
        size="small"
        sx={{
          color: "text.primary",
        }}
      >
        {supportedLanguageCodes[currentLang]}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={onMenuClose}
      >
        {supportedLanguages?.map(
          (lang: TranslationLanguage) =>
            lang && (
              <MenuItem key={lang} onClick={() => onMenuItemClick(lang)}>
                {supportedLanguageNames[lang]}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
};
