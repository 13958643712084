import React from 'react';
import styled from 'styled-components';
import { theme } from '@marketing-site/styles';
import { IGenericSection, IImage } from '@marketing-site/types/shared.model';
import { IDescriptionPoint } from '@marketing-site/types/description-points.model';
import { RichTextToHtml } from '@marketing-site/utils';
import GenericHeader from '../GenericHeader';
import ImageBadge from './ImageBadge';
import { getInnerText } from '@marketing-site/utils/richTextRenderer';

interface TenetsAndValuesProps extends IDescriptionPoint {
  icon: IImage;
}
export const TenetsAndValuesSection = (
  props: IGenericSection<TenetsAndValuesProps>
) => {
  return (
    <Container>
      <GenericHeader
        preheadline={props.preheadline}
        headline={props.headline}
        flareColor={props.flareColor}
        description={props.description}
      />
      <List>
        {props.body?.items?.map((item, i) => {
          const text = getInnerText(
            RichTextToHtml(item.description.json)
          ).toUpperCase();
          let color;
          if (text === 'VALUE' || text === 'VALOR' || text === 'VALEUR') {
            color = theme.colors.skyblue;
          } else color = theme.colors.coral;
          return (
            <ImageBadge
              key={i}
              image={item.icon}
              label={item.headline}
              badgeText={text}
              badgeColor={color}
            />
          );
        })}
      </List>
      <CtaPlaceholder />
    </Container>
  );
};

const Container = styled.div`
  max-width: 90vw;
  margin: 0 auto 2rem;
  padding: 2rem 0;
`;

const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    flex: 1 0 21%;
    margin: 0 1rem;
  }
  margin: 0 auto;
  max-width: 1440px;
`;

const CtaPlaceholder = styled.div`
  height: 50px;
  widht: 100%;
`;
