import { Tabs, TabsProps } from "./components";
import { FeatureFlags, FeatureFlagsProvider } from "./context";

export type RootProps = {
  isAirEnabled: boolean;
  isLodgingEnabled: boolean;
  isStaysEnabled: boolean;
  isCalendarPredictionEnabled: boolean;
  initalHotelAdultGuests?: number;
  initialTab?: TabsProps["initialTab"];
  showRooms?: boolean;
  onSearch?: (path: string) => void;
  handleSession?: () => void;
};

export const Root = ({
  isAirEnabled,
  isLodgingEnabled,
  isStaysEnabled,
  isCalendarPredictionEnabled,
  onSearch,
  initalHotelAdultGuests = 2,
  showRooms = false,
  initialTab,
  handleSession,
}: RootProps) => (
  <FeatureFlagsProvider
    flags={{
      [FeatureFlags.AIR]: isAirEnabled,
      [FeatureFlags.LODGING]: isLodgingEnabled,
      [FeatureFlags.CALENDAR_PREDICTION]: isCalendarPredictionEnabled,
      [FeatureFlags.ROOMS]: showRooms,
      [FeatureFlags.STAYS]: isStaysEnabled,
    }}
  >
    <Tabs
      initalHotelAdultGuests={initalHotelAdultGuests}
      initialTab={initialTab}
      onSearch={onSearch}
      handleSession={handleSession}
    />
  </FeatureFlagsProvider>
);
