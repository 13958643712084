import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface IDeviceTypes {
  matchesMobile: boolean;
  matchesMediumDesktopOnly: boolean;
  matchesLargeDesktop: boolean;
}

export const useDeviceTypes = (): IDeviceTypes => {
  const theme = useTheme();

  const results = {
    //  0 to `md` value px
    //  0px to 960px
    matchesMobile: useMediaQuery(theme.breakpoints.down("md"), { noSsr: true }),
    // `md` px to `lg` value px
    //  960px to 1280px
    matchesMediumDesktopOnly: useMediaQuery(theme.breakpoints.only("md"), {
      noSsr: true,
    }),

    // `lg` value px and up
    //  1280px and up
    matchesLargeDesktop: useMediaQuery(theme.breakpoints.up("lg"), {
      noSsr: true,
    }),

    //   // Usage [https://v4.mui.com/customization/breakpoints/#theme-breakpoints-down-key-media-query]
    //   // 0px to `sm` value px
    //   // 0px to 600px
    //   isMobile: useMediaQuery(theme.breakpoints.down("xs")),

    //   // `sm` px to `md` value px
    //   // 600px to960px
    //   // tablet portrait
    //   isTablet: useMediaQuery(theme.breakpoints.only("sm")),

    //   // `md` px to `lg` value px
    //   // 960px to 1280px
    //   // tablet landspace
    //   isMediumDesktop: useMediaQuery(theme.breakpoints.only("md")),

    //   // `md` value px and up
    //   // 960px and up
    //   // desktop
    //   isDesktop: useMediaQuery(theme.breakpoints.up("md")),
  };

  return results;
};

export const useIsExtraSmallScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
};

export const useIsSmallScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"));
};

export const useIsMediumScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("lg"));
};

export const useIsLargeScreen = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("xl"));
};
