import React from 'react';
import * as Components from '@marketing-site/components/_contentful';
import * as Sections from '@marketing-site/components/generic-section';
import { theme } from '@marketing-site/styles';
import { withBackgroundColor } from './hoc/withBackgroundColor';

export interface ContentfulComponentMap {
  [key: string]: any;
}

/**
 * Terminology
 * Component: (e.g. React.FC) React function or class. This is what is imported and mapped in COMPONENTS
 * Element: (aka ComponentElement, CElement) What gets returned from React components. Describes virtual DOM nodes. This is not an HTML element
 * @param collection - the list of contentful props returned by contentful for a given page
 * @param pageType - the slug for the given page
 * @param customMap - optional custom mapping for when you want to override all occurances of a corresponding Contentful React component with a different component. For cases where there are multiple GenericSections, use the 'handleUnmappedGenericSection' function.
 */
const mapComponent = (
  collection: unknown[],
  pageType?: string,
  customMap: ContentfulComponentMap = {}
) => {
  const COMPONENTS = {
    AlertBanner: Components.AlertBanner,
    AccordionItem: Components.AccordionItem,
    Break: Components.Break,
    CalloutFullWidth: Components.CalloutFullWidth,
    Carousel: Components.Carousel,
    Countdown: Components.Countdown,
    CtaButton: Components.CtaButton,
    CtaDescription: Components.CtaDescription,
    CtaFooter: Components.CtaFooter,
    DescriptionPoint: Components.DescriptionPoint,
    DescriptionPointsSection: Components.DescriptionPointsSection,
    Faq: Components.Faq,
    HeroCarousel: Components.HeroCarousel,
    HeroProduct: Components.HeroProduct,
    ImageTextDescription: Components.ImageTextDescription,
    NavigationPage: Components.NavigationPage,
    PageNav: Components.PageNav,
    Review: Components.Review,
    ReviewsSection: Components.ReviewsSection,
    SaleItem: Components.SaleItem,
    SaleSection: Components.SaleSection,
    SectionHeader: Components.SectionHeader,
    TakeoverAnnouncement: Components.TakeoverAnnouncement,
    TakeoverImage: Components.TakeoverImage,
  };
  // childElements is an array like [<ReactElement1 />, <ReactElement2 />, <ReactElement3 />]
  const childElements: React.CElement<any, React.Component<any, any, any>>[] =
    [];
  let backgroundColor: string;
  let prevBackgroundColor: string;

  const componentsMap = Object.assign({}, COMPONENTS, customMap);
  const unknownComponents: any[] = [];

  collection.forEach((contentfulProps: any, i: number) => {
    if (componentsMap[contentfulProps.__typename]) {
      if (contentfulProps.__typename === 'Break' && !contentfulProps.isLine) {
        // It's a page break and a wave. update background color.
        // Do some wonkiness for grey
        // Remember previous background Color for a hill.
        prevBackgroundColor = backgroundColor;
        backgroundColor =
          contentfulProps.waveColor === 'gray'
            ? theme.colors.grey[100]
            : (theme.colors as any)[contentfulProps.waveColor.toLowerCase()];
        prevBackgroundColor = prevBackgroundColor ?? backgroundColor;
        childElements.push(
          <Components.Break
            {...contentfulProps}
            backgroundColor={backgroundColor}
            prevBackgroundColor={prevBackgroundColor}
          />
        );
      }
      // Check if the component has a background color itself
      else {
        // Handle background color transition
        if (contentfulProps.backgroundColor !== undefined) {
          prevBackgroundColor = backgroundColor;
          backgroundColor =
            contentfulProps.backgroundColor === 'gray'
              ? theme.colors.grey[100]
              : (theme.colors as any)[
                  contentfulProps.backgroundColor.toLowerCase()
                ];
        }
        const Component = withBackgroundColor(
          componentsMap[contentfulProps.__typename],
          backgroundColor
        );
        childElements.push(
          <Component {...contentfulProps} pageType={pageType} />
        );
      }
    } else {
      if (
        contentfulProps.__typename === 'GenericSection' &&
        contentfulProps.meta?.reactComponent
      ) {
        const GenericComponent = withBackgroundColor(
          handleUnmappedGenericSection(contentfulProps),
          backgroundColor
        );
        childElements.push(<GenericComponent {...contentfulProps} />);
      } else {
        unknownComponents.push(contentfulProps);
      }
    }
  });
  return childElements;
};

/**
 * Handles mapping generic section components to their respective react components. The main use-case is for when their are two different variations of a generic section, which cannot be mapped with a simple map. This will read off the meta field's 'reactComponent' property.
 * @param contentfulProps - contentful data for a particular generic section
 * @returns a React FunctionalComponent (Maybe ComponentElement instead?) that can then be inserted into the childElements Array
 */
const handleUnmappedGenericSection = (contentfulProps: any) => {
  const componentName: keyof typeof Sections =
    contentfulProps.meta?.reactComponent;
  const SectionsSet = new Set();

  // Check that the sections exist. Will break page if there is a typo or the component doesnt exist in the code
  Object.entries(Sections).forEach((item) => SectionsSet.add(item[0]));
  return SectionsSet.has(componentName) ? Sections[componentName] : () => null;
};

export default mapComponent;
