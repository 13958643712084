"use client";
import { getCurrentLanguage } from "@hopper-b2b/hopper-utils";
import { TranslationLanguage } from "@hopper-b2b/i18n";
import { Context, createContext, PropsWithChildren, useContext } from "react";

export const SUPPORTED_LANGUAGES = [
  TranslationLanguage.en,
  TranslationLanguage.es,
  TranslationLanguage.fr,
];

export type LanguageContextType = {
  supportedLanguages: TranslationLanguage[];
  currentLang?: TranslationLanguage;
  onChange?: () => void;
};
export const LanguageContext: Context<LanguageContextType> = createContext({
  supportedLanguages: SUPPORTED_LANGUAGES,
});

export const useLanguageContext = () => {
  const ctx = useContext(LanguageContext);
  if (ctx === undefined) {
    throw new Error(`must be used within a LanguageProvider`);
  }
  return ctx;
};

export const LanguageProvider = ({
  children,
  onChange,
}: PropsWithChildren<Pick<LanguageContextType, "onChange">>) => {
  const currentLang = getCurrentLanguage();

  return (
    <LanguageContext.Provider
      value={{ supportedLanguages: SUPPORTED_LANGUAGES, currentLang, onChange }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
