import media from "@marketing-site/styles/media";
import { IStyledBackground } from "@marketing-site/types";
import { Helpers } from "@marketing-site/utils";
import { selectBackgroundColor } from "@marketing-site/redux/utilitiesQuery";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "@marketing-site/components/link";
import styled from "styled-components";

interface INavItem {
  text: string;
  image: {
    title: string;
    url: string;
  };
  url: string;
  isExternal: boolean;
}

interface IPageNav {
  navigationType: string;
  showNav: boolean;
  navLinksCollection: {
    items: INavItem[];
  };
  backgroundColor: string;
  pageType?: string;
}
const PageNav = (props: IPageNav) => {
  // I tried to use Gatsby's <Link /> as well as just an a tag with href to #id
  // but I'm getting strange rendering issues where all of a page's "content" components
  // rerender again on a page.
  const idHelper = (link: string) => {
    const id = link.split("#")[1];
    const ele = document.getElementById(id);
    if (ele) {
      ele.scrollIntoView();
    }
  };

  const globalBackgroundColor = useSelector(selectBackgroundColor);
  const backgroundColor =
    props.pageType === "product"
      ? props.backgroundColor
      : globalBackgroundColor;

  return (
    <Background
      style={{ transition: "background-color 1s ease-out" }}
      backgroundColor={
        backgroundColor ? backgroundColor : props.backgroundColor || "#fff"
      }
    >
      {props.navigationType === "pagelevel" && props.showNav ? (
        <Container>
          {props.navLinksCollection.items.map(
            (navItem: INavItem, i: number) => {
              return (
                <PLItem key={i} onClick={() => idHelper(navItem.url)}>
                  <PLIcon src={navItem.image.url} alt={navItem.image.title} />
                  <PLText>{navItem.text}</PLText>
                </PLItem>
              );
            }
          )}
        </Container>
      ) : props.navigationType === "superapp" && props.showNav ? (
        <SAContainer
          id="superAppNavContainer"
          backgroundColor={
            backgroundColor ? backgroundColor : props.backgroundColor || "#fff"
          }
        >
          {props.navLinksCollection.items.map(
            (navItem: INavItem, i: number) => {
              return (
                <SAItem key={i} destination={navItem.url}>
                  <SAIconWrapper>
                    <SAIcon src={navItem.image.url} alt={navItem.image.title} />
                  </SAIconWrapper>
                  <SAText>{navItem.text}</SAText>
                </SAItem>
              );
            }
          )}
        </SAContainer>
      ) : (
        <></>
      )}
    </Background>
  );
};

export default PageNav;

const Background = styled.div<IStyledBackground>`
  background-color: ${(p) => p.backgroundColor};
  padding-top: 12px;
`;

// May end up being moved to PL nav since SA has a slightly different view
const Container = styled.div`
  width: 94%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-evenly;
  ${media.desktop`
    flex-wrap: wrap;
    column-gap: 10px;
  `};
`;

// Styles for page level (PL) nav
const PLItem = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

const PLIcon = styled.img`
  width: 46px;
`;

const PLText = styled.h4`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 10px;
  width: 50px; // There's probably a better way to adjust width...
`;

const SAContainer = styled.div<IStyledBackground>`
  width: 98%;
  max-width: 900px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
`;

const SAItem = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  max-width: 82px;
`;

const SAIcon = styled.img`
  width: 46px;
`;

const SAIconWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  padding: 10px 18px;
  border-radius: 10px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SAText = styled.h4`
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 10px;
  color: ${(p) => p.theme.colors.grey[100]};
`;
