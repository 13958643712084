import { IBucketedDate, IDateBucket, IMonthBucket } from "@hopper-b2b/types";
import dayjs from "dayjs";

export const transformDateBuckets = (
  dateBuckets: IDateBucket[]
): IMonthBucket[] => {
  const processedMonths = dateBuckets.reduce(
    (months, { dates }, bucketIndex) => {
      const reducedMonths = dates.reduce(
        (monthBuckets: IMonthBucket[], dateString: string) => {
          const date = dayjs(dateString).toDate();
          const bucketedDate: IBucketedDate = { bucket: bucketIndex, date };
          const currentMonthIndex = dayjs(date).month();
          let existingMonthBucket = monthBuckets.find(({ monthIndex }) => {
            return monthIndex === currentMonthIndex;
          });

          if (typeof existingMonthBucket === "undefined") {
            existingMonthBucket = { monthIndex: currentMonthIndex, dates: [] };
            monthBuckets.push(existingMonthBucket);
          }

          existingMonthBucket.dates.push(bucketedDate);

          return monthBuckets;
        },
        months
      );

      return reducedMonths;
    },
    [] as IMonthBucket[]
  );
  const sortedMonths = processedMonths.map((processedMonth) => {
    const sortedDates = processedMonth.dates.sort(
      (a: IBucketedDate, b: IBucketedDate) => dayjs(a.date).diff(b.date)
    );
    return { monthIndex: processedMonth.monthIndex, dates: sortedDates };
  });

  return sortedMonths;
};
