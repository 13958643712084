import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import styles from "./TripCategoryPicker.module.scss";

export type TripCategoryPickerProps = {
  tripCategory: TripCategory;
  disabled?: boolean;
  onChange: (tripCategory: TripCategory) => void;
};

export const TripCategoryPicker = ({
  tripCategory,
  disabled,
  onChange,
}: TripCategoryPickerProps) => {
  const { t } = useI18nContext();

  return (
    <Paper variant="outlined" className={styles.container}>
      <Grid container alignItems="center" className={styles.grid}>
        <Grid item>
          <RadioGroup
            className={clsx(styles.radioGroup, "trip-category-picker")}
            value={tripCategory}
            onChange={(_, v: TripCategory) => {
              if (!v) return;
              onChange(v);
            }}
            row={true}
          >
            <FormControlLabel
              value={TripCategory.ROUND_TRIP}
              label={
                <Typography color="textSecondary" variant="body2">
                  {t?.("flightRoundTrip")}
                </Typography>
              }
              disabled={disabled}
              control={<Radio size="small" color="primary" />}
            />
            <FormControlLabel
              value={TripCategory.ONE_WAY}
              label={
                <Typography color="textSecondary" variant="body2">
                  {t?.("flightOneWay")}
                </Typography>
              }
              disabled={disabled}
              control={<Radio size="small" color="primary" />}
            />
          </RadioGroup>
        </Grid>
      </Grid>
    </Paper>
  );
};
