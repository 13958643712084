import { HeaderNavigationMenuItemType } from "@hopper-b2b/hopper-utils";
import { Button, Menu } from "@mui/material";
import { useState } from "react";
import { NavigationMenuItem } from "../NavigationMenuItem";

export type NavigationMenuProps = HeaderNavigationMenuItemType;

export const NavigationMenu = ({
  text,
  identifier,
  children,
}: NavigationMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onMenuOpen = (event) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={identifier}
        aria-owns={anchorEl ? `${identifier}-menu` : undefined}
        aria-haspopup="true"
        onClick={onMenuOpen}
        onMouseOver={onMenuOpen}
      >
        {text}
      </Button>
      <Menu
        id={`${identifier}-menu`}
        anchorEl={anchorEl}
        open={!!anchorEl}
        keepMounted={true}
        onClose={onMenuClose}
        MenuListProps={{ onMouseLeave: onMenuClose }}
      >
        {children?.map((item: HeaderNavigationMenuItemType, index: number) => (
          <NavigationMenuItem key={item.identifier ?? index} {...item} />
        ))}
      </Menu>
    </>
  );
};
