import { SendCodeRequestEnum, SendCodeResponseEnum } from "@b2bportal/auth-api";
import { sendCode } from "../../api";
import { AuthMachineContext } from "../context";
import { getAuthStateToken } from "../selectors";

export const sendCodeForMergeService = (context: AuthMachineContext, _event) =>
  new Promise((resolve, reject) => {
    const authStateToken = getAuthStateToken({ context }) as string;

    sendCode({
      authStateToken: authStateToken,
      SendCodeRequest: SendCodeRequestEnum.SendCodeForMerge,
    })
      .then((res) => {
        if (res.SendCodeResponse === SendCodeResponseEnum.SendCodeFailed) {
          reject("SendCodeResponseEnum.SendCodeFailed");
        } else {
          resolve(res);
        }
      })
      .catch((error) => reject(error));
  });
