import { SessionInfo } from "../types";
import {
  AuthActions,
  FINALIZE_USER_FAILED,
  FINALIZE_USER_SUCCESS,
  FINALIZED_REQUIRED,
  REMOVE_SESSION,
  UPDATE_SESSION,
} from "./actions";

export type UserInfoState = {
  sessionInfo?: SessionInfo;
  missingFields?: string[];
};
export const authReducer = (state: UserInfoState, action: AuthActions) => {
  switch (action.type) {
    case UPDATE_SESSION: {
      return {
        ...state,
        sessionInfo: { ...state?.sessionInfo, ...action.sessionInfo },
      };
    }
    case FINALIZED_REQUIRED: {
      return { ...state, authStateToken: action.authStateToken };
    }
    case FINALIZE_USER_SUCCESS: {
      return { ...state, authStateToken: action.authStateToken };
    }
    case FINALIZE_USER_FAILED: {
      return { ...state, authStateToken: "" };
    }
    case REMOVE_SESSION: {
      return { ...state, sessionInfo: undefined };
    }
    default:
      return state;
  }
};
