import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { PageQuery } from "@marketing-site/api/urbana/contentful/queries";
import {
  ITakeoverImage,
  TakeoverImage,
} from "@marketing-site/components/_contentful/takeover-image/takeoverImage";
import { Page } from "@marketing-site/pages/Page/Page";
import React from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import { LoadingPage } from "../Page/LoadingPage";

export interface IProductProps {
  locale?: string;
  "*"?: string;
}

export const Product = () => {
  const pageData: IProductProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const slug = pageData["*"] || "";

  const {
    data: contentfulData,
    error: _contentfulError,
    isLoading: contentfulLoading,
  } = useFetchContentQuery({
    query: PageQuery(slug, locale),
    space: "hopperCom",
  });

  const takeoverImage: ITakeoverImage =
    contentfulData?.takeoverImagesCollection?.items[0];

  return contentfulLoading || !contentfulData ? (
    <LoadingPage />
  ) : (
    <>
      <Page
        slug={slug}
        locale={locale}
        canonical={path}
        content={contentfulData}
        pageType={"product"}
      />
      {takeoverImage && <TakeoverImage {...takeoverImage} />}
    </>
  );
};
