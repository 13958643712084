import HopperLogo from "@marketing-site/static/icons/hopperLogo-red.png";
import HopperLogoFull from "@marketing-site/static/icons/hopperLogo.png";
import HopperLogoWhite from "@marketing-site/static/icons/hopperLogo-white.png";
import { HamburgerMenuIcon, XIcon } from "@marketing-site/static/icons";
import { media, theme } from "@marketing-site/styles";
import { IStyledBackground, IStyledIsDesktop } from "@marketing-site/types";
import { selectBackgroundColor } from "@marketing-site/redux/utilitiesQuery";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import MenuDrawer from "./drawer";
import { isColorLight } from "@marketing-site/utils/helpers";
import { Link } from "@marketing-site/components/link";
import { LocaleContext } from "@marketing-site/utils/hooks/localeContext";
import { GetIcon } from "@marketing-site/utils";
import LanguageDropdown from "../language-dropdown/LanguageDropdown";
import CtaButton from "../_contentful/cta-button";
import { ICtaButton } from "../_contentful/cta-button/ctaButton";

interface INavItem {
  text: string;
  identifier?: string;
  icon?: string;
  link?: string;
  isExternal?: boolean;
  children?: INavItem[];
}

export interface IHeaderProps {
  navigation: INavItem[];
  backgroundColor: string;
  more?: INavItem[];
  useGlobalColor?: boolean;
  className?: string;
  cta: ICtaButton;
}

export const Header = ({ className, ...props }: IHeaderProps) => {
  const globalBackgroundColor = useSelector(selectBackgroundColor);
  const bgColor = props.useGlobalColor
    ? globalBackgroundColor
    : isColorLight(props.backgroundColor)
    ? theme.colors.white
    : props.backgroundColor;
  const [displayMenu, setDisplayMenu] = useState(false);

  const [width, setWidth] = useState<number>(window.innerWidth);

  const [selectedNav, setSelectedNav] = useState<string>("");
  const localeContext = useContext(LocaleContext);

  const windowHandler = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", windowHandler);
    return () => {
      window.removeEventListener("resize", windowHandler);
    };
  }, []);

  const onEnterHandler = (id?: string) => {
    let ele: HTMLElement | null;
    if (id && id !== selectedNav) {
      ele = document.getElementById(selectedNav);
      if (ele) {
        ele.style.display = "none";
        ele.style.zIndex = "0";
      }
      ele = document.getElementById(id);
      if (ele) {
        ele.style.display = "block";
        ele.style.zIndex = "12";
        setSelectedNav(id);
      }
    }
  };

  const onLeaveHandler = (id?: string) => {
    let ele: HTMLElement | null;
    if (id) {
      ele = document.getElementById(id);
      if (ele) {
        ele.style.display = "none";
        ele.style.zIndex = "0";
        setSelectedNav("");
      }
    }
  };

  const rowArrowIcon = GetIcon("chevron-right-heavy-blue");

  return width < 991 ? (
    // is mobile
    <Background
      style={{ transition: "background-color 1s ease-out" }}
      backgroundColor={"#FFF"}
      className={className}
    >
      <MenuDrawer toggleDrawer={setDisplayMenu} open={displayMenu}>
        <DrawerContainer>
          <DrawerHeader>
            <Logo destination={`/${localeContext}`}>
              <LogoIcon src={HopperLogo} />
            </Logo>
            <Spacer />
            <Close onClick={() => setDisplayMenu(false)}>
              <XIcon color={theme.colors.grey[800]} size={32} />
            </Close>
          </DrawerHeader>
          <DrawerBody>
            {props.navigation.map((navItem: INavItem, i: number) => {
              return navItem.children ? (
                <Fragment key={`NavItem-${i}`}>
                  <MobileLinkHeader>{navItem.text}</MobileLinkHeader>
                  {navItem.children.map((child: INavItem, j: number) => {
                    return (
                      <MobileLink
                        key={`NavItem${i}-child${j}`}
                        href={child.link}
                      >
                        {child.text}
                      </MobileLink>
                    );
                  })}
                </Fragment>
              ) : (
                <MobileLink key={`NavItem-${i}`} href={navItem.link}>
                  {navItem.text}
                </MobileLink>
              );
            })}
          </DrawerBody>
        </DrawerContainer>
      </MenuDrawer>

      <Container className="nav-header-deal-drops">
        <Logo destination={`/${localeContext}`}>
          <LogoIcon
            src={
              isColorLight(props.backgroundColor) ? HopperLogo : HopperLogoWhite
            }
            desktop
          />
        </Logo>
        <Flex>
          <LanguageDropdown />
          <HamburgerWrapper onClick={() => setDisplayMenu(true)}>
            <HamburgerMenuIcon
              color={
                isColorLight(props.backgroundColor)
                  ? theme.colors.skyblue
                  : theme.colors.white
              }
              size={32}
            />
          </HamburgerWrapper>
        </Flex>
      </Container>
    </Background>
  ) : (
    // desktop
    <Background backgroundColor={"#FFF"} className={className}>
      <Container>
        <Logo destination={`/${localeContext}`}>
          <LogoIcon
            src={
              isColorLight(props.backgroundColor)
                ? HopperLogoFull
                : HopperLogoWhite
            }
            desktop
          />
        </Logo>
        <HeaderNavigation>
          <Flex style={{ gap: "24px" }}>
            {props.navigation.map((navSection, i) => {
              return (
                <NavTitle
                  key={`NavTitle-${i}`}
                  tabIndex={0}
                  onMouseEnter={() => onEnterHandler(navSection.identifier)}
                  onFocus={() => onEnterHandler(navSection.identifier)}
                  onMouseLeave={() => onLeaveHandler(navSection.identifier)}
                >
                  {navSection.text}
                  {navSection.identifier && (
                    <NavDropdown id={navSection.identifier}>
                      {navSection.children?.map((navRow, j) => {
                        const icon = GetIcon(navRow.icon!);
                        return (
                          <NavDropdownRowAnchor
                            destination={navRow.link!}
                            key={`NavRow-${i}-child${j}`}
                          >
                            <NavDropdownRow>
                              <Flex>
                                <NavRowIcon>{icon}</NavRowIcon>
                                <NavRowText>{navRow.text}</NavRowText>
                              </Flex>
                              <NavRowArrow>{rowArrowIcon}</NavRowArrow>
                            </NavDropdownRow>
                          </NavDropdownRowAnchor>
                        );
                      })}
                    </NavDropdown>
                  )}
                </NavTitle>
              );
            })}
          </Flex>
          {props.more && (
            <>
              <NavDivider />
              <Flex>
                {props.more.map((navItem, i) => {
                  return (
                    <NavTitleLink
                      destination={navItem.link!}
                      key={`NavTitleLink-${i}`}
                    >
                      {navItem.text}
                      {navItem.isExternal && GetIcon("externalLink")}
                    </NavTitleLink>
                  );
                })}
              </Flex>
            </>
          )}
          <Flex>
            <LanguageDropdown />
            <CtaButton {...props.cta} />
          </Flex>
        </HeaderNavigation>
      </Container>
    </Background>
  );
};

export default Header;

const Background = styled.div<IStyledBackground>`
  background-color: ${(p) =>
    p.backgroundColor === theme.colors.beige
      ? theme.colors.skyblue
      : p.backgroundColor};
  padding: 14px 0;
  border-bottom: 2px solid ${theme.colors.grey[200]};
`;

const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 63px;
  max-width: 1400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
`;

const DrawerContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 14px 0;

  ${media.tablet`
    height: 100vh;
  `}
`;

const DrawerHeader = styled.div`
  display: flex;
  position: relative;
  height: 63px;
`;

const DrawerBody = styled.div`
  margin: 0 auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
`;
const Spacer = styled.span`
  flex: 1 0 auto;
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
`;

const LogoIcon = styled.img<IStyledIsDesktop>`
  max-width: 150px;
  max-height: 40px;
  ${(p: IStyledIsDesktop) =>
    p.desktop &&
    `
    margin-right: 24px;
  `};
`;

const HeaderNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 18px;
  align-items: center;
  width: 100%;
  max-width: 800px;
`;

const NavTitleCss = css`
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 23px;
  padding-bottom: 23px;
  color: ${theme.colors.grey[800]};
`;

const NavTitle = styled.div`
  ${NavTitleCss}
  :hover {
    cursor: pointer;
  }
`;

const NavTitleLink = styled(Link)`
  ${NavTitleCss}
  display: flex;
  align-items: center;
  text-decoration: none;
  svg {
    width: 14px;
  }
`;

const NavDropdown = styled.div`
  display: none;
  position: absolute;
  margin-top: 22px;
  padding: 20px;
  border-radius: 20px;
  margin-left: -80px;
  background: ${theme.colors.white};
  box-shadow: 0 0 20px 5px rgb(0 0 0 / 12%);
  width: 420px;
`;

const NavDropdownRowAnchor = styled(Link)`
  text-decoration: none;
`;

const NavRowIcon = styled.div`
  margin-left: 20px;
  filter: saturate(0%);
  opacity: 0.5;
  svg {
    width: 40px;
    height: 40px;
  }
  transition: 200ms ease-in;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;
  gap: 12px;
`;

const NavRowText = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-left: 14px;

  transition: 200ms ease-in;
`;

const NavRowArrow = styled.div`
  margin-right: 20px;
  opacity: 0;
  transition: 200ms ease-in;
`;

const NavDropdownRow = styled.div`
  border-radius: 10px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  :hover {
    background-color: rgba(1, 170, 228, 0.1);

    ${NavRowIcon} {
      filter: saturate(100%);
      opacity: 1;
    }

    ${NavRowText} {
      color: ${theme.colors.skyblue};
    }

    ${NavRowArrow} {
      opacity: 1;
    }
  }
  display: flex;
  align-items: center;
  transition: 200ms ease-in;
`;

const NavDivider = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: ${(p) => p.theme.colors.grey[300]};
  width: 0px;
  height: 14px;
`;

const HamburgerWrapper = styled.button`
  display: flex;
  align-items: center;

  border: none;
  background-color: transparent;
  cursor: pointer;
  z-index: 10;
`;

const Close = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
`;

const MobileLink = styled.a`
  text-decoration: none;
  font-size: 24px;
  color: ${(p) => theme.colors.grey[800]};
  display: block;
  transition: 0.3s;
  text-align: center;
  margin-bottom: 24px;
  :first-of-type {
    margin-top: 20px;
  }
`;

const MobileLinkHeader = styled.h5`
  text-align: center;
  margin-top: 38px;
`;
