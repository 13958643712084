import { TrackEventRequest } from "@b2bportal/event-tracking-api";
import { IApiConfig } from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";

import { analyticsApiPrefix } from "../paths";

/**
 * @deprecated Do not use directly, use `@hopper-b2b/tracking` instead
 */
export const trackEvent = async (
  req: TrackEventRequest,
  config?: IApiConfig
): Promise<boolean> => {
  const analyticsApi = config?.analyticsApiPrefix || analyticsApiPrefix;
  const END_POINT = `${analyticsApi}/event`;

  try {
    const res = await axiosInstance.post(END_POINT, req);
    const responseBody = res.data;
    return responseBody;
  } catch (e) {
    console.error(e);
    // Don't reject promise to prevent ad block from interfering with api calls
    return false;
  }
};
