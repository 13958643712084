import React from 'react';

import ChevronRight from '../static/icons/chevronRight';
import ChevronRightHeavyBlue from '../static/icons/chevronRight-heavy-blue';
import ChevronLeft from '../static/icons/chevronLeft';
import ChevronUp from '@marketing-site/static/icons/chevronUp';
import Dot from '../static/icons/dot';
import ExternalLink from '@marketing-site/static/icons/externalLink';
import Globe from '@marketing-site/static/icons/globe';
import * as Products from '@marketing-site/static/icons/products';
import * as Travel from '@marketing-site/static/icons/travel';

const iconMap = {
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-right-heavy-blue': ChevronRightHeavyBlue,
  'chevron-up': ChevronUp,
  dot: Dot,
  externalLink: ExternalLink,
  globe: Globe,
  // Products
  carrotCash: Products.CarrotCashIcon,
  pricePrediction: Products.PricePredictionIcon,
  priceFreeze: Products.PriceFreezeIcon,
  cancelForAnyReason: Products.CancelForAnyReasonIcon,
  changeForAnyReason: Products.ChangeForAnyReasonIcon,
  leaveForAnyReason: Products.LeaveForAnyReasonIcon,
  flightDisruptionGuarantee: Products.FlightDisruptionGuaranteeIcon,
  vipSupport: Products.VipSupportIcon,
  // Travel Verticals
  flights: Travel.FlightsIcon,
  hotels: Travel.HotelsIcon,
  cars: Travel.CarsIcon,
  homes: Travel.HomesIcon,
};

const GetIcon = (key: string) => {
  const Icon = iconMap[key as keyof typeof iconMap];
  if (Icon !== undefined) {
    // icon exists, send it back
    return <Icon />;
  }
  return null;
};

export default GetIcon;
