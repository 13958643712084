//@ts-nocheck
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import media from '@marketing-site/styles/media';
import { RichTextToHtml, Helpers } from '@marketing-site/utils';

const AccordionItem = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen);

  const AccordionClicked = () => {
    props.setOpen(props.headline);
  };

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <AccordionItemContainer isOpen={isOpen} onClick={AccordionClicked}>
      <AccordionHeader>
        <Icon src={props.icon.url} alt={props.icon.title} selected={isOpen} />
        <Headline>{props.headline}</Headline>
        <Arrow />
      </AccordionHeader>
      {isOpen && (
        <>
          <AccordionBody>
            <BreakLine />
            <AccordionText>
              {RichTextToHtml(props.description.json)}
            </AccordionText>
          </AccordionBody>
        </>
      )}
    </AccordionItemContainer>
  );
};

export default AccordionItem;

AccordionItem.propTypes = {
  headline: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  link: PropTypes.object,
  description: PropTypes.object.isRequired,
  sectionImage: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  clickedHandler: PropTypes.func,
};

const AccordionItemContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  padding: 0px 30px ${(p) => (p.isOpen ? '30' : '0')}px 30px;
  width: 530px;
  margin-bottom: 20px;

  transition: 1s;
`;

const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

const Icon = styled.img`
  align-self: center;
  width: 40px;
  height: 40px;
`;

const Headline = styled.p`
  color: ${(p) => p.theme.colors.grey[800]};
  font-size: 22px;
  line-height: 26px;
  font-weight: 600;
  margin-left: 16px;
`;

const Arrow = styled.img``;

const BreakLine = styled.div`
  background: ${(p) => p.theme.colors.grey[100]};
  height: 2px;
  border-radius: 2px;
`;

const AccordionBody = styled.div``;

const AccordionText = styled.div`
  p {
    font-size: 18px;
    line-height: 26px;
  }
`;
