import React from 'react';

const BadgeSticker = (props: { id: string; color: string }) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.8558 7.04671L41.8558 7.04671L37.2678 6.73918C36.9786 6.71979 36.7037 6.60589 36.4856 6.41501L33.0248 3.38721C33.0248 3.3872 33.0248 3.3872 33.0248 3.3872C31.6345 2.17083 29.8501 1.50026 28.0028 1.5C26.1555 1.49974 24.3709 2.16981 22.9802 3.38579L19.5192 6.41214C19.3009 6.60297 19.026 6.7168 18.7368 6.7361C18.7367 6.7361 18.7367 6.7361 18.7367 6.7361L14.1495 7.04204L14.1495 7.04204C12.3061 7.16502 10.57 7.95274 9.26354 9.25893C7.95706 10.5651 7.16894 12.301 7.04553 14.1443C7.04553 14.1443 7.04553 14.1444 7.04553 14.1444L6.73837 18.7309C6.719 19.0203 6.60508 19.2952 6.41414 19.5135C6.41414 19.5135 6.41413 19.5135 6.41413 19.5135L3.38733 22.9731L3.38733 22.9731C2.17086 24.3635 1.50025 26.1481 1.5 27.9956C1.49975 29.843 2.16989 31.6278 3.38598 33.0186L3.38599 33.0186L6.41239 36.4795C6.60325 36.6978 6.7171 36.9728 6.73641 37.2621L7.04266 41.8496C7.04266 41.8496 7.04266 41.8496 7.04266 41.8496C7.16567 43.6928 7.9533 45.4287 9.25931 46.7351C10.5653 48.0416 12.301 48.8297 14.1442 48.9533L14.1442 48.9533L18.7322 49.2608C19.0214 49.2802 19.2963 49.3941 19.5144 49.585L22.9752 52.6128C24.3655 53.8292 26.1499 54.4997 27.9972 54.5C29.8445 54.5003 31.6291 53.8302 33.0197 52.6142L36.4808 49.5879C36.6991 49.397 36.974 49.2832 37.2632 49.2639L41.8505 48.958C43.6938 48.835 45.4299 48.0473 46.7364 46.7411C48.0429 45.4349 48.831 43.699 48.9545 41.8557L49.2616 37.2691C49.281 36.9797 49.3949 36.7048 49.5859 36.4866L52.6127 33.0269L51.4837 32.0393L52.6127 33.0269C53.8291 31.6365 54.4998 29.8519 54.5 28.0044C54.5002 26.157 53.8301 24.3722 52.614 22.9815L49.5876 19.5205C49.3967 19.3022 49.2829 19.0272 49.2636 18.7379L48.9573 14.1505C48.8343 12.3073 48.0467 10.5713 46.7407 9.2649C45.4347 7.95846 43.699 7.17029 41.8558 7.04671Z"
        fill={`url(#${props.id})`}
        stroke="white"
        strokeWidth="3"
      />
      <defs>
        <linearGradient
          id={props.id}
          x1="28"
          y1="3"
          x2="28"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={props.color} />
          <stop offset="1" stopColor={`${props.color}f0`} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default BadgeSticker;
