import React from "react";
import styled from "styled-components";
import media from "@marketing-site/styles/media";
import { Review } from "@marketing-site/components/_contentful";
import { IStyledBackground } from "@marketing-site/types";
import { IReviewProps } from "@marketing-site/components/_contentful/review/review";

export interface IReviewsSectionProps {
  preheadline: string;
  headline: string;
  sectionId: string;
  reviewsCollection: {
    items: [IReviewProps];
  };
  __typename?: string;
}

const ReviewsSection = (props: IReviewsSectionProps) => {
  return (
    <Wrapper>
      <ReviewsSectionContainer id={props.sectionId}>
        <Header>
          <Preheadline>{props.preheadline}</Preheadline>
          <Headline>{props.headline}</Headline>
        </Header>
        <Reviews>
          {props.reviewsCollection.items.map((review: any, i: number) => {
            return <Review {...review} key={`Review-${i}`} />;
          })}
        </Reviews>
      </ReviewsSectionContainer>
    </Wrapper>
  );
};

export default ReviewsSection;

const Wrapper = styled.div`
  padding-bottom: 72px;
`;

const ReviewsSectionContainer = styled.div`
  padding-top: 24px;
  margin: 0 auto;
  width: 94%;
  max-width: 1080px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Preheadline = styled.h5`
  margin-bottom: 0;
`;

const Headline = styled.h2`
  margin-top: 10px;
  width: 716px;
  text-align: center;
  margin-bottom: 18px;
`;

const Reviews = styled.div`
  display: flex;
  margin-top: 60px;
  margin: 0 auto;
  flex-direction: row;
  ${media.desktop`
    flex-direction: column;
  `};
`;
