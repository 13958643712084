import {
  ActiveExperimentsResponse,
  Experiment,
  ExperimentState,
  ExperimentsType,
  IApiConfig,
  NETWORK_CALL_FAILED,
} from "@hopper-b2b/types";
import { axiosInstance } from "../AxiosInterceptor";
import { experimentsApiPrefix } from "../paths";
import { trackEvent } from "../tracking/trackEvent";

export const putFetchActiveExperiments = async (
  config?: IApiConfig
): Promise<ExperimentState> => {
  const experimentPrefix = config?.experimentsApiPrefix || experimentsApiPrefix;
  const END_POINT = `${experimentPrefix}/active`;

  try {
    const res = await axiosInstance.put<
      ActiveExperimentsResponse | ExperimentsType
    >(END_POINT, {});

    if (Object.keys(res.data).includes("experiments")) {
      const { experiments: activeExperiments, ...rest } =
        res.data as ActiveExperimentsResponse;

      const experiments: ExperimentsType = activeExperiments?.reduce(
        (map: ExperimentsType, experiment: Experiment) => {
          map[experiment.id] = experiment.variant;
          return map;
        },
        {}
      );

      return {
        experiments: experiments,
        ...rest,
      };
    } else {
      return { experiments: res.data as ExperimentsType };
    }
  } catch (e) {
    trackEvent(
      {
        eventName: NETWORK_CALL_FAILED,
        properties: {
          url: window.location.pathname,
        },
      },
      config
    );
    throw e;
  }
};
