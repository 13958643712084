// This is static content that is used while a page is loading.
// This should be updated every so often to be kept up to date.

export const headerData = {
  cta: {
    headline: "Download the app",
    fullyRounded: true,
    backgroundColor: "white",
    isFilled: false,
    short: true,
    link: "https://go.hopper.com/to/home?pid=website&c=header&install=true&af_web_dp=https://www.hopper.com/download/",
  },
  more: [
    {
      link: "https://media.hopper.com",
      text: "Hopper Media",
      identifier: "media",
      isExternal: true,
    },
  ],
  navigation: [
    {
      text: "Travel",
      children: [
        {
          icon: "flights",
          link: "/travel/flights",
          text: "Flights",
        },
        {
          icon: "hotels",
          link: "/travel/hotels",
          text: "Hotels",
        },
        {
          icon: "cars",
          link: "/travel/rental-cars",
          text: "Rental Cars",
        },
        {
          icon: "homes",
          link: "/travel/homes",
          text: "Homes",
        },
      ],
      identifier: "travel",
    },
    {
      text: "Products",
      children: [
        {
          icon: "pricePrediction",
          link: "/product/price-prediction",
          text: "Price Prediction",
        },
        {
          icon: "priceFreeze",
          link: "/product/price-freeze",
          text: "Price Freeze™",
        },
        {
          icon: "cancelForAnyReason",
          link: "/product/cancel-for-any-reason",
          text: "Cancel for Any Reason",
        },
        {
          icon: "changeForAnyReason",
          link: "/product/change-for-any-reason",
          text: "Change for Any Reason",
        },
        {
          icon: "leaveForAnyReason",
          link: "/product/leave-for-any-reason",
          text: "Leave for Any Reason",
        },
        {
          icon: "flightDisruptionGuarantee",
          link: "/product/premium-disruption-assistance",
          text: "Premium Disruption Assistance",
        },
        {
          icon: "vipSupport",
          link: "/product/vip-support",
          text: "VIP Support",
        },
      ],
      identifier: "products",
    },
    {
      text: "Company",
      children: [
        {
          link: "/about",
          text: "About",
        },
        {
          link: "/careers",
          text: "Careers",
        },
        {
          link: "/culture",
          text: "Culture",
        },
      ],
      identifier: "company",
    },
  ],
  backgroundColor: "#fff",
};

export const footerData = {
  left: {
    hasLogo: true,
    socialMedia: [
      {
        url: "https://www.facebook.com/hoppertravel",
        platform: "Facebook",
      },
      {
        url: "https://twitter.com/hopper",
        platform: "Twitter",
      },
      {
        url: "https://www.instagram.com/hopper",
        platform: "Instagram",
      },
      {
        url: "https://www.linkedin.com/company/hopper",
        platform: "LinkedIn",
      },
      {
        url: "https://www.youtube.com/user/HopperBoston",
        platform: "YouTube",
      },
      {
        url: "https://www.tiktok.com/@hopper",
        platform: "Tiktok",
      },
    ],
  },
  columns: [
    {
      links: [
        {
          url: "https://hopper.com/travel/flights",
          title: "Flights",
        },
        {
          url: "https://hopper.com/travel/hotels",
          title: "Hotels",
        },
        {
          url: "https://hopper.com/travel/rental-cars",
          title: "Rental Cars",
        },
        {
          url: "https://hopper.com/travel/homes",
          title: "Homes",
        },
      ],
      header: "Travel",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/refer",
          title: "Referral Program",
        },
        {
          url: "https://hopper.com/product/price-prediction",
          title: "Price Prediction",
        },
        {
          url: "https://hopper.com/product/change-for-any-reason",
          title: "Change for Any Reason",
        },
        {
          url: "https://hopper.com/product/cancel-for-any-reason",
          title: "Cancel for Any Reason",
        },
        {
          url: "https://hopper.com/product/leave-for-any-reason",
          title: "Leave for Any Reason",
        },
        {
          url: "https://hopper.com/product/premium-disruption-assistance",
          title: "Premium Disruption Assistance",
        },
        {
          url: "https://hopper.com/product/price-freeze",
          title: "Price Freeze™",
        },
        {
          url: "https://hopper.com/product/vip-support",
          title: "VIP Support",
        },
      ],
      header: "Products",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://hopper.com/about",
          title: "About",
        },
        {
          url: "https://hopper.com/culture",
          title: "Culture",
        },
        {
          url: "https://hopper.com/careers",
          title: "Careers",
        },
        {
          url: "https://hts.hopper.com",
          title: "HTS (Hopper Technology Solutions)",
        },
        {
          url: "https://hosts.hopper.com/",
          title: "Partner with Hopper Homes",
        },
        {
          url: "https://form.typeform.com/to/PVt36UoW",
          title: "Partner with Hopper Hotels",
        },
        {
          url: "https://hopper.com/affiliates",
          title: "Hopper Affiliate Program",
        },
      ],
      header: "Company",
      isExternal: false,
    },
    {
      links: [
        {
          url: "https://help.hopper.com/en_us/categories/help-with-flights-Hywr1lPz_",
          title: "Flights",
        },
        {
          url: "https://help.hopper.com/en_us/categories/help-with-hotels-SJKr1lwfd",
          title: "Hotels & Homes",
        },
        {
          url: "https://help.hopper.com/en_us/categories/hopper-products-S1oSJlwzd",
          title: "Hopper Products",
        },
        {
          url: "https://help.hopper.com/en_us/categories/billing-and-payments-rJIHJevz_",
          title: "Billing & Payment",
        },
        {
          url: "https://help.hopper.com/en_us/categories/using-the-app-H13HJgvMu",
          title: "Using the App",
        },
        {
          url: "https://hopper.com/legal/privacy-policy",
          title: "Privacy Notice",
        },
        {
          url: "https://hopper.com/legal/terms-and-conditions",
          title: "Terms & Conditions",
        },
      ],
      header: "FAQs",
      isExternal: true,
    },
    {
      links: [
        {
          url: "https://media.hopper.com/news",
          title: "News",
        },
        {
          url: "https://media.hopper.com/press-kit",
          title: "Press Kit",
        },
        {
          url: "https://media.hopper.com/research",
          title: "Research",
        },
        {
          url: "https://media.hopper.com/enquiries",
          title: "Media Enquiries",
        },
      ],
      header: "Media",
      isExternal: true,
    },
  ],
};
