import React from "react";
import Drawer from "@mui/material/Drawer";

interface MenuDrawerProps {
  open: boolean;
  toggleDrawer: (x: boolean) => void;
  children?: React.ReactNode;
}

const MenuDrawer = ({ open, toggleDrawer, children }: MenuDrawerProps) => {
  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={() => toggleDrawer(false)}
      disableScrollLock={true}
      transitionDuration={500}
    >
      {children}
    </Drawer>
  );
};

export default MenuDrawer;
