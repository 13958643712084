import { v4 as uuidv4 } from "uuid";

export const config: Record<string, any> = {
  apiKey: process.env?.NX_URBANA_API_KEY ?? "",
  datadogSite: process.env?.NX_DATADOG_SITE ?? "us5.datadoghq.com",
  datadogApplicationID:
    process.env?.NX_DATADOG_APPLICATION_ID ??
    "1b4472f1-1aed-4ec1-abc8-955ef82667c1",
  datadogClientToken:
    process.env?.NX_DATADOG_CLIENT_TOKEN ??
    "pub2022b6e2d5e83461d41b6774ce9770bf",
  datadogEnvironment: process.env?.NX_DEPLOYMENT_ENV ?? "local",
  environment: process.env?.NX_DEPLOYMENT_ENV ?? "local",
  googleIdentityClientId:
    process.env.NX_GOOGLE_IDENTITY_CLIENT_ID ??
    "377011190789-11k6jp76qb833bb7dupu19lom5h2csjp.apps.googleusercontent.com",
  googleOptimizeContainerId:
    process.env.NX_GOOGLE_OPTIMIZE_CONTAINER_ID ?? "OPT-W3CG9SS",
  googleAnalyticsMeasurementId:
    process.env.NX_GOOGLE_ANALYTICS_MEASUREMENT_ID ?? "UA-18588876-14",
  recaptchaKey:
    process.env.NX_RECAPTCHA_KEY ?? "6LdwgHEpAAAAAHvSV0VHdWsU4FZcEj8Q4r64M3Me",
  recaptchaActionKey:
    process.env.NX_RECAPTCHA_ACTION_KEY ??
    "6LcadWQpAAAAAOp5rKK_zZKcE28O7Lu6g8HYzxZW",
  segmentAnalyticsKey:
    process.env.NX_SEGMENT_ANALYTICS_KEY ?? "OkQlg0e0CETJpUYqBxMj5xqVMM5MAMXU",
  appVersion: process.env.VITE_REACT_APP_VERSION ?? `local-${uuidv4()}`,
};
