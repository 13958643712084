import { ButtonWrap, IconName } from "@hopper-b2b/ui";
import { Dialog, DialogTitle, Icon } from "@mui/material";
import { PropsWithChildren } from "react";
import "./styles.scss";

interface AuthModalProps extends PropsWithChildren {
  onClose: () => void;
  open: boolean;
  modalTitle?: string;
}

export const AuthModal = ({
  modalTitle,
  onClose,
  open = false,
  children,
}: AuthModalProps) => {
  return (
    <Dialog onClose={onClose} open={open} className="auth-shared-modal">
      {modalTitle ? <DialogTitle>{modalTitle}</DialogTitle> : null}
      <div className="auth-module-content">
        <ButtonWrap
          aria-label="Close"
          onClick={onClose}
          className="close-button-icon"
        >
          <Icon
            className="mobile-modal-close-button"
            name={IconName.Close}
            alt=""
          />
        </ButtonWrap>
        {children}
      </div>
    </Dialog>
  );
};
