import React from 'react';

const ChevronUp = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.356834 5.29722C-0.0312349 5.69018 -0.0272654 6.32334 0.365699 6.7114C0.758664 7.09947 1.39182 7.0955 1.77989 6.70254L0.356834 5.29722ZM6.00606 0.999878L6.71758 0.297218C6.5297 0.106963 6.27345 -0.000122301 6.00606 -0.000122289C5.73867 -0.000122277 5.48242 0.106963 5.29453 0.297218L6.00606 0.999878ZM10.2322 6.70254C10.6203 7.0955 11.2535 7.09947 11.6464 6.7114C12.0394 6.32333 12.0434 5.69018 11.6553 5.29722L10.2322 6.70254ZM1.77989 6.70254L6.71758 1.70254L5.29453 0.297218L0.356834 5.29722L1.77989 6.70254ZM5.29453 1.70254L10.2322 6.70254L11.6553 5.29722L6.71758 0.297218L5.29453 1.70254Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ChevronUp;
