import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CtaButton } from "@marketing-site/components/_contentful";
import { theme } from "@marketing-site/styles";
import { IStyledCtaDescription } from "@marketing-site/types";

const CtaDescription = (props: any) => {
  return (
    <Wrapper id={props.sectionId}>
      <Container imageTop={props.imageTop}>
        <Image src={props.image.url} isImageSmall={props.isImageSmall} />
        <Content>
          <Headline>{props.headline}</Headline>
          <Description>{props.description}</Description>
          <CtaButton
            {...props.action}
            backgroundColor={props.backgroundColor}
          />
        </Content>
      </Container>
    </Wrapper>
  );
};

export default CtaDescription;

CtaDescription.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  imageTop: PropTypes.bool,
  image: PropTypes.object,
  isImageSmall: PropTypes.bool,
  action: PropTypes.object,
  sectionId: PropTypes.string,
};

const Wrapper = styled.div`
  padding-bottom: 10px;
  padding-top: 10px;
`;

const Container = styled.div<IStyledCtaDescription>`
  display: flex;
  flex-direction: ${(p: IStyledCtaDescription) =>
    p.imageTop ? "column" : "column-reverse"};
  align-items: center;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const Image = styled.img<IStyledCtaDescription>`
  ${(p: IStyledCtaDescription) => {
    return p.isImageSmall
      ? `
      width: 60px;
    `
      : `
      width: 70%;
      max-width: 420px;
    `;
  }}
  margin-top: 10px;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Headline = styled.h2`
  font-weight: 800;
  font-size: 28px;
  color: ${(p) => p.theme.colors.white};
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: ${theme.colors.white};
  text-align: center;
  margin-top: 0;
`;
