"use client";

import { ExperimentsProvider } from "@hopper-b2b/experiments";
import {
  AuthProvider,
  IAxiosInterceptors,
  UnauthorizationProvider,
  useAuthSessionContext,
} from "@hopper-b2b/hopper-auth";
import {
  HopperThemingProvider,
  branding,
  tenantContext as defaultTenantContext,
} from "@hopper-b2b/hopper-theming";
import {
  apiConfig,
  defaultLanguage,
  getCurrentLanguage,
} from "@hopper-b2b/hopper-utils";
import I18nProvider, {
  Translation,
  TranslationLanguage,
  en_hopperTranslations,
  es_hopperTranslations,
  fr_hopperTranslations,
  getLang,
  setLang,
} from "@hopper-b2b/i18n";
import {
  FeatureFlagsSessionIdRefresher,
  ISSRContextProps,
  ITenantContextProps,
  SSRContextProvider,
  SessionContextProvider,
  TenantContextProvider,
  UserSourceProvider,
  defaultSSRContext,
} from "@hopper-b2b/utilities";
import { useParams } from "next/navigation";
import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const DEFAULT_LANG = "en-US";

export type HopperProvidersProps = IAxiosInterceptors & {
  tenantContext?: ITenantContextProps;
  ssrContext?: ISSRContextProps;
  enableHDSTheme?: boolean;
};

export const HopperProviders = (
  props: PropsWithChildren<HopperProvidersProps>
) => {
  return (
    <AuthProvider>
      <HopperProvidersInternal {...props} />
    </AuthProvider>
  );
};

const HopperProvidersInternal = ({
  version,
  recaptchaActionKey,
  tenantContext,
  ssrContext = defaultSSRContext,
  enableHDSTheme,
  children,
}: PropsWithChildren<HopperProvidersProps>) => {
  const locale = getLang();

  const sessionContext = useAuthSessionContext();

  const [defaultLng, setDefaultLng] = useState<string>(getLang(DEFAULT_LANG));

  const params = useParams();

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();

    setDefaultLng(currentLanguage);
    setLang(
      currentLanguage === defaultLanguage ? DEFAULT_LANG : currentLanguage
    );
  }, []);

  const tenantTranslation: Translation = useMemo(() => {
    return {
      [TranslationLanguage.en]: en_hopperTranslations,
      [TranslationLanguage.es]: es_hopperTranslations,
      [TranslationLanguage.fr]: fr_hopperTranslations,
    };
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <html lang={locale} />
      </Helmet>
      <SSRContextProvider ssrContext={ssrContext} locale={locale}>
        <SessionContextProvider sessionContext={sessionContext}>
          <I18nProvider
            defaultLng={defaultLng}
            branding={branding}
            tenantTranslation={tenantTranslation}
            localeParam={params?.locale as string}
          >
            <ExperimentsProvider
              apiConfig={apiConfig}
              isLoggedIn={!!sessionContext}
            >
              <HopperThemingProvider enableHDSTheme={enableHDSTheme}>
                <TenantContextProvider
                  tenantContext={tenantContext || defaultTenantContext}
                >
                  <UserSourceProvider>
                    <FeatureFlagsSessionIdRefresher>
                      <UnauthorizationProvider
                        version={version}
                        recaptchaActionKey={recaptchaActionKey}
                      >
                        {children}
                      </UnauthorizationProvider>
                    </FeatureFlagsSessionIdRefresher>
                  </UserSourceProvider>
                </TenantContextProvider>
              </HopperThemingProvider>
            </ExperimentsProvider>
          </I18nProvider>
        </SessionContextProvider>
      </SSRContextProvider>
    </HelmetProvider>
  );
};
