import { removeTrailingSlash } from '@marketing-site/utils/helpers';
import { NavigationQuery } from '../queries';

export const AboutPageQuery = (slug: string, locale = 'en') => {
  return `query {
  pageCollection(limit: 1, locale: "${locale}", where: {slug_contains: "${removeTrailingSlash(
    slug
  )}" }) {
    items{
      slug
      showNavigation
      ${NavigationQuery}
      seo {
        title
        description
        sharedImage {
          description
          title
          url
        }
      }
      showBookingComponent
      bookingComponentPosition
      contentCollection(limit: 15) {
        items {
          ... on Primitives {
            title
            textList
            richText {
              json
            }
            shortText
            __typename
          }

          ... on HeroProduct {
            preIcon {
              title
              url
            }
            cornerColor
            headline
            heroProductDescription: description {
              json
            }
            ctaButton {
              preheadline
              headline
              postheadline
              link
              color
              isFilled
              size
            }
            image {
              title
              url
            }
            backgroundColor
            __typename
          }
          ... on Break {
            isLine
            waveColor
            waveType
            useGlobalColor
            __typename
          }
          ... on ImageTextDescription {
            preHeadline
            headline
            headlineHasFlare
            headlineFlareColor
            textDescription: description {
              json
            }
            image {
              title
              url
            }
            imageLocation
            actionLink: link {
              text
              image {
                title
                url
              }
              url
              isExternal
            }
            __typename
          }
          ... on CtaFooter {
            actionsCollection(limit: 2) {
              items {
                preheadline
                headline
                postheadline
                link
                color
                isFilled
                size
              }
            }
            headline
            headlineHasFlare
            hasLightBackground
            footerDescription: description {
              json
            }
            sectionId
            __typename
          }
          
          ... on GenericSection {
            title
            meta
            preheadline
            headline
            subheadline
            description {
              json
            }
            body : bodyCollection {
              items {
                ... on Link {
                  text
                  image {
                    title
                    url
                  }
                  url
                  isExternal
                }
              }
            }
            __typename
            
          }
        }
      }
    }
  }
}
`;
};
