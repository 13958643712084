import { getLang } from "./getLang";

/**
 * Returns just the root language, excludes country example: `en-US` -> `en`
 */
export const getRootLang = (defaultLng: string): string => {
  const re = /([a-z]*)\W?/i;
  const matches = getLang(defaultLng)?.match(re);
  return matches ? matches[1] : defaultLng;
};
