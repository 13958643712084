import { SendCodeRequestEnum, SendCodeResponseEnum } from "@b2bportal/auth-api";
import { sendCode } from "../../api";
import { AuthMachineContext } from "../context";
import { getPhoneNumber } from "../selectors";

export const sendCodeToPhoneService = (context: AuthMachineContext, _event) =>
  new Promise((resolve, reject) => {
    const phoneNumber = getPhoneNumber({ context }) as string;

    sendCode({
      phone: phoneNumber,
      SendCodeRequest: SendCodeRequestEnum.SendCodeSms,
    })
      .then((res) => {
        if (res.SendCodeResponse === SendCodeResponseEnum.SendCodeFailed) {
          reject("SendCodeResponseEnum.SendCodeFailed");
        } else {
          resolve(res);
        }
      })
      .catch((error) => reject(error));
  });
