import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import {
  IMonthBucket,
  IPassengerCounts,
  ITripTerminus,
  TripCategory,
} from "@hopper-b2b/types";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import "./EditFlightSearch.scss";
import { PATH_HOME } from "@hopper-b2b/hopper-utils";
import { Suggestion } from "@b2bportal/air-shopping-api";
import { LocationModal } from "../LocationModal";
import { CalendarPicker } from "../../common/CalendarPickerButton";

export interface IEditFlightSearchProps {
  assets?: Record<string, any>;
  isOneWay: boolean;
  origin: Suggestion | ITripTerminus;
  destination: Suggestion | ITripTerminus;
  departureDate: string;
  returnDate?: string;
  paxCount: IPassengerCounts;
  disabled?: boolean;
  updatePaxCount: (paxCount: IPassengerCounts) => void;
  updateTripCategory: (tripCategory: TripCategory) => void;
  handleOriginChange: (value: Suggestion | ITripTerminus | null) => void;
  handleDestinationChange: (value: Suggestion | ITripTerminus | null) => void;
  onOpen?: () => void;
  handleSearch: ({
    origin,
    destination,
    deptDate,
    retDate,
  }: {
    origin?: Suggestion | ITripTerminus | null;
    destination?: Suggestion | ITripTerminus | null;
    deptDate?: Date | null;
    retDate: Date | null;
  }) => void;
  tripCategory: TripCategory;
  monthsBucket: IMonthBucket[];
  priceBucket: string[];
  handleDepartureDate: (date: Date) => void;
  handleReturnDate: (date: Date) => void;
}

export const EditFlightSearch = ({
  isOneWay,
  origin,
  destination,
  departureDate,
  returnDate,
  assets,
  paxCount,
  disabled,
  updatePaxCount,
  updateTripCategory,
  handleOriginChange,
  handleDestinationChange,
  onOpen,
  handleSearch,
  tripCategory,
  monthsBucket,
  priceBucket,
  handleDepartureDate,
  handleReturnDate,
}: IEditFlightSearchProps) => {
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();

  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openCalendarModal, setOpenCalendarModal] = useState(false);

  const [tempDepartureDate, setTempDepartureDate] = useState<Date | null>(
    departureDate ? new Date(departureDate) : null
  );
  const [tempReturnDate, setTempReturnDate] = useState<Date | null>(
    returnDate ? new Date(returnDate) : null
  );

  const [tempOrigin, setTempOrigin] = useState<
    Suggestion | ITripTerminus | null
  >(origin);
  const [tempDestination, setTempDestination] = useState<
    Suggestion | ITripTerminus | null
  >(destination);

  useEffect(() => {
    setTempOrigin(origin);
    setTempDestination(destination);
  }, [origin, destination]);

  useEffect(() => {
    setTempDepartureDate(departureDate ? new Date(departureDate) : null);
    setTempReturnDate(returnDate ? new Date(returnDate) : null);
  }, [departureDate, returnDate]);

  const handleEditCalendar = useCallback(() => {
    if (matchesMobile) {
      setOpenCalendarModal(true);
    } else {
      history.push(PATH_HOME);
    }
  }, [history, matchesMobile, setOpenCalendarModal]);

  const handleEditLocation = useCallback(() => {
    if (matchesMobile) {
      setOpenLocationModal(true);
    } else {
      history.push(PATH_HOME);
    }
  }, [history, matchesMobile, setOpenLocationModal]);

  const resetModalUpdates = useCallback(() => {
    if (!openLocationModal) {
      setTempDepartureDate(departureDate ? new Date(departureDate) : null);
      setTempReturnDate(returnDate ? new Date(returnDate) : null);
    }
    if (!openCalendarModal) {
      setTempOrigin(origin);
      setTempDestination(destination);
    }
  }, [
    departureDate,
    destination,
    openCalendarModal,
    openLocationModal,
    origin,
    returnDate,
    setTempDepartureDate,
    setTempDestination,
    setTempOrigin,
    setTempReturnDate,
  ]);

  useEffect(() => {
    if (!openCalendarModal && !openLocationModal) {
      resetModalUpdates();
    }
  }, [openCalendarModal, openLocationModal, resetModalUpdates]);

  const originCode = useMemo(
    () => (origin?.id?.Id === "Flight" ? origin?.id?.code?.code : undefined),
    [origin]
  );
  const destinationCode = useMemo(
    () =>
      destination?.id?.Id === "Flight"
        ? destination?.id?.code?.code
        : undefined,
    [destination]
  );

  const locationLabel = useMemo(
    () =>
      `${origin?.label.split(",")[0]} (${originCode}) - ${
        destination?.label.split(",")[0]
      } (${destinationCode})`,
    [origin, destination]
  );

  const onComplete = useCallback(() => {
    handleOriginChange(tempOrigin);
    handleDestinationChange(tempDestination);
    handleDepartureDate(tempDepartureDate);
    handleReturnDate(tempReturnDate);
    setOpenCalendarModal(false);

    handleSearch({
      origin: tempOrigin,
      destination: tempDestination,
      deptDate: tempDepartureDate,
      retDate: tempReturnDate,
    });
  }, [
    tempOrigin,
    tempDestination,
    tempDepartureDate,
    tempReturnDate,
    setOpenCalendarModal,
    handleOriginChange,
    handleDestinationChange,
    handleDepartureDate,
    handleReturnDate,
    handleSearch,
  ]);

  return (
    <div className="locations-and-dates-section">
      <button className="locations-section" onClick={handleEditLocation}>
        {assets?.locationMarker ? (
          <img
            alt="Location Icon"
            className="location-section-icon"
            src={assets.locationMarker}
          />
        ) : (
          <IconComponent
            ariaLabel="Location Icon"
            name={IconName.B2BMapPin}
            className="location-section-icon"
          />
        )}
        <span className="locations-section-value">{locationLabel}</span>
      </button>
      <button className="dates-section" onClick={handleEditCalendar}>
        {assets?.calendar ? (
          <img
            alt="Date Icon"
            className="dates-section-icon"
            src={assets.calendar}
          />
        ) : (
          <IconComponent
            ariaLabel="Date Icon"
            className="dates-section-icon"
            name={IconName.Calendar}
          />
        )}
        <span className="dates-section-value">
          {isOneWay &&
            departureDate &&
            `${dayjs(departureDate).format("MMM DD")}`}
          {!isOneWay &&
            departureDate &&
            returnDate &&
            `${dayjs(departureDate).format("MMM DD")} - ${dayjs(
              returnDate
            ).format("MMM DD")}`}
        </span>
      </button>
      {openLocationModal && (
        <LocationModal
          open={openLocationModal}
          onClose={() => {
            setOpenLocationModal(false);
          }}
          origin={tempOrigin as Suggestion}
          destination={tempDestination as Suggestion}
          setOrigin={setTempOrigin}
          setDestination={setTempDestination}
          setReturnDate={setTempReturnDate}
          paxCount={paxCount}
          disabled={disabled}
          updatePaxCount={updatePaxCount}
          updateTripCategory={updateTripCategory}
          handleDestinationChange={handleDestinationChange}
          handleOriginChange={handleOriginChange}
          onOpen={onOpen}
          handleSearch={() => onComplete()}
          tripCategory={tripCategory}
          setOpenLocationModal={setOpenLocationModal}
          setOpenCalendarModal={setOpenCalendarModal}
          destinationCode={destinationCode}
          originCode={originCode}
        />
      )}
      {openCalendarModal && (
        <CalendarPicker
          onComplete={onComplete}
          tripCategory={tripCategory}
          open={openCalendarModal}
          departureDate={tempDepartureDate}
          returnDate={tempReturnDate}
          setDepartureDate={(date) => setTempDepartureDate(date)}
          setReturnDate={(date) => setTempReturnDate(date)}
          closePopup={() => {
            setOpenCalendarModal(false);
          }}
          disabled={disabled}
          trackingProperties={{
            origin: origin?.label || "",
            destination: destination?.label || "",
            tripCategory: tripCategory,
          }}
          isMobile={matchesMobile}
          months={monthsBucket}
          headerTitle="Select Dates"
          startDateLabel="Departure"
          endDateLabel="Return"
          prices={priceBucket}
        />
      )}
    </div>
  );
};
