export enum TranslationLanguage {
  en = "en",
  es = "es",
  fr = "fr",
  pt = "pt",
}

export type Translation = {
  [key in TranslationLanguage]?: unknown;
};
