import {
  CalendarBuckets,
  Suggestion,
  TripFilterEnum,
  TripType,
} from "@b2bportal/air-shopping-api";
import { fetchCalendar, trackEvent } from "@hopper-b2b/api";
import { PATH_FLIGHTS_SHOP } from "@hopper-b2b/hopper-utils";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  IMonthBucket,
  IPassengerCounts,
  ITripTerminus,
  PERFORMED_FLIGHT_SEARCH,
  ShopFilter,
  SliceStopCountFilter,
  TripCategory,
} from "@hopper-b2b/types";
import { ActionButton } from "@hopper-b2b/ui-core";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { transformDateBuckets } from "../../../utils/dateBuckets";
import { flightShopQueryBuilder } from "../../../utils/flightShopQueryBuilder";
import { useIsCalendarPredictionEnabled } from "../../context";
import { CalenderPickerButton } from "../common/CalendarPickerButton";
import { FlightLocationAutoComplete } from "./FlightLocationAutocomplete";
import { TravelerPicker } from "./TravelerPicker/TravelerPicker";
import { TripCategoryPicker } from "./TripCategoryPicker";
import { EditFlightSearch } from "./EditFlightSearch";
import { useDeviceTypes } from "@hopper-b2b/utilities";

const initialPaxCount: IPassengerCounts = {
  adultsCount: 1,
  childrenCount: 0,
  infantsInSeatCount: 0,
  infantsOnLapCount: 0,
};

export type FlightSearchFormProps = {
  tripCategory?: TripCategory;
  origin?: Suggestion;
  destination?: Suggestion;
  departureDate?: string;
  returnDate?: string;
  paxCount?: IPassengerCounts;
};

export interface IIcons {
  location?: string;
}

export type FlightSearchFormHandlers = {
  onDepDateChange?: (date: Date) => void;
  onDestChange?: (dest: Suggestion) => void;
  onOriginChange?: (origin: Suggestion) => void;
  onPaxChange?: (pax: IPassengerCounts) => void;
  onRetDateChange?: (date: Date) => void;
  onTripCatChange?: (tripCat: TripCategory) => void;
};

export type FlightSearchProps = {
  changeHandlers?: FlightSearchFormHandlers;
  icons?: IIcons;
  disabled?: boolean;
  hidePassengerPicker?: boolean;
  hideTripCategoryToggle?: boolean;
  enableCalendarPrediction?: boolean;
  initialValues?: FlightSearchFormProps;
  lgDirection?: "row" | "column";
  onOpen?: () => void;
  onSearch?: (
    searchUrl: string,
    searchFormProps: FlightSearchFormProps
  ) => void;
  openDatesModal?: boolean;
  onSetOpenCalendarModal?: (value: boolean) => void;
  isEditing?: boolean;
  originProp?: Suggestion | ITripTerminus;
  destinationProp?: Suggestion | ITripTerminus;
  tripCategoryProp?: TripCategory;
  onFiltersClick?: () => void;
  onEditSearch?: ({
    origin,
    destination,
    deptDate,
    retDate,
  }: {
    origin: Suggestion | ITripTerminus | null;
    destination: Suggestion | ITripTerminus | null;
    deptDate: Date | null;
    retDate: Date | null;
  }) => void;
};

export const FlightSearch = ({
  changeHandlers,
  disabled,
  hidePassengerPicker,
  hideTripCategoryToggle,
  enableCalendarPrediction: enableCalendarPredictionProps,
  initialValues,
  lgDirection = "row",
  onOpen,
  onSearch,
  onEditSearch,
  icons,
  openDatesModal,
  onSetOpenCalendarModal,
  isEditing,
  originProp,
  destinationProp,
  tripCategoryProp,
}: FlightSearchProps) => {
  const { t } = useI18nContext();
  const enableCalendarPredictionFlag = useIsCalendarPredictionEnabled();
  const { matchesMobile } = useDeviceTypes();

  const enableCalendarPrediction =
    enableCalendarPredictionProps ?? enableCalendarPredictionFlag;

  const [tripCategory, setTripCategory] = useState(
    initialValues?.tripCategory ?? TripCategory.ROUND_TRIP
  );

  const [origin, setOrigin] = useState<Suggestion>(initialValues?.origin);
  const [destination, setDestination] = useState<Suggestion>(
    initialValues?.destination
  );

  const [paxCount, setPaxCount] = useState<IPassengerCounts>({
    adultsCount:
      initialValues?.paxCount?.adultsCount ?? initialPaxCount.adultsCount,
    childrenCount:
      initialValues?.paxCount?.childrenCount ?? initialPaxCount.childrenCount,
    infantsInSeatCount:
      initialValues?.paxCount?.infantsInSeatCount ??
      initialPaxCount.infantsInSeatCount,
    infantsOnLapCount:
      initialValues?.paxCount?.infantsOnLapCount ??
      initialPaxCount.infantsOnLapCount,
  });

  const [departureDate, setDepartureDate] = useState<string>(
    initialValues?.departureDate ?? null
  );
  const [returnDate, setReturnDate] = useState<string>(
    initialValues?.returnDate ?? null
  );

  const [monthsBucket, setMonthsBucket] = useState<IMonthBucket[]>();
  const [priceBucket, setPriceBucket] = useState<string[]>([]);

  const isRow = lgDirection === "row";

  const originCode = useMemo(
    () => (origin?.id?.Id === "Flight" ? origin?.id?.code?.code : undefined),
    [origin]
  );
  const originPropCode = useMemo(
    () =>
      originProp?.id?.Id === "Flight" ? originProp?.id?.code?.code : undefined,
    [originProp]
  );
  const destinationCode = useMemo(
    () =>
      destination?.id?.Id === "Flight"
        ? destination?.id?.code?.code
        : undefined,
    [destination]
  );
  const destinationPropCode = useMemo(
    () =>
      destinationProp?.id?.Id === "Flight"
        ? destinationProp?.id?.code?.code
        : undefined,
    [destinationProp]
  );

  const isSearchReady = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? originCode && destinationCode && departureDate
      : originCode && destinationCode && departureDate && returnDate;
  }, [departureDate, destinationCode, originCode, returnDate, tripCategory]);

  const headerTitle = useMemo(
    () =>
      tripCategory === TripCategory.ONE_WAY
        ? t("searchControl.calendarOneWay")
        : t("searchControl.calendarRoundTrip"),
    [t, tripCategory]
  );

  const handleDepartureDate = useCallback(
    (date: Date) => {
      if (date) {
        setDepartureDate(date.toString());
        if (returnDate) {
          setReturnDate(undefined);
        }
      }

      if (changeHandlers?.onDepDateChange) {
        changeHandlers.onDepDateChange(date);
      }
    },
    [changeHandlers, returnDate]
  );

  const handleDestinationChange = useCallback(
    (dest: Suggestion) => {
      setDestination(dest);

      if (changeHandlers?.onDestChange) {
        changeHandlers.onDestChange(dest);
      }
    },
    [changeHandlers]
  );

  const handleOriginChange = useCallback(
    (origin: Suggestion) => {
      setOrigin(origin);

      if (changeHandlers?.onOriginChange) {
        changeHandlers.onOriginChange(origin);
      }
    },
    [changeHandlers]
  );

  const handleReturnDate = useCallback(
    (date: Date) => {
      if (date) {
        setReturnDate(date.toString());
      }

      if (changeHandlers?.onRetDateChange) {
        changeHandlers.onRetDateChange(date);
      }
    },
    [changeHandlers]
  );

  const updatePaxCount = useCallback(
    (guestCount: IPassengerCounts) => {
      setPaxCount(guestCount);

      if (changeHandlers?.onPaxChange) {
        changeHandlers.onPaxChange(guestCount);
      }
    },
    [changeHandlers]
  );

  const updateTripCategory = useCallback(
    (tripCategory: TripCategory) => {
      setTripCategory(tripCategory);
      if (tripCategory === TripCategory.ONE_WAY && returnDate) {
        setReturnDate(undefined);
      }
      if (changeHandlers?.onTripCatChange) {
        changeHandlers.onTripCatChange(tripCategory);
      }
    },
    [changeHandlers, returnDate]
  );

  useEffect(() => {
    const originCode =
      origin?.id?.Id === "Flight" ? origin?.id?.code : undefined;
    const originPropCode =
      originProp?.id?.Id === "Flight" ? originProp?.id?.code : undefined;
    const destinationCode =
      destination?.id?.Id === "Flight" ? destination?.id?.code : undefined;
    const destinationPropCode =
      destinationProp?.id?.Id === "Flight"
        ? destinationProp?.id?.code
        : undefined;

    const updateCalendarBuckets = async () => {
      const req: CalendarBuckets = {
        route: {
          origin: originCode || originPropCode,
          destination: destinationCode || destinationPropCode,
        },
        tripType:
          tripCategory === TripCategory.ONE_WAY
            ? TripType.one_way
            : TripType.round_trip,
        filter: {
          TripFilter: TripFilterEnum.NoFilter,
        },
      };
      const res = await fetchCalendar(req);

      const monthBuckets = transformDateBuckets(res.departureDateBuckets);
      const priceBuckets = res.departureDateBuckets.reduce((acc, bucket) => {
        acc.push(bucket.legend);
        return acc;
      }, []);

      setPriceBucket(priceBuckets);
      setMonthsBucket(monthBuckets);
    };

    if (
      enableCalendarPrediction &&
      (originCode || originPropCode) &&
      (destinationCode || destinationPropCode)
    ) {
      updateCalendarBuckets();
    }
  }, [
    originCode,
    originPropCode,
    destinationCode,
    destinationPropCode,
    tripCategory,
    enableCalendarPrediction,
  ]);

  const searchUrl = useMemo(() => {
    const shopQuery = flightShopQueryBuilder({
      origin: originCode,
      destination: destinationCode,
      departureDate,
      returnDate,
      isOneWay: tripCategory === TripCategory.ONE_WAY,
      paxCount,
    });
    return `${PATH_FLIGHTS_SHOP}?${shopQuery}`;
  }, [
    departureDate,
    destinationCode,
    originCode,
    paxCount,
    returnDate,
    tripCategory,
  ]);

  const handleSearch = useCallback(() => {
    const searchFormProps: FlightSearchFormProps = {
      tripCategory,
      destination,
      origin,
      departureDate,
      returnDate,
      paxCount,
    };

    trackEvent({
      eventName: PERFORMED_FLIGHT_SEARCH,
      properties: {
        searched_pax_total: Object.values(paxCount).reduce(
          (a: number, b: number) => a + b,
          0
        ),
        searched_pax_total_infant_lap: paxCount.infantsOnLapCount,
        total_stops: SliceStopCountFilter.ANY_NUMBER,
        trip_filter: ShopFilter.NoFilter,
        trip_type: returnDate ? "round_trip" : "one_way",
      },
    });
    onSearch
      ? onSearch(searchUrl, searchFormProps)
      : (window.location.href = searchUrl);
  }, [
    departureDate,
    destination,
    onSearch,
    origin,
    paxCount,
    returnDate,
    searchUrl,
    tripCategory,
  ]);

  if (isEditing && matchesMobile) {
    return (
      <EditFlightSearch
        isOneWay={
          tripCategory === TripCategory.ONE_WAY ||
          tripCategoryProp === TripCategory.ONE_WAY
        }
        origin={origin || originProp}
        destination={destination || destinationProp}
        departureDate={departureDate}
        handleDepartureDate={handleDepartureDate}
        returnDate={returnDate}
        handleReturnDate={handleReturnDate}
        passengerCount={paxCount}
        paxCount={paxCount}
        disabled={disabled}
        updatePaxCount={updatePaxCount}
        updateTripCategory={updateTripCategory}
        handleOriginChange={handleOriginChange}
        handleDestinationChange={handleDestinationChange}
        onOpen={onOpen}
        handleSearch={onEditSearch}
        monthsBucket={monthsBucket}
        priceBucket={priceBucket}
        tripCategory={tripCategory || tripCategoryProp}
      />
    );
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className="flight-search-controls"
    >
      {hidePassengerPicker && hideTripCategoryToggle ? null : (
        <Grid item>
          <Grid container direction="row" spacing={2}>
            {hidePassengerPicker ? null : (
              <Grid item xs={12} sm="auto">
                <TravelerPicker
                  paxCount={paxCount}
                  disabled={disabled}
                  updatePaxCount={updatePaxCount}
                />
              </Grid>
            )}
            {hideTripCategoryToggle ? null : (
              <Grid item xs={12} sm="auto">
                <TripCategoryPicker
                  tripCategory={tripCategory}
                  disabled={disabled}
                  onChange={updateTripCategory}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12} sm={6} lg={isRow ? 3 : 6}>
            <FlightLocationAutoComplete
              id="origin"
              defaultValue={origin}
              label={t?.("searchControl.whereFrom")}
              onChange={handleOriginChange}
              onOpen={onOpen}
              disabled={disabled}
              icon={icons?.location}
              additionalSearchControl={{
                activeControl: "origin",
                destination: destinationCode || undefined,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={isRow ? 3 : 6}>
            <FlightLocationAutoComplete
              id="destination"
              defaultValue={destination}
              label={t?.("searchControl.whereTo")}
              onChange={handleDestinationChange}
              onOpen={onOpen}
              disabled={disabled}
              icon={icons?.location}
              additionalSearchControl={{
                activeControl: "destination",
                origin: originCode || undefined,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={9} lg={isRow ? 4 : 9}>
            <CalenderPickerButton
              returnDate={returnDate ? new Date(returnDate) : null}
              departureDate={departureDate ? new Date(departureDate) : null}
              tripCategory={tripCategory}
              setDepartureDate={handleDepartureDate}
              setReturnDate={handleReturnDate}
              trackingProperties={{
                origin: origin?.label || "",
                destination: destination?.label || "",
                tripCategory: tripCategory,
              }}
              months={monthsBucket}
              prices={priceBucket}
              headerTitle={headerTitle}
              disabled={disabled}
              openDatesModal={openDatesModal}
              onSetOpenCalendarModal={onSetOpenCalendarModal}
            />
          </Grid>

          <Grid item xs={12} sm={3} lg={isRow ? 2 : 3}>
            <ActionButton
              onClick={handleSearch}
              fullWidth
              disabled={disabled || !isSearchReady}
              size="large"
              message={t?.("searchButton")}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
