import { DoneInvokeEvent, assign } from "xstate";
import { AuthMachineContext, initialAuthContext } from "./context";
// TODO: Move getObjectKeysAsObject to utilities
import { getObjectKeysAsObject, setContextWithKey } from "@hopper-b2b/checkout";
import {
  SetPhoneNumberEvent,
  SetEmailEvent,
  SetErrorEvent,
  SetPreviousUserTrackingEvent,
} from "./events";
import {
  FinalizedRequired,
  MatchingAccountsFound,
  ResendCodeSucceeded,
  SendCodeSucceeded,
} from "@b2bportal/auth-api";
import { AuthType } from "../types/tracking";

export const AuthActions = {
  setSendCodeResponse: assign(
    (
      context: AuthMachineContext,
      event: DoneInvokeEvent<SendCodeSucceeded>
    ) => {
      context.authStateToken = event.data.authStateToken;
      context.verificationAlternative = event.data.verificationAlternative;
      return context;
    }
  ),
  setServiceOnError: assign(
    (context: AuthMachineContext, event: DoneInvokeEvent<string>) => {
      context.error = event.data;
      return context;
    }
  ),
  resetPhoneNumber: assign((context: AuthMachineContext, _event) => {
    context.phoneNumber = undefined;
    return context;
  }),
  setResendCodeResponse: assign(
    (
      context: AuthMachineContext,
      event: DoneInvokeEvent<ResendCodeSucceeded>
    ) => {
      context.authStateToken = event.data.authStateToken;
      return context;
    }
  ),
  setVerifyCodeFinalizeResponse: assign(
    (
      context: AuthMachineContext,
      event: DoneInvokeEvent<FinalizedRequired>
    ) => {
      const finalizedRequiredData = event.data as FinalizedRequired;
      context.authStateToken = finalizedRequiredData.authStateToken;
      context.missingFields = finalizedRequiredData.missingFields;
      return context;
    }
  ),
  setPhoneNumber: assign(
    (context: AuthMachineContext, event: SetPhoneNumberEvent) => {
      context.phoneNumber = event.phoneNumber;
      return context;
    }
  ),
  setFinalizeMatchingAccountResponse: assign(
    (
      context: AuthMachineContext,
      event: DoneInvokeEvent<MatchingAccountsFound>
    ) => {
      context.authStateToken = event.data.authStateToken;
      context.userMatch = event.data.userMatch;
      return context;
    }
  ),
  setEmail: assign((context: AuthMachineContext, event: SetEmailEvent) => {
    context.email = event.email;
    return context;
  }),
  setPreviousUserTracking: assign(
    (
      context: AuthMachineContext,
      event: DoneInvokeEvent<SetPreviousUserTrackingEvent>
    ) => {
      context.previousUserTrackingToken = event.data.previousUserTracking;
      return context;
    }
  ),
  resetAuthContext: assign(() => {
    return { ...initialAuthContext };
  }),
  setGoogleAuthType: assign((ctx: AuthMachineContext) =>
    setContextWithKey(ctx, "authType", AuthType.Google)
  ),
  setPhoneAuthType: assign((ctx: AuthMachineContext) =>
    setContextWithKey(ctx, "authType", AuthType.Phone)
  ),
  setEmailAuthType: assign((ctx: AuthMachineContext) =>
    setContextWithKey(ctx, "authType", AuthType.Email)
  ),

  // NOT USED
  setError: assign((context: AuthMachineContext, event: SetErrorEvent) => {
    context.error = event.error;
    return context;
  }),
};

export const AuthActionTypes = getObjectKeysAsObject(AuthActions);
