import { useFetchContentQuery } from "@marketing-site/api/urbana/contentful/apiSlice";
import { GiftCardPageQuery } from "@marketing-site/api/urbana/contentful/queries/giftcard.query";
import { SEO } from "@marketing-site/components/_contentful";
import { HeroCareers } from "@marketing-site/components/_contentful/hero/HeroCareers";
import { IHeroProductAlias } from "@marketing-site/components/_contentful/hero/HeroProduct";
import { NavigationBar } from "@marketing-site/components/navbar/NavigationBar";
import { IContentChild } from "@marketing-site/types/shared.model";
import { mapComponents } from "@marketing-site/utils";
import React, { Fragment } from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";
import styled from "styled-components";
import { LoadingPage } from "../Page/LoadingPage";
import Footer from "@whopper/footer";

export interface IGiftCardProps {
  locale?: string;
}
export const GiftCardPage = () => {
  const pageData: IGiftCardProps = useParams();
  const location = useLocation();
  const locale = pageData.locale || "en";
  const path = location.pathname;
  const slug = "gift-card";

  const { data: contentfulData, isLoading: contentfulLoading } =
    useFetchContentQuery({
      query: GiftCardPageQuery(slug, locale),
      space: "hopperCom",
    });

  const getBody = () => {
    if (contentfulLoading || !contentfulData) {
      return <LoadingPage />;
    } else {
      const page = contentfulData;
      const children = page.contentCollection.items.map(
        (child: IContentChild<any>) => child
      );

      const careersPageMap = {
        HeroProduct: (props: React.PropsWithChildren<IHeroProductAlias>) => {
          return <HeroCareers {...props} />;
        },
      };

      const childComponents = mapComponents(children, slug, careersPageMap);

      return (
        <>
          <SEO {...page.seo} locale={locale} canonical={path} />
          <NavigationBar contentfulNavProps={page.navigation} />
          <Body>
            {childComponents.map((component, i) => {
              return <Fragment key={i}>{component}</Fragment>;
            })}
          </Body>
          <Footer {...page.navigation.footer} />
        </>
      );
    }
  };

  return <React.Fragment>{getBody()}</React.Fragment>;
};

const Body = styled.div`
  position: relative;
  z-index: 10;

  h2,
  h3 {
    font-weight: 800;
    line-height: 150%;
  }

  a,
  b {
    font-weight: 700;
  }

  #lets-get-started {
    > div:last-child > div {
      h2,
      div {
        align-items: center;
        display: flex;
        justify-content: center;
      }

      h2 {
        font-size: 42px;
      }
    }

    a {
      color: white;
    }
  }
`;
