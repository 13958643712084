import bunny from './running_bunny.gif';
import clsx from 'clsx';
import React from 'react';

export interface ILoadingBunnyProps {
  className?: string;
  style?: React.CSSProperties;
}

export const LoadingBunny = ({ className, style }: ILoadingBunnyProps) => (
  <img
    className={clsx('loading-bunny', className)}
    src={bunny}
    style={style || {}}
    alt="loading..."
  />
);
