import { RefreshSessionRequest, authApi } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";
import { setRefreshToken } from "./localStorage";

export const refreshSession = async (request: RefreshSessionRequest) => {
  try {
    const response = await authApi(axiosInstance).apiV0AuthSessionRefreshPut(
      request
    );
    if (response.headers["hopper-refresh"]) {
      setRefreshToken(response.headers["hopper-refresh"]);
    }
    return response.data;
  } catch (error) {
    console.error("Refresh session error:", error);
    throw Error("Refresh session error");
  }
};
