import { removeTrailingSlash } from '@marketing-site/utils/helpers';
import { NavigationQuery } from '../queries';

export const CulturePageQuery = (slug: string, locale: string) => {
  return `query {
    pageCollection(limit: 1, locale: "${locale}", where: {slug_contains: "${removeTrailingSlash(
    slug
  )}" }) {
      items{
        slug
        showNavigation
        ${NavigationQuery}
        seo {
          title
          description
          sharedImage {
            description
            title
            url
          }
        }
        showBookingComponent
        bookingComponentPosition
        contentCollection(limit: 20) {
          items {
            ... on HeroProduct {
              preIcon {
                title
                url
              }
              cornerColor
              headline
              heroProductDescription: description {
                json
              }
              ctaButton {
                preheadline
                headline
                postheadline
                link
                openInNewTab
                color
                isFilled
                size
              }
              secondCtaButton {
                preheadline
                headline
                postheadline
                link
                openInNewTab
                color
                isFilled
                size
              }
              image {
                title
                url
              }
              backgroundColor
              __typename
            }
            ... on Break {
              isLine
              waveColor
              waveType
              useGlobalColor
              __typename
            }
            ... on SectionHeader {
              preheadline
              headline
              headlineHasFlares
              flareColor
              subheader: description {
                json
              }
              __typename
            }
            ... on DescriptionPointsSection {
              showPreheadline
              preheadline
              numberPerRow
              alignment
              descriptionPointsCollection(limit: 6) {
                items {
                  ... on DescriptionPoint {
                    headline
                    description {
                      json
                    }
                    actionLink: link {
                      text
                      image {
                        title
                        url
                      }
                      url
                      isExternal
                    }
                    icon {
                      title
                      url
                    }
                  }
                }
              }
              __typename
            }
            ... on DescriptionPoint {
              headline
              description {
                json
              }
              actionLink: link {
                text
                image {
                  title
                  url
                }
                url
                isExternal
              }
              icon {
                title
                url
              }
              __typename
            }
            ... on ImageTextDescription {
              preHeadline
              headline
              headlineHasFlare
              headlineFlareColor
              textDescription: description {
                json
              }
              image {
                title
                url
              }
              preIcon {
                title
                url
              }
              imageLocation
              actionLink: link {
                text
                image {
                  title
                  url
                }
                url
                isExternal
              }
              __typename
            }
            ... on GenericSection {
              title
              meta
              preheadline
              headline
              flareColor
              subheadline
              description {
                json
              }
              preIcon {
                title
                url
              }
              body : bodyCollection(limit: 40) {
                items {
                  ... on DescriptionPoint {
                    headline
                    description {
                      json
                    }
                    actionLink: link {
                      text
                      image {
                        title
                        url
                      }
                      url
                      isExternal
                    }
                    icon {
                      title
                      url
                    }
                  }
                  ... on DescriptionPointsSection {
                    showPreheadline
                    numberPerRow
                    alignment
                    preheadline
                    descriptionPointsCollection(limit: 6) {
                      items {
                        headline
                        description {
                          json
                        }
                        icon {
                          title
                          url
                        }
                      }
                    }
                  }
                  ... on FeatureCard {
                    imageLg {
                      title
                      url
                    }
                    imageSm {
                      title
                      url
                    }
                    title
                    subtitle
                    tags
                    cardDescription: description
                    tagColor
                    __typename
                  }
                }
              }
              bodyMedia : bodyMediaCollection(limit: 18) {
                items {
                  ... on Asset {
                    title
                    description
                    url
                  }
                }
              }
              __typename
              
            }
            ... on CtaButton {
                preheadline
                headline
                postheadline
                link
                openInNewTab
                color
                isFilled
                size
                __typename
              }
            ... on CtaFooter {
              actionsCollection(limit: 2) {
                items {
                  preheadline
                  headline
                  postheadline
                  link
                  color
                  isFilled
                  size
                }
              }
              headline
              headlineHasFlare
              hasLightBackground
              footerDescription: description {
                json
              }
              sectionId
              __typename
            }
            
          }
        }
      }
    }
  }
`;
};
