import { authApi, SendCodeRequest } from "@b2bportal/auth-api";
import { axiosInstance } from "@hopper-b2b/api";

export const sendCode = async (request: SendCodeRequest) => {
  try {
    const res = await authApi(axiosInstance).apiV0AuthCodeSendPost(request);
    return res.data;
  } catch (error) {
    console.error("Send code error", error);
    throw Error("Send code error");
  }
};
