"use client";

import React, { createContext, useContext } from "react";
import { type Slots, defaultSlots } from "./slots";

export const SlotContext = createContext<Slots>(defaultSlots);

interface Props {
  children: React.ReactNode;
  slots: Partial<Slots>;
}

export function SlotProvider({ children, slots }: Props) {
  // Allow for nested SlotProviders
  const outerSlots = useContext(SlotContext);

  return (
    <SlotContext.Provider value={{ ...defaultSlots, ...outerSlots, ...slots }}>
      {children}
    </SlotContext.Provider>
  );
}
