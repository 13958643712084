import React from "react";
import styled from "styled-components";
import { media, theme } from "@marketing-site/styles";
import { RichTextToHtml } from "@marketing-site/utils";
import { CtaButton } from "@marketing-site/components/_contentful";
import { IHeroProduct, IRichText } from "@marketing-site/types/shared.model";
import { useLocation } from "react-router-dom-v5-compat";

const CornerSVG = (props: { cornerColor: string }) => {
  return (
    <svg
      width="836"
      height="586"
      viewBox="0 166 836 586"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M836 0H0C7.03816 24.7539 33.685 96.5284 118.812 163.998C229.841 251.997 369.878 269.997 512.915 373.996C608.857 443.752 664.955 549.994 783.986 576.994C801.149 580.934 818.512 583.94 836 586V0Z"
        fill={(theme.colors as any)[props.cornerColor]}
      />
    </svg>
  );
};

export interface IHeroProductAlias extends Omit<IHeroProduct, "description"> {
  heroProductDescription: IRichText;
}

const HeroProduct = ({
  cornerColor,
  preIcon,
  headline,
  heroProductDescription,
  ctaButton,
  image,
}: IHeroProductAlias) => {
  const { pathname } = useLocation();

  const showCornerWave = !pathname.match("/travel");
  return (
    <Wrapper compact={!showCornerWave}>
      {showCornerWave && (
        <CornerSVGWrapper>
          <CornerSVG cornerColor={cornerColor} />
        </CornerSVGWrapper>
      )}

      <Container>
        <Content>
          <Text>
            <PreIcon src={preIcon.url} />
            <Headline>{headline}</Headline>
            <Description>
              {RichTextToHtml(heroProductDescription.json)}
            </Description>
          </Text>
          {ctaButton && (
            <CtaButton {...ctaButton} backgroundColor={theme.colors.white} />
          )}
        </Content>
        <Image src={image?.url} />
      </Container>
    </Wrapper>
  );
};

export default HeroProduct;

type WrapperStyleProps = {
  compact?: boolean;
};

const Wrapper = styled.div<WrapperStyleProps>`
  position: relative;
  padding: ${(p) => (p.compact ? "0" : "40px 0")};
`;

const CornerSVGWrapper = styled.div`
  // Styles are different for desktop and mobile
  // Mobile positioning is more "tucked away" than desktop
  position: absolute;
  right: 0;
  top: -6px;
  svg {
    width: 600px;
  }
  ${media.desktop`
    svg {
      width: 300px;
      height: 300px;
    }
  `};
`;

const Container = styled.div`
  position: relative;
  display: flex;
  z-index: 20;
  width: 94%;
  max-width: 1080px;
  flex-direction: row;

  margin: 0 auto;
  ${media.tablet`
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const Text = styled.div`
  margin-left: 30px;
  margin-right: 2px;
`;

const PreIcon = styled.img`
  margin-top: 40px;
  width: 32px;
  height: 32px;
`;

const Headline = styled.h1`
  ${media.tablet`
      width: 280px;
  `};
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 42px;
  line-height: 46px;
`;

const Description = styled.div`
  margin-bottom: 12px;
`;

const Image = styled.img`
  max-width: 464px;
  z-index: 5;
`;
