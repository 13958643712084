import React from 'react';

export const StarIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.787 20.75C14.2682 20.749 13.762 20.5899 13.3358 20.2939L10.0003 18.0024L6.66474 20.2939C6.23152 20.5914 5.7175 20.7485 5.19217 20.7441C4.66684 20.7396 4.15555 20.5738 3.72743 20.269C3.29931 19.9643 2.975 19.5352 2.79837 19.04C2.62174 18.5447 2.6013 18.0071 2.73981 17.4998L3.81724 13.5551L0.942263 11.2024C0.552047 10.8834 0.264353 10.4564 0.115103 9.97473C-0.0341481 9.49304 -0.0383404 8.97802 0.103044 8.49397C0.244429 8.00992 0.525129 7.57828 0.910096 7.25294C1.29506 6.92761 1.76724 6.723 2.26767 6.66465H2.26829L5.76383 6.25747L7.68476 2.2149C7.89282 1.77653 8.22081 1.40622 8.63066 1.14692C9.0405 0.887632 9.51541 0.75 10.0003 0.75C10.4851 0.75 10.96 0.887632 11.3699 1.14692C11.7797 1.40622 12.1077 1.77653 12.3157 2.2149L14.2367 6.25747L17.7322 6.66465C18.2327 6.7229 18.705 6.92743 19.09 7.25273C19.4751 7.57802 19.7558 8.00965 19.8973 8.49373C20.0387 8.9778 20.0346 9.49286 19.8854 9.97459C19.7362 10.4563 19.4485 10.8834 19.0582 11.2024L16.1833 13.5551L17.2607 17.501C17.3635 17.8821 17.3775 18.2817 17.3016 18.6691C17.2257 19.0564 17.0619 19.4211 16.8229 19.7351C16.5838 20.049 16.2759 20.3038 15.923 20.4798C15.57 20.6558 15.1813 20.7482 14.787 20.75Z"
        fill="#FDC93F"
      />
    </svg>
  );
};
