import React from 'react';
import styled from 'styled-components';
import { media, theme } from '@marketing-site/styles';
import { GetIcon, RichTextToHtml } from '@marketing-site/utils';
import HopperLogo from '@marketing-site/static/icons/hopperLogo.png';
import { IHeroProduct, IRichText } from '@marketing-site/types/shared.model';
import { QRCode } from 'react-qrcode-logo';
import { generateDeepLinkUrl, setLink } from '@marketing-site/utils/helpers';
import { isMobile } from 'react-device-detect';
import CtaButton from '../cta-button';
import { withFlare } from '@marketing-site/utils/hoc/withFlare';

export interface IHeroDownloadAlias extends Omit<IHeroProduct, 'description'> {
  heroProductDescription: IRichText;
  scanToDownloadText: string;
  getTheAppText: string;
}

export const HeroDownload = ({
  children,
  ...props
}: React.PropsWithChildren<IHeroDownloadAlias>) => {
  const deepLinkUrl = generateDeepLinkUrl(window.location.search, isMobile);
  const HeadlineWithFlare = withFlare(Headline);

  const CTAButtonProps = {
    headline: props.getTheAppText,
    link: deepLinkUrl,
    color: 'skyblue',
    isFilled: true,
  };

  return (
    <Background>
      <Container>
        <Logo href={setLink('/')}>
          <LogoIcon src={HopperLogo} />
        </Logo>
        <Content>
          <Text>
            {children}
            <HeadlineWithFlare>{props.headline}</HeadlineWithFlare>
            <Description>
              {RichTextToHtml(props.heroProductDescription.json)}
            </Description>
          </Text>
          <QRCodeWrapper>
            {isMobile ? (
              <CtaButton {...CTAButtonProps} />
            ) : (
              <>
                <QRCode
                  value={deepLinkUrl}
                  size={232}
                  quietZone={5}
                  eyeRadius={4}
                  qrStyle="dots"
                />
                <QrCodeText>
                  {props.scanToDownloadText}
                  {GetIcon('chevron-up')}
                </QrCodeText>
              </>
            )}
          </QRCodeWrapper>
        </Content>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${theme.colors.white};
  padding-top: 20px;
  padding-bottom: 20px;
  height: 80vh;
  ${media.tablet`
    padding-bottom: 40px;
  `};
  ${media.phone_large`
    height: 100vh;
    overflow: scroll;
  `};
`;

const Container = styled.div`
  display: flex;
  width: 94%;
  max-width: 880px;
  flex-direction: column;
  margin: 10vh auto;
  ${media.tablet`
    margin: auto;
  `};
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.tablet`
    flex-direction: column;
    align-items: center;
  `}
`;

const Text = styled.div`
  max-width: 520px;
  ${media.tablet`
    max-width: auto;
    margin: auto;
  `}
`;

const Logo = styled.a`
  margin-bottom: 42px;
  max-width: 150px;
`;

const LogoIcon = styled.img`
  width: 150px;
`;

const Headline = styled.h1`
  ${media.tablet`
    font-size: 54px;
    line-height: 60px;
  `};
  margin-top: 32px;
  margin-bottom: 12px;
`;

const Description = styled.div`
  margin-bottom: 12px;
  p {
    font-size: 22px;
    line-height: 34px;
    ${media.tablet`
      font-size: 20px;
    `};
  }
`;

const QRCodeWrapper = styled.div`
  margin-top: 28px;
`;

const QrCodeText = styled.h3`
  color: ${theme.colors.skyblue};
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.2px;
  margin-left: 6px;
  margin-top: 6px;
  max-width: 238px;

  svg {
    margin-left: 12px;
    margin-bottom: 2px;
  }
`;
