import React from 'react';
import styled from 'styled-components';
import {
  IDescriptionPoint,
  IDescriptionPointsSection,
} from '@marketing-site/types/description-points.model';
import { IGenericSection } from '@marketing-site/types/shared.model';
import { RichTextToHtml } from '@marketing-site/utils';
import { theme } from '@marketing-site/styles';

export const DescriptionPointsTableSection = (
  props: IGenericSection<IDescriptionPointsSection>
) => {
  // this assumes the number of points in each section is the same.
  const numOfRows =
    props.body.items[0].descriptionPointsCollection.items.length;
  let rowCounter = 0;
  let tableData = [];

  while (rowCounter < numOfRows) {
    let rowData: IDescriptionPoint[] = [];
    props.body.items.forEach((section) => {
      rowData.push(section.descriptionPointsCollection.items[rowCounter]);
    });
    tableData.push(rowData);
    rowCounter++;
  }

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            {props.body.items.map((item, i) => (
              <TableHead key={`tableHeader-${i}`}>{item.preheadline}</TableHead>
            ))}
          </tr>
        </thead>
        <TableBody>
          {tableData.map((rowData, i) => {
            return (
              <TableRow key={`tableRow-${i}`}>
                {rowData.map((cellData: IDescriptionPoint, j) => {
                  return (
                    <TableCell key={`tableCell-${i}-${j}`}>
                      <CellItem>
                        {cellData.icon && (
                          <Icon
                            src={cellData.icon.url}
                            title={
                              cellData.icon.title || cellData.icon.description
                            }
                            alt={
                              cellData.icon.description || cellData.icon.title
                            }
                          />
                        )}
                        {RichTextToHtml(cellData.description.json)}
                      </CellItem>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  padding-bottom: 12px;
`;

const Table = styled.table`
  margin: 0 auto;
  border-collapse: collapse;
`;

const TableHead = styled.th`
  color: ${theme.colors.grey[400]};
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  padding-bottom: 18px;
`;

const TableBody = styled.tbody`
  border-bottom: solid 3px blue;
  border-collapse: collapse;

  :last-child {
    border-bottom: none;
  }
`;

const TableRow = styled.tr`
  border-bottom: solid 3px ${theme.colors.grey[200]};
  :last-child {
    border-bottom: none;
  }
`;

const TableCell = styled.td`
  padding-top: 18px;
  vertical-align: baseline;
`;

const CellItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 380px;
  p {
    margin-left: 14px;
    margin-top: 0;
  }
`;

const Icon = styled.img`
  width: 22px;
  height: 22px;
`;
