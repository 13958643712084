import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  CreateSessionResponseEnum,
} from "@b2bportal/auth-api";
import { createSession } from "../../api";
import { AuthMachineContext } from "../context";
import { getAuthStateToken } from "../selectors";
import { VerifyCodeEvent } from "../events";
import { trackEvent } from "@hopper-b2b/api";
import {
  AuthTrackingEvents,
  AuthType,
  MergeVerifyFailureEvent,
  MergeVerifySuccessEvent,
} from "../../types/tracking";

export const verifyMergeUserService = (
  context: AuthMachineContext,
  event: VerifyCodeEvent
) =>
  new Promise((resolve, reject) => {
    const authStateToken = getAuthStateToken({ context }) as string;

    createSession({
      authMethod: {
        authStateToken: authStateToken,
        code: event.code,
        AuthMethod: AuthMethodEnum.VerifyCode,
      },
      CreateSessionRequest: CreateSessionRequestEnum.Merge,
    })
      .then((res) => {
        if (
          res.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionFailed
        ) {
          trackFailure(event.auth_type);
          reject("CreateSessionResponseEnum.CreateSessionFailed");
        } else {
          trackSuccess(event.auth_type);
          resolve(res);
        }
      })
      .catch((error) => {
        trackFailure(event.auth_type);
        reject(error);
      });
  });

const trackFailure = (authType: AuthType) =>
  trackEvent({
    eventName: AuthTrackingEvents.MergeVerifyFailure,
    properties: {
      auth_type: authType,
    },
  } as MergeVerifyFailureEvent);

const trackSuccess = (authType: AuthType) => {
  trackEvent({
    eventName: AuthTrackingEvents.MergeVerifySuccess,
    properties: {
      auth_type: authType,
    },
  } as MergeVerifySuccessEvent);
};
