import React from 'react';

const Globe = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389953 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520203 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9973 6.61389 17.0482 4.32629 15.3609 2.63905C13.6737 0.951819 11.3861 0.00273039 9 0V0ZM16.2598 8.15625H13.1999C13.1574 6.12484 12.7028 4.12323 11.8637 2.27273C13.0493 2.77995 14.08 3.59103 14.8519 4.62406C15.6238 5.65709 16.1094 6.8755 16.2598 8.15625V8.15625ZM9 16.3125C8.03451 16.3125 6.66102 13.8192 6.4875 9.84375H11.5125C11.339 13.8192 9.9655 16.3125 9 16.3125ZM6.4875 8.15625C6.66102 4.18078 8.03451 1.6875 9 1.6875C9.9655 1.6875 11.339 4.18078 11.5125 8.15625H6.4875ZM6.13635 2.27279C5.29726 4.12327 4.84265 6.12486 4.80006 8.15625H1.74024C1.89062 6.87551 2.37626 5.65713 3.14813 4.62411C3.92 3.59109 4.95076 2.78002 6.13635 2.27279V2.27279ZM1.74024 9.84375H4.80006C4.84266 11.8751 5.29725 13.8767 6.13628 15.7271C4.95071 15.2199 3.91995 14.4089 3.1481 13.3759C2.37624 12.3428 1.89061 11.1245 1.74024 9.84375V9.84375ZM11.8637 15.7272C12.7028 13.8767 13.1574 11.8751 13.1999 9.84375H16.2598C16.1094 11.1245 15.6238 12.3429 14.8519 13.3759C14.0801 14.4089 13.0493 15.22 11.8637 15.7272V15.7272Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Globe;
