import {
  mobileModel,
  browserName,
  osName,
  osVersion,
  mobileVendor,
  browserVersion,
} from "react-device-detect";
import { getDeviceData } from "./getDeviceData";

const B2B_USER_LOCATION_KEY = "b2bportal-user-location";
const getPlatform = () => {
  // In the hopper env ios & android is for the app
  // if (isIOS) return "c1";
  // if (isAndroid) return "m1";
  return "w1";
};

export const getUserDeviceData = () => {
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const userLocation = localStorage.getItem(B2B_USER_LOCATION_KEY);

  return {
    browserName,
    browser_version: browserVersion,
    $os: osName,
    $os_version: osVersion,
    platform: getPlatform(),
    deviceType: getDeviceData().type,
    device: mobileModel,
    $brand: mobileVendor,
    $manufacturer: mobileVendor,
    $model: mobileModel,
    country: userLocation && regionNames ? regionNames.of?.(userLocation) : "",
  };
};
