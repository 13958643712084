import React from 'react';
import styled from 'styled-components';
import { GetIcon } from '@marketing-site/utils';
import { ILink } from '@marketing-site/types/shared.model';
import { theme } from '@marketing-site/styles';
import { setLink } from '@marketing-site/utils/helpers';

const LinkText = ({ text, isExternal, url }: ILink) => {
  return (
    <ActionLink
      href={setLink(url, isExternal)}
      target={isExternal ? '_blank' : 'self'}
    >
      <Text>{text}</Text>
      {GetIcon('chevron-right-heavy-blue')}
    </ActionLink>
  );
};

export default LinkText;

const ActionLink = styled.a`
  text-decoration: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  color: ${() => theme.colors.skyblue};

  display: flex;
  align-items: center;
`;

const Text = styled.span`
  margin-right: 7px;
`;
