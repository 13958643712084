import { VerificationMethod } from "@b2bportal/auth-api";
import { getChildState } from "@hopper-b2b/checkout";
import { ActionButton } from "@hopper-b2b/ui-core";
import { useI18nContext } from "@hopper-b2b/i18n";
import { B2BTextField } from "@hopper-b2b/ui";
import { FormEvent, useState } from "react";
import { AdditionalOptionSection, ResendSection } from "..";
import {
  AuthEvents,
  AuthMachineEventType,
  getEmail,
  getVerificationAlternatives,
  useAuthSelector,
  useAuthState,
} from "../../../../machine";
import { AuthType } from "../../../../types/tracking";
import "./styles.scss";

export const VerifyEmailModal = () => {
  const { t } = useI18nContext();
  const [state, send] = useAuthState<AuthEvents, unknown>();
  const childState = getChildState(state.value);
  const [code, setCode] = useState<string>("");

  const email = useAuthSelector(getEmail);
  const verificationAlternatives = useAuthSelector(getVerificationAlternatives);
  const hasAlternateMethod = verificationAlternatives?.redactedPhone;
  const redactedEmail = verificationAlternatives?.redactedEmail;

  const onContinue = (e: FormEvent<HTMLFormElement>) => {
    send({
      type: AuthMachineEventType.VERIFY_CODE,
      code,
      auth_type: AuthType.Email,
    });
    e.preventDefault();
  };

  const resendCode = () => {
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByEmail,
    });
  };

  const onAdditionalOptionClick = () => {
    send(AuthMachineEventType.OTHER_OPTIONS);
  };

  return childState === "additionalOptions" ? (
    <AdditionalOption />
  ) : (
    <>
      <div className="title-container">
        <h1 className="title">{t("verifyEmailTitle")}</h1>
        <p className="subtitle">
          {t("verifyCodeSubtitle", { emailOrPhone: email || redactedEmail })}
        </p>
      </div>
      <div className="verify-code-container">
        <form onSubmit={onContinue} noValidate>
          <B2BTextField
            onChange={(newCode: string) => setCode(newCode)}
            disabled={false}
            inputMode="numeric"
            className="verify-code-input-field"
            placeholder={t("verifyCodePlaceholder")}
            autoFocus
            allowInputSizeOverride
          />
          <ActionButton
            fullWidth
            className="continue-button"
            type="submit"
            message={t("verify")}
          />
        </form>
        <ResendSection onResend={resendCode} />
        <AdditionalOptionSection
          onClick={onAdditionalOptionClick}
          show={!!hasAlternateMethod}
        />
      </div>
    </>
  );
};

export const AdditionalOption = () => {
  const { t } = useI18nContext();
  const [, send] = useAuthState<AuthEvents, unknown>();

  const sendCode = () =>
    send({
      type: AuthMachineEventType.RESEND,
      verificationMethod: VerificationMethod.VerifyByPhone,
    });

  const redactedPhone = useAuthSelector(
    getVerificationAlternatives
  )?.redactedPhone;

  return (
    <div className="email-additional-option-content">
      <div className="title-container">
        <h1 className="title">{t("additionalModalTitle")}</h1>
        <p className="subtitle">{t("additionalModalSubtitle")}</p>
      </div>
      <div className="additional-method-card">
        <div className="labels">
          <p className="label">{t("phoneNumber")}</p>
          <p className="redacted">{redactedPhone}</p>
        </div>
        <ActionButton onClick={sendCode} message={t("sendCode")} />
      </div>
    </div>
  );
};
