import { theme } from '@marketing-site/styles';
import queryStringParser from 'query-string';
import { AppsFlyerLinkParams, ParseUrlParams } from './queryStringHelpers';

const availableLocales = ['en', 'fr', 'es'];

/**
 * Link function to take relative urls and attach a base url when needed.
 * Note: This may be obsolete in the future as more pages are built out and we
 * can just rely on relative links.
 *
 * @param path
 * @param isExternal
 * @returns updated url for a link to go to
 */
const setLink = (path: string, isExternal?: boolean): string => {
  // Regex to match everything after "http(s)://"
  const regex = /(?!https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?/;

  if (isExternal === true) {
    return `https://${path.match(regex)}`;
  } else {
    const baseUrl =
      typeof window !== 'undefined'
        ? window.location.origin
        : 'https://hopper.com';
    if (path === null) return '';
    if (path[0] === '/' && path.length > 1) {
      // add base url since it is a relative link.
      return `${baseUrl}${path}`;
    }
    return path;
  }
};

/**
 * Check if the destination is url
 * @param destination
 */
const isUrl = (destination: string): boolean => {
  const destinationLowercased = destination.toLowerCase();
  return (
    !destinationLowercased.startsWith('mailto:') &&
    !destinationLowercased.startsWith('tel:') &&
    !destinationLowercased.startsWith('javascript:')
  );
};

/**
 * Check if the destination is external
 * @param destination
 */
const isExternal = (destination: string): boolean => {
  const destinationLowercased = destination.toLowerCase();
  return (
    destinationLowercased.startsWith('http://') ||
    destinationLowercased.startsWith('https://')
  );
};

/**
 * Get AppsFlyer params from a url, ignoring af_web_dp. For now this is only
 * grabbing the parameters that include "af_".
 *
 * @param url
 * @returns object with AppsFlyer parameters.
 * ex: https://go.hopper.com/to/wallet?pid=website&c=hp&af_adset=takeover&af_channel=nuv1_takeover&install=true&af_web_dp=https://www.hopper.com/download/
 * returns: {af_adset: takeover, af_channel: nuv1_takeover}
 */
const getAppsFlyerParams = (url: string): AppsFlyerLinkParams => {
  const params: AppsFlyerLinkParams = queryStringParser.parse(url);
  return {
    af_adset: params.af_adset,
    af_channel: params.af_channel,
  };
};

/**
 * Check if the given color is light. Currently check for white or light gray.
 *
 * @param color
 * @returns boolean
 */
const isColorLight = (color: string): boolean => {
  return color === theme.colors.white || color === theme.colors.grey[100];
};

/**
 * Create a deep link url to go to the app, based on the location parameters
 * passed in. If there are no additional parameters given, default to defaultParams.
 *
 * Deep Link Docs: https://hopper-jira.atlassian.net/wiki/spaces/GROW/pages/1372225558/Deep+Links
 *
 * @param locationSearch additional parameters in current url
 * @param isMobile is the user mobile
 * @returns
 */
const generateDeepLinkUrl = (
  locationSearch: string,
  isMobile: boolean
): string => {
  const base = 'https://go.hopper.com/to/home';
  const defaultParams = '?pid=website&c=download';
  const siteId = isMobile ? '&af_siteid=mobile' : '&af_siteid=desktop';
  return `${base}${
    locationSearch.length === 0 ? defaultParams : locationSearch
  }${siteId}&install=true`;
};

/**
 * Get a locale from a pathname. If there is no locale shown in the pathname,
 * default to en. NOTE: Any future locales that are added need to be updated
 * here.
 *
 * @param pathname path of a page.
 * @returns locale in pathname
 */
const getLocaleFromPathname = (pathname: string): string => {
  const defaultLocale = 'en';
  const firstPath = pathname.split('/')[1];
  return availableLocales.includes(firstPath) ? firstPath : defaultLocale;
};

/**
 * Get the pathname without a locale. Since we know the path will begin with the
 * locale (if there is one) we can check if availableLocales has the current locale.
 * If it doesn't we assume there is no Locale (English default) and returh the path as is.
 *
 * @param pathname path of a page
 * @returns path without a locale
 */
const getPathnameWithoutLocale = (pathname: string): string => {
  const splitPath = pathname.split('/');
  if (availableLocales.includes(splitPath[1])) {
    splitPath.splice(1, 1);
    return splitPath.join('/');
  }
  return pathname;
};

/**
 * Sanitize a deep link url. If the deep link url is to the download page,
 * insert the user's locale so it doesn't bounce between languages.
 * query-string by default sorts the params, so we're setting to false.
 * By passing in a locale we can get around breaking the rules of hooks
 * https://reactjs.org/docs/hooks-rules.html
 *
 * @param url deeplink url
 * @returns
 */
const getSanitizedDeepLinkUrl = (url: string, currLocale: string): string => {
  const urlParams: ParseUrlParams = queryStringParser.parseUrl(url, {
    sort: false,
  });
  const afWebDp = urlParams.query.af_web_dp;
  if (afWebDp?.includes('download')) {
    // The deeplink is going to the download page, let's verify locale.
    if (currLocale !== 'en') {
      // location of /download, insert locale in since it's not English.
      const downloadIndex = afWebDp.indexOf('download');
      const downloadUrl =
        afWebDp.slice(0, downloadIndex) +
        currLocale +
        '/' +
        afWebDp.slice(downloadIndex);
      urlParams.query.af_web_dp = downloadUrl;
      return queryStringParser.stringifyUrl(urlParams, { sort: false });
    }
  }
  return url;
};

/**
 * Update a pathname with a new locale. We want to change the url so let's
 * first get the pathname without the locale, then attach the base path.
 * If the base path without a locale is the homepage /, just return the base locale.
 *
 * @param pathname path of a page
 * @param baseLocalePath base locale
 * @returns new pathname to navigate to based on locale
 */
const updatePathnameWithNewLocale = (
  pathname: string,
  baseLocalePath: string
): string => {
  const pathnameWithoutLocale = getPathnameWithoutLocale(pathname);
  // If base locale path is /, return pathname without locale
  if (baseLocalePath === '/') {
    return pathnameWithoutLocale;
  }
  return pathnameWithoutLocale === '/'
    ? baseLocalePath
    : baseLocalePath + pathnameWithoutLocale;
};

const removeTrailingSlash = (slug: string) => {
  return slug.replace(/\/+$/g, '');
};

const removeLeadingSlash = (slug: string) => {
  return slug.replace(/^\/+/g, '');
};

export {
  setLink,
  isExternal,
  isUrl,
  getAppsFlyerParams,
  isColorLight,
  generateDeepLinkUrl,
  getLocaleFromPathname,
  getSanitizedDeepLinkUrl,
  updatePathnameWithNewLocale,
  removeTrailingSlash,
  removeLeadingSlash,
};
