import { media, theme } from "@marketing-site/styles";
import React from "react";
import styled from "styled-components";
import { withFlare } from "@marketing-site/utils/hoc/withFlare";
import BunLegal from "@marketing-site/static/images/Bun-Legal.webp";

interface IHeroLegal {
  headline: string;
}
export const HeroLegal = (props: IHeroLegal) => {
  const HeadlineWithFlare = withFlare(Headline, {
    size: 35,
    color: theme.colors.skyblue,
    left: "-20%",
  });

  return (
    <HeroContainer>
      <Content>
        <HeadlineWithFlare>{props.headline}</HeadlineWithFlare>
      </Content>
      <Image src={BunLegal} />
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  display: flex;
  max-width: 1080px;
  flex-direction: row;
  margin: 40px auto;
  padding-right: 2rem;
`;

const Content = styled.div`
  flex: 1 0 60%;
  display: flex;
  flex-direction: column;
  align-self: center;

  ${media.phone_large`
    flex: 1 0 100%;
  `}

  > div, h1 {
    padding: 0 30px;
  }
`;

const Headline = styled.h1`
  width: 480px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 72px;
  line-height: 76px;
  ${media.tablet`
    font-size: 46px;
    line-height: 50px;
    width: 280px;
  `};
`;

const Image = styled.img`
  flex: 1 1 auto;
  object-fit: contain;
  max-height: 420px;
  width: 100%;
  height: auto;
  ${media.phone_large`
    display: none;
  `}
`;
