import {
  HeaderNavigationMenuItemType,
  HeaderNavigationType,
} from "@hopper-b2b/hopper-utils";
import { Header } from "./components/Header";
import { LanguageProvider } from "./components/LanguageContext";

export type RootProps = {
  headerNavigation?: HeaderNavigationType;
  showLanguageSelect?: boolean;
  onLanguageSelect?: () => void;
  rightActions?: HeaderNavigationMenuItemType[];
};

export const Root = ({ onLanguageSelect, ...rest }: RootProps) =>
  rest.showLanguageSelect ? (
    <LanguageProvider onChange={onLanguageSelect}>
      <Header {...rest} />
    </LanguageProvider>
  ) : (
    <Header {...rest} />
  );
