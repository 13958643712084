import React from 'react';
import styled from 'styled-components';
import { IDescriptionPoint } from '@marketing-site/types/description-points.model';
import { IGenericSection } from '@marketing-site/types/shared.model';
import GenericHeader from './GenericHeader';
import { RichTextToHtml } from '@marketing-site/utils';
import { media } from '@marketing-site/styles';

export const BasicSection = (props: IGenericSection<IDescriptionPoint>) => {
  return (
    <Container>
      <GenericHeader
        preheadline={props.preheadline}
        headline={props.headline}
        flareColor={props.flareColor}
        description={props.genericSectionDescription}
      />
      <List>
        {props.body?.items?.map((item, i) => {
          return (
            <Item key={item.headline + i}>
              {item.icon && (
                <Image
                  src={item.icon.url}
                  alt={item.icon.description}
                  title={item.icon.title}
                />
              )}
              <Headline>{item.headline}</Headline>
              <Body>{RichTextToHtml(item.description.json)}</Body>
            </Item>
          );
        })}
      </List>
    </Container>
  );
};

const Container = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  padding: 2rem 0;
`;

const List = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    flex: 1 0 21%;
    margin: 0 1rem;
  }
  margin: 0 auto;
  max-width: 1200px;
  ${media.tablet`
    flex-direction: column;
    align-content: center;
    > div {
      margin-bottom: 12px;
    }
  `};
`;

const Item = styled.div`
  text-align: center;
  max-width: 300px;
  margin-bottom: 24px;
`;

const Image = styled.img`
  width: 180px;
  height: 180px;
  ${media.tablet`
    width: 120px;
    height: 120px;
  `};
`;

const Headline = styled.h4`
  margin: 12px;
`;

const Body = styled.div`
  p {
    margin-top: 0;
  }
`;
