/*******************************************************
 * Normalizing place query to be used in the url. The old implementation was using a regex to remove caracters.
 * This was failing for cases where the place query was not in english and the customer swtiched to english, the place
 * could not be resolved anymore.
 *
 * Ex: "Miami États-Unis (Florida)" was converted to "miami_tatsunis_florida". When using french, all good, we were able to
 * resolve the place. But when switching to english, the place query was miami_tatsunis_florida and we could not resolve it.
 *
 * With the new implementation, we are using the normalize function to remove the diacritics first and keep the name as close as possible.
 *
 * Ex: "Miami États-Unis (Florida)" is converted to "miami_etats-unis_florida", which can be resolved in both languages.
 *******************************************************/
export const placeLabelToUrl = (label?: string) => {
  return label
    ? label
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .toLowerCase()
        .trim()
        .replace(/[^a-zA-Z0-9_\s\\-]/g, "")
        .replace(/\s+/g, "_")
    : "";
};

/**
 * We need this utility to handles cities with same name but different state/country.
 * E.g. Honolulu in HI and NC or London in Ontario and England.
 */
export const placeLabelAndSublabelToUrl = (
  label?: string,
  subLabel?: string
) => {
  if (subLabel) {
    return `${placeLabelToUrl(label)}_${placeLabelToUrl(subLabel)}`;
  }
  return placeLabelToUrl(label);
};

export const placeLabelToCity = (label?: string) => {
  return label ? label.split(",")[0] : "";
};

export const urlToPlaceQuery = (url?: string) => {
  return url
    ? url
        .toLowerCase()
        .trim()
        .replace(/[^a-zA-Z0-9_\\-]/g, "")
        .replace(/_/g, " ")
    : "";
};
