// Locale identifier
export const PATH_LOCALE = ':locale';

// Home page
export const PATH_HOME = '/';

// About page
export const PATH_ABOUT = '/about';

// Download page
export const PATH_DOWNLOAD = '/download';

// Not available yet page
export const PATH_NOT_AVAILABLE_YET = '/not-available-yet';

// Affiliates page
export const PATH_AFFILIATES = '/affiliates';

// Referral page
export const PATH_REFER = '/refer';

// Gift card pages
export const PATH_GIFT_CARD = '/gift-cards';

// Careers page
export const PATH_CAREERS = '/careers/*';

// Jobs page
export const PATH_JOBS = '/careers/jobs/*';

// Culture pages
export const PATH_CULTURE = '/culture/*';

// Legal pages
export const PATH_LEGAL = '/legal/*';

// Product pages
export const PRODUCT_PAGES = '/product/*';

// Travel pages
export const TRAVEL_PAGES = '/travel/*';

// Waterloo legacy redirects
export const PATH_W = '/w';
export const PATH_W_DEALS = '/deals';
export const PATH_W_REPORTS = '/reports';
export const PATH_W_TOOLS = '/tools';
export const PATH_W_FLIGHTS = '/flights';

// 410 catch-all route if page is gone
export const PATH_410 = '/410';

// 404 catch-all route if Leamouth fails
export const PATH_404 = '/404';

// Hopper Search api config
export const apiVersionPrefix = `/api/v0`;

export const fetchUserInfoPath = `${apiVersionPrefix}/user/getInfo`;
export const analyticsApiPrefix = `${apiVersionPrefix}/tracking`;
export const rewardsApiPrefix = `${apiVersionPrefix}/rewards`;

export const experimentsApiPrefix = `${apiVersionPrefix}/experiments`;
export const takeoversApiPrefix = `${apiVersionPrefix}/takeovers`;

export const apiConfig = {
  experimentsApiPrefix,
  rewardsApiPrefix,
  takeoversApiPrefix,
  analyticsApiPrefix,
  fetchUserInfoPath,
};
