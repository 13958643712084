import React from 'react';

const ChevronLeft = () => {
  return (
    <svg
      width="13"
      height="23"
      viewBox="0 0 13 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.72721 21.7723C10.4302 22.4752 11.5698 22.4752 12.2728 21.7723C12.9757 21.0694 12.9757 19.9297 12.2728 19.2267L9.72721 21.7723ZM2 11.4995L0.727209 10.2267C0.0242652 10.9297 0.0242651 12.0694 0.727209 12.7723L2 11.4995ZM12.2728 3.7723C12.9757 3.06936 12.9757 1.92966 12.2728 1.22672C11.5699 0.523777 10.4302 0.523777 9.72721 1.22672L12.2728 3.7723ZM12.2728 19.2267L3.27279 10.2267L0.727209 12.7723L9.72721 21.7723L12.2728 19.2267ZM3.27279 12.7723L12.2728 3.7723L9.72721 1.22672L0.727209 10.2267L3.27279 12.7723Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default ChevronLeft;
