import media from "@marketing-site/styles/media";
import theme from "@marketing-site/styles/theme";
import { IStyledIsSelectedNum } from "@marketing-site/types";
import { IContentCollection, IImage } from "@marketing-site/types/shared.model";
import { GetIcon } from "@marketing-site/utils/";
import MobileStepper from "@mui/material/MobileStepper";
import { styled as MUIstyled } from "@mui/material/styles";
import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";
import styled from "styled-components";

interface CarouselProps {
  headline: string;
  subheadline: string;
  itemsCollection: IContentCollection<IImage>;
  sectionId: string;
}
const Carousel = (props: CarouselProps) => {
  const [itemSelected, setItemSelected] = useState(0);
  const totalItems = props.itemsCollection.items.length;

  const ItemId = "carousel-item-";

  const handleArrowClick = (side: string) => {
    if (side === "left") {
      setItemSelected(itemSelected === 0 ? totalItems - 1 : itemSelected - 1);
    } else {
      // right
      setItemSelected(itemSelected === totalItems - 1 ? 0 : itemSelected + 1);
    }
  };

  const onSwipeHandler = useSwipeable({
    onSwipedRight: () =>
      setItemSelected(itemSelected === 0 ? totalItems - 1 : itemSelected - 1),
    onSwipedLeft: () =>
      setItemSelected(itemSelected === totalItems - 1 ? 0 : itemSelected + 1),
  });

  return (
    <CarouselWrapper>
      <Subheadline>{props.subheadline}</Subheadline>
      <Headline>{props.headline}</Headline>
      <CarouselContainer selected={itemSelected} {...onSwipeHandler}>
        {props.itemsCollection.items.map((item: any, i: number) => {
          return (
            <CarouselItem
              tabIndex={0}
              src={item.url}
              alt={item.title}
              key={`carousel-${i}`}
              id={`${ItemId}${i}`}
            />
          );
        })}
      </CarouselContainer>
      <CarouselNav>
        <CustomMobileStepper
          steps={props.itemsCollection.items.length}
          position="static"
          activeStep={itemSelected}
          backButton={
            <CarouselSelector onClick={() => handleArrowClick("left")}>
              {GetIcon("chevron-left")}
            </CarouselSelector>
          }
          nextButton={
            <CarouselSelector onClick={() => handleArrowClick("right")}>
              {GetIcon("chevron-right")}
            </CarouselSelector>
          }
        />
      </CarouselNav>
    </CarouselWrapper>
  );
};

export default Carousel;

const CarouselWrapper = styled.div`
  margin-top: 24px;
  width: 94%;
  max-width: 1080px;
  margin: 0 auto;
  padding-bottom: 36px;
  padding-top: 22px;
`;

const CustomMobileStepper = styled(MUIstyled(MobileStepper)({}))`
  .MuiMobileStepper-dotActive {
    background-color: ${theme.colors.grey[700]};
  }

  .MuiMobileStepper-dots {
    margin: 8px 8px 10px 8px;
  }
`;

const Subheadline = styled.h4`
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => p.theme.colors.grey[400]};
  margin-bottom: 0;
  margin-top: 0;
`;

const Headline = styled.h3`
  font-weight: 800;
  font-size: 28px;
  color: ${(p) => p.theme.colors.grey[800]};
  margin-top: 0;
  margin-bottom: 0;
`;

const CarouselContainer = styled.div<IStyledIsSelectedNum>`
  margin: 0 auto;
  width: 104%;
  ${media.desktop_up`
    justify-content: center;
  `};
  display: flex;
  flex-direction: row;
  overflow: visible;
  // TODO: There's got to be a better way of doing this.
  margin-left: ${(p: IStyledIsSelectedNum) =>
    p.selected === 0 ? 20 : p.selected * -284}px;
  transition: margin-left 0.7s;
`;

const CarouselItem = styled.img`
  width: 80%;
  max-width: 330px;
`;

const CarouselNav = styled.div`
  margin: 0 auto;
  width: fit-content;
  display: flex;
  gap: 12px;
  align-items: center;
  ${media.desktop_up`
  display: none;
  `}

  .MuiMobileStepper-root {
    background-color: transparent;
  }
`;

const CarouselSelector = styled.div``;
