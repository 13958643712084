import {
  AuthMethodEnum,
  CreateSessionRequestEnum,
  CreateSessionResponseEnum,
} from "@b2bportal/auth-api";
import { createSession } from "../../api";
import { AuthMachineContext } from "../context";
import { GoogleSignInEvent } from "../events";
import { trackEvent } from "@hopper-b2b/api";
import { AuthTrackingEvents, SsoFailureEvent } from "../../types/tracking";

export const createGoogleIdentitySessionService = (
  context: AuthMachineContext,
  event: GoogleSignInEvent
) =>
  new Promise((resolve, reject) => {
    createSession({
      authMethod: {
        idToken: event.idToken,
        AuthMethod: AuthMethodEnum.GoogleIdentityBased,
      },
      CreateSessionRequest: CreateSessionRequestEnum.SignIn,
    })
      .then((res) => {
        if (
          res.CreateSessionResponse ===
          CreateSessionResponseEnum.CreateSessionFailed
        ) {
          trackFailure();
          reject("CreateSessionResponseEnum.CreateSessionFailed");
        } else {
          resolve(res);
        }
      })
      .catch((error) => {
        trackFailure();
        reject(error);
      });
  });

const trackFailure = () =>
  trackEvent({
    eventName: AuthTrackingEvents.SsoFailure,
    properties: {},
  } as SsoFailureEvent);
