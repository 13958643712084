import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@marketing-site/api/urbana/v2';

type ContentfulResponse = {
  data: {
    pageCollection: {
      items: any[];
    };
    associatedLocales?: {
      items: [{ locale: string }];
    };
  };
};

const path = '/api/v1/contentful';
export const contentfulApi = createApi({
  reducerPath: 'contentfulApi',
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    fetchContent: build.query<any, any>({
      query: (body) => ({
        url: path,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: ContentfulResponse, meta) => {
        if (response.data.associatedLocales) {
          // Some pages will have a list of associated locales, transform them to be an array of strings
          // see the legal page query for reference.
          response.data.pageCollection.items[0]['associatedLocales'] =
            response.data.associatedLocales.items.reduce((prev, curr) => {
              return [...prev, curr.locale];
            }, []);
        }
        return { ...response.data.pageCollection.items[0], ...meta };
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        queryFulfilled
          .then((res) => {
            return { ...res };
          })
          .catch((err) => {
            return new Error(err?.data?.error || 'Unexpected response');
          });
      },
    }),
  }),
});

export const { useFetchContentQuery } = contentfulApi;
