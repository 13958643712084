import { createMachine } from "xstate";
import { initialAuthContext } from "./context";
import { AuthActionTypes, AuthActions } from "./actions";
import { AuthServiceTypes, AuthServices } from "./services";
import { AuthGuardTypes, AuthGuards } from "./guards";
import { AuthMachineEventType } from "./events";

const machineId = "auth-machine";

export const authMachine = createMachine(
  {
    id: machineId,
    initial: "landing",
    states: {
      landing: {
        on: {
          SEND_CODE: "phoneLogin",
          GOOGLE_SIGN_IN: "googleLogin",
          SET_PHONE_NUMBER: { actions: AuthActionTypes.setPhoneNumber },
        },
      },

      googleLogin: {
        entry: AuthActionTypes.setGoogleAuthType,
        invoke: {
          src: AuthServiceTypes.createGoogleIdentitySessionService,
          onDone: [
            {
              cond: AuthGuardTypes.isVerifySuccess,
              target: "#auth-machine.success",
            },
            {
              target: "#auth-machine.finalize",
              actions: AuthActionTypes.setVerifyCodeFinalizeResponse,
            },
          ],
          onError: {
            target: "#auth-machine.error",
            actions: AuthActionTypes.setServiceOnError,
          },
        },
      },
      phoneLogin: {
        initial: "sendCodeToPhone",
        entry: AuthActionTypes.setPhoneAuthType,
        states: {
          sendCodeToPhone: {
            invoke: {
              src: AuthServiceTypes.sendCodeToPhoneService,
              onDone: {
                target: "verifyCodeForm",
                actions: AuthActionTypes.setSendCodeResponse,
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          resend: {
            invoke: {
              src: AuthServiceTypes.resendCodeService,
              onDone: {
                target: "verifyCodeForm",
                actions: AuthActionTypes.setResendCodeResponse,
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          verifyCodeForm: {
            on: {
              VERIFY_CODE: "verify",
              OTHER_OPTIONS: "additionalOptions",
              RESEND: "resend",
            },
          },
          additionalOptions: {
            on: {
              [AuthMachineEventType.RESEND]: {
                target: "#auth-machine.emailLogin.resend",
              },
            },
          },
          verify: {
            invoke: {
              src: AuthServiceTypes.verifyCodeService,
              onDone: [
                {
                  cond: AuthGuardTypes.isVerifySuccess,
                  target: "#auth-machine.success",
                },
                {
                  target: "#auth-machine.finalize",
                  actions: AuthActionTypes.setVerifyCodeFinalizeResponse,
                },
              ],
              onError: {
                target: "verifyCodeForm",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
        },
      },
      emailLogin: {
        initial: "sendCodeToEmail",
        entry: AuthActionTypes.setEmailAuthType,
        states: {
          sendCodeToEmail: {
            invoke: {
              src: AuthServiceTypes.sendCodeToEmailService,
              onDone: {
                target: "verifyCodeForm",
                actions: AuthActionTypes.setSendCodeResponse,
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          verifyCodeForm: {
            on: {
              VERIFY_CODE: "verify",
              OTHER_OPTIONS: "additionalOptions",
              RESEND: "resend",
            },
          },
          additionalOptions: {
            on: {
              [AuthMachineEventType.RESEND]: {
                target: "#auth-machine.phoneLogin.resend",
              },
            },
          },
          verify: {
            invoke: {
              src: AuthServiceTypes.verifyCodeService,
              onDone: [
                {
                  cond: AuthGuardTypes.isVerifySuccess,
                  target: "#auth-machine.success",
                },
                {
                  target: "#auth-machine.finalize",
                  actions: AuthActionTypes.setVerifyCodeFinalizeResponse,
                },
              ],
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          resend: {
            invoke: {
              src: AuthServiceTypes.resendCodeService,
              onDone: {
                target: "verifyCodeForm",
                actions: AuthActionTypes.setResendCodeResponse,
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
        },
      },
      finalize: {
        initial: "form",
        states: {
          form: {
            on: {
              FINALIZE_USER: "finalize",
            },
          },
          finalize: {
            invoke: {
              src: AuthServiceTypes.finalizeUserService,
              onDone: [
                {
                  cond: AuthGuardTypes.isFinalizeMatchingAccount,
                  target: "#auth-machine.merge",
                  actions: AuthActionTypes.setFinalizeMatchingAccountResponse,
                },
                {
                  target: "#auth-machine.success",
                },
              ],
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
        },
      },
      merge: {
        initial: "idle",
        states: {
          idle: {
            on: {
              SEND_CODE: "sendCode",
              TRY_AGAIN: {
                target: "#auth-machine.landing",
                actions: AuthActionTypes.resetAuthContext,
              },
            },
          },
          sendCode: {
            invoke: {
              src: AuthServiceTypes.sendCodeForMergeService,
              onDone: {
                actions: AuthActionTypes.setSendCodeResponse,
                target: "verifyForm",
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          verifyForm: {
            on: { VERIFY_CODE: "verify", RESEND: "resend" },
          },
          verify: {
            invoke: {
              src: AuthServiceTypes.verifyMergeUserService,
              onDone: {
                cond: AuthGuardTypes.isVerifySuccess,
                target: "#auth-machine.success",
              },
              onError: {
                target: "#auth-machine.error",
                actions: AuthActionTypes.setServiceOnError,
              },
            },
          },
          resend: {
            // TODO: Figure out flow
          },
        },
      },
      success: {
        entry: AuthActionTypes.setPreviousUserTracking,
        type: "final",
        on: {
          ERROR: "#auth-machine.error",
        },
      },
      error: {
        on: {
          TRY_AGAIN: {
            target: "#auth-machine.landing",
            actions: AuthActionTypes.resetAuthContext,
          },
        },
      },
    },
    context: initialAuthContext,
  },
  {
    services: AuthServices,
    actions: AuthActions,
    guards: AuthGuards,
  }
);
